import React, { useState } from 'react'
import { Stack, Typography, IconButton, TextField } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'

import { Role, useAuthState } from '@firstbase/context/auth/AuthProvider'
import { UPDATE_CATEGORY_REFRESH_RATE } from '@firstbase/data/Organization/mutations'
import { GET_ORGANIZATION_CATEGORIES_WITH_REFRESH_MONTHS } from '@firstbase/data/Organization/queries'
import {
  updateCategoryRefreshRate as updateCategoryRefreshRateType,
  updateCategoryRefreshRateVariables,
} from '@firstbase/data/Organization/__generated__/updateCategoryRefreshRate'

interface OwnProps {
  categoryCode: string
  initialValue: number
  orgSlug: string
}

const ClientCategoryRefreshRate = ({
  categoryCode,
  initialValue,
  orgSlug,
}: OwnProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const [editing, setEditing] = useState(false)
  const [refreshRate, setRefreshRate] = useState(initialValue)
  const { hasRole } = useAuthState()

  const onError = () => {
    enqueueSnackbar(
      `Failed to update refresh rate for ${categoryCode}.  Please refresh the window and try again or share this error with the team.`
    )
  }
  const onCompleted = () => {
    enqueueSnackbar(`Successfully updated refresh rate for ${categoryCode}`)
    setEditing(false)
  }
  const [updateCategoryRefreshRate, { loading }] = useMutation<
    updateCategoryRefreshRateType,
    updateCategoryRefreshRateVariables
  >(UPDATE_CATEGORY_REFRESH_RATE, {
    onError,
    onCompleted,
    refetchQueries: [GET_ORGANIZATION_CATEGORIES_WITH_REFRESH_MONTHS],
  })

  const handleRefreshRateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      target: { value },
    } = event

    const newRate = Number(value) > 1 ? Number(value) : 1
    setRefreshRate(newRate)
  }

  const onClickSaveRefreshRate = () => {
    updateCategoryRefreshRate({
      variables: {
        slug: orgSlug,
        updateCategoryRefreshMonths: {
          category: categoryCode,
          refreshMonths: refreshRate,
        },
      },
    })
  }

  const renderCellContent = () => {
    if (!hasRole(Role.Admin)) return <Typography>{refreshRate}</Typography>

    if (!editing)
      return (
        <>
          <Typography>{refreshRate}</Typography>
          <IconButton
            aria-label="edit refresh rate"
            onClick={() => setEditing(true)}
          >
            <EditIcon />
          </IconButton>
        </>
      )

    return (
      <>
        <TextField
          label="Refresh rates (in months)"
          size="small"
          onChange={handleRefreshRateChange}
          type="number"
          value={refreshRate}
          disabled={loading}
        />
        <IconButton
          aria-label="save refresh rate"
          disabled={loading}
          onClick={onClickSaveRefreshRate}
        >
          <CheckIcon />
        </IconButton>
        <IconButton
          aria-label="close edit refresh rate"
          disabled={loading}
          onClick={() => {
            setRefreshRate(initialValue)
            setEditing(false)
          }}
        >
          <CloseIcon />
        </IconButton>
      </>
    )
  }

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      gap={1}
    >
      {renderCellContent()}
    </Stack>
  )
}

export default ClientCategoryRefreshRate
