import { gql } from '@apollo/client'

export const WAREHOUSE = gql`
  fragment Warehouse on WarehousesDTO {
    id
    name
    code
    active
    address {
      id
      countryCode
    }
  }
`
