import { Box, Container, Skeleton, Typography } from '@mui/material'
import React, { useEffect } from 'react'

import Breadcrumbs from '@atoms/Breadcrumbs'
import TitleSection from './TitleSection'

export type Props = {
  breadcrumb?: boolean
  children: React.ReactNode | string
  title?: string | JSX.Element
  subtitle?: string | JSX.Element
  isLoading?: boolean
  error?: Error
  titleAction?: () => JSX.Element | null
}

function Page({
  breadcrumb,
  children,
  title,
  subtitle,
  isLoading,
  titleAction,
  error,
}: Props) {
  const renderChildren = () => {
    if (isLoading) {
      return (
        <Skeleton
          data-testid="page-content-loading"
          variant="rectangular"
          height="calc(100vh - 16rem)"
        />
      )
    }

    return children
  }

  useEffect(() => {
    if (error) throw error
  }, [error])

  return (
    <Container disableGutters maxWidth={false}>
      {breadcrumb && <Breadcrumbs />}
      <Box
        sx={{
          paddingTop: breadcrumb ? 1 : 6,
          paddingBottom: 2,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography variant="h4" role="header" data-testid="page-header">
            {!title && isLoading ? (
              <Skeleton data-testid="page-header-loading" width="12rem" />
            ) : (
              title
            )}
          </Typography>
          <Typography variant={typeof subtitle === 'string' ? 'body1' : 'h6'}>
            {subtitle !== undefined && isLoading ? (
              <Skeleton data-testid="page-header-loading" width="8rem" />
            ) : (
              subtitle
            )}
          </Typography>
        </Box>
        {titleAction && titleAction()}
      </Box>
      {renderChildren()}
    </Container>
  )
}

export default Page

Page.TitleSection = TitleSection
