import { Chip as MUIChip, ChipProps } from '@mui/material'
import { sentenceCase } from 'change-case'
import React from 'react'

export type OwnProps = Omit<ChipProps, 'children'> & { children: string }

const Chip = (props: OwnProps) => {
  const { children, ...chipProps } = props

  return children ? (
    <MUIChip
      {...(chipProps as ChipProps)}
      sx={{ whiteSpace: 'nowrap', ...(chipProps.sx || {}) }}
      label={sentenceCase(children)}
    />
  ) : null
}

export default Chip
