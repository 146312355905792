import React from 'react'
import { useQuery } from '@apollo/client'
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from '@mui/material'

import { GET_ALL_VENDORS } from '@firstbase/data/Vendor/queries'
import {
  getAllVendors,
  getAllVendorsVariables,
} from '@firstbase/data/Vendor/__generated__/getAllVendors'
import { SortDirection } from '@globalTypes'

// lazy way of accepting the fields prop from react-hook-form
interface OwnProps<T extends { value: string }> {
  field: T
}

// Define a smaller CircularProgress component for the icon
const SmallCircularProgress = () => (
  <CircularProgress size={20} sx={{ marginRight: '1rem' }} />
)

/**
 * Could potentially consolidate with VendorSelect, but no plans
 * as of this writing, given other priorities.
 */
export const BrandSelect = <T extends { value: string }>({
  field,
}: OwnProps<T>) => {
  const { data, loading } = useQuery<getAllVendors, getAllVendorsVariables>(
    GET_ALL_VENDORS,
    {
      variables: {
        pageNumber: 1,
        pageSize: 1000,
        sort: [
          {
            direction: SortDirection.ASC,
            field: 'name',
          },
        ],
      },
    }
  )

  return (
    <FormControl fullWidth>
      <InputLabel id="brand-label">Brand</InputLabel>
      <Select
        label="Brand"
        labelId="brand-label"
        disabled={loading}
        IconComponent={loading ? SmallCircularProgress : undefined}
        {...field}
      >
        {data?.getAllVendors.data.map((vendor) => (
          <MenuItem key={vendor.id} value={vendor.id}>
            {vendor.name}
          </MenuItem>
        )) ?? null}
      </Select>
    </FormControl>
  )
}
