import { useQuery } from '@apollo/client'
import Page from '@firstbase/components/atoms/Page'
import { useBreadcrumbs } from '@firstbase/context/breadcrumbs/BreadcrumbsProvider'
import { GET_ORGANIZATION_BY_ID } from '@firstbase/data/Organization/queries'
import {
  getOrganizationById,
  getOrganizationByIdVariables,
} from '@firstbase/data/Organization/__generated__/getOrganizationById'
import { OrderPageParams } from '@firstbase/types/Pages'
import { sentenceCase } from 'change-case'
import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import EditReturnOrder from './EditReturnOrder'
import OrderReturnDetail from './OrderReturnDetail'
import ReplacementDetail from './ReplacementDetail'
import useOrderData from './hooks/useOrderData'
import { formatDate } from '@firstbase/utils/datetime/format'

const ORDER_TYPE = {
  ORDER: 'ORDER',
  RETURN: 'RETURN',
  REPLACEMENT: 'REPLACEMENT',
}

const Order = () => {
  const { orderId, clientId } = useParams<OrderPageParams>()
  const { setBreadcrumbs } = useBreadcrumbs()
  const { data: orgData, loading: loadingOrg } = useQuery<
    getOrganizationById,
    getOrganizationByIdVariables
  >(GET_ORGANIZATION_BY_ID, {
    variables: {
      orgId: clientId,
    },
  })

  const { orderData, loading: loadingOrder } = useOrderData(orderId, clientId)
  const orderType = useMemo(() => {
    if (!orderData) return undefined

    if ('orderType' in orderData) return orderData.orderType
    return ORDER_TYPE.REPLACEMENT as keyof typeof ORDER_TYPE
  }, [orderData])

  // create the breadcrumbs
  useEffect(
    () =>
      setBreadcrumbs([
        { label: 'Clients', pathId: 'clients' },
        {
          isLoading: loadingOrg,
          label: orgData?.getOrganizationById.name,
          pathId: 'client',
          searchParams: { tab: 'people' },
        },
        {
          isLoading: loadingOrder,
          label: `${orderData?.person.forename} ${orderData?.person.surname}`,
          pathId: 'person',
          searchParams: { tab: 'orders' },
        },
        {
          isLoading: loadingOrder,
          label: `${sentenceCase(orderType || '')} ID: ${orderId}`,
        },
      ]),
    [
      loadingOrder,
      loadingOrg,
      orderType,
      orderData?.person.forename,
      orderData?.person.surname,
      orderId,
      orgData?.getOrganizationById.name,
      setBreadcrumbs,
    ]
  )

  const pageTitle = () => {
    if (loadingOrder) return undefined

    const orderDate = formatDate(orderData?.createdAt)

    if (orderType === ORDER_TYPE.RETURN) {
      return `Return started on ${orderDate}`
    }
    if (orderType === ORDER_TYPE.REPLACEMENT) {
      return `Replacement started on ${orderDate}`
    }
    return `Order from ${orderDate}`
  }

  const renderContent = () => {
    if (loadingOrder || !orderData || !orgData) return null

    if (orderType === ORDER_TYPE.REPLACEMENT && 'order' in orderData) {
      return (
        <ReplacementDetail organization={orgData} replacement={orderData} />
      )
    }
    if ('placedBy' in orderData) {
      return <OrderReturnDetail organization={orgData} order={orderData} />
    }
  }

  const renderEditReturn = () => {
    if (loadingOrder || !orderData || !orgData) return null

    const isReturnOrder =
      orderData &&
      'orderType' in orderData &&
      orderData.orderType === ORDER_TYPE.RETURN
    if (isReturnOrder) {
      return (
        <EditReturnOrder returnOrder={orderData} person={orderData.person} />
      )
    }
    return null
  }

  return (
    <Page
      breadcrumb
      title={pageTitle()}
      isLoading={loadingOrder}
      titleAction={renderEditReturn}
    >
      {renderContent()}
    </Page>
  )
}

export default Order
