import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  Box,
  Card,
  SelectChangeEvent,
  TableContainer,
  Typography,
} from '@mui/material'
import FLAGS from '@firstbase/constants/featureFlags'
import EditOverrideServiceFees from './OverrideServiceFeesForm'
import EditServiceFeesTable from './ServiceFeesTableForm'
import EditOverrideServiceFeesTable from './OverrideServiceFeesTableForm'
import {
  isOverridden,
  isOverriddenWithDifferentPrices,
} from '../../settingsUtils'
import {
  getServiceFeeTypes,
  useSupportedCurrencies,
  useSupportedRegions,
} from '@firstbase/utils/contractUtils'
import { FeeType, FeesRow } from '@firstbase/types/Fee'
import { PercentageInput } from '@firstbase/components/atoms/PercentageInput'

import CurrencyCodeSelect from './CurrencyCodeSelect'

type OwnProps = {
  formCurrenciesByRegion: Record<string, string>
  getFeeForRegionCode: (regionCode: string, feeType: FeeType) => number | string
  updateFeePrice: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    regionCode: string,
    feeType: FeeType,
    defaultValue?: any,
    currencyCode?: string
  ) => void
  regionIsSupported: (regionCode: string) => boolean
  formFeesByRegionByType: Record<string, Record<FeeType, FeesRow>>
  contractVersion?: number
  updateAllOverrideCurrencyCode: (currencyCode: string | undefined) => void
  serviceFeeDiscount: number | null
  updateServiceFeeDiscount: (discount: number | null) => void
}

function ServiceFeesForm({
  formCurrenciesByRegion,
  getFeeForRegionCode,
  updateFeePrice,
  regionIsSupported,
  formFeesByRegionByType,
  contractVersion,
  updateAllOverrideCurrencyCode,
  serviceFeeDiscount,
  updateServiceFeeDiscount,
}: OwnProps) {
  const flags = useFlags()
  const overrideDifferentPricesFlag =
    flags[FLAGS.SE_3532_OVERRIDE_DIFFERENT_PRICES]
  const discountsFlag = flags[FLAGS.DISCOUNTS_MGMT]

  const supportedRegions = useSupportedRegions()
  const supportedCurrencies = useSupportedCurrencies()
  const regionsToOverride = useMemo(
    () => Object.keys(supportedRegions).filter(regionIsSupported),
    [supportedRegions, regionIsSupported]
  )
  const onDemandFees = useMemo(
    () =>
      Object.values(formFeesByRegionByType).map((regionFees) =>
        Object.values(regionFees).filter(
          ({ billingType }) => billingType === 'OnDemand'
        )
      ),
    [formFeesByRegionByType]
  )
  const orgServiceFees = useMemo(
    () => [...new Set([...getServiceFeeTypes(contractVersion)])],
    [contractVersion]
  )

  const overrideCurrencies = useMemo(
    () => [...Object.keys(supportedCurrencies)].slice(0, -1),
    [supportedCurrencies]
  )
  const [override, setOverride] = useState<null | {
    fees: Record<string, string>
  }>(() => isOverridden(onDemandFees))

  const [overrideWithDifferentPrices, setOverrideWithDifferentPrices] =
    useState(() =>
      overrideDifferentPricesFlag
        ? isOverriddenWithDifferentPrices(onDemandFees, formCurrenciesByRegion)
        : false
    )

  // Wrapper to help with persistence
  // Without this, the previously chosen currency code would not upon revisiting view
  const getInitialOverrideCurrencyCode = () => {
    if (
      !isOverridden(onDemandFees) &&
      !isOverriddenWithDifferentPrices(onDemandFees, formCurrenciesByRegion)
    )
      return overrideCurrencies[0]

    if (!onDemandFees.length) return overrideCurrencies[0]

    // Get the first fee's currency code
    const [{ currencyCode }] = onDemandFees.flat()
    return currencyCode
  }
  const [overrideCurrencyCode, setOverrideCurrencyCode] = useState(
    getInitialOverrideCurrencyCode()
  )

  const updateServiceFees = useCallback(
    (
      feePrice: string | null,
      currencyCode?: string,
      singleFeeType?: FeeType
    ) => {
      const feeTypes = singleFeeType ? [singleFeeType] : orgServiceFees

      feeTypes.forEach((feeType) =>
        regionsToOverride.forEach((region) =>
          updateFeePrice(
            {
              target: { value: feePrice },
            } as React.FocusEvent<HTMLInputElement>,
            region,
            feeType,
            null,
            currencyCode || formCurrenciesByRegion[region]
          )
        )
      )
    },
    [formCurrenciesByRegion, regionsToOverride, updateFeePrice, orgServiceFees]
  )

  useEffect(() => {
    updateAllOverrideCurrencyCode(
      overrideWithDifferentPrices ? overrideCurrencyCode : undefined
    )
  }, [
    overrideWithDifferentPrices,
    overrideCurrencyCode,
    updateAllOverrideCurrencyCode,
  ])

  // when supported currencies changes, retrigger override
  // to handle cases where region is added or removed from support
  // after overriding
  useEffect(() => {
    if (override) {
      orgServiceFees?.forEach((feeType) =>
        updateServiceFees(
          override.fees[feeType]?.toString(),
          overrideCurrencyCode,
          feeType
        )
      )
    }
  }, [override, overrideCurrencyCode, updateServiceFees, orgServiceFees])

  const updateCurrencyCode = (e: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = e

    setOverrideCurrencyCode(value)
  }

  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: 500, mb: 2 }}>
        Service Fees
      </Typography>
      {discountsFlag ? (
        <Box
          sx={{
            marginBottom: '1rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
          }}
        >
          <PercentageInput
            label="Action Volume Discount Percentage"
            value={serviceFeeDiscount || ''}
            onChange={updateServiceFeeDiscount}
            sx={{ width: '350px' }}
          />
          <Typography sx={{ fontSize: '0.75rem' }}>
            Note: This discount will be applied to all fees below and will be
            reflected in the service fees table on the previous page after you
            save this form.
          </Typography>
        </Box>
      ) : null}
      <EditOverrideServiceFees
        overrideCurrencies={overrideCurrencies}
        override={override}
        setOverride={setOverride}
        updateServiceFees={updateServiceFees}
        orgServiceFees={orgServiceFees}
        overrideWithDifferentPrices={overrideWithDifferentPrices}
        setOverrideWithDifferentPrices={setOverrideWithDifferentPrices}
      />
      {override || overrideWithDifferentPrices ? (
        <CurrencyCodeSelect
          onChange={updateCurrencyCode}
          options={overrideCurrencies}
          value={overrideCurrencyCode}
        />
      ) : null}
      <Card variant="outlined" sx={{ marginBottom: '48px' }}>
        <TableContainer sx={{ marginBottom: '48px' }}>
          {override ? (
            <EditOverrideServiceFeesTable
              override={override}
              setOverride={setOverride}
              updateServiceFees={updateServiceFees}
              orgServiceFees={orgServiceFees}
              currencyCode={overrideCurrencyCode}
            />
          ) : null}
          <div style={{ display: override ? 'none' : 'block' }}>
            <EditServiceFeesTable
              getFeeForRegionCode={getFeeForRegionCode}
              updateFeePrice={updateFeePrice}
              regionIsSupported={regionIsSupported}
              formCurrenciesByRegion={formCurrenciesByRegion}
              orgServiceFees={orgServiceFees}
              overrideCurrencyCode={
                overrideWithDifferentPrices ? overrideCurrencyCode : null
              }
            />
          </div>
        </TableContainer>
      </Card>
    </>
  )
}

export default ServiceFeesForm
