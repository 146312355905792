import React, { ReactElement, SetStateAction, useState } from 'react'
import papa from 'papaparse'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Stack } from '@mui/material'
import { Box } from '@mui/system'

import Page from '@atoms/Page'
import Loader from '@firstbase/components/atoms/Loader'

type OwnProps = {
  children?: ReactElement
  dataParser: (data: any) => any
  templateHref?: string
  setData: SetStateAction<any>
  uploadTitle: string
  uploadSubtitle: string | JSX.Element
  loading?: boolean
  disabled?: boolean
  displayRemoveBtn?: boolean
}

const UploadStep = ({
  children,
  dataParser,
  templateHref,
  setData,
  uploadTitle,
  uploadSubtitle,
  loading = false,
  disabled = false,
  displayRemoveBtn = false,
}: OwnProps) => {
  const [fileName, setFileName] = useState<string>('')

  const handleFile = (e: any) => {
    if (e.target.files.length > 0) {
      setFileName(e.target.files[0].name)
      papa.parse(e.target.files[0], {
        header: true,
        skipEmptyLines: 'greedy',
        transformHeader: (h) => h.trim(),
        complete: (r) => {
          setData(dataParser(r.data))
        },
      })
    }

    return null
  }

  return (
    <>
      {loading && <Loader />}
      <Page.TitleSection
        sectionTitle={uploadTitle}
        sectionSubtitle={uploadSubtitle}
      />
      <Stack sx={{ width: 'fit-content' }}>
        {templateHref ? (
          <Button sx={{ mb: 2, width: 'fit-content' }} href={templateHref}>
            Download import template
          </Button>
        ) : null}
        {children}
        <label htmlFor="contained-button-file">
          <input
            data-testid="upload-input"
            accept=".csv"
            style={{ display: 'none' }}
            id="contained-button-file"
            multiple
            onChange={handleFile}
            type="file"
          />
          <Stack alignItems="center" direction="row">
            <Button variant="contained" component="span" disabled={disabled}>
              Choose file...
            </Button>
            <Box sx={{ marginLeft: 1 }}>{fileName}</Box>
            {fileName && displayRemoveBtn && (
              <Box>
                <IconButton
                  aria-label="close edit refresh rate"
                  disabled={loading}
                  onClick={() => {
                    setFileName('')
                    setData(null)
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            )}
          </Stack>
        </label>
      </Stack>
    </>
  )
}

export default UploadStep
