import FLAGS from '@firstbase/constants/featureFlags'
import { Box, CircularProgress } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'

function Loader() {
  const flags = useFlags()

  return (
    <Box
      sx={{
        display: 'flex',
        zIndex: 10000,
        backgroundColor: 'rgba(0,0,0,0.25)',
        top: 0,
        position: 'fixed',
        left: 0,
        width: '100vw',
        height: '100vh',
      }}
      data-testid="loader"
    >
      {flags[FLAGS.SE_3028_CHRISTMAS] ? (
        <svg width="50vh" height="50vh" style={{ margin: 'auto' }}>
          <image xlinkHref="/static/Christmas.gif" width="50vh" height="50vh" />
        </svg>
      ) : (
        <CircularProgress sx={{ margin: 'auto' }} />
      )}
    </Box>
  )
}

export default Loader
