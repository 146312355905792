import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Alert, Button } from '@mui/material'
import { useHistory } from 'react-router-dom'

import Page from '@firstbase/components/atoms/Page'
import Table from '@firstbase/components/atoms/Table'

import useTableGraphqlQuery from '@firstbase/hooks/useTableGraphqlQuery'
import {
  getAllInventories as getAllInventoriesType,
  getAllInventoriesVariables,
} from '@firstbase/data/Inventory/__generated__/getAllInventories'
import { GET_ALL_INVENTORIES } from '@firstbase/data/Inventory/queries'
import SearchBox from '@firstbase/components/atoms/SearchBox'
import { Role } from '@firstbase/context/auth/AuthProvider'
import ProtectedChildren from '@firstbase/components/atoms/ProtectedChildren'
import { ALL_INVENTORY_COLUMNS } from '@firstbase/constants/inventoryColumns'
import FloatingContainer from '@firstbase/components/atoms/FloatingContainer'

const AllInventory = () => {
  const history = useHistory()
  const [searchTerm, setSearchTerm] = useState('')
  const query = useTableGraphqlQuery<
    getAllInventoriesType,
    getAllInventoriesVariables
  >(
    GET_ALL_INVENTORIES,
    { variables: { searchTerm } as getAllInventoriesVariables },
    ({ getAllInventories }) => getAllInventories
  )

  const renderTitleAction = () => (
    <ProtectedChildren hasRole={Role.Admin}>
      <Button
        variant="contained"
        onClick={() => history.push('/platform/inventory/bulk')}
      >
        <AddIcon /> Bulk upload
      </Button>
    </ProtectedChildren>
  )

  return (
    <>
      <Page.TitleSection
        sectionTitle="Inventory"
        sectionSubtitle="List of inventory that are on the platform"
        subtitleAction={renderTitleAction}
      />
      <SearchBox
        placeholder="Search inventory by ID or serial number"
        disabled={(query.isLoading && !query.isPreviousData) || !!query.error}
        onChange={setSearchTerm}
        label="Search Inventory"
      />
      <Alert sx={{ my: 1 }} severity="warning">
        Loading all inventory records may take up to 1 minute
      </Alert>
      <FloatingContainer>
        <Table
          defaultSortId="serialNumber"
          query={{ ...query, variables: { searchTerm } }}
          tableId="inventory"
          columns={ALL_INVENTORY_COLUMNS()}
          pagination
          customRowsPerPageOptions={[100, 500, 5000]}
        />
      </FloatingContainer>
    </>
  )
}

export default AllInventory
