import React from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'

type OwnProps = {
  loading: boolean
  name: string
  setName: (name: string) => void
  generateApiToken: () => void
}

const ClientApiTokensCreateForm = ({
  name,
  loading,
  setName,
  generateApiToken,
}: OwnProps) => {
  return (
    <>
      <Box data-testid="client-api-token-create-form">
        <Typography variant="subtitle2">
          Create a new API Token for this organization:
        </Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            marginTop: 1,
          }}
        >
          <TextField
            label="Name"
            inputProps={{
              'data-testid': 'api-token-create-name',
            }}
            value={name}
            onChange={(e) => {
              setName(e.target.value)
            }}
            fullWidth
            size="small"
          />

          <Button
            size="medium"
            variant="contained"
            disabled={!name || loading}
            sx={{ minWidth: '300px' }}
            onClick={generateApiToken}
          >
            Generate API Token
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default ClientApiTokensCreateForm
