import { getOrderStatusColor } from '@firstbase/components/atoms/StatusChip/getColors'
import { cellComponents } from '@firstbase/components/atoms/Table/cellComponents/cellComponents'
import { ColumnI } from '@firstbase/components/atoms/Table/types'
import { readableReturnReason } from '@firstbase/constants/returnReasons'
import { sentenceCase } from 'change-case'
import { getOrderByIdForOrganisation_getOrderByIdForOrganisation_products } from '@firstbase/data/Order/__generated__/getOrderByIdForOrganisation'
import { getReplacementByIdForOrganization_getReplacementByIdForOrganization_order_products } from '@firstbase/data/Order/__generated__/getReplacementByIdForOrganization'

const PRODUCT_NAME: ColumnI = {
  header: 'Product name',
  id: 'productTitle',
  cell: {
    value: ({
      sku,
    }: getOrderByIdForOrganisation_getOrderByIdForOrganisation_products) =>
      sku.skuInformation.productTitle,
  },
}
const RETURN_REASON: ColumnI = {
  header: 'Return reason',
  id: 'returnReason',
  cell: {
    value: ({
      returnReason,
    }: getOrderByIdForOrganisation_getOrderByIdForOrganisation_products) =>
      readableReturnReason(returnReason),
  },
}
const STATUS: ColumnI = {
  header: 'Status',
  id: 'status',
  cell: {
    as: cellComponents.Chip,
    asProps: ({
      status,
    }: getOrderByIdForOrganisation_getOrderByIdForOrganisation_products) => ({
      color: getOrderStatusColor(status),
    }),
    value: ({
      status,
    }: getOrderByIdForOrganisation_getOrderByIdForOrganisation_products) =>
      sentenceCase(status || '-'),
  },
}
const RETURN_STATUS: ColumnI = {
  header: 'Status',
  id: 'status',
  cell: {
    as: cellComponents.ChipWithHelperText,
    asProps: ({
      status,
      inventory,
    }:
      | getOrderByIdForOrganisation_getOrderByIdForOrganisation_products
      | getReplacementByIdForOrganization_getReplacementByIdForOrganization_order_products) => ({
      chip: {
        color: getOrderStatusColor(status),
        text: sentenceCase(status || '-'),
      },
      text: sentenceCase(inventory?.deployReason || ''),
    }),
    value: () => null,
  },
}
const INVENTORY_ID: ColumnI = {
  header: 'Inventory ID',
  id: 'inventoryId',
  cell: {
    as: cellComponents.ID,
    value: ({
      inventory,
    }:
      | getOrderByIdForOrganisation_getOrderByIdForOrganisation_products
      | getReplacementByIdForOrganization_getReplacementByIdForOrganization_order_products) =>
      inventory?.id || '-',
  },
}
const SERIAL_NUMBER: ColumnI = {
  header: 'Serial Number',
  id: 'serialNumber',
  cell: {
    value: ({
      serialNumber,
    }: getOrderByIdForOrganisation_getOrderByIdForOrganisation_products) =>
      serialNumber,
  },
}
const ORDER_TRACKING: ColumnI = {
  header: 'Tracking',
  id: 'trackingLink',
  cell: {
    as: cellComponents.Link,
    asProps: ({
      trackingLink,
    }: getOrderByIdForOrganisation_getOrderByIdForOrganisation_products) => ({
      href: trackingLink,
    }),
    value: ({
      trackingLink,
    }: getOrderByIdForOrganisation_getOrderByIdForOrganisation_products) =>
      trackingLink ? 'Track' : '',
  },
}
const RETURN_OUTBOUND_TRACKING: ColumnI = {
  header: 'Outbound Tracking',
  id: 'trackingLink',
  cell: {
    as: cellComponents.Link,
    asProps: ({
      trackingLink,
    }: getOrderByIdForOrganisation_getOrderByIdForOrganisation_products) => ({
      href: trackingLink,
    }),
    value: ({
      trackingLink,
    }: getOrderByIdForOrganisation_getOrderByIdForOrganisation_products) =>
      trackingLink ? 'Track' : '',
  },
}
const RETURN_INBOUND_TRACKING: ColumnI = {
  header: 'Inbound Tracking',
  id: 'inboundTrackingLink',
  cell: {
    as: cellComponents.Link,
    asProps: ({
      inboundTrackingLink,
    }: getOrderByIdForOrganisation_getOrderByIdForOrganisation_products) => ({
      href: inboundTrackingLink,
    }),
    value: ({
      inboundTrackingLink,
    }: getOrderByIdForOrganisation_getOrderByIdForOrganisation_products) =>
      inboundTrackingLink ? 'Track' : '',
  },
}
const ORDER_ID: ColumnI = {
  header: 'Order Item ID',
  id: 'id',
  cell: {
    as: cellComponents.ID,
    value: ({
      id,
    }: getOrderByIdForOrganisation_getOrderByIdForOrganisation_products) => id,
  },
}
const RETURN_ID: ColumnI = {
  header: 'Return Item ID',
  id: 'id',
  cell: {
    as: cellComponents.ID,
    value: ({
      id,
    }: getOrderByIdForOrganisation_getOrderByIdForOrganisation_products) => id,
  },
}

export const RETURN_COLUMNS = [
  PRODUCT_NAME,
  RETURN_REASON,
  RETURN_STATUS,
  RETURN_OUTBOUND_TRACKING,
  RETURN_INBOUND_TRACKING,
  SERIAL_NUMBER,
  RETURN_ID,
  INVENTORY_ID,
]
export const ORDER_COLUMNS = [
  PRODUCT_NAME,
  STATUS,
  ORDER_TRACKING,
  SERIAL_NUMBER,
  ORDER_ID,
]
