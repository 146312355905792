import React, { useState } from 'react'

import Page from '@atoms/Page'
import SearchBox from '@atoms/SearchBox'

import { ReactComponent as ArrowDown } from '@firstbase/assets/ArrowDown.svg'
import ProtectedChildren from '@firstbase/components/atoms/ProtectedChildren'
import StatusChip from '@firstbase/components/atoms/StatusChip'
import TablePaginationActions from '@firstbase/components/atoms/Table/TablePagination'
import { Role } from '@firstbase/context/auth/AuthProvider'
import { useGetProductTiers, usePagingAndSorting } from '@firstbase/hooks'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import {
  Alert,
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useGetProductTierFeatures } from './useGetProductTierFeatures'

export const ProductTiers = () => {
  const history = useHistory()

  const { pagingAndSorting, setPagingAndSorting } = usePagingAndSorting()
  const [searchTerm, setSearchTerm] = useState('')
  const {
    productTiers,
    totalElements,
    loading: isProductTiersLoading,
    previousData: isProductTiersPreviousData,
    error: productTiersError,
  } = useGetProductTiers({
    pagingAndSorting: pagingAndSorting,
    filter: { name: searchTerm },
  })
  const { totalElements: totalFeaturesCount } = useGetProductTierFeatures()

  return (
    <>
      <Page.TitleSection
        sectionTitle="Product Tiers"
        sectionSubtitle="List of all product tiers supported by Firstbase, including custom tiers"
        subtitleAction={CreateProductTierButton}
      />

      <SearchBox
        placeholder="Search by name"
        label="Search Product Tiers"
        disabled={
          (isProductTiersLoading && !isProductTiersPreviousData) ||
          !!productTiersError
        }
        onChange={setSearchTerm}
      />

      <Card variant="outlined">
        <Table aria-label="product tiers table">
          <TableHead className="bg-neutral-300">
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Features Selected</TableCell>
              <TableCell>Number of Customers</TableCell>
              <TableCell>Status</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {(!productTiers || productTiers.length === 0) && (
              <TableRow>
                <TableCell colSpan={5} className="text-center">
                  <Alert severity="warning">No data available</Alert>
                </TableCell>
              </TableRow>
            )}

            {!!productTiers &&
              productTiers.map(
                ({ id, name, features: productTierFeatures, status }) => (
                  <TableRow key={id} className="last-of-type:border-0">
                    <TableCell>{name}</TableCell>
                    {!!productTierFeatures && totalFeaturesCount && (
                      <TableCell>
                        {productTierFeatures.length} of {totalFeaturesCount}
                      </TableCell>
                    )}
                    <TableCell>-</TableCell>
                    <TableCell>
                      <StatusChip type="productTier" status={status} />
                    </TableCell>

                    <TableCell>
                      <ProtectedChildren hasRole={Role.Admin}>
                        <IconButton
                          aria-label="edit product tier"
                          onClick={() =>
                            history.push(`/platform/product_tiers/edit/${id}`)
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </ProtectedChildren>
                    </TableCell>
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>

        {productTiers && productTiers.length > 0 && (
          <TablePagination
            data-testid="table-pagination"
            component="div"
            SelectProps={{
              IconComponent: () => (
                <ArrowDown
                  style={{ marginLeft: '-1rem' }}
                  fill="#191A1B"
                  opacity="0.52"
                  width="1.5rem"
                  height="1.5rem"
                />
              ),
            }}
            ActionsComponent={TablePaginationActions}
            count={totalElements ?? productTiers.length}
            page={pagingAndSorting.pageNumber - 1}
            onPageChange={(_, newPageNumber) =>
              setPagingAndSorting.pageNumber(newPageNumber + 1)
            }
            rowsPerPage={pagingAndSorting.pageSize}
            onRowsPerPageChange={(e) => {
              setPagingAndSorting.pageSize(Number(e.target.value) ?? 10)
              setPagingAndSorting.pageNumber(1)
            }}
          />
        )}
      </Card>
    </>
  )
}

const CreateProductTierButton = () => {
  const history = useHistory()

  return (
    <ProtectedChildren hasRole={Role.Admin}>
      <Button
        variant="contained"
        onClick={() => history.push('/platform/product_tiers/create')}
      >
        <AddIcon />
        Create product tier
      </Button>
    </ProtectedChildren>
  )
}
