import { useMutation } from '@apollo/client'
import { UPDATE_GLOBAL_SKU } from '@firstbase/data/SKU/mutations'
import { useSnackbar } from 'notistack'
import React from 'react'
import { GlobalProductActionProps } from './GlobalProductAction'
import GlobalProductForm, {
  Product,
} from './GlobalProductForm/GlobalProductForm'
import {
  updateSKU,
  updateSKUVariables,
} from '@firstbase/data/SKU/__generated__/updateSKU'
import { SKU } from '@firstbase/types/Product'
import { useUpdateStandardCatalogListing } from '@firstbase/views/Home/Platform/Products/hooks/useUpdateStandardCatalogListing'

interface OwnProps
  extends Omit<GlobalProductActionProps, 'staticGenericCategory' | 'product'> {
  product: SKU
}

const EditGlobalProduct = ({ handleClose, open, product }: OwnProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const [handleUpdateSku, { loading: saving, error }] = useMutation<
    updateSKU,
    updateSKUVariables
  >(UPDATE_GLOBAL_SKU)

  const [
    updateStandardCatalogListing,
    {
      loading: updatingStandardCatalogListing,
      error: errorUpdatingStandardCatalogListing,
    },
  ] = useUpdateStandardCatalogListing()

  const handlePostUpdate = async (title: string, standardCatalog?: boolean) => {
    if (standardCatalog !== undefined) {
      await updateStandardCatalogListing(product.id, standardCatalog)
    }

    enqueueSnackbar(`Successfully updated ${title}`, {
      autoHideDuration: 10000,
    })
    handleClose(true)
  }

  const onSubmit = async (
    {
      image,
      metadata,
      genericCategory: _genericCategory,
      ...updatedProduct
    }: Product,
    standardCatalog?: boolean
  ) => {
    // submitting the original image results in API error
    // due to type of file not being of image type
    // 'hack' here is to not submit the image if it hasn't changed
    const skuImages = image === product?.skuImages?.[0] ? undefined : [image]

    const skuUpdateVariables: updateSKUVariables = {
      skuId: product.id,
      updateSKU: {
        productTitle: updatedProduct.title,
        categoryCode: updatedProduct.category,
        skuImages,
        standardPricing: updatedProduct.standardPricing,
        metadata,
        vendorSku: updatedProduct.vendorSku || null,
        alternativeMpn: updatedProduct.alternativeMpn || null,
        upc: updatedProduct.upc || null,
        ean: updatedProduct.ean || null,
      },
    }
    handleUpdateSku({ variables: skuUpdateVariables }).then(() =>
      handlePostUpdate(updatedProduct.title, standardCatalog)
    )
  }

  return (
    <GlobalProductForm
      handleClose={handleClose}
      handleSubmit={onSubmit}
      open={open}
      saving={saving || updatingStandardCatalogListing}
      error={error?.message || errorUpdatingStandardCatalogListing?.message}
      product={product}
    />
  )
}

export default EditGlobalProduct
