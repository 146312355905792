import React, { Dispatch, SetStateAction } from 'react'
import { Alert, TextField } from '@mui/material'

interface OwnProps {
  setImage: Dispatch<SetStateAction<string>> | ((url: string) => void)
  showImageError: boolean
  image: string
  setIsValidImage:
    | Dispatch<SetStateAction<boolean>>
    | ((isValid: boolean) => void)
  isValidImage: boolean
}

const ImageUploader = ({
  setImage,
  showImageError,
  image,
  setIsValidImage,
  isValidImage,
}: OwnProps) => {
  const handleImageChange = async (
    e: React.SyntheticEvent<HTMLInputElement>
  ) => {
    const imageURL = (e.target as HTMLInputElement).value
    setImage(imageURL)

    const canImageLoad = new Image()
    canImageLoad.onload = () => setIsValidImage(true)
    canImageLoad.onerror = () => setIsValidImage(false)
    canImageLoad.src = imageURL
  }

  return (
    <>
      <TextField
        id="image"
        label="Image URL"
        fullWidth
        size="small"
        error={showImageError}
        helperText="URL must be a valid .jpg, .jpeg, .png, .svg link"
        inputProps={{
          value: image,
          onChange: handleImageChange,
        }}
      />
      {image && isValidImage && <img src={image} width={200} />}
      {image && !isValidImage && (
        <Alert severity="error">
          This image cannot be found - please enter a valid image URL
        </Alert>
      )}
    </>
  )
}

export default ImageUploader
