import { gql } from '@apollo/client'

export const GET_DOCUMENTS = gql`
  query getDocuments {
    documents {
      id
      title
      indexed
      url
      createdAt
      updatedAt
      fileUrl
    }
  }
`

export const GET_CHAT_MESSAGES = gql`
  query getChatMessages($chatId: ID!) {
    chat(id: $chatId) {
      id
      messages {
        id
        content
        isBotResponse
      }
    }
  }
`
