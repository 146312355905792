import React, { useReducer, useMemo, useState } from 'react'

import { useHistory } from 'react-router-dom'
import FullscreenModal from '@firstbase/components/molecules/FullscreenModal'
import SuccessStep from './SuccessStep'
import { CircularProgress } from '@mui/material'

enum Pages {
  ADD_FILE = 'ADD_FILE',
  COMPLETED = 'COMPLETED',
}

// limit the number of operations which can be conducted in one upload
// as parsing a greater number will jam the Event loop
// TODO: if this proves a limitation, can explore web workers
const MAX_UPLOAD_LENGTH = 500

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'error':
      return { ...state, errors: [...state.errors, action.message] }
    case 'success':
      return { ...state, successful: [...state.successful, action.message] }
    case 'reset':
      return { errors: [], successful: [] }
    default:
      throw new Error()
  }
}

type OwnProps = {
  addMessage: string
  children: React.ReactNode
  handleCreateRow: (data: any, logStatus: React.Dispatch<any>) => any
  data: any[]
  continueDisabled: boolean
  uploadTitle: string
}

const BulkUpload = ({
  addMessage,
  children,
  handleCreateRow,
  data,
  continueDisabled,
  uploadTitle,
}: OwnProps) => {
  const history = useHistory()
  const [page, setPage] = useState<Pages>(Pages.ADD_FILE)
  const [saving, setSaving] = useState<boolean>(false)

  const [{ errors, successful }, logStatus] = useReducer(reducer, {
    errors: [],
    successful: [],
  })

  const handleSubmit = async () => {
    // Reset error and success messages
    logStatus({ type: 'reset' })

    if (data) {
      setSaving(true)
      await Promise.allSettled(
        data.map((dataRow: any) => handleCreateRow(dataRow, logStatus))
      ).finally(() => {
        setSaving(false)
      })

      return setPage(Pages.COMPLETED)
    }
  }

  const renderBody = () => {
    if (page === Pages.COMPLETED) {
      return <SuccessStep errors={errors} successful={successful} />
    }

    return children
  }

  const handleBack = () => {
    if (page === Pages.COMPLETED) return setPage(Pages.ADD_FILE)

    return history.goBack()
  }

  const handleNext = () => {
    if (page === Pages.COMPLETED) return history.goBack()

    return handleSubmit()
  }

  const label = useMemo(() => {
    if (saving) return <CircularProgress size="1.5rem" />
    if (page === Pages.COMPLETED) return 'Finish'
    if (!data?.length) return 'Submit'
    if (data.length > MAX_UPLOAD_LENGTH)
      return `Unable to upload more than ${MAX_UPLOAD_LENGTH} records`

    return data.length ? addMessage : 'Submit'
  }, [addMessage, data.length, page, saving])

  const closeLabel = useMemo(() => {
    if (page === Pages.COMPLETED) return 'Back'

    return 'Close'
  }, [page])

  return (
    <FullscreenModal
      title={uploadTitle}
      open={true}
      handleClose={handleBack}
      closeLabel={closeLabel}
      continueProps={{
        disabled: saving || continueDisabled || data.length > MAX_UPLOAD_LENGTH,
        color: 'primary',
        variant: 'contained',
        onClick: handleNext,
        label,
      }}
    >
      {renderBody()}
    </FullscreenModal>
  )
}

export default BulkUpload
