import { useMutation } from '@apollo/client'
import Loader from '@firstbase/components/atoms/Loader'
import { REACTIVATE_PERSON_AND_CANCEL_RETURNS } from '@firstbase/data/Person/mutations'
import {
  getPersonBySlugAndOrganizationSlug_getPersonBySlugAndOrganizationSlug as PersonI,
  getPersonBySlugAndOrganizationSlug_getPersonBySlugAndOrganizationSlug_orders_products as ProductI,
} from '@firstbase/data/Person/__generated__/getPersonBySlugAndOrganizationSlug'
import { updatePersonBySlugVariables } from '@firstbase/data/Person/__generated__/updatePersonBySlug'
import { OrderStatus, PersonStatus } from '@globalTypes'
import { Button } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import ReactivatePersonConfirmReturnItemsModal from './ReactivatePersonConfirmReturnItemsModal'
import ReactivatePersonConfirmReactivationModal from './ReactivatePersonConfirmReactivationModal'

type OwnProps = {
  loading: boolean
  person?: PersonI
}

const CAN_REACTIVATE_PERSON_STATUSES = [
  PersonStatus.OFFBOARDING,
  PersonStatus.INACTIVE,
]

enum REACTIVATION_STATES {
  closed,
  confirmReturnItems,
  confirmReactivation,
}

export type ProductWithOrderInformation = ProductI & {
  orderId: string
  orderStatus: OrderStatus | null
}

const ReactivatePerson = ({ person, loading }: OwnProps) => {
  const [returnOrderItems, setReturnOrderItems] = useState<
    ProductWithOrderInformation[]
  >([])
  const [reactivationState, setReactivationState] =
    useState<REACTIVATION_STATES>(REACTIVATION_STATES.closed)
  const { enqueueSnackbar } = useSnackbar()
  const [
    reactivatePersonAndCancelPendingReturnsMutation,
    { loading: isReactivating },
  ] = useMutation<updatePersonBySlugVariables>(
    REACTIVATE_PERSON_AND_CANCEL_RETURNS,
    {
      refetchQueries: ['getPersonBySlugAndOrganizationSlug'],
    }
  )

  // If person doesn't have a status allowing reactivation or is loading, we don't display anything
  const canDisplayReactivateButton =
    person &&
    person.status &&
    CAN_REACTIVATE_PERSON_STATUSES.includes(person.status) &&
    !loading

  // We get return order items from person orders
  useEffect(() => {
    if (person?.orders) {
      const returnOrders = person.orders.filter((o) => o.orderType === 'RETURN')
      setReturnOrderItems(
        returnOrders.reduce((items, order) => {
          if (!order.products) return items
          const returnItems = (order.products || []) as ProductI[]
          return [
            ...items,
            ...returnItems.map((item) => ({
              ...item,
              orderId: order.id,
              orderStatus: order.orderStatus,
            })),
          ]
        }, [] as ProductWithOrderInformation[])
      )
    }
  }, [person?.orders])

  // A user can be reactivated if he has no order in the status 'processing'
  const returnItemsWithinProcessingOrders = returnOrderItems.filter(
    (item) => item.orderStatus === OrderStatus.PROCESSING
  )
  const canBeReactivated = returnItemsWithinProcessingOrders.length === 0

  // Pending return items will be cancelled during reactivation
  const pendingReturnItems = returnOrderItems.filter(
    (item) => item.status === OrderStatus.PENDING
  )

  // On reactivation, we update the person status to ACTIVE
  // And we cancel all pending return items
  const reactivatePerson = async () => {
    try {
      await reactivatePersonAndCancelPendingReturnsMutation({
        variables: {
          slug: person?.slug,
          updateReturnItem: pendingReturnItems.map(({ id }) => ({
            id,
            status: OrderStatus.CANCELLED,
          })),
        },
      })
      enqueueSnackbar(
        `Successfully reactivated ${person?.forename} ${person?.surname}'s account`
      )
      setReactivationState(REACTIVATION_STATES.closed)
    } catch (err) {
      enqueueSnackbar('Failed to reactivate user', {
        variant: 'error',
      })
    }
  }

  const cancelReactivation = () =>
    setReactivationState(REACTIVATION_STATES.closed)

  const handleReactivation = () => {
    // If there no orders processing and no return items pending,
    // We can reactivate the user directly
    if (
      person?.status === PersonStatus.INACTIVE ||
      (returnItemsWithinProcessingOrders.length === 0 &&
        pendingReturnItems.length === 0)
    ) {
      return setReactivationState(REACTIVATION_STATES.confirmReactivation)
    }

    // Otherwise we display problematic items and ask for a confirmation to reactivate
    setReactivationState(REACTIVATION_STATES.confirmReturnItems)
  }

  if (!canDisplayReactivateButton) return null

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleReactivation}
        disabled={isReactivating}
      >
        Reactivate
      </Button>
      {reactivationState === REACTIVATION_STATES.confirmReturnItems && (
        <ReactivatePersonConfirmReturnItemsModal
          openReturnOrderIds={[
            ...new Set(
              pendingReturnItems
                .concat(returnItemsWithinProcessingOrders)
                .map(({ orderId }) => orderId)
            ),
          ].join(', ')}
          cancelReactivation={cancelReactivation}
          confirmReactivation={() => {
            setReactivationState(REACTIVATION_STATES.confirmReactivation)
          }}
          canBeReactivated={canBeReactivated}
          person={person}
          returnOrderItems={returnOrderItems}
        />
      )}
      {reactivationState === REACTIVATION_STATES.confirmReactivation && (
        <ReactivatePersonConfirmReactivationModal
          person={person}
          cancelReactivation={cancelReactivation}
          reactivatePerson={reactivatePerson}
        />
      )}
      {isReactivating && <Loader />}
    </>
  )
}

export default ReactivatePerson
