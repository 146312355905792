import React from 'react'
import { Card, Chip, Grid, Typography } from '@mui/material'

export type OwnProps = {
  image?: string
  vendor: string
  skuTitle: string
  skuChips: any
  marginBottom?: boolean
  skuId?: string
  noBorder?: boolean
}

export const SkuProductCard = ({
  image,
  vendor,
  skuTitle,
  skuChips,
  marginBottom,
  skuId,
  noBorder,
}: OwnProps) => {
  const placeholderImage = '/placeholder-image.png'

  return (
    <Card
      sx={{
        height: 'fit-content',
        width: 500,
        marginBottom: marginBottom ? '1rem' : 'none',
        ...(noBorder ? { border: 'none' } : {}),
      }}
    >
      <div
        style={{
          margin: 15,
          display: 'flex',
          alignItems: 'center',
          minHeight: 100,
        }}
      >
        <img
          src={image || placeholderImage}
          width={100}
          height={!image ? 100 : ''}
        />
        <div style={{ padding: '0.5rem 1rem' }}>
          <Typography color="text.secondary" gutterBottom variant="body2">
            {vendor}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            {skuTitle}
          </Typography>
          {skuId ? (
            <Typography color="text.secondary" gutterBottom variant="body2">
              {skuId}
            </Typography>
          ) : null}
          <Grid container sx={{ gap: 0.5 }}>
            {skuChips.map((chip: string) => (
              <Grid key={chip} item>
                <Chip
                  label={chip.toUpperCase()}
                  variant="outlined"
                  size="small"
                  sx={{
                    backgroundColor: '#fafafa',
                    borderRadius: 1.5,
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </Card>
  )
}
