import { GET_ORGANIZATION_INVENTORIES } from '@firstbase/data/Organization/queries'
import React from 'react'
import { useParams } from 'react-router-dom'

import Table from '@firstbase/components/atoms/Table'
import useTableGraphqlQuery from '@firstbase/hooks/useTableGraphqlQuery'

import {
  getOrganizationInventoriesById,
  getOrganizationInventoriesByIdVariables,
} from '@firstbase/data/Organization/__generated__/getOrganizationInventoriesById'
import { ORGANIZATION_INVENTORY_COLUMNS } from '@firstbase/constants/inventoryColumns'
import { ClientPageParams } from '@firstbase/types/Pages'

const ClientInventory = () => {
  const params: ClientPageParams = useParams()
  const query = useTableGraphqlQuery<
    getOrganizationInventoriesById,
    getOrganizationInventoriesByIdVariables
  >(
    GET_ORGANIZATION_INVENTORIES,
    {
      variables: {
        orgId: params.clientId!,
      } as getOrganizationInventoriesByIdVariables,
    },
    ({
      getOrganizationById: { inventories },
    }: getOrganizationInventoriesById) => inventories
  )

  return (
    <Table
      noDataMessage="No inventory found"
      query={query}
      tableId={`org=${params.clientId!}-inventories`}
      pagination
      defaultSortId="sku.skuInformation.productTitle"
      columns={ORGANIZATION_INVENTORY_COLUMNS(params.clientId)}
      customRowsPerPage={100}
      customRowsPerPageOptions={[100, 200, 300]}
    />
  )
}

export default ClientInventory
