export const pluralEndingCheck = (
  count: number,
  singular: string,
  plural: string
): string => {
  return count === 1 ? singular : plural
}

export const concatenateLongList = (
  names: string[],
  totalLength: number,
  singularRemainingText = 'other',
  pluralRemainingText = 'others'
): string => {
  const remaining = totalLength - names.length
  const nodes = [...names]

  if (remaining > 0) {
    nodes.push(
      `${remaining} ${pluralEndingCheck(
        remaining,
        singularRemainingText,
        pluralRemainingText
      )}`
    )
  }

  if (nodes.length > 2) {
    return `${nodes.slice(0, -1).join(', ')}, and ${nodes.at(-1)}`
  }

  return nodes.join(' and ')
}
