import React from 'react'

import Page from '@atoms/Page'

import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'

import { capitalize } from '@mui/material'
import BulkUploadInventory from './Inventory/BulkUploadInventory'
import BulkSendActivationEmail from './People/BulkSendActivationEmail'
import BulkUploadPeople from './People/BulkUploadPeople'
import EditProductPage from './Products/EditProductPage'
import NewGenericSKUPage from './Products/NewGenericSKUPage'
import NewProductPage from './Products/NewProductPage'
import {
  ProductTierEditFormPage,
  ProductTierFeatureCreateFormPage,
  ProductTierCreateFormPage,
  ProductTierFeatureEditFormPage,
} from './ProductTiers'
import {
  CreateWarrantyType,
  WarrantyMetadataConfig,
  CreateWarrantySku,
  EditWarrantySku,
} from './Warranties'
import { usePlatformTabs } from './usePlatformTabs'

type OwnProps = {
  label: string
  onClick: () => void
}

function LinkTab({ label, onClick }: OwnProps) {
  return <Tab label={label} onClick={onClick} />
}

const Platform = () => {
  const tabs = usePlatformTabs()
  const match = useRouteMatch()
  const location = useLocation()
  const history = useHistory()

  const index = tabs.findIndex((t) => location.pathname.endsWith(t.label))

  return (
    <Switch>
      <Route path={`${match.path}/products/new`}>
        <NewProductPage />
      </Route>
      <Route path={`${match.path}/products/new_generic`}>
        <NewGenericSKUPage />
      </Route>
      <Route path={`${match.path}/products/:skuId`}>
        <EditProductPage />
      </Route>
      <Route path={`${match.path}/product_tiers/edit/:productTierId`}>
        <ProductTierEditFormPage />
      </Route>
      <Route path={`${match.path}/product_tiers/create`}>
        <ProductTierCreateFormPage />
      </Route>
      <Route path={`${match.path}/product_tiers/feature/create`}>
        <ProductTierFeatureCreateFormPage />
      </Route>
      <Route
        path={`${match.path}/product_tiers/feature/edit/:productTierFeatureId`}
      >
        <ProductTierFeatureEditFormPage />
      </Route>
      <Route>
        <Page title="Platform">
          <Box
            sx={{ borderBottom: 1, marginBottom: 4, borderColor: 'divider' }}
          >
            <Tabs aria-label="basic tabs example" value={index > 0 ? index : 0}>
              {tabs.map((t, i) => (
                <LinkTab
                  key={t.label}
                  label={capitalize(t.label.replace('_', ' '))}
                  onClick={() =>
                    history.push(
                      i === 0 ? match.url : `${match.url}/${t.label}`
                    )
                  }
                />
              ))}
            </Tabs>
          </Box>
          <Switch>
            {tabs.map(({ label, component: Component }, i) => {
              return (
                <Route
                  key={label}
                  exact
                  path={i === 0 ? match.path : `${match.path}/${label}`} // This creates a default tab of products
                >
                  <Component />
                </Route>
              )
            })}
            <Route exact path={`${match.path}/inventory/bulk`}>
              <BulkUploadInventory />
            </Route>
            <Route exact path={`${match.path}/people/bulk/create`}>
              <BulkUploadPeople />
            </Route>
            <Route exact path={`${match.path}/people/bulk/sendActivationEmail`}>
              <BulkSendActivationEmail />
            </Route>
            <Route exact path={`${match.path}/warranties/types/new`}>
              <CreateWarrantyType />
            </Route>
            <Route exact path={`${match.path}/warranties/metadata/config`}>
              <WarrantyMetadataConfig />
            </Route>
            <Route exact path={`${match.path}/warranties/sku/new`}>
              <CreateWarrantySku />
            </Route>
            <Route exact path={`${match.path}/warranties/sku/edit/:skuId`}>
              <EditWarrantySku />
            </Route>
          </Switch>
        </Page>
      </Route>
    </Switch>
  )
}

export default Platform
