import React from 'react'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { OutlinedInput } from '@mui/material'

import { getAllWarrantySKUMetadataOptionValues_getAllWarrantySKUMetadataOptionValues } from '@firstbase/data/Warranties'
import { formatSkuMetadataFieldLabel } from '@utils/formatSkuMetadataFieldLabel'

import { CommonProps } from '../types'

interface FieldSelectProps {
  field: getAllWarrantySKUMetadataOptionValues_getAllWarrantySKUMetadataOptionValues
  value: string
  dispatch: CommonProps['dispatch']
  multiple?: boolean
}

export const FieldSelect = ({
  field,
  value,
  dispatch,
  multiple,
}: FieldSelectProps) => {
  const label = formatSkuMetadataFieldLabel(field.name)

  /**
   * Some setup is needed to ensure correct behavior for when the field
   * is a multi-select. This is for consistency in the UI microinteractions.
   */
  const isMultiSelectWithValue =
    multiple && !!value && value.split(',').length > 0
  const labelProps = multiple ? { shrink: isMultiSelectWithValue } : {}

  return (
    <FormControl fullWidth>
      <InputLabel
        id={`field-select-label-${field.id}`}
        required={field.required}
        {...labelProps}
      >
        {label}
      </InputLabel>
      <Select
        labelId={`field-select-label-${field.id}`}
        id={`field-select-${field.id}`}
        label={label}
        value={multiple ? value.split(',') || [] : value}
        onChange={(e) => {
          dispatch({
            type: 'SET_METADATA_FIELD',
            payload: {
              fieldId: field.id,
              value: Array.isArray(e.target.value)
                ? e.target.value
                    .filter((v) => v)
                    .sort()
                    .join(',')
                : e.target.value,
              name: field.name,
            },
          })
        }}
        multiple={multiple}
        input={
          multiple ? (
            <OutlinedInput notched={isMultiSelectWithValue} label={label} />
          ) : undefined
        }
      >
        {field.values.map((fieldValue) => (
          <MenuItem key={fieldValue.id} value={fieldValue.value}>
            {fieldValue.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
