import { theme } from '@firstbase/muiTheme'
import {
  Alert,
  AppBar,
  Button,
  Container,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React from 'react'
import { BottomBarProps } from './types'
import BottomBarContinueButton from './BottomBarContinueButton'

function BottomBar({
  helperText,
  errorText,
  handleClose,
  closeDisabled,
  closeLabel = 'Cancel',
  continueProps,
  secondaryContinueProps,
}: BottomBarProps) {
  const renderPrimaryContinue = () => {
    const { label: continueLabel = 'Continue', ...restContinueProps } =
      continueProps || {}

    return (
      <BottomBarContinueButton
        label={continueLabel}
        variant="contained"
        {...restContinueProps}
      />
    )
  }

  const renderSecondaryContinue = () => {
    if (!secondaryContinueProps) return null

    return (
      <BottomBarContinueButton
        color="secondary"
        variant="contained"
        {...secondaryContinueProps}
      />
    )
  }

  return (
    <AppBar
      sx={{
        position: 'fixed',
        bottom: 0,
        top: 'unset',
        backgroundColor: '#ffffff',
      }}
      position="fixed"
    >
      <Toolbar>
        <Container
          disableGutters={useMediaQuery(theme.breakpoints.up('lg'))}
          maxWidth={false}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 2,
            maxWidth: 1154,
            alignItems: 'center',
          }}
        >
          {!!errorText && <Alert severity="warning">{errorText}</Alert>}
          <Typography variant="body2">{helperText}</Typography>
          <Button
            variant="text"
            color="secondary"
            disabled={closeDisabled}
            onClick={handleClose}
          >
            {closeLabel}
          </Button>
          {renderSecondaryContinue()}
          {continueProps && (
            <Tooltip title={continueProps.tooltip || ''}>
              <span>{renderPrimaryContinue()}</span>
            </Tooltip>
          )}
        </Container>
      </Toolbar>
    </AppBar>
  )
}

export default BottomBar
