import SmartToyIcon from '@mui/icons-material/SmartToy'

import Client from '@views/Home/Client/Client'
import Clients from '@views/Home/Clients/Clients'
import Product from '@views/Home/Product/Product'
import Person from '@views/Home/Person/Person'

import { ReactComponent as BriefcaseIcon } from '../assets/Briefcase.svg'
import { ReactComponent as BriefcaseFilledIcon } from '../assets/BriefcaseFilled.svg'
import { ReactComponent as HarddriveIcon } from '../assets/Harddrive.svg'
import { ReactComponent as HarddriveFilledIcon } from '../assets/HarddriveFilled.svg'
import { ReactComponent as ListIcon } from '../assets/List.svg'
import { ReactComponent as ListFilledIcon } from '../assets/ListFilled.svg'

import Platform from '@firstbase/views/Home/Platform/Platform'
import Reports from '@firstbase/views/Reports/Reports'
import Package from '@firstbase/views/Home/Package/Package'
import Assistant from '@firstbase/views/Assistant'

import { HasRoleInput } from '@firstbase/context/auth/AuthProvider'
import Order from '@firstbase/views/Home/Person/Order'
import BulkUpgrade from '@firstbase/views/Home/Client/BulkActions/BulkUpgrade/BulkUpgrade'

type Child = {
  [key in any]: Routes
}

export type Routes = {
  excludeParentPath?: boolean
  exact?: boolean
  path: string
  component: (props: any) => JSX.Element
  id?: string
  NavIcon?: (props: any) => JSX.Element
  NavIconActive?: (props: any) => JSX.Element
  navLabel?: string
  children?: Child
  role?: HasRoleInput
}

const routesTree: Routes = {
  id: 'home',
  path: '/home',
  component: Clients,
  children: {
    clients: {
      excludeParentPath: true,
      path: '/clients',
      component: Clients,
      navLabel: 'Clients',
      NavIcon: BriefcaseFilledIcon,
      NavIconActive: BriefcaseIcon,
      children: {
        client: {
          excludeParentPath: true,
          path: '/client/:clientId',
          component: Client,
          children: {
            product: {
              path: '/product/:productId',
              component: Product,
            },
            'bulk-upgrade': {
              path: '/actions/bulk-upgrade',
              component: BulkUpgrade,
            },
            person: {
              path: '/person/:personSlug',
              component: Person,
              children: {
                orderDetail: {
                  path: '/order/:orderId',
                  component: Order,
                },
              },
            },
            package: {
              path: '/package/:packageSlug',
              component: Package,
            },
          },
        },
      },
    },
    reports: {
      excludeParentPath: true,
      path: '/reports',
      component: Reports,
      navLabel: 'Reports',
      exact: false,
      NavIcon: ListIcon,
      NavIconActive: ListFilledIcon,
    },
    platform: {
      excludeParentPath: true,
      path: '/platform',
      component: Platform,
      navLabel: 'Platform',
      exact: false,
      NavIcon: HarddriveIcon,
      NavIconActive: HarddriveFilledIcon,
    },
    assistant: {
      excludeParentPath: true,
      path: '/assistant',
      component: Assistant,
      navLabel: 'Assistant',
      exact: false,
      NavIcon: SmartToyIcon,
      NavIconActive: SmartToyIcon,
    },
  },
}

export default routesTree
