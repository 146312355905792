import { Skeleton } from '@mui/material'
import React, { FC } from 'react'

interface OwnProps {}

const ProductLoadingSkeleton: FC<OwnProps> = ({}) => {
  return (
    <>
      <Skeleton width="100%" height="5rem" />
      <Skeleton width="100%" height="4rem" />
      <Skeleton width="100%" height="4rem" />
      <Skeleton width="15rem" height="12rem" />
      <Skeleton width="100%" height="4rem" />
      <Skeleton width="100%" height="4rem" />
    </>
  )
}

export default ProductLoadingSkeleton
