import { styled } from '@mui/material'
import React from 'react'
import Link from '@atoms/Link'

import Boolean from './Boolean'
import Chip from './Chip'
import ChipWithHelperText from './ChipWithHelperText'

const ID = styled('div')(() => ({
  whiteSpace: 'nowrap',
}))

export const cellComponents = {
  default: React.Fragment,
  Chip,
  ID,
  Boolean,
  Link,
  ChipWithHelperText,
}
