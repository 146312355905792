import { useQuery } from '@apollo/client'
import {
  getAllProductTiers,
  getAllProductTiers_getAllProductTiers_productTiers_data,
  getAllProductTiersVariables,
} from '@firstbase/data/ProductTier/__generated__/getAllProductTiers'
import { GET_ALL_PRODUCT_TIERS } from '@firstbase/data/ProductTier/productTierQueries'
import { useMemo } from 'react'

export const useGetProductTiers = (
  variables?: getAllProductTiersVariables,
  id?: getAllProductTiers_getAllProductTiers_productTiers_data['id']
) => {
  const productTiers = useQuery<
    getAllProductTiers,
    getAllProductTiersVariables
  >(GET_ALL_PRODUCT_TIERS, {
    variables: variables || {
      pagingAndSorting: { pageNumber: 1, pageSize: 100 },
    },
  })

  const productTierById = useMemo(() => {
    if (!id) {
      return
    }

    return productTiers.data?.getAllProductTiers.productTiers?.data.find(
      (feature) => feature.id === id
    )
  }, [id, productTiers.data?.getAllProductTiers.productTiers?.data])

  return {
    ...productTiers,
    totalElements:
      productTiers.data?.getAllProductTiers.productTiers?.totalElements,
    productTierById,
    productTiers: productTiers.data?.getAllProductTiers.productTiers?.data,
  }
}
