import React, { useMemo } from 'react'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'

import { NOT_SUPPORTED } from '@firstbase/constants/contract'
import { useSupportedCurrencies } from '@firstbase/utils/contractUtils'

interface CurrencySelectProps {
  selectedCurrencyCode: string
  setSelectedCurrencyCode: (code: string) => void
  filterCurrencies: (codes: string[]) => string[]
}

export const CurrencySelect = ({
  selectedCurrencyCode,
  setSelectedCurrencyCode,
  filterCurrencies,
}: CurrencySelectProps) => {
  const supportedCurrencies = useSupportedCurrencies()
  const overrideCurrencies = useMemo(
    () =>
      Object.keys(supportedCurrencies).filter((code) => code !== NOT_SUPPORTED),
    [supportedCurrencies]
  )

  return (
    <FormControl sx={{ width: '100%' }}>
      <InputLabel
        size="small"
        id="mgmt-standard-pricing-add-currency-select-label"
      >
        Select currency
      </InputLabel>
      <Select
        label="Select currency"
        id="mgmt-standard-pricing-add-currency-select"
        labelId="mgmt-standard-pricing-add-currency-select-label"
        value={selectedCurrencyCode}
        fullWidth
        size="small"
        onChange={(e) => setSelectedCurrencyCode(e.target.value)}
      >
        {filterCurrencies(overrideCurrencies).map((currency) => (
          <MenuItem key={currency} value={currency}>
            {currency}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
