import React from 'react'
import { useLocation } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'

const IDPAuth = () => {
  const { search } = useLocation()
  const { oktaAuth } = useOktaAuth()

  const params = new URLSearchParams(search)
  const idp = params.get('idp')
  if (idp) {
    oktaAuth.setOriginalUri('/')
    oktaAuth.signInWithRedirect({ idp })
  }

  return <div>Loading</div>
}

export default IDPAuth
