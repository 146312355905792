import React from 'react'

import Page from '@firstbase/components/atoms/Page'
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { getPersonBySlugAndOrganizationSlug_getPersonBySlugAndOrganizationSlug as PersonI } from '@firstbase/data/Person/__generated__/getPersonBySlugAndOrganizationSlug'

type OwnProps = {
  person: PersonI
  cancelReactivation: () => void
  reactivatePerson: () => void
}

const ReactivatePersonConfirmReactivationModal = ({
  person,
  cancelReactivation,
  reactivatePerson,
}: OwnProps) => {
  return (
    <Dialog onClose={cancelReactivation} maxWidth="sm" fullWidth open>
      <DialogTitle>
        <Page.TitleSection
          sectionTitle={`Are you sure you want to reactivate ${person.forename} ${person.surname}’s account?`}
          sectionSubtitle={`Their status will revert back to what it was prior to offboarding. 
          Reactivating this person will not send them an email notification.`}
        />
      </DialogTitle>
      <DialogActions>
        <Button variant="text" color="secondary" onClick={cancelReactivation}>
          Cancel
        </Button>

        <Button variant="contained" onClick={reactivatePerson}>
          Yes, reactivate them
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReactivatePersonConfirmReactivationModal
