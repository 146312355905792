import React from 'react'
import { useQuery } from '@apollo/client'
import { Skeleton } from '@mui/material'

import { GET_NEW_PRODUCT_DATA } from '@firstbase/data/SKU/queries'
import {
  getNewProductData,
  getNewProductDataVariables,
} from '@firstbase/data/SKU/__generated__/getNewProductData'
import { CategorySelect } from '@firstbase/components/molecules/CategorySelect'
import { VendorSelect } from '@firstbase/components/molecules/VendorSelect'

import { CommonProps } from './types'
import { useArrayFeatureFlag } from '@firstbase/hooks/useFeatureFlag'
import FLAGS from '@firstbase/constants/featureFlags'

interface OwnProps extends CommonProps {
  disabled: boolean
}

// Group together to quickly reuse one existing query
export const CategoryBrandSelectGroup = ({
  state,
  dispatch,
  disabled,
}: OwnProps) => {
  const { data, loading } = useQuery<
    getNewProductData,
    getNewProductDataVariables
  >(GET_NEW_PRODUCT_DATA, {
    variables: { pagingAndSorting: { pageSize: 1000, pageNumber: 1 } },
  })
  const warrantableCategories = useArrayFeatureFlag(FLAGS.WARRANTY_CATEGORIES)

  if (loading)
    return (
      <>
        <Skeleton variant="rectangular" height={40} />
        <Skeleton variant="rectangular" height={40} />
      </>
    )

  return (
    <>
      <CategorySelect
        data={data}
        category={state.category}
        setCategory={(category: string) =>
          dispatch({ type: 'SET_CATEGORY', payload: category })
        }
        disabled={disabled}
        allowed={warrantableCategories}
      />
      <VendorSelect
        data={data}
        vendor={state.vendor.code}
        setVendor={(code: string, name: string) =>
          dispatch({ type: 'SET_VENDOR', payload: { code, name } })
        }
        hideCreateOption
        disabled={disabled}
      />
    </>
  )
}
