export type FeeBillingType = 'OnDemand' | 'Subscription'

export type FeesRow = {
  id?: string
  regionCode: string
  currencyCode: string
  feeType: FeeType
  price: number | string
  billingType: FeeBillingType
  overrideCurrencyCode?: string
  discountedPrice?: number
}

export type FeeType =
  | 'Management fee'
  | 'Off ramp fee'
  | 'On ramp fee'
  | 'Major order fee'
  | 'Minor order fee'
  | 'Major expedited order shipping fee'
  | 'Minor expedited order shipping fee'
  | 'Expedited shipping'
  | 'Major return fee'
  | 'Minor return fee'
  | 'ITAD return fee'
  | 'Intake fee'
  | 'Imaging fee'
  | string

export type V3FeeType =
  | 'Standard order fee'
  | 'Lightweight order fee'
  | 'Standard expedited order fee'
  | 'Lightweight expedited order fee'
  | 'Standard return fee'
  | 'Lightweight return fee'
  | 'ITAD return fee'
  | 'Intake fee'
  | 'Imaging fee'
  | string

export const serviceFeeTypes: FeeType[] = [
  'Off ramp fee',
  'On ramp fee',
  'Major order fee',
  'Minor order fee',
  'Major expedited order shipping fee',
  'Minor expedited order shipping fee',
  'Major return fee',
  'Minor return fee',
  'Expedited shipping',
  'ITAD return fee',
  'Intake fee',
  'Imaging fee',
]

export const contractV3ServiceFeeTypes: V3FeeType[] = [
  'Standard order fee',
  'Lightweight order fee',
  'Standard expedited order fee',
  'Lightweight expedited order fee',
  'Standard return fee',
  'Lightweight return fee',
  'ITAD return fee',
  'Intake fee',
  'Imaging fee',
]

export const contractV3ServiceFeeTypesMap: Record<V3FeeType, FeeType> = {
  'Standard order fee': 'Major order fee',
  'Lightweight order fee': 'Minor order fee',
  'Standard expedited order fee': 'Major expedited order shipping fee',
  'Lightweight expedited order fee': 'Minor expedited order shipping fee',
  'Standard return fee': 'Major return fee',
  'Lightweight return fee': 'Minor return fee',
  'ITAD return fee': 'ITAD return fee',
  'Intake fee': 'Intake fee',
  'Imaging fee': 'Imaging fee',
}

export type FormFeesByRegionByType = Record<string, Record<FeeType, FeesRow>>
