import {
  DocumentNode,
  LazyQueryHookOptions,
  useLazyQuery,
} from '@apollo/client'
import { SortDirections } from '@firstbase/components/atoms/Table'
import { PageQueryParams } from '@firstbase/components/atoms/Table/Table'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { SortDirection } from '@globalTypes'

import isEqual from 'lodash.isequal'

/**
 *
 * wraps apollo/client useLazyQuery to conform to the @atoms/Table
 * query prop construct
 */
const useTableGraphqlQuery = <QueryType, VariablesType = any>(
  query: DocumentNode,
  options?: LazyQueryHookOptions<QueryType, VariablesType>,
  getData: (response: QueryType) => any = (data: QueryType) => data
) => {
  const frozenInitialOptions = useRef(options)
  const memoGetData = useCallback(getData, [getData])
  const [stateVariables, setStateVariables] = useState(options?.variables || {})
  const [apolloFetch, { loading: isLoading, error, data, previousData }] =
    useLazyQuery<QueryType, VariablesType>(query, frozenInitialOptions.current)

  // prevent refetch from useLazyQuery when variables change
  // by explicity re-executing the query with updated variables
  useEffect(() => {
    if (options?.variables) {
      const newVariables = options?.variables || {}

      if (!isEqual(newVariables, stateVariables))
        setStateVariables(newVariables)
    }
  }, [options?.variables, stateVariables])

  const fetch = useCallback(
    ({
      pageIndex,
      rowsPerPage,
      activeSortDirection,
      activeSortId,
    }: PageQueryParams) =>
      apolloFetch({
        variables: {
          ...stateVariables,
          pageNumber: pageIndex + 1,
          pageSize: rowsPerPage,
          ...(activeSortId
            ? {
                sort: [
                  {
                    direction:
                      activeSortDirection === SortDirections.desc
                        ? SortDirection.DESC
                        : SortDirection.ASC,
                    field: activeSortId,
                  },
                ],
              }
            : {}),
        } as unknown as VariablesType,
      }),
    [apolloFetch, stateVariables]
  )

  const fullDataToPresent = data || previousData
  const tableData = fullDataToPresent
    ? memoGetData(fullDataToPresent)
    : undefined

  const returnQuery = useMemo(
    () => ({
      fetch,
      isLoading,
      error,
      data: tableData,
      isPreviousData: previousData && isLoading,
    }),
    [tableData, error, fetch, isLoading, previousData]
  )

  return returnQuery
}

export default useTableGraphqlQuery
