import React from 'react'

import Page from '@firstbase/components/atoms/Page'
import Table from '@firstbase/components/atoms/Table'
import { GET_DOCUMENTS, getDocuments } from '@firstbase/data/Assistant'
import useTableGraphqlQuery from '@firstbase/hooks/useTableGraphqlQuery'

import { DOCUMENTS_COLUMNS } from './documentsColumns'

const Documents = () => {
  const query = useTableGraphqlQuery<getDocuments>(
    GET_DOCUMENTS,
    undefined,
    // HACK: This is a hack to get the documents to display correctly, table expects a data key
    (data) => ({ data: data.documents })
  )

  return (
    <>
      <Page.TitleSection
        sectionTitle="Documents"
        sectionSubtitle="List of all of the documents used to train the assistant"
      />
      <Table
        defaultSortId="title"
        tableId="documents"
        columns={DOCUMENTS_COLUMNS}
        query={query}
      />
    </>
  )
}

export default Documents
