import { gql } from '@apollo/client'

export const GET_ALL_CATEGORIES = gql`
  query getAllCategories($pageNumber: Int!, $pageSize: Int!, $sort: [Sort]) {
    getAllCategories(
      pagingAndSorting: {
        pageNumber: $pageNumber
        pageSize: $pageSize
        sort: $sort
      }
    ) {
      totalElements
      data {
        id
        nameSingular
        code
        skuType
      }
    }
  }
`
