import React from 'react'
import { Box, IconButton, ListItem, ListItemText } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import { useAuthState, Role } from '@firstbase/context/auth/AuthProvider'

import { MetadataOption } from './types'

interface OwnProps {
  handleDeleteOption: () => void
  isInConfirmDeleteMode: boolean
  materialUiProps: React.HTMLAttributes<HTMLLIElement>
  option: MetadataOption
  toggleConfirmDeleteMode: (val: boolean) => void
}

const MetadataDropdownOption = ({
  handleDeleteOption,
  isInConfirmDeleteMode,
  materialUiProps,
  option,
  toggleConfirmDeleteMode,
}: OwnProps) => {
  const { hasRole } = useAuthState()
  const isCatalogAdmin = hasRole(Role.CatalogAdmin)
  const isAddNewOption = option.label.includes('Add "')

  return (
    <ListItem
      {...materialUiProps}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        '&:hover .hoverable-icon': {
          visibility: 'visible',
        },
      }}
    >
      {!isInConfirmDeleteMode ? (
        <>
          <ListItemText primary={option.label} />
          {!isAddNewOption && isCatalogAdmin ? (
            <IconButton
              className="hoverable-icon"
              sx={{
                visibility: 'hidden',
              }}
              onClick={(event) => {
                event.stopPropagation()
                toggleConfirmDeleteMode(true)
              }}
              aria-label="delete"
              size="small"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          ) : null}
        </>
      ) : (
        <>
          <ListItemText primary="Are you sure you want to delete?" />
          <Box sx={{ display: 'flex', gap: '0.5rem' }}>
            <IconButton
              onClick={(event) => {
                event.stopPropagation()
                toggleConfirmDeleteMode(false)
              }}
              aria-label="cancel"
              size="small"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
            <IconButton
              onClick={(event) => {
                event.stopPropagation()
                handleDeleteOption()
              }}
              aria-label="confirm"
              size="small"
            >
              <CheckIcon fontSize="small" />
            </IconButton>
          </Box>
        </>
      )}
    </ListItem>
  )
}

export default MetadataDropdownOption
