import { PagingAndSorting } from '@globalTypes'
import { useMemo, useState } from 'react'

export const usePagingAndSorting = () => {
  const [pageNumber, setPageNumber] =
    useState<PagingAndSorting['pageNumber']>(1)
  const [pageSize, setPageSize] = useState<PagingAndSorting['pageSize']>(50)
  const [sort, setSort] = useState<PagingAndSorting['sort']>()

  const pagingAndSorting: PagingAndSorting = useMemo(
    () => ({
      pageNumber,
      pageSize,
      sort,
    }),
    [pageNumber, pageSize, sort]
  )

  const setPagingAndSorting = {
    pageNumber: setPageNumber,
    pageSize: setPageSize,
    sort: setSort,
  }

  return {
    pagingAndSorting,
    setPagingAndSorting,
  }
}
