import { MinimumInventoryWithUpdatedQuantity } from './productTypes'

const doesErrorExistForMinimumInventory = (
  minimumQuantity: number,
  includeUsed: boolean | null
) => includeUsed && minimumQuantity === -1

export const doesEmptyMinimumInventoryExist = (
  minimumInventory: MinimumInventoryWithUpdatedQuantity[]
) => {
  return minimumInventory.some(({ minimumQuantity, includeUsed }) =>
    doesErrorExistForMinimumInventory(minimumQuantity, includeUsed)
  )
}

export const filterOutEmptyMinimumInventories = (
  minimumInventory: MinimumInventoryWithUpdatedQuantity[]
) =>
  minimumInventory.filter(
    ({ minimumQuantity, includeUsed }) =>
      !doesErrorExistForMinimumInventory(minimumQuantity, includeUsed)
  )
