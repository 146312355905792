import { Alert, AlertProps, AlertTitle } from '@mui/material'
import React from 'react'

const defaultProps = {
  title: 'Error',
  body: 'An error occurred whilst loading this content. If this persists please contact Engineering',
}

type OwnProps = AlertProps & {
  error?: Error
  title?: string
  body?: string
}

function SectionError({
  error,
  title = defaultProps.title,
  body = defaultProps.body,
  ...alertProps
}: OwnProps) {
  return (
    <Alert {...alertProps} severity="error">
      <AlertTitle>{title}</AlertTitle>
      {error?.message || body}
    </Alert>
  )
}

export default SectionError
