import { ReturnReason } from '@globalTypes'

const RETURN_REASON_TO_READABLE_VALUE_MAP = {
  [ReturnReason.FULFILLMENT_ERROR]: 'Person received incorrect item',
  [ReturnReason.OFF_CYCLE]: 'Person no longer needs item',
  [ReturnReason.DAMAGED]: 'Item is damaged',
  [ReturnReason.UNKNOWN]: 'Not available',
  [ReturnReason.UPGRADE]: 'Eligible for an upgrade',
  [ReturnReason.LOST_BY_WORKER]: 'Person lost item',
  [ReturnReason.LOST_BY_PARTNER]: 'Item was lost in transit',
  [ReturnReason.STANDARD_LIFECYCLE]: 'Requires lifecycle replacement',
  [ReturnReason.PRODUCT_CHANGE]: 'Prefers a different item',
  [ReturnReason.OFFBOARDING]: 'Offboarding',
  [ReturnReason.OTHER]: 'Other',
}

export const readableReturnReason = (returnReason: ReturnReason | null) =>
  RETURN_REASON_TO_READABLE_VALUE_MAP[returnReason || ReturnReason.OTHER] ||
  ReturnReason.OTHER
