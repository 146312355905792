import React, { useRef, useState, useMemo, useEffect } from 'react'
import {
  MenuItem,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  FormControl,
  InputLabel,
} from '@mui/material'
import { NOT_SUPPORTED } from '@firstbase/constants/contract'
import Select from '@atoms/FBSelect'
import { theme } from '@firstbase/muiTheme'
import {
  useRegionCurrency,
  useSupportedCurrencies,
  useSupportedRegions,
} from '@utils/contractUtils'

type OwnProps = {
  formCurrenciesByRegion: Record<string, string>
  updateRegionCurrency: (regionCode: string, currency: string) => void
}

type SupportedRegionsArray = {
  regionCode: string
  regionName: string
}[]

function CurrenciesTableForm({
  formCurrenciesByRegion,
  updateRegionCurrency,
}: OwnProps) {
  // freeze initial form currencies to control disabled regions
  const initialFormCurrenciesByRegion = useRef(formCurrenciesByRegion)

  const supportedRegions = useSupportedRegions()
  const supportedCurrencies = useSupportedCurrencies()
  const regionCurrency = useRegionCurrency()

  const allSupportedRegions = useMemo(
    () =>
      Object.entries(supportedRegions).map(([regionCode, regionName]) => ({
        regionCode: regionCode,
        regionName: regionName,
      })),
    [supportedRegions]
  )
  const [supportedRegionsInitialized, setSupportedRegionsInitialized] =
    useState(false)
  const [configuredSupportedRegions, setConfiguredSupportedRegions] =
    useState<SupportedRegionsArray>([])
  const [nonConfiguredSupportedRegions, setNonConfiguredSupportedRegions] =
    useState<SupportedRegionsArray>([])

  const addNewSupportedRegion = (regionCode: string) => {
    const foundEntry: { regionCode: string; regionName: string } | undefined =
      allSupportedRegions.find((region) => region.regionCode === regionCode)
    if (foundEntry) {
      setConfiguredSupportedRegions((prevConfiguredCountries) => {
        return [
          ...prevConfiguredCountries,
          {
            regionCode: foundEntry?.regionCode,
            regionName: foundEntry?.regionName,
          },
        ]
      })
    }

    setNonConfiguredSupportedRegions((prevNonConfiguredCountries) =>
      prevNonConfiguredCountries.filter(
        (region) => region.regionCode !== regionCode
      )
    )
    updateRegionCurrency(regionCode, regionCurrency[regionCode].acronym)
  }

  /**
   * Quick hack to reinitialize the state variables after the
   * queries resolve, upon mounting the component
   */
  useEffect(() => {
    if (!supportedRegionsInitialized && allSupportedRegions.length > 0) {
      setConfiguredSupportedRegions(() =>
        allSupportedRegions.filter(({ regionCode }) =>
          initialFormCurrenciesByRegion.current.hasOwnProperty(regionCode)
        )
      )
      setNonConfiguredSupportedRegions(() =>
        allSupportedRegions.filter(
          ({ regionCode: regionCode }) =>
            !initialFormCurrenciesByRegion.current.hasOwnProperty(regionCode)
        )
      )
      setSupportedRegionsInitialized(true)
    }
  }, [allSupportedRegions, supportedRegionsInitialized])

  return (
    <>
      <Typography sx={{ fontWeight: 500, marginBottom: 1.5 }}>
        Supported regions & currency
      </Typography>
      <Card variant="outlined" sx={{ maxWidth: '858px', marginBottom: 4 }}>
        <TableContainer>
          <Table aria-label="currency/region table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ padding: '16px 8px 8px 16px' }}>
                  <Typography variant="inherit" sx={{ fontWeight: 'bold' }}>
                    Region
                  </Typography>
                </TableCell>
                <TableCell sx={{ padding: '16px 8px 8px 16px' }}>
                  <Typography variant="inherit" sx={{ fontWeight: 'bold' }}>
                    Charged currency
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {configuredSupportedRegions.map(({ regionCode, regionName }) => (
                <TableRow
                  key={`Supported Region - ${regionCode}`}
                  data-testid={`Supported Region Row - ${regionCode}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell sx={{ padding: 1.5, width: '50%' }}>
                    <Typography variant="inherit">{regionName}</Typography>
                  </TableCell>
                  <TableCell sx={{ padding: 1.5, width: '50%' }}>
                    <FormControl fullWidth>
                      <InputLabel
                        size="small"
                        id={`${regionCode}-currency-label`}
                      >
                        Currency
                      </InputLabel>
                      <Select
                        labelId={`${regionCode}-currency-label`}
                        id={`${regionCode}-currency-select`}
                        value={
                          formCurrenciesByRegion[regionCode] || NOT_SUPPORTED
                        }
                        onChange={(e) =>
                          updateRegionCurrency(regionCode, e.target.value)
                        }
                        label="Currency"
                        size="small"
                        data-testid={`Supported Region Dropdown - ${regionCode}`}
                        inputProps={{
                          'data-testid': `Supported Region Select - ${regionCode}`,
                        }}
                      >
                        {Object.keys(supportedCurrencies).map(
                          (currency: String) => (
                            <MenuItem
                              value={currency as any}
                              key={`${regionCode} - ${currency}`}
                            >
                              {currency}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
              {!!nonConfiguredSupportedRegions.length && (
                <TableRow
                  key={`Add Region`}
                  data-testid={`Add Supported Region Row`}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    backgroundColor: theme.palette.grey[300],
                  }}
                >
                  <TableCell>
                    <Typography sx={{ fontWeight: 500 }}>
                      Add new region
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: 1.5, width: '50%' }}>
                    <FormControl fullWidth>
                      <InputLabel size="small" id={`add-region-label`}>
                        Select region
                      </InputLabel>
                      <Select
                        labelId={`add-region-label`}
                        id={`add-region-select`}
                        onChange={(e) =>
                          addNewSupportedRegion(e.target.value + '')
                        }
                        label="Select region"
                        size="small"
                      >
                        {nonConfiguredSupportedRegions.map(
                          ({ regionCode, regionName }) => (
                            <MenuItem
                              value={regionCode as any}
                              key={`${regionName}`}
                            >
                              {regionName}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  )
}

export default CurrenciesTableForm
