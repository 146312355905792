import React, { useState } from 'react'
import { Alert, Box } from '@mui/material'
import ClientApiTokensCreateForm from './ClientApiTokensCreateForm'
import { useMutation } from '@apollo/client'
import { CREATE_API_TOKEN_FOR_ORGANIZATION } from '@firstbase/data/ApiToken/queries'
import { createApiTokenForOrg } from '@firstbase/data/ApiToken/__generated__/createApiTokenForOrg'
import { useSnackbar } from 'notistack'
import ProtectedChildren from '@firstbase/components/atoms/ProtectedChildren/ProtectedChildren'
import { Role } from '@firstbase/context/auth/AuthProvider'

type OwnProps = {
  clientId: string
}

const ClientApiTokensCreate = ({ clientId }: OwnProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const [name, setName] = useState<string>('')
  const [createApiTokenMutation, { loading }] =
    useMutation<createApiTokenForOrg>(CREATE_API_TOKEN_FOR_ORGANIZATION, {
      refetchQueries: ['getAllApiTokensForOrganization'],
    })

  const generateApiToken = async () => {
    try {
      const result = await createApiTokenMutation({
        variables: {
          tokenName: name.trim(),
          organisationId: clientId,
        },
      })
      navigator.clipboard.writeText(
        result.data?.createApiTokenForOrg.token || ''
      )
      setName('')
      enqueueSnackbar(
        `Successfully created API token for organization ${clientId}. Token was copied in your clipboard.`
      )
    } catch (err) {
      const error = err as Error
      enqueueSnackbar(
        `Failed to generate API token for organization ${clientId}: ${error.message}`,
        {
          variant: 'error',
        }
      )
    }
  }

  return (
    <>
      <Alert severity="info">
        Only CX and Admin users can create and delete API tokens. When creating
        a token, it will automatically be copied into your clipboard.
      </Alert>
      <ProtectedChildren hasRole={[Role.CX, Role.Admin]}>
        <Box
          sx={{
            marginBottom: 2,
            marginTop: 2,
          }}
        >
          <ClientApiTokensCreateForm
            loading={loading}
            name={name}
            setName={setName}
            generateApiToken={generateApiToken}
          />
        </Box>
      </ProtectedChildren>
    </>
  )
}

export default ClientApiTokensCreate
