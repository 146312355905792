import { gql } from '@apollo/client'
import { WAREHOUSE } from './fragments'

export const GET_ORGANIZATION_WAREHOUSES = gql`
  query getWarehousesForOrgId($orgId: ID!) {
    getWarehousesForOrgId(orgId: $orgId) {
      name
      code
      id
      active
    }
  }
`

export const GET_PAGED_WAREHOUSES = gql`
  ${WAREHOUSE}
  query getPagedWarehouses($pageNumber: Int!, $pageSize: Int!, $sort: [Sort]) {
    getAllWarehouses(
      pagingAndSorting: {
        pageNumber: $pageNumber
        pageSize: $pageSize
        sort: $sort
      }
    ) {
      data {
        ...Warehouse
      }
      totalElements
    }
  }
`

export const GET_ALL_WAREHOUSES = gql`
  ${WAREHOUSE}
  query getAllWarehouses {
    getAllWarehouses(pagingAndSorting: { pageNumber: 1, pageSize: 500 }) {
      data {
        ...Warehouse
      }
    }
  }
`
