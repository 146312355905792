import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'

import OktaSignInWidget from './OktaSignInWidget'

import { Tokens } from '@okta/okta-auth-js'
import Loader from '@atoms/Loader'

type OwnProps = {
  config: {
    baseUrl: string
    clientId: string
    redirectUri: string
  }
}

const SignInForm = ({ config }: OwnProps) => {
  const { oktaAuth, authState } = useOktaAuth()
  const [checkSession, setCheckSession] = useState(true)

  // sessionChecker checks to see if we have a cookie from idp validated login
  const sessionChecker = async () => {
    const session = await oktaAuth.session.exists()
    if (session) {
      const tokens = await oktaAuth.token.getWithoutPrompt()
      oktaAuth.handleLoginRedirect(tokens.tokens)
    }
    setCheckSession(false)
  }

  const onSuccess = (tokens: Tokens) => {
    oktaAuth.handleLoginRedirect(tokens)
  }

  const onError = (err: Error) => {
    //  eslint-disable-next-line no-console
    console.error('error logging in', err)
  }

  if (!authState) return null

  if (authState.isAuthenticated) {
    // check if user was JIT created
    const personId = authState.accessToken?.claims?.personId
    if (!personId || String(personId).trim().length < 1) {
      return <Loader />
    }

    const path = '/home'

    return <Redirect to={{ pathname: path }} />
  }

  if (checkSession) {
    sessionChecker()
    return <Loader />
  }

  return (
    <OktaSignInWidget config={config} onSuccess={onSuccess} onError={onError} />
  )
}
export default SignInForm
