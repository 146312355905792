import { gql } from '@apollo/client'

export const GET_PERSON_BY_ORGANIZATION_SLUG_AND_PERSON_SLUG = gql`
  query getPersonBySlugAndOrganizationSlug(
    $slug: String
    $organizationSlug: String
  ) {
    getPersonBySlugAndOrganizationSlug(
      slug: $slug
      organizationSlug: $organizationSlug
    ) {
      id
      email
      forename
      surname
      appAccess
      slug
      secondaryEmail
      startDate
      status
      newHire
      address {
        addressLine1
        addressLine2
        administrativeArea
        countryCode
        dependentLocality
        locality
        postalCode
        sortingCode
        phoneNumber
      }
      emailNotifications {
        id
        type
      }
      orders {
        id
        orderType
        orderStatus
        products {
          id
          status
          sku {
            id
            skuInformation {
              productTitle
            }
          }
          returnReason
        }
      }
      packages {
        skus {
          id
          category {
            nameSingular
          }
          vendor {
            name
          }
          skuInformation {
            productTitle
          }
        }
      }
      inventories {
        id
        serialNumber
        description
        warehouse {
          name
        }
        sku {
          id
          skuInformation {
            productTitle
          }
        }
        pricing {
          priceOption
        }
        active
        conditionStatus
        deployStatus
        deployReason
        firstbaseSupplied
        estimatedRenewalDate
        renewalDate
        unlockDate
      }
      integrations {
        workatoUserId
      }
    }
  }
`

export const ORDER = gql`
  fragment Order on OrderResponse {
    id
    orderStatus
    adminPlaced
    createdAt
    placedBy
    products {
      trackingLink
      id
      status
      sku {
        skuInformation {
          productTitle
        }
      }
    }
    orderType
    productPackage {
      id
      approvalRequired
      automaticOrder
      slug
      name
      active
    }
  }
`
export const GET_PERSON_ALL_ORDERS_BY_ORGANIZATION_SLUG_AND_PERSON_SLUG = gql`
  query getPersonOrdersBySlugAndOrganizationSlug(
    $slug: String
    $organizationSlug: String
  ) {
    getPersonBySlugAndOrganizationSlug(
      slug: $slug
      organizationSlug: $organizationSlug
    ) {
      id
      orders {
        id
        orderStatus
        orderType
        createdAt
      }
      replacements {
        id
        status
        createdAt
      }
    }
  }
`
