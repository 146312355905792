import React, { useState } from 'react'
import { IconButton } from '@mui/material'
import { ReactComponent as PencilEditIcon } from '@firstbase/assets/PencilEdit.svg'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'

import {
  DELETE_DOCUMENT,
  deleteDocument as deleteDocumentType,
  deleteDocumentVariables,
} from '@firstbase/data/Assistant'

interface OwnProps {
  id: string
}

const DeleteDocumentCell = ({ id }: OwnProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const [isInDeleteMode, setIsInDeleteMode] = useState(false)

  const [deleteDocument, { loading: deleting }] = useMutation<
    deleteDocumentType,
    deleteDocumentVariables
  >(DELETE_DOCUMENT)

  const handleDelete = () => {
    deleteDocument({
      variables: { id },
      update(cache, { data }) {
        if (data?.deleteDocument.success) {
          cache.evict({ id: `Document:${id}` })
          cache.gc()
        }
      },
      onCompleted(data) {
        if (data?.deleteDocument.success) {
          enqueueSnackbar('Document deleted', { variant: 'success' })
        } else {
          // eslint-disable-next-line no-console
          console.error(
            'Deleting document errors:',
            data?.deleteDocument.errors
          )
          enqueueSnackbar(
            'Failed to delete document. Please check the console.',
            { variant: 'error' }
          )
        }
      },
    })
  }

  if (isInDeleteMode) {
    return (
      <div>
        <IconButton
          onClick={() => setIsInDeleteMode(false)}
          disabled={deleting}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <IconButton onClick={handleDelete} disabled={deleting}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </div>
    )
  }

  return (
    <IconButton onClick={() => setIsInDeleteMode(true)}>
      <PencilEditIcon width="1rem" height="1rem" />
    </IconButton>
  )
}

export default DeleteDocumentCell
