import { useQuery } from '@apollo/client'

import {
  getOrderByIdForOrganisation,
  getOrderByIdForOrganisationVariables,
  getOrderByIdForOrganisation_getOrderByIdForOrganisation,
} from '@firstbase/data/Order/__generated__/getOrderByIdForOrganisation'
import {
  getReplacementByIdForOrganization,
  getReplacementByIdForOrganizationVariables,
  getReplacementByIdForOrganization_getReplacementByIdForOrganization,
} from '@firstbase/data/Order/__generated__/getReplacementByIdForOrganization'
import {
  GET_ORDER_RETURN_BY_ID,
  GET_REPLACEMENT_BY_ID,
} from '@firstbase/data/Order/query'

import { ORDER_TYPE } from '../types'

interface ExtendedGetOrderByIdForOrganisation
  extends getOrderByIdForOrganisation_getOrderByIdForOrganisation {
  orderType: keyof typeof ORDER_TYPE
}

type OrderData =
  | ExtendedGetOrderByIdForOrganisation
  | getReplacementByIdForOrganization_getReplacementByIdForOrganization

interface OrderDataResult {
  orderData: OrderData | undefined
  loading: boolean
}

function useOrderData(orderId: string, clientId: string): OrderDataResult {
  /**
   * only one of these requests will success
   * ie. the order is either a Order/Return
   * or a Replacement
   *
   * make both requests then use the result to determine the child detail
   * to render
   *
   * this keeps a consistent URI for all orders
   */
  const { data: orderReturn, loading: loadingOrderReturn } = useQuery<
    getOrderByIdForOrganisation,
    getOrderByIdForOrganisationVariables
  >(GET_ORDER_RETURN_BY_ID, {
    variables: { orderId, orgId: clientId },
  })
  const { data: replacement, loading: loadingReplacement } = useQuery<
    getReplacementByIdForOrganization,
    getReplacementByIdForOrganizationVariables
  >(GET_REPLACEMENT_BY_ID, {
    variables: {
      replacementId: orderId,
      orgId: clientId,
    },
  })

  const orderData: OrderData | undefined =
    (orderReturn?.getOrderByIdForOrganisation as OrderData) ||
    replacement?.getReplacementByIdForOrganization

  return { orderData, loading: loadingOrderReturn || loadingReplacement }
}

export default useOrderData
