import Typography from '@mui/material/Typography'
import MUIBreadcrumbs from '@mui/material/Breadcrumbs'
import React from 'react'
import { Skeleton } from '@mui/material'
import { useParams, Link as RouterLink } from 'react-router-dom'

import { useBreadcrumbs } from '@firstbase/context/breadcrumbs/BreadcrumbsProvider'
import { interpolateQueryParamsInPath } from '@firstbase/routes/routeUtils'
import Link from '@atoms/Link'

const getPropsAndComponent = (
  isLast: boolean
): [
  (props: any) => JSX.Element,
  {
    color: string
    underline?: string
    variant?: string
    sx?: any
    component?: (props: any) => JSX.Element | null
  }
] => {
  if (isLast) {
    return [
      Typography,
      {
        color: 'text.primary',
        sx: {
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          maxWidth: '50vw',
          whiteSpace: 'nowrap',
        },
      },
    ]
  }

  return [
    Link,
    {
      underline: 'hover',
      color: 'inherit',
      variant: 'inherit',
      component: RouterLink,
    },
  ]
}

function Breadcrumbs() {
  const params = useParams()
  const { breadcrumbs } = useBreadcrumbs()

  return (
    <MUIBreadcrumbs
      maxItems={3}
      itemsBeforeCollapse={0}
      itemsAfterCollapse={2}
      aria-label="breadcrumb"
      sx={{ pb: 1, pt: 2 }}
    >
      {breadcrumbs.map(({ label, pathId, searchParams, isLoading }, index) => {
        if (isLoading)
          return <Skeleton key={`${label}-crumb`} sx={{ width: '5rem' }} />

        const isLast = index === breadcrumbs.length - 1
        const [Component, props] = getPropsAndComponent(isLast)

        return (
          <Component
            key={`${label}-crumb`}
            to={
              pathId
                ? interpolateQueryParamsInPath(pathId, params, searchParams)
                : undefined
            }
            {...props}
          >
            {label}
          </Component>
        )
      })}
    </MUIBreadcrumbs>
  )
}

export default Breadcrumbs
