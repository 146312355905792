import React from 'react'
import { Route, Switch } from 'react-router-dom'

import PageWrapper from '@firstbase/components/molecules/PageWrapper/PageWrapper'
import { useAuthState } from '@firstbase/context/auth/AuthProvider'

import routes, { isAuthorizedForRoute } from '@firstbase/routes/routes'
import ErrorBoundary from '@firstbase/components/atoms/ErrorBoundary'

function FourOhFour() {
  return <div> Missing Page </div>
}

function Home() {
  const authData = useAuthState()

  if (!authData) {
    return <div>Error finding user role</div>
  }

  const userRoutes = routes.filter(isAuthorizedForRoute(authData.hasRole))

  return (
    <PageWrapper>
      <ErrorBoundary>
        <Switch>
          {userRoutes.map(({ component: Component, ...rest }) => (
            <Route {...rest} key={rest.key}>
              <Component />
            </Route>
          ))}
          <Route path="*" component={FourOhFour} />
        </Switch>
      </ErrorBoundary>
    </PageWrapper>
  )
}

export default Home
