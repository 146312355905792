import { useQuery } from '@apollo/client'

import { SkuMetadataFieldName } from '@globalTypes'

import {
  GET_AVAILABLE_WARRANTY_METADATA_FIELDS,
  getAvailableWarrantyMetadataFields,
  getOrganizationWarrantiesVariables,
  GET_WARRANTY_METADATA_CONFIG_FOR_CATEGORY,
  getWarrantyMetadataConfigForCategory,
  getWarrantyMetadataConfigForCategoryVariables,
} from '@firstbase/data/Warranties'
import { filterNullsOut } from '@firstbase/utils/array/filterNullsOut'

export const useMetadataConfigFields = (categoryCode: string) => {
  const { data: fieldNames, loading: fieldNamesLoading } = useQuery<
    getAvailableWarrantyMetadataFields,
    getOrganizationWarrantiesVariables
  >(GET_AVAILABLE_WARRANTY_METADATA_FIELDS)

  const { data: configFields, loading: configFieldsLoading } = useQuery<
    getWarrantyMetadataConfigForCategory,
    getWarrantyMetadataConfigForCategoryVariables
  >(GET_WARRANTY_METADATA_CONFIG_FOR_CATEGORY, {
    variables: {
      categoryCode: categoryCode ?? '',
    },
    skip: !categoryCode,
    fetchPolicy: 'network-only',
  })

  const getFieldValue = (fieldName: SkuMetadataFieldName) => {
    if (!configFields?.getConfigurationsForCategoryCode.length) return null

    const value = filterNullsOut(
      configFields?.getConfigurationsForCategoryCode || []
    ).find((fieldData) => fieldData.fieldName === fieldName)

    return value
  }

  return {
    fieldNames: fieldNames?.getAvailableFieldNames || [],
    loading: fieldNamesLoading || configFieldsLoading,
    getFieldValue,
  }
}
