import React from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { format } from 'date-fns'

import { CREATE_SKU } from '@firstbase/data/SKU/mutations'
import {
  createSku,
  createSkuVariables,
} from '@firstbase/data/SKU/__generated__/createSku'

import { WarrantySkuForm, ReducerState } from './WarrantySkuForm'

export const CreateWarrantySku = () => {
  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()
  const closeModal = () => history.replace('/platform/warranties')

  const [createSkuMutation, { loading: createSkuLoading }] = useMutation<
    createSku,
    createSkuVariables
  >(CREATE_SKU, {
    onCompleted: () => {
      enqueueSnackbar('Warranty created successfully', { variant: 'success' })
      closeModal()
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error)
      enqueueSnackbar(
        'Error creating warranty. Please check the console and share with the team.',
        { variant: 'error' }
      )
    },
  })

  // Copy of what is in add global product
  const generateVendorSku = () => {
    const currentDate = new Date()
    const formattedDate = format(currentDate, 'yyyyMMdd')
    const random4DigitNumber = Math.floor(1000 + Math.random() * 9000)

    return `UNKNOWN-${formattedDate}-${random4DigitNumber}`
  }

  const handleSubmit = (state: ReducerState) => {
    const metdataFieldsArray = Object.entries(state.metadataFields)
      .filter(([_, { value }]) => value !== '')
      .map(([key, { value }]) => ({
        fieldId: key,
        value,
      }))

    createSkuMutation({
      variables: {
        createSku: {
          productTitle: metdataFieldsArray
            .map((field) => field.value)
            .join(' '),
          genericCategory: false,
          categoryCode: 'WARRANTY',
          vendorCode: state.vendor.code,
          skuImages: [state.imageData.url],
          vendorSku: state.mpn || generateVendorSku(),
          partNumber: state.mpn || null,
          standardPricing: state.pricingDetails.rows,
          metadata: metdataFieldsArray,
        },
      },
    })
  }

  return (
    <WarrantySkuForm
      closeModal={closeModal}
      title="Add new warranty"
      initialState={{
        category: '',
        vendor: { name: '', code: '' },
        metadataFields: {},
        imageData: {
          url: '',
          isValid: true,
        },
        mpn: '',
        pricingDetails: {
          regionCodeSelect: '',
          currencyCodeSelect: '',
          rows: [],
        },
      }}
      handleSubmit={handleSubmit}
      submitting={createSkuLoading}
    />
  )
}
