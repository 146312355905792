import { TabProps } from '@mui/material'
import { createContext } from 'react'

export type SetTabSelectorType = (id: string, props: Partial<TabProps>) => void
type TabGroupContextType = {
  activeTabId?: string
  setTabSelector: SetTabSelectorType
}

export const TabGroupContext = createContext<TabGroupContextType>({
  activeTabId: '',
  setTabSelector: () => null,
})
