import { gql } from '@apollo/client'

export const GET_ALL_VENDORS = gql`
  query getAllVendors($pageNumber: Int!, $pageSize: Int!, $sort: [Sort]) {
    getAllVendors(
      pagingAndSorting: {
        pageNumber: $pageNumber
        pageSize: $pageSize
        sort: $sort
      }
    ) {
      totalElements
      data {
        id
        name
        code
      }
    }
  }
`
