import { gql } from '@apollo/client'

export const SKU_BASICS = gql`
  fragment SkuBasics on SKU {
    id
    vendorSku
    alternativeMpn
    ean
    upc
    vendor {
      id
      name
      code
    }
    genericCategory
    category {
      nameSingular
      code
      id
      skuType
    }
    skuImages
    skuInformation {
      productTitle
    }
    status
    standardCatalog
    standardPricing {
      regionCode
      currencyCode
      purchasePrice
      subscriptionPricing {
        length
        interval
        price
      }
    }
    metadata {
      id
      field
      value
    }
  }
`

export const SKU_METADATA_OPTION_FIELD_VALUES = gql`
  fragment SkuMetadataOptionFieldValues on SkuMetadataOptionField {
    id
    values {
      id
      value
    }
  }
`
