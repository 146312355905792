import React, { Dispatch, SetStateAction } from 'react'
import { Grid, TextField } from '@mui/material'

interface OwnProps {
  genericCategory: boolean
  category: string
  title: string
  setTitle: Dispatch<SetStateAction<string>>
}

const ProductTitle = ({
  genericCategory,
  category,
  title,
  setTitle,
}: OwnProps) => {
  return (
    <Grid item xs={12} md={12}>
      <TextField
        id="title"
        label="Product Title"
        fullWidth
        size="small"
        disabled={genericCategory && !category}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
    </Grid>
  )
}

export default ProductTitle
