import { gql } from '@apollo/client'

export const CREATE_FEATURE = gql`
  mutation createFeature($params: CreateFeatureParams!) {
    createFeature(params: $params) {
      feature {
        id
        name
        status
        description
        category
      }
    }
  }
`

export const UPDATE_FEATURE = gql`
  mutation updateFeature($params: UpdateFeatureParams!) {
    updateFeature(params: $params) {
      feature {
        id
        name
        status
        description
      }
    }
  }
`
