import cloneDeep from 'lodash/cloneDeep'

import {
  FeeBillingType,
  FeeType,
  FeesRow,
  FormFeesByRegionByType,
} from '@firstbase/types/Fee'
import {
  getV3TypeFromPreviousVersion,
  getPreviousVersionFromV3Type,
} from '@firstbase/utils/contractUtils'

/**
 * Wrapper for executing the logic necessary for actually creating the new
 * object that has the updated value for overrideCurrency
 */
export const updateAllOverrideCurrencyCode = (
  formFees: FormFeesByRegionByType,
  newCurrency: string | undefined,
  billingType: FeeBillingType = 'OnDemand'
) => {
  const updatedObject: FormFeesByRegionByType = {}

  for (const countryCode in formFees) {
    updatedObject[countryCode] = {}
    for (const feeType in formFees[countryCode]) {
      updatedObject[countryCode][feeType] = {
        ...formFees[countryCode][feeType], // shallow copy the current feeType object
      }

      if (updatedObject[countryCode][feeType].billingType === billingType) {
        updatedObject[countryCode][feeType].overrideCurrencyCode = newCurrency
      }
    }
  }

  return updatedObject
}

/**
 * Wrapper for updating a fee for a region
 */
export const updateFeeForRegion = (
  formFees: FormFeesByRegionByType,
  price: string | number,
  regionCode: string,
  feeType: FeeType,
  currencyCodeMap: Record<string, string>,
  overrideCurrencyCode?: string
) => {
  /**
   * HACK: The country will not always exist and thus, will need
   * to be initialized properly
   */
  const regionExistsInForm = !!formFees[regionCode]
  const feeExistsForRegion =
    regionExistsInForm && !!formFees[regionCode][feeType]

  if (!feeExistsForRegion) {
    return {
      ...formFees,
      [regionCode]: {
        ...(regionExistsInForm ? formFees[regionCode] : {}),
        [feeType]: {
          price,
          currencyCode: currencyCodeMap[regionCode],
          overrideCurrencyCode,
          regionCode: regionCode,
          billingType: (feeType === 'Management fee'
            ? 'Subscription'
            : 'OnDemand') as FeeBillingType,
          feeType,
        },
      },
    }
  }

  return {
    ...formFees,
    [regionCode]: {
      ...formFees[regionCode],
      [feeType]: {
        ...formFees[regionCode][feeType],
        price,
        overrideCurrencyCode,
      },
    },
  }
}

/**
 * Wrapper for executing the logic necessary for updating a service fee
 * for all countries
 */
export const updateFeeForAllCountries = (
  formFees: FormFeesByRegionByType,
  feeType: FeeType,
  price: number | string,
  currencyCodeMap: Record<string, string>,
  overrideCurrencyCode: string | undefined
) =>
  Object.entries(formFees).reduce((acc, [countryCode, fees]) => {
    const feeExistsForCountry = !!formFees[countryCode][feeType]

    if (!feeExistsForCountry) {
      return {
        ...acc,
        [countryCode]: {
          ...fees,
          [feeType]: {
            price,
            currencyCode: currencyCodeMap[countryCode],
            overrideCurrencyCode,
            countryCode,
            billingType: (feeType === 'Management fee'
              ? 'Subscription'
              : 'OnDemand') as FeeBillingType,
            feeType,
          },
        },
      }
    }

    return {
      ...acc,
      [countryCode]: {
        ...fees,
        [feeType]: {
          ...fees[feeType],
          price,
          overrideCurrencyCode,
        },
      },
    }
  }, {})

/**
 * Legacy utility for converting fees between contract
 * versions
 * TODO: Test and cleanup if you have time
 */
export const convertFeesBetweenContractVersions = (
  formFees: FormFeesByRegionByType,
  newVersion: number
) => {
  const clonedFormFees = cloneDeep(formFees)

  // So... This function is the worst. It works, just is more complicated than I wish it was.
  // Does exactly what it says, converts fees between contract versions when transitioning into or out of version 3.
  const conversionFunction =
    newVersion === 3
      ? getV3TypeFromPreviousVersion
      : getPreviousVersionFromV3Type

  // These nested loops iterate through every feetype of every country
  Object.keys(clonedFormFees).forEach((countryCode) => {
    Object.keys(clonedFormFees[countryCode]).forEach((feeType) => {
      const fee = clonedFormFees[countryCode][feeType]
      const convertedFeeType = conversionFunction(feeType)
      const alreadyHasV3Type = Object.keys(
        clonedFormFees[countryCode]
      ).includes(convertedFeeType)

      if (convertedFeeType && !alreadyHasV3Type) {
        clonedFormFees[countryCode] = {
          ...(clonedFormFees[countryCode] || {}),
          [convertedFeeType]: {
            price: fee.price,
            overrideCurrencyCode: fee.currencyCode,
            billingType: fee.billingType,
          } as FeesRow,
        }
      }
    })
  })

  return clonedFormFees
}
