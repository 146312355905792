import { alpha, createTheme } from '@mui/material/styles'
import React from 'react'
import { ReactComponent as CheckboxUnchecked } from '@firstbase/assets/CheckboxUnchecked.svg'
import { ReactComponent as CheckboxChecked } from '@firstbase/assets/CheckboxChecked.svg'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    textLink: React.CSSProperties
  }

  interface Palette {
    caution?: Palette['primary']
  }
  interface PaletteOptions {
    caution?: PaletteOptions['primary']
  }
  interface TypographyVariantsOptions {
    textLink?: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    textLink: true
  }
}

// Extend color prop on components
declare module '@mui/material/Alert' {
  export interface AlertPropsColorOverrides {
    caution: true
  }
}

const caution = createTheme().palette.augmentColor({
  color: {
    main: '#F6DFC8',
    dark: '#5A2505',
    contrastText: '#fff',
  },
})

export const theme = createTheme({
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          border: '1px solid #CACACB',
          borderRadius: '0.5rem',
        },
      },
    },
    // AppBar inherits Paper
    MuiAppBar: {
      styleOverrides: {
        root: {
          border: 'unset',
          borderRadius: 'unset',
        },
      },
    },
    // Alert inherits from Paper
    MuiAlert: {
      styleOverrides: {
        root: {
          border: 'unset',
        },
        standardWarning: {
          color: caution.dark,
          background: caution.main,
          '.MuiAlert-icon': {
            color: caution.dark,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingTop: '1rem',
          paddingBottom: '1rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: '#fff',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          margin: '0.75rem',
          padding: 0,
          '&.Mui-disabled': {
            opacity: '0.27',
          },
        },
      },
      defaultProps: {
        icon: <CheckboxUnchecked width="1rem" height="1rem" />,
        checkedIcon: (
          <CheckboxChecked fillOpacity={1} width="1rem" height="1rem" />
        ),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-colorPrimary': {
            backgroundColor: alpha('#F82EA3', 0.08),
            color: '#F82EA3',
          },
          '&.MuiChip-colorSecondary': {
            backgroundColor: alpha('#191A1B', 0.08),
            color: '#191A1B',
          },
          '&.MuiChip-colorWarning': {
            backgroundColor: '#FAC8CB',
            color: '#AE071E',
          },
          '&.MuiChip-colorSuccess': {
            backgroundColor: '#DEF4EA',
            color: '#225841',
          },
          '&.MuiChip-colorInfo': {
            backgroundColor: '#C9EDFA',
            color: '#2D5DD8',
          },
          '&.MuiChip-colorCaution': {
            backgroundColor: '#F6DFC8',
            color: '#5A2505',
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#F82EA3',
    },
    secondary: {
      main: '#191A1B',
      contrastText: '#fff',
    },
    warning: {
      main: '#AE071E',
      contrastText: '#fff',
    },
    success: {
      main: '#2E7D32',
      contrastText: '#fff',
    },
    info: {
      main: '#2D5DD8',
      contrastText: '#fff',
    },
    caution,
    grey: {
      '100': '#f9fafa',
      '500': '#CACACB',
      '900': '#757676',
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans serif'].join(','),
    allVariants: {
      color: '#191A1B',
    },
    textLink: {
      color: '#2D5DD8',
    },
  },
  spacing: 12,
})
