import React from 'react'
import { TextField, TextFieldProps } from '@mui/material'

const Textarea = (props: TextFieldProps) => (
  <TextField
    fullWidth
    multiline
    minRows={4}
    InputLabelProps={{ shrink: false, size: 'normal' }}
    {...props}
  />
)

export default Textarea
