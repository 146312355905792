import {
  Skeleton,
  SkeletonProps,
  Typography,
  TypographyProps,
} from '@mui/material'
import React from 'react'

type OwnProps = {
  skeletonProps?: SkeletonProps
  isLoading?: boolean
  children: React.ReactNode
} & TypographyProps

const TextSkeleton = ({
  isLoading,
  children,
  skeletonProps,
  ...typographyProps
}: OwnProps) => {
  return (
    <Typography {...typographyProps}>
      {isLoading ? (
        <Skeleton
          sx={{
            display: typographyProps?.display,
          }}
          variant="text"
          width="6rem"
          {...skeletonProps}
        />
      ) : (
        children
      )}
    </Typography>
  )
}

export default TextSkeleton
