import { useFeatureFlag } from '@firstbase/hooks/useFeatureFlag'
import FLAGS from '@firstbase/constants/featureFlags'

import Categories from './Categories/Categories'
import AllInventory from './Inventory/AllInventory'
import InventoryOrders from './InventoryOrders/InventoryOrders'
import Offices from './Offices/Offices'
import People from './People/People'
import Products from './Products/Products'
import { ProductTiers } from './ProductTiers'
import Vendors from './Vendors/Vendors'
import Warehouses from './Warehouses/Warehouses'
import { Warranties } from './Warranties'

export const usePlatformTabs = () => {
  const warrantiesFlag = useFeatureFlag(FLAGS.WARRANTIES_MGMT)

  return [
    { label: 'products', component: Products },
    { label: 'categories', component: Categories },
    { label: 'vendors', component: Vendors },
    { label: 'inventory', component: AllInventory },
    { label: 'inventory_orders', component: InventoryOrders },
    { label: 'people', component: People },
    { label: 'offices', component: Offices },
    { label: 'warehouses', component: Warehouses },
    ...(warrantiesFlag ? [{ label: 'warranties', component: Warranties }] : []),
    { label: 'product_tiers', component: ProductTiers },
  ]
}
