import { Role } from '@firstbase/constants/roles'
import { useAuthState } from '@firstbase/context/auth/AuthProvider'

type RenderPropType = (userHasRole: boolean) => React.ReactNode

type OwnProps = {
  children: React.ReactNode | RenderPropType
  hasRole: Role | Role[]
}

const ProtectedChildren = ({ children, hasRole: roles }: OwnProps) => {
  const { hasRole } = useAuthState()
  const userHasRole = hasRole(roles)

  // supports render-prop
  if (typeof children === 'function') return children(userHasRole)

  if (!userHasRole) return null
  return children
}

export default ProtectedChildren
