import {
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import Select from '@atoms/FBSelect'

import React, { useState } from 'react'
import { BillingType } from './Product'
import { useFeatureFlag } from '@firstbase/hooks/useFeatureFlag'
import FLAGS from '@firstbase/constants/featureFlags'

type Props = {
  onChange: (type: BillingType) => void
  defaultValue?: BillingType
  allowSubscription: boolean
}

const BillTypeSelect = ({
  onChange,
  defaultValue,
  allowSubscription,
}: Props) => {
  const [billType, setBillType] = useState<BillingType>(
    defaultValue || 'Purchased'
  )
  const [originalBillType] = useState<BillingType>(defaultValue || 'Purchased')

  const handleBillTypeChange = (e: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = e

    const billingType = value as BillingType

    setBillType(billingType)
    onChange(billingType)
  }

  const disableSubscriptionPricing = useFeatureFlag(
    FLAGS.PE_139_DISABLE_SUBSCRIPTION_OPTION
  )
  const displaySubscriptionPricing = () => {
    return (
      originalBillType == 'Subscription' ||
      allowSubscription ||
      !disableSubscriptionPricing
    )
  }

  return (
    <FormControl sx={{ width: '50%', my: 2 }}>
      <InputLabel id="billingTypeLabel">Billing Type</InputLabel>
      <Select
        labelId="billingTypeLabel"
        id="billingTypeSelect"
        value={billType}
        label="Billing Type"
        onChange={handleBillTypeChange}
      >
        {displaySubscriptionPricing() ? (
          <MenuItem value="Subscription">Subscription</MenuItem>
        ) : null}
        <MenuItem value="Purchased">Purchased</MenuItem>
      </Select>
      {billType === 'Subscription' && (
        <Typography sx={{ mt: 2 }}>
          <Typography component="span" sx={{ fontWeight: 'bold' }}>
            Note:
          </Typography>{' '}
          For subscription, enter monthly prices only
        </Typography>
      )}
    </FormControl>
  )
}

export default BillTypeSelect
