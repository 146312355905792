import { useQuery } from '@apollo/client'
import {
  getAllFeatures,
  getAllFeatures_getAllFeatures_features_data,
  getAllFeaturesVariables,
} from '@firstbase/data/ProductTier/__generated__/getAllFeatures'
import { GET_ALL_FEATURES } from '@firstbase/data/ProductTier/productTierFeatureQueries'
import { useMemo } from 'react'

export const useGetProductTierFeatures = (
  variables?: getAllFeaturesVariables,
  id?: getAllFeatures_getAllFeatures_features_data['id']
) => {
  const featuresQuery = useQuery<getAllFeatures, getAllFeaturesVariables>(
    GET_ALL_FEATURES,
    {
      variables: variables ?? {
        pagingAndSorting: { pageNumber: 1, pageSize: 100 },
      },
    }
  )

  const features = featuresQuery.data?.getAllFeatures.features?.data

  const featureById = useMemo(() => {
    if (!id || !features) {
      return
    }

    return features.find((feature) => feature.id === id)
  }, [features, id])

  const categories = useMemo(() => {
    if (!features) {
      return []
    }

    return Array.from(
      new Set(
        features
          .map((feature) => feature.category)
          .filter((category): category is string => Boolean(category))
      )
    )
  }, [features])

  // sort features first by category, then by name
  const sortedFeatures = useMemo(() => {
    if (!features) {
      return []
    }

    return features.toSorted((a, b) => {
      const aCategory = a.category ?? ''
      const bCategory = b.category ?? ''
      if (aCategory === bCategory) {
        return a.name.localeCompare(b.name)
      }

      return aCategory.localeCompare(bCategory)
    })
  }, [features])

  return {
    ...featuresQuery,
    categories,
    totalElements: featuresQuery.data?.getAllFeatures.features?.totalElements,
    featureById,
    features,
    sortedFeatures,
  }
}
