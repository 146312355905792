import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { Skeleton } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'

import FLAGS from '@firstbase/constants/featureFlags'
import { ClientPageParams } from '@firstbase/types/Pages'
import {
  GET_ORGANIZATION_CATEGORIES,
  GET_ORGANIZATION_CATEGORIES_WITH_REFRESH_MONTHS,
} from '@firstbase/data/Organization/queries'
import {
  getOrganizationCategories,
  getOrganizationCategoriesVariables,
  getOrganizationCategories_getOrganizationById_organizationCategories,
} from '@firstbase/data/Organization/__generated__/getOrganizationCategories'
import {
  getOrganizationCategoriesWithRefreshMonths,
  getOrganizationCategoriesWithRefreshMonthsVariables,
  getOrganizationCategoriesWithRefreshMonths_getOrganizationById_organizationCategories,
} from '@firstbase/data/Organization/__generated__/getOrganizationCategoriesWithRefreshMonths'
import Page from '@firstbase/components/atoms/Page'
import Table from '@firstbase/components/atoms/Table'

import ClientCategoriesGiftedSwitch from './ClientCategoriesGiftedSwitch'
import ClientCategoryRefreshRate from './ClientCategoryRefreshRate'

const ClientCategories = () => {
  const params: ClientPageParams = useParams()
  const flags = useFlags()
  const refreshRateFlagOn = flags[FLAGS.SE_4230_REFRESH_RATES]

  const { data, loading, error } = useQuery<
    getOrganizationCategories,
    getOrganizationCategoriesVariables
  >(GET_ORGANIZATION_CATEGORIES, {
    variables: {
      orgId: params.clientId,
    },
    skip: refreshRateFlagOn,
  })
  const {
    data: queryWithRefreshMonths,
    loading: queryWithRefreshMonthsLoading,
    error: queryWithRefreshMonthsError,
  } = useQuery<
    getOrganizationCategoriesWithRefreshMonths,
    getOrganizationCategoriesWithRefreshMonthsVariables
  >(GET_ORGANIZATION_CATEGORIES_WITH_REFRESH_MONTHS, {
    variables: {
      orgId: params.clientId,
    },
    skip: !refreshRateFlagOn,
  })

  /**
   * Useful, mainly, for when updating return required by running the mutation.
   * The result of the mutation updates the apollo cache with the updated
   * entities, and thus, the order of categories is updated from server.
   * This function provides consistent alphabetical sorting throughout
   * this component's lifecycle.
   */
  const sortCategories = (
    categories:
      | (getOrganizationCategories_getOrganizationById_organizationCategories | null)[]
      | (getOrganizationCategoriesWithRefreshMonths_getOrganizationById_organizationCategories | null)[]
  ) =>
    categories
      .filter((categoryData) => !!categoryData?.category)
      .sort((a, b) => {
        if (!a?.category?.code || !b?.category?.code) return 0
        return a.category.code.localeCompare(b.category.code)
      })

  const queryIsLoading = loading || queryWithRefreshMonthsLoading
  const queryError = error || queryWithRefreshMonthsError
  const queryData = data || queryWithRefreshMonths

  if (queryError) throw queryError

  if (queryIsLoading || !queryData)
    return <Skeleton variant="rectangular" height="200px" />

  return (
    <>
      <Page.TitleSection
        sectionTitle="Categories"
        sectionSubtitle={`List of categories and settings that are available for ${queryData.getOrganizationById.name}`}
      />
      <Table
        tableId="organization-categories-table"
        noDataMessage="No categories found"
        query={{
          data: {
            data: sortCategories(
              queryData.getOrganizationById.organizationCategories || []
            ),
          },
        }}
        columns={[
          {
            header: 'Name',
            id: 'category.nameSingular',
            cell: {
              value: ({
                category,
              }:
                | getOrganizationCategories_getOrganizationById_organizationCategories
                | getOrganizationCategoriesWithRefreshMonths_getOrganizationById_organizationCategories) =>
                category?.nameSingular || '',
            },
          },
          {
            header: 'Giftable Category?',
            id: 'gift',
            cell: {
              as: ClientCategoriesGiftedSwitch,
              asProps: ({
                gift,
                category,
              }:
                | getOrganizationCategories_getOrganizationById_organizationCategories
                | getOrganizationCategoriesWithRefreshMonths_getOrganizationById_organizationCategories) => ({
                initialValue: gift,
                orgSlug: queryData.getOrganizationById.slug,
                categoryCode: category?.code || '',
              }),
              value: () => null,
            },
          },
          ...(refreshRateFlagOn
            ? [
                {
                  header: 'Refresh Rate (months)',
                  id: 'refresh-rate',
                  cell: {
                    as: ClientCategoryRefreshRate,
                    asProps: ({
                      refreshMonths,
                      category,
                    }: getOrganizationCategoriesWithRefreshMonths_getOrganizationById_organizationCategories) => ({
                      categoryCode: category?.code || '',
                      initialValue: refreshMonths || 0,
                      orgSlug: queryData.getOrganizationById.slug,
                    }),
                    value: () => null,
                    sx: { width: '350px' },
                  },
                },
              ]
            : []),
        ]}
      />
    </>
  )
}

export default ClientCategories
