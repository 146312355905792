import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  SelectProps,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material'

import { getOrganizationWarranties_getWarrantyTypes } from '@firstbase/data/Warranties'
import { filterNullsOut } from '@firstbase/utils/array/filterNullsOut'

import { useClientWarrantiesMutations } from './useClientWarrantiesMutations'
import { OrgWarrantyAssignmentMap } from './clientWarrantiesTypes'

interface OwnProps {
  orgWarrantyAssignmentMap: OrgWarrantyAssignmentMap
  warranties: getOrganizationWarranties_getWarrantyTypes[]
}

const columns = [
  'Warranty Type',
  'Warranty Length',
  'Warranty Tier',
  'Enabled?',
]

export const ClientWarrantiesTable = ({
  orgWarrantyAssignmentMap,
  warranties,
}: OwnProps) => (
  <TableContainer>
    <Table>
      <TableHead className="bg-neutral-300">
        <TableRow>
          {columns.map((column) => (
            <TableCell key={column}>{column}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {warranties.map((warranty) => (
          <WarrantyRow
            key={warranty.id}
            assignment={orgWarrantyAssignmentMap[warranty.id]}
            warrantyType={warranty}
          />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)

const WarrantyRow = ({
  warrantyType,
  assignment,
}: {
  warrantyType: getOrganizationWarranties_getWarrantyTypes
  assignment: OrgWarrantyAssignmentMap[string]
}) => {
  const {
    createOrganizationWarrantyTypeAssignment,
    updateOrganizationWarrantyTypeAssignment,
    removeOrganizationWarrantyTypeAssignment,
  } = useClientWarrantiesMutations()

  const assignmentExists = !!assignment
  const assignmentId = assignment?.id || ''
  const warrantyId = warrantyType.id

  // Use widths for each cell to ensure that all tables line up
  return (
    <TableRow>
      <TableCell width="40%">{warrantyType.name}</TableCell>
      <TableCell>
        <TableSelect
          label="Term*"
          options={filterNullsOut(warrantyType.options.terms) ?? []}
          value={assignment?.term?.toString() ?? ''}
          onChange={(e) => {
            const term = Number(e.target.value) ?? 0

            if (assignmentExists) {
              updateOrganizationWarrantyTypeAssignment(
                assignmentId,
                warrantyId,
                { term, tier: assignment?.tier ?? '' }
              )
            } else {
              createOrganizationWarrantyTypeAssignment(warrantyId, term)
            }
          }}
        />
      </TableCell>
      <TableCell>
        <DisabledTooltip show={!assignmentExists}>
          <TableSelect
            label="Tier"
            options={filterNullsOut(warrantyType.options.tiers) ?? []}
            value={assignment?.tier ?? ''}
            disabled={!assignmentExists}
            onChange={(e) => {
              updateOrganizationWarrantyTypeAssignment(
                assignmentId,
                warrantyId,
                {
                  tier:
                    typeof e.target.value === 'string' ? e.target.value : '',
                  term: assignment?.term ?? 0,
                }
              )
            }}
          />
        </DisabledTooltip>
      </TableCell>
      <TableCell>
        <DisabledTooltip show={!assignmentExists}>
          <Switch
            checked={assignmentExists}
            disabled={!assignmentExists}
            onChange={() => {
              if (assignmentExists)
                removeOrganizationWarrantyTypeAssignment(assignmentId)
            }}
          />
          <Typography sx={{ width: '40px', display: 'inline-block' }}>
            {assignmentExists ? 'YES' : 'NO'}
          </Typography>
        </DisabledTooltip>
      </TableCell>
    </TableRow>
  )
}

interface TableSelectProps extends SelectProps {
  options: string[]
}

const TableSelect = ({ label, options, value, ...rest }: TableSelectProps) => (
  <FormControl fullWidth>
    <InputLabel id={`${label}-label`}>{label}</InputLabel>
    <Select value={value} label={label} labelId={`${label}-label`} {...rest}>
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

interface DisabledTooltipProps {
  children: React.ReactNode
  show: boolean
}

const DisabledTooltip = ({ children, show }: DisabledTooltipProps) => {
  if (!show) return <>{children}</>

  return (
    <Tooltip title="Select a warranty length to enable this">
      <span>{children}</span>
    </Tooltip>
  )
}
