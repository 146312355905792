import React from 'react'

import Page from '@atoms/Page'

import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useHistory } from 'react-router-dom'
import { Role } from '@firstbase/context/auth/AuthProvider'
import ProtectedChildren from '@firstbase/components/atoms/ProtectedChildren'

const People = () => {
  const history = useHistory()

  const renderTitleAction = () => (
    <ProtectedChildren hasRole={Role.Admin}>
      <div style={{ display: 'flex', gap: '0.5rem' }}>
        <Button
          variant="contained"
          onClick={() => history.push('/platform/people/bulk/create')}
        >
          <AddIcon /> Bulk upload
        </Button>
        <Button
          variant="contained"
          onClick={() =>
            history.push('/platform/people/bulk/sendActivationEmail')
          }
        >
          <AddIcon /> Bulk resend activation email
        </Button>
      </div>
    </ProtectedChildren>
  )

  return (
    <>
      <Page.TitleSection
        sectionTitle="People"
        sectionSubtitle="List of people that are available on the platform (coming soon)"
        subtitleAction={renderTitleAction}
      />
    </>
  )
}

export default People
