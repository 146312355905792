import React from 'react'
import { CircularProgress, Stack } from '@mui/material'

import { getChatMessages_chat_messages } from '@firstbase/data/Assistant'
import MarkdownRenderer from '@firstbase/components/molecules/MarkdownRenderer'

import Message from './Message'

interface OwnProps {
  messages: getChatMessages_chat_messages[]
}

const Messages = ({ messages }: OwnProps) => {
  const isOptimisticResponseForBot = (
    message: getChatMessages_chat_messages
  ) => {
    return !message.content && message.isBotResponse
  }

  return (
    <Stack spacing={0.5}>
      {messages.map((message) => (
        <Message
          key={message.id}
          user={message.isBotResponse ? 'Assistant' : 'You'}
        >
          {!isOptimisticResponseForBot(message) ? (
            <MarkdownRenderer text={message.content} />
          ) : (
            <CircularProgress size={25} />
          )}
        </Message>
      ))}
    </Stack>
  )
}

export default Messages
