import { SxProps } from '@mui/material'

import { cellComponents } from './cellComponents/cellComponents'

export enum SortDirections {
  asc = 'asc',
  desc = 'desc',
}

export interface CellI {
  sx?: SxProps
  as?:
    | typeof cellComponents[keyof typeof cellComponents]
    | ((a: any) => JSX.Element)
  asProps?: (a: any) => object | undefined
  value: (a: any) => JSX.Element | string | boolean | null | undefined
}

export type Align = 'center' | 'right' | 'left' | 'inherit' | 'justify'
export type Sticky = 'start' | 'end'

export interface ColumnI {
  id: string
  header: string
  defaultSort?: SortDirections
  align?: Align
  sx?: SxProps
  cell: CellI
  sticky?: Sticky
}

export interface PagedResponseI {
  pageNumber: number
  size: number
  totalElements: number
  totalPages: number
  data: any[]
}
