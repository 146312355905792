import Page from '@firstbase/components/atoms/Page'
import Table from '@firstbase/components/atoms/Table'
import { cellComponents } from '@firstbase/components/atoms/Table/cellComponents/cellComponents'
import { ColumnI } from '@firstbase/components/atoms/Table/types'
import { getPackageSelectionsBySlug_getPackageBySlug_skus } from '@firstbase/data/Package/__generated__/getPackageSelectionsBySlug'
import { interpolateQueryParamsInPath } from '@firstbase/routes/routeUtils'
import { PackagePageParams } from '@firstbase/types/Pages'
import React from 'react'
import { useParams } from 'react-router-dom'

const columns = (orgId: string) =>
  [
    {
      header: 'Product name',
      id: 'productName',
      cell: {
        as: cellComponents.Link,
        asProps: ({ id }) => ({
          to: interpolateQueryParamsInPath('product', {
            clientId: orgId,
            productId: id,
          }),
        }),
        value: ({ skuInformation }) => skuInformation.productTitle,
      },
    },
    {
      header: 'ID',
      id: 'id',
      cell: {
        as: cellComponents.ID,
        value: ({ id }) => id,
      },
    },
    {
      header: 'Category',
      id: 'category',
      cell: {
        value: ({ category }) => category.nameSingular,
      },
    },
    {
      header: 'Brand',
      id: 'vendor',
      cell: {
        value: ({ vendor }) => vendor.name,
      },
    },
  ] as ColumnI[]

type OwnProps = {
  skus: (getPackageSelectionsBySlug_getPackageBySlug_skus | null)[] | null
  loading: boolean
}

const Selections = ({ skus, loading }: OwnProps) => {
  const params: PackagePageParams = useParams()

  return (
    <>
      <Page.TitleSection
        sectionTitle="Selections"
        sectionSubtitle="List of items that are a part of this package"
      />
      <Table
        tableId={`package=${params.packageSlug}-skus`}
        columns={columns(params.clientId)}
        query={{
          data: { data: skus || [] },
          isLoading: loading,
        }}
      />
    </>
  )
}

export default Selections
