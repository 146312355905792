import React from 'react'
import { TextField, CircularProgress } from '@mui/material'

import {
  useMsaWarrantyData,
  useUpdateMsaWarrantyNumber,
  useMsaWarrantyInputValue,
} from './appleMsaWarrantyNumberHooks'

// As of this writing, no need for other vendors. Thus, this specific one.
export const AppleMsaWarrantyNumberInput = () => {
  const { data, loading: loadingMsaWarrantyData } = useMsaWarrantyData()
  const { updateMsaWarrantyNumber, loading: loadingMsaWarrantyNumberUpdate } =
    useUpdateMsaWarrantyNumber()
  const { inputValue, setInputValue } = useMsaWarrantyInputValue(
    data?.msaWarrantyNumber ?? '',
    loadingMsaWarrantyData
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    updateMsaWarrantyNumber(data?.slug, e.target.value.trim())
  }

  return (
    <TextField
      label="Apple Warranty Service Agreement Number"
      onChange={handleChange}
      value={inputValue}
      variant="outlined"
      sx={{ width: '300px' }}
      disabled={loadingMsaWarrantyData}
      InputProps={{
        endAdornment:
          loadingMsaWarrantyData || loadingMsaWarrantyNumberUpdate ? (
            <CircularProgress size={20} />
          ) : null,
      }}
    />
  )
}
