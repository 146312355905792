import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { History } from 'history'
import SectionError from '../SectionError'
import { Box } from '@mui/material'

type OwnProps = RouteComponentProps & {
  history: History
}

type OwnState = {
  error: boolean
  errorInfo: Error | null
}

class ErrorBoundary extends React.Component<OwnProps, OwnState> {
  constructor(props: OwnProps) {
    super(props)
    this.state = { error: false, errorInfo: null }
  }

  static getDerivedStateFromError(errorInfo: Error) {
    return { error: true, errorInfo }
  }

  render() {
    const { error, errorInfo } = this.state
    const { children, history } = this.props

    history.listen(() => {
      if (error) {
        this.setState({
          error: false,
        })
      }
    })

    if (error) {
      return (
        <Box sx={{ my: 2 }}>
          <SectionError title="Something went wrong" body={errorInfo?.stack} />
        </Box>
      )
    }

    return children
  }
}

export default withRouter(ErrorBoundary)
