import React, { useRef, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Box } from '@mui/material'

import {
  GET_CHAT_MESSAGES,
  CREATE_CHAT,
  createChat as createChatMutationType,
  createChatVariables,
  getChatMessages,
  getChatMessagesVariables,
} from '@firstbase/data/Assistant'
import useLocalStorage from '@firstbase/hooks/useLocalStorage/useLocalStorage'
import { useAuthState } from '@firstbase/context/auth/AuthProvider'

import { useSendMessage } from './useSendMessage'
import SendMessageForm from './SendMessageForm'
import Messages from './Messages'

const Chat = () => {
  const { authState } = useAuthState()
  const personId = authState?.accessToken?.claims?.personId
  const organizationId = authState?.accessToken?.claims?.org

  const messagesEndRef = useRef<HTMLDivElement>(null)

  const [chatId, setChatIdInStorage] = useLocalStorage('assistantChatId', '')

  const [createChat] = useMutation<createChatMutationType, createChatVariables>(
    CREATE_CHAT,
    {
      variables: { personId, organizationId },
      onCompleted: (data) => {
        const { id } = data.createChat
        setChatIdInStorage(id)
      },
    }
  )

  const { sendMessage, sendingMessage } = useSendMessage()

  const { data: chatMessagesData, loading: chatMessagesLoading } = useQuery<
    getChatMessages,
    getChatMessagesVariables
  >(GET_CHAT_MESSAGES, { variables: { chatId }, skip: !chatId })

  const handleSubmitMessage = (message: string) => {
    sendMessage({ chatId, message, personId, organizationId })
  }

  useEffect(() => {
    if (chatMessagesData?.chat.messages && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [chatMessagesData?.chat.messages])

  useEffect(() => {
    if (!chatId) createChat()
  }, [chatId, createChat])

  if (!chatMessagesData || chatMessagesLoading) return <>Loading</>

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="500px"
      sx={{ backgroundColor: 'white', borderRadius: '1rem' }}
    >
      <Box flex="1" overflow="auto">
        <Messages messages={chatMessagesData.chat.messages ?? []} />
        <div ref={messagesEndRef} />
      </Box>
      <Box>
        <SendMessageForm
          submit={handleSubmitMessage}
          loading={sendingMessage}
        />
      </Box>
    </Box>
  )
}

export default Chat
