import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { format, parseISO } from 'date-fns'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import request from '@firstbase/utils/request'
import TextSkeleton from '@firstbase/components/atoms/TextSkeleton/TextSkeleton'
import { sentenceCase } from 'change-case'
import { ContractResponse } from '@firstbase/types/Contract'
import { ClientPageParams } from '@firstbase/types/Pages'
import { useSupportedRegions } from '@utils/contractUtils'

const ContractSettings = () => {
  const { clientId }: ClientPageParams = useParams()
  const { data: contractResponse, isLoading: isLoadingContract } = useQuery({
    queryKey: ['client contract', { clientId }],
    queryFn: () =>
      request<ContractResponse>({
        url: `/organizations/${clientId}/contract`,
      }),
  })
  const supportedRegions = useSupportedRegions()
  const {
    contractLengthMonths,
    contractStartDate,
    version,
    versionDescription,
    haasBillingFrequency,
    feesBillingFrequency,
    defaultsToRegion,
  } = contractResponse?.contract || {}

  // presume has fees whilst loading
  const hasFees = isLoadingContract || !!contractResponse?.fees.length

  return (
    <>
      <Box
        sx={{ width: '50%', display: 'flex', flexDirection: 'column', mt: 2 }}
      >
        <Stack gap={0.5}>
          <Box>
            <Typography display="inline" sx={{ fontWeight: 600 }}>
              Contract version:{' '}
            </Typography>
            <TextSkeleton
              display="inline-block"
              isLoading={isLoadingContract}
              data-testid="contract-version"
            >
              {version}
            </TextSkeleton>
          </Box>
          <Box>
            <Typography display="inline" sx={{ fontWeight: 600 }}>
              Contract description:{' '}
            </Typography>
            <TextSkeleton
              display="inline-block"
              isLoading={isLoadingContract}
              data-testid="version-description"
            >
              {versionDescription}
            </TextSkeleton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <Typography display="inline" sx={{ fontWeight: 600 }}>
                Contract length:{' '}
              </Typography>
              <TextSkeleton
                display="inline-block"
                isLoading={isLoadingContract}
              >
                {contractLengthMonths} months
              </TextSkeleton>
            </div>
            <TextSkeleton sx={{ opacity: 0.6 }} isLoading={isLoadingContract}>
              Started on{' '}
              {contractStartDate &&
                format(parseISO(contractStartDate), 'MMM d, yyyy')}
            </TextSkeleton>
          </Box>
          <Box>
            <Typography display="inline" sx={{ fontWeight: 600 }}>
              Hardware billing frequency:{' '}
            </Typography>
            <TextSkeleton display="inline-block" isLoading={isLoadingContract}>
              {haasBillingFrequency && sentenceCase(haasBillingFrequency)}
            </TextSkeleton>
          </Box>
          {hasFees && (
            <Box>
              <Typography display="inline" sx={{ fontWeight: 600 }}>
                Management billing frequency:{' '}
              </Typography>
              <TextSkeleton
                display="inline-block"
                isLoading={isLoadingContract}
              >
                {feesBillingFrequency && sentenceCase(feesBillingFrequency)}
              </TextSkeleton>
            </Box>
          )}
          <Box>
            <Typography display="inline" sx={{ fontWeight: 600 }}>
              Default to billing region:{' '}
            </Typography>
            <TextSkeleton display="inline-block" isLoading={isLoadingContract}>
              {defaultsToRegion && supportedRegions[defaultsToRegion]}
            </TextSkeleton>
          </Box>
        </Stack>
      </Box>
    </>
  )
}

export default ContractSettings
