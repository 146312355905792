import { useMutation } from '@apollo/client'
import {
  createProductTier as CreateProductTier,
  createProductTierVariables,
} from '@firstbase/data/ProductTier/__generated__/createProductTier'
import {
  updateProductTier as UpdateProductTier,
  updateProductTierVariables,
} from '@firstbase/data/ProductTier/__generated__/updateProductTier'
import {
  CREATE_PRODUCT_TIER,
  UPDATE_PRODUCT_TIER,
} from '@firstbase/data/ProductTier/productTierMutations'
import { GET_ALL_PRODUCT_TIERS } from '@firstbase/data/ProductTier/productTierQueries'

export const useProductTierMutations = () => {
  const [createProductTier, { loading: isCreateProductTierLoading }] =
    useMutation<CreateProductTier, createProductTierVariables>(
      CREATE_PRODUCT_TIER,
      { refetchQueries: [GET_ALL_PRODUCT_TIERS] }
    )

  const [updateProductTier, { loading: isUpdateProductTierLoading }] =
    useMutation<UpdateProductTier, updateProductTierVariables>(
      UPDATE_PRODUCT_TIER,
      { refetchQueries: [GET_ALL_PRODUCT_TIERS] }
    )

  return {
    createProductTier,
    isCreateProductTierLoading,

    updateProductTier,
    isUpdateProductTierLoading,
  }
}
