import { SortDirections } from '@firstbase/components/atoms/Table'
import { ColumnI } from '@firstbase/components/atoms/Table/types'
import React from 'react'

import MinInventoryAvailableCell, {
  getAvailableCellStyle,
} from './MinInventoryAvailableCell'

// It is not possible to sort by product name in
// global min inventory view
const productNameColumn = (isOrg: boolean) =>
  ({
    header: 'Product Name',
    id: 'sku.skuInformation.productTitle',
    defaultSort: isOrg && SortDirections.asc,
    cell: {
      sx: { width: '50%' },
      value: ({ sku }) => sku.skuInformation.productTitle,
    },
  } as ColumnI)

const standardColumns = [
  {
    header: 'Warehouse',
    id: 'warehouse.name',
    cell: { value: ({ warehouse }) => warehouse.name },
  },
  {
    header: 'Available',
    id: 'available',
    cell: {
      sx: getAvailableCellStyle,
      value: ({ available, minimumInventory, ordered }) => (
        <MinInventoryAvailableCell
          available={available}
          minimumInventory={minimumInventory}
          ordered={ordered}
        />
      ),
    },
  },
  {
    header: 'Minimum',
    id: 'minimumInventory.minimumQuantity',
    cell: { value: ({ minimumInventory }) => minimumInventory.minimumQuantity },
  },
  {
    header: 'Ordered',
    id: 'ordered',
    cell: { value: ({ ordered }) => ordered || '' },
  },
] as ColumnI[]

export const orgColumns = [productNameColumn(true), ...standardColumns]

export const globalColumns = [
  {
    header: 'Organization',
    id: 'organization',
    cell: { value: ({ organization: { name } }) => name },
  },
  productNameColumn(false),
  ...standardColumns,
] as ColumnI[]
