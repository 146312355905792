import Table from '@firstbase/components/atoms/Table'
import { PageQueryParams } from '@firstbase/components/atoms/Table/Table'
import { PERSON_INVENTORY_COLUMNS } from '@firstbase/constants/inventoryColumns'
import { getPersonBySlugAndOrganizationSlug_getPersonBySlugAndOrganizationSlug_inventories } from '@firstbase/data/Person/__generated__/getPersonBySlugAndOrganizationSlug'
import { PersonPageParams } from '@firstbase/types/Pages'
import { getComparator } from '@firstbase/utils/tableUtils'
import React, { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

type Props = {
  inventories: (
    | getPersonBySlugAndOrganizationSlug_getPersonBySlugAndOrganizationSlug_inventories
    | any
  )[]
}

const PersonInventory = ({ inventories }: Props) => {
  const [tableInventories, setTableInventories] = useState(inventories)
  const queryParams: PersonPageParams = useParams()

  const fetch = useCallback(
    ({ activeSortDirection, activeSortId }: PageQueryParams) => {
      if (!activeSortId) return null

      setTableInventories((prevInventory) =>
        [...prevInventory].sort(
          getComparator(activeSortDirection, activeSortId || '')
        )
      )
    },
    []
  )

  const query = useMemo(
    () => ({
      isLoading: false,
      data: { data: tableInventories },
      fetch,
    }),
    [fetch, tableInventories]
  )

  return (
    <Table
      tableId={`${queryParams.personSlug}-inventory`}
      defaultSortId="sku.skuInformation.productTitle"
      query={query}
      columns={PERSON_INVENTORY_COLUMNS(queryParams.clientId)}
    />
  )
}

export default PersonInventory
