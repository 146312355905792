import React, { useMemo } from 'react'
import { format } from 'date-fns'
import { useParams } from 'react-router-dom'
import SettingsForm from '../ContractSettingsForm/SettingsForm'
import { constructNewContract } from '@firstbase/utils/contractUtils'
import { ClientPageParams } from '@firstbase/types/Pages'
import { ContractResponse } from '@firstbase/types/Contract'
type OwnProps = {
  open: boolean
  handleClose: () => void
}

const CreateSettings = ({ open, handleClose }: OwnProps) => {
  const { clientId }: ClientPageParams = useParams()
  const contract: ContractResponse = useMemo(() => {
    const { fees, currencies, ...contractDetails } = constructNewContract(
      {
        contractEditedRevision: 0,
        contractLengthMonths: 1,
        contractStartDate: format(new Date(), 'yyyy-MM-dd'),
        feesBillingFrequency: 'Annually',
        haasBillingFrequency: 'Annually',
        version: 2,
        prepaidSeats: null,
        versionDescription: '',
        defaultsToRegion: 'US',
        description: '',
        serviceFeeDiscount: null,
      },
      {},
      {},
      clientId
    )

    return {
      contract: { ...contractDetails, serviceFeeDiscount: null },
      fees,
      currencies,
      serviceFeeDiscount: null,
    }
  }, [clientId])

  return (
    <SettingsForm
      contract={contract}
      createNewContract
      open={open}
      handleClose={handleClose}
    />
  )
}

export default CreateSettings
