import { GET_ORGANIZATION_INVENTORY_ORDERS } from '@firstbase/data/Organization/queries'
import React from 'react'
import { useParams } from 'react-router-dom'
import Table, { SortDirections } from '@firstbase/components/atoms/Table'
import useTableGraphqlQuery from '@firstbase/hooks/useTableGraphqlQuery'
import { cellComponents } from '@firstbase/components/atoms/Table/cellComponents/cellComponents'
import { interpolateQueryParamsInPath } from '@firstbase/routes/routeUtils'

import { format, parseISO } from 'date-fns'
import { sentenceCase } from 'change-case'
import {
  getOrganizationInventoryOrdersById,
  getOrganizationInventoryOrdersByIdVariables,
} from '@firstbase/data/Organization/__generated__/getOrganizationInventoryOrdersById'
import { ColumnI } from '@firstbase/components/atoms/Table/types'
import { ClientPageParams } from '@firstbase/types/Pages'

const INVENTORY_ORDERS_COLUMNS = (orgId: string) =>
  [
    {
      header: 'ID',
      id: 'id',
      defaultSort: SortDirections.asc,
      cell: {
        as: cellComponents.ID,
        value: ({ id }) => id,
      },
    },
    {
      header: 'SKU ID',
      id: 'sku.id',
      defaultSort: SortDirections.asc,
      cell: {
        as: cellComponents.Link,
        asProps: ({ sku }) => ({
          to: interpolateQueryParamsInPath('product', {
            clientId: orgId,
            productId: sku?.id,
          }),
          sx: { whiteSpace: 'nowrap' },
        }),
        value: ({ sku }) => sku.id,
      },
    },
    {
      header: 'Product title',
      id: 'sku.skuInformation.productTitle',
      defaultSort: SortDirections.asc,
      cell: {
        sx: { minWidth: '33vw' },
        value: ({ sku }) => sku?.skuInformation?.productTitle,
      },
    },
    {
      header: 'Warehouse',
      id: 'warehouse.name',
      defaultSort: SortDirections.asc,
      cell: {
        value: ({ warehouse }) => warehouse.name,
      },
    },
    {
      header: 'Quantity',
      id: 'quantity',
      defaultSort: SortDirections.asc,
      cell: { value: ({ quantity }) => quantity },
    },
    {
      header: 'Created date',
      id: 'createdAt',
      defaultSort: SortDirections.asc,
      cell: {
        value: ({ createdAt }) => format(parseISO(createdAt), 'dd MMM yyyy'),
      },
    },
    {
      header: 'Category',
      id: 'sku.category.code',
      defaultSort: SortDirections.asc,
      cell: {
        value: ({ sku }: any) => sentenceCase(sku.category?.code).toUpperCase(),
      },
    },
    {
      header: 'Order status',
      id: 'status',
      defaultSort: SortDirections.asc,
      cell: {
        as: cellComponents.Chip,
        asProps: ({ status }) => {
          if (status === 'CANCELLED') return { color: 'warning' }
          if (status === 'SHIPPED') return { color: 'primary' }
          if (status === 'COMPLETE') return { color: 'success' }
        },
        value: ({ status }: any) => status,
      },
    },
  ] as ColumnI[]

const ClientInventoryOrders = () => {
  const params: ClientPageParams = useParams()
  const query = useTableGraphqlQuery<
    getOrganizationInventoryOrdersById,
    getOrganizationInventoryOrdersByIdVariables
  >(
    GET_ORGANIZATION_INVENTORY_ORDERS,
    {
      variables: {
        orgId: params.clientId!,
      } as getOrganizationInventoryOrdersByIdVariables,
    },
    ({
      getOrganizationById: { inventoryOrders },
    }: getOrganizationInventoryOrdersById) => inventoryOrders
  )

  return (
    <Table
      noDataMessage="No inventory orders found"
      query={query}
      tableId={`org=${params.clientId!}-inventory-orders`}
      pagination
      defaultSortId="sku.skuInformation.productTitle"
      columns={INVENTORY_ORDERS_COLUMNS(params.clientId)}
      customRowsPerPage={100}
      customRowsPerPageOptions={[100, 200, 300]}
    />
  )
}

export default ClientInventoryOrders
