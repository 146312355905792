import Table from '@firstbase/components/atoms/Table'
import { TableQuery } from '@firstbase/components/atoms/Table/Table'
import { ClientResponse } from '@firstbase/types/Client'
import React from 'react'

import { globalColumns, orgColumns } from './minInventoryColumns'

type OwnProps = {
  org?: ClientResponse
  query: TableQuery
}

const MinInventoryTable = ({ org, query }: OwnProps) => {
  const visibleColumns = org ? orgColumns : globalColumns

  return (
    <Table
      tableId={`${org?.id ? `${org?.id}-` : ''}minInventory`}
      query={query}
      dataKey="minimumInventory.id"
      columns={visibleColumns}
      noDataMessage="No Minimum Inventories found"
      pagination
    />
  )
}

export default MinInventoryTable
