import { gql } from '@apollo/client'

export const GET_ALL_OFFICES = gql`
  query getAllOffices(
    $pageNumber: Int!
    $pageSize: Int!
    $sort: [Sort]
    $officeFilter: String = ""
  ) {
    getAllOffices(
      pagingAndSorting: {
        pageNumber: $pageNumber
        pageSize: $pageSize
        sort: $sort
      }
      filter: { searchString: $officeFilter }
    ) {
      data {
        id
        name
        organization {
          name
        }
      }
    }
  }
`
