import {
  getPersonBySlugAndOrganizationSlug_getPersonBySlugAndOrganizationSlug,
  getPersonBySlugAndOrganizationSlug_getPersonBySlugAndOrganizationSlug_integrations,
} from '@firstbase/data/Person/__generated__/getPersonBySlugAndOrganizationSlug'
import { Box, Chip, Divider, Stack, Typography } from '@mui/material'
import { sentenceCase } from 'change-case'
import React from 'react'

type OwnProps = {
  person: getPersonBySlugAndOrganizationSlug_getPersonBySlugAndOrganizationSlug
}

type personKeys =
  | keyof getPersonBySlugAndOrganizationSlug_getPersonBySlugAndOrganizationSlug
  | keyof getPersonBySlugAndOrganizationSlug_getPersonBySlugAndOrganizationSlug_integrations

type Field = {
  keys: personKeys[]
  label?: string
}

const fields: Field[] = [
  { keys: ['email'] },
  { keys: ['secondaryEmail'] },
  { keys: ['slug'] },
  { keys: ['status'] },
  { keys: ['appAccess'], label: 'Access' },
  { keys: ['newHire'], label: 'Is new hire' },
  { keys: ['integrations', 'workatoUserId'], label: 'Workato User ID' },
]

const Overview = ({ person }: OwnProps) => {
  const makeRow = ({ keys, label }: Field) => {
    const result =
      keys.reduce((obj, key) => {
        const reducedElement = obj ? (obj as any)[key] : undefined
        if (reducedElement === true) return 'YES'
        if (reducedElement === false) return 'NO'
        return obj && reducedElement !== undefined ? reducedElement : undefined
      }, person) || 'unknown'

    return (
      <Box key={result.toString()}>
        <b>{label || sentenceCase(keys[keys.length - 1])}: </b>
        {result}
      </Box>
    )
  }
  return (
    <>
      <Stack gap={0.5}>{fields.map(makeRow)}</Stack>
      {person.appAccess?.toLowerCase() === 'admin' && (
        <>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" sx={{ mb: 1 }}>
            Notifications
          </Typography>
          {person.emailNotifications?.map(({ id, type }) => (
            <Chip sx={{ mx: 0.5 }} key={id} label={type} />
          )) || (
            <Typography variant="body2">
              {person.forename} has not set any notifications preferences
            </Typography>
          )}
        </>
      )}
    </>
  )
}

export default Overview
