import React from 'react'
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material'

import Select from '@atoms/FBSelect'

interface OwnProps {
  onChange: (e: SelectChangeEvent<string>) => void
  options: string[]
  value: string
}

// TODO: Reactor by consolidating with what is in CurrenciesTableForm
const CurrencyCodeSelect = ({ onChange, options, value }: OwnProps) => (
  <Box sx={{ mt: 1, mb: 2, display: 'flex', gap: 2 }}>
    <FormControl sx={{ minWidth: '33%' }}>
      <InputLabel size="small" id="service-fee-override-currency-select-label">
        Currency
      </InputLabel>
      <Select
        labelId="service-fee-override-currency-select-label"
        id="service-fee-override-currency-select"
        value={value}
        label="Currency"
        size="small"
        onChange={onChange}
      >
        {options.map((currency) => (
          <MenuItem
            key={currency}
            data-testid={`service-override-currency-option?currency=${currency}`}
            value={currency}
          >
            {currency}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
)

export default CurrencyCodeSelect
