import React from 'react'
import Page from '@firstbase/components/atoms/Page'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { EditProductPageParams } from '@firstbase/types/Pages'
import { GET_SKU } from '@firstbase/data/SKU/queries'
import {
  getSKU,
  getSKUVariables,
} from '@firstbase/data/SKU/__generated__/getSKU'
import EditGlobalProduct from '@firstbase/views/Home/Platform/Products/EditGlobalProduct'
import SectionError from '@firstbase/components/atoms/SectionError'
import ProductLoadingSkeleton from '@firstbase/views/Home/Platform/Products/ProductLoadingSkeleton'

const EditProductPage = () => {
  const history = useHistory()
  const params: EditProductPageParams = useParams()
  const { data: sku, error: skuError } = useQuery<getSKU, getSKUVariables>(
    GET_SKU,
    {
      variables: { id: params.skuId },
      defaultOptions: {
        fetchPolicy: 'no-cache',
      },
    }
  )

  if (skuError) {
    return <SectionError title="Error loading product" body={skuError?.stack} />
  }

  if (!sku) {
    return <ProductLoadingSkeleton />
  }

  return (
    <Page title="Edit Product">
      <EditGlobalProduct
        open
        handleClose={() => history.push('/platform')}
        product={sku.getSKU}
      />
    </Page>
  )
}

export default EditProductPage
