import SmartySDK from 'smartystreets-javascript-sdk'
import environmentVariables from '@firstbase/utils/environmentVariables'
import { AddressI } from '@firstbase/types/Address'

const SmartyCore = SmartySDK.core
const { Lookup: UsLookup } = SmartySDK.usStreet
const { Lookup: InternationalLookup } = SmartySDK.internationalStreet

// used as wrapper for util fn below
const isAdminAreaRequired = (countryCode: string) =>
  countryCode === 'US' || countryCode === 'CA'

/**
 * Utility function used to check if the delivery address is "incomplete".
 * This is useful for the use case where the address is not complete and
 * thus, the validation request for Smarty should not be sent.  This comes
 * from needing to support a use case where we can save address, but the address
 * is incomplete.  In this case, Smarty returns a 400, so there's no point in
 * validating for this case.
 */
export const isAddressComplete = (
  address: AddressI,
  includePhoneNumber: boolean = false
) => {
  const { countryCode, administrativeArea } = address
  const adminAreaIsValid = isAdminAreaRequired(countryCode || '')
    ? !!administrativeArea
    : true
  const phoneNumberIsValid = includePhoneNumber ? !!address.phoneNumber : true

  return (
    !!address.addressLine1 &&
    !!address.locality &&
    adminAreaIsValid &&
    !!countryCode &&
    !!address.postalCode &&
    phoneNumberIsValid
  )
}

// Wrap in fn to ensure environmentVariables.get is called within right context
const getCredentialsObject = () =>
  new SmartyCore.SharedCredentials(
    environmentVariables.get().VITE_SMARTY_API_KEY
  )

/**
 * Smarty recommends line 1 and line 2, from our UI, be combined into line 1 for the lookup.
 * This, according to their team, produces the best results.
 */
const getLine1ForLookup = (line1: string, line2: string) => {
  if (!line1) return ''
  return line2 ? `${line1} ${line2}` : line1
}

/**
 * Address lines requires a "special check".  The reason for this is that
 * in our UI, address lines 1 and 2 are separate.  For Smarty's international
 * validation, the payload for the request and the response have both lines combined
 * into 1 -- address1.  Thus, we need to specifically check if both lines are included
 * in the response.
 */
const wereAddressLinesChanged = (
  smartyAddressLine1: string,
  addressLine1FromUi: string,
  addressLine2FromUi: string
) => {
  const normalizedSmartyAddressLine1 = smartyAddressLine1.toLocaleLowerCase()
  const isIncludedInSmartyResult = (addressLine: string) =>
    normalizedSmartyAddressLine1.includes(addressLine.toLocaleLowerCase())

  return (
    !isIncludedInSmartyResult(addressLine1FromUi) ||
    // Line 2 is optional; check if it is truthy first
    (!!addressLine2FromUi && !isIncludedInSmartyResult(addressLine2FromUi))
  )
}

/**
 * Wrapper for normalizing and comparing strings
 * Note: result from smarty is typed as any
 */
const wasAddressComponentChanged = (result: any, lookup: string) => {
  // HACK: result is any, so check if not truthy
  const normalizedResult = (result || '').toLocaleLowerCase()
  const normalizedLookup = lookup.toLocaleLowerCase()
  return normalizedResult.trim() !== normalizedLookup.trim()
}

/**
 * Used in international validation to check if changes were made to any
 * of the components.  The reason this is used, vs the "changes.components"
 * object from Smarty, is that we want to make sure we are comparing what
 * our users inputted in the UI vs what was returned.  Sometimes, Smarty
 * does not mark a change as a change, and thus, we lose control over what
 * becomes a change and what doesn't.  This lead to buggy behavior.
 */
const wereAddressComponentsChanged = (
  result: SmartySDK.internationalStreet.Candidate,
  lookup: SmartySDK.internationalStreet.Lookup,
  addressLine1FromUi: string,
  addressLine2FromUi: string
) =>
  wereAddressLinesChanged(
    result.address1,
    addressLine1FromUi,
    addressLine2FromUi
  ) ||
  wasAddressComponentChanged(result.components.locality, lookup.locality) ||
  wasAddressComponentChanged(
    result.components.administrativeArea,
    lookup.administrativeArea
  ) ||
  wasAddressComponentChanged(result.components.postalCode, lookup.postalCode)

const validateAddressFormatInternational = async (
  countryCode: string,
  address: AddressI
): Promise<{
  validAddress: AddressI | null
  didLargeChangeHappen: boolean
}> => {
  try {
    const {
      addressLine1,
      addressLine2,
      administrativeArea,
      locality,
      postalCode,
    } = address
    let validAddress: AddressI | null = null
    let didLargeChangeHappen = false

    const intClientBuilder = new SmartyCore.ClientBuilder(
      getCredentialsObject()
    ).withLicenses(['international-global-plus-cloud'])
    const internationalClient =
      intClientBuilder.buildInternationalStreetClient()

    const lookup = new InternationalLookup(countryCode, '')
    lookup.address1 = getLine1ForLookup(addressLine1 || '', addressLine2 || '')
    lookup.locality = locality || ''
    lookup.administrativeArea = administrativeArea || ''
    lookup.country = countryCode
    lookup.postalCode = postalCode || ''

    const {
      result: [result],
    } = await internationalClient.send(lookup)
    const { verificationStatus, addressPrecision, maxAddressPrecision } =
      result.analysis
    const addressVerifiedWithMaxPrecision =
      addressPrecision === maxAddressPrecision

    /**
     * Checking the verified status should be enough.  However, the API documentation
     * is unclear as to whether verification status might show verified, while having
     * a component (e.g. Address Line 1) unverified if working with different precision
     * level.  The second check is just insurance to make sure everything is verified.
     */
    if (verificationStatus === 'Verified' && addressVerifiedWithMaxPrecision) {
      validAddress = {
        ...address,
        addressLine1: result.address1,
        /**
         * Related to line 1 lookup: Smarty recommends only using line 1 for international addresses,
         * for the best results. Additionally, the result returned from Smarty, for line 2, includes
         * other components; this means things like locality would be included again on line 2, leading
         * to weird addresses.
         */
        addressLine2: '',
        locality: result.components.locality,
        postalCode: result.components.postalCode,
      }
      // Given admin area is optional for intl, it won't always exist
      if (result.components.administrativeArea) {
        validAddress.administrativeArea = result.components.administrativeArea
      }
      didLargeChangeHappen = wereAddressComponentsChanged(
        result,
        lookup,
        addressLine1 || '',
        addressLine2 || ''
      )
    }

    return {
      validAddress,
      didLargeChangeHappen,
    }
  } catch (error) {
    throw error
  }
}

const USSmartyFootnotes: { [key: string]: string } = {
  A: 'Corrected ZIP Code',
  B: 'Corrected city/state spelling',
  C: 'Invalid city/state/ZIP',
  D: 'No ZIP+4 assigned',
  E: 'Same ZIP for multiple',
  F: 'Address not found',
  G: 'Used addressee data',
  H: 'Missing secondary number',
  I: 'Insufficient/ incorrect address data',
  J: 'Dual address',
  K: 'Cardinal rule match',
  L: 'Changed address component',
  LL: 'Flagged address for LACSLink',
  LI: 'Flagged address for LACSLink',
  M: 'Corrected street spelling',
  N: 'Fixed abbreviations',
  O: 'Multiple ZIP+4; lowest used',
  P: 'Better address exists',
  Q: 'Unique ZIP match',
  R: 'No match; EWS: Match soon',
  S: 'Unrecognized secondary address',
  T: 'Multiple response due to magnet street syndrome',
  U: 'Unofficial city name',
  V: 'Unverifiable city/state',
  W: 'Invalid delivery address',
  X: 'Unique ZIP Code',
  Y: 'Military match',
  Z: 'Matched with ZIPMOVE',
}

// Potentially auto-validate some components
const AutomaticValidations = ['A', 'B', 'N']

const getFootnotes = (footnotes: string) => {
  if (!footnotes) return null

  const keys = footnotes.split('#').filter((s: string) => !!s)

  // Don't return footnotes if they match automatic validations
  if (keys.every((k) => AutomaticValidations.includes(k))) {
    return null
  }

  const notes: string[] = []

  keys.forEach((k) => {
    if (USSmartyFootnotes[k]) {
      notes.push(USSmartyFootnotes[k])
    }
  })

  return notes
}

const validateAddressFormatUS = async (address: AddressI) => {
  const {
    addressLine1,
    addressLine2,
    administrativeArea,
    locality,
    postalCode,
  } = address

  const usClientBuilder = new SmartyCore.ClientBuilder(
    getCredentialsObject()
  ).withBaseUrl('https://us-street.api.smartystreets.com/street-address')
  const usClient = usClientBuilder.buildUsStreetApiClient()

  const lookup = new UsLookup()
  lookup.street = addressLine1 || ''
  lookup.street2 = addressLine2 || ''
  lookup.city = locality || ''
  lookup.state = administrativeArea || ''
  lookup.zipCode = postalCode || ''
  lookup.maxCandidates = 3
  lookup.match = 'strict'

  let validAddress: AddressI | null = null
  let footnotes: string[] | null = null

  const response = await usClient.send(lookup)

  if (response.lookups[0].result.length > 0) {
    const proposedAddress = lookup.result[0]

    footnotes = getFootnotes(proposedAddress.analysis.footnotes)

    validAddress = {
      ...address,
      addressLine1: proposedAddress.deliveryLine1,
      addressLine2: proposedAddress.deliveryLine2 || '',
      administrativeArea: proposedAddress.components.state,
      locality: proposedAddress.components.cityName,
      postalCode: `${proposedAddress.components.zipCode}-${proposedAddress.components.plus4Code}`,
    }
  }

  return { validAddress, footnotes }
}

export const validateAddress = async (address: AddressI): Promise<AddressI> => {
  try {
    if (!isAddressComplete(address)) return address
    const {
      addressLine1,
      addressLine2,
      locality,
      administrativeArea,
      countryCode,
      postalCode,
    } = address
    let validatedAddress: AddressI | null = null

    if (countryCode === 'US') {
      const result = await validateAddressFormatUS({
        addressLine1,
        addressLine2,
        administrativeArea,
        locality,
        postalCode,
      })

      if (result.validAddress) {
        validatedAddress = {
          countryCode: address.countryCode,
          phoneNumber: address.phoneNumber,
          ...result.validAddress,
        }
      }
    } else {
      const result = await validateAddressFormatInternational(
        countryCode || '',
        {
          addressLine1,
          addressLine2,
          administrativeArea,
          locality,
          postalCode,
        }
      )

      if (result.validAddress) {
        validatedAddress = {
          countryCode: address.countryCode,
          phoneNumber: address.phoneNumber,
          ...result.validAddress,
        }
      }
    }

    return validatedAddress || address
  } catch (error) {
    // eslint-disable-next-line
    console.log(error)
    throw new Error(
      'Looks like something went wrong when trying to validate the address.'
    )
  }
}
