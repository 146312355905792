import { gql } from '@apollo/client'

export const CREATE_SKU_ASSIGNMENT = gql`
  mutation createSKUAssignment($createSKUAssignment: CreateSKUAssignment!) {
    createSKUAssignment(createSKUAssignment: $createSKUAssignment) {
      id
    }
  }
`

export const CREATE_SKU = gql`
  mutation createSku($createSku: CreateSKU!) {
    createSKU(createSKU: $createSku) {
      id
    }
  }
`

export const UPDATE_SKU_ASSIGNMENT_BY_ORG_BY_SKU = gql`
  mutation updateSKUAssignmentByOrgIdSkuId(
    $orgId: ID!
    $skuId: ID!
    $updateSKUAssignment: UpdateSKUAssignment!
  ) {
    updateSKUAssignmentByOrgIdSkuId(
      orgId: $orgId
      skuId: $skuId
      updateSKUAssignment: $updateSKUAssignment
    ) {
      id
    }
  }
`

export const UPDATE_GLOBAL_SKU = gql`
  mutation updateSKU($skuId: ID!, $updateSKU: UpdateSKU!) {
    updateSKU(skuId: $skuId, updateSKU: $updateSKU) {
      id
    }
  }
`

export const ADD_SKU_TO_STANDARD_CATALOG = gql`
  mutation addItemToStandardCatalog($id: ID!) {
    addItemToStandardCatalog(item: { skuId: $id }) {
      sku {
        id
      }
    }
  }
`

export const REMOVE_SKU_FROM_STANDARD_CATALOG = gql`
  mutation removeItemFromStandardCatalog($id: ID!) {
    removeItemFromStandardCatalog(skuId: $id)
  }
`

export const CREATE_SKU_METADATA_OPTION_VALUE = gql`
  mutation createSKUMetadataOptionValue(
    $categoryCode: String!
    $vendorCode: String!
    $fieldId: ID!
    $value: String!
  ) {
    createSKUMetadataOptionValue(
      createSKUMetadataOptionValue: {
        categoryCode: $categoryCode
        vendorCode: $vendorCode
        fieldId: $fieldId
        value: $value
      }
    ) {
      id
      values {
        id
        value
      }
    }
  }
`

export const DELETE_SKU_METADATA_OPTION_VALUE = gql`
  mutation deleteSKUMetadataOptionValue($id: ID!) {
    deleteSKUMetadataOptionValue(id: $id)
  }
`
