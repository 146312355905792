import request, { Request } from '@firstbase/utils/request'

type PagedResponseType<DataType> = {
  pageNumber: number
  size: number
  totalElements: number
  totalPages: number
  data: DataType[]
}

type PageParams = {
  page: number
  rowsPerPage: number
  activeSortDirection: string
  activeSortId?: string
}

function tableRequest<DataType>(
  resource: string,
  pageParams: PageParams & { [key: string]: unknown }
): Request<PagedResponseType<DataType>> {
  const {
    page,
    rowsPerPage,
    activeSortDirection,
    activeSortId,
    ...extraParams
  } = pageParams

  const sort = activeSortId
    ? `${activeSortId}:${activeSortDirection}`
    : activeSortDirection

  const params = new URLSearchParams({
    page: page.toString(),
    size: rowsPerPage.toString(),
    sort,
    ...extraParams,
  })
  const url = `${resource}?${params.toString()}`

  return request<PagedResponseType<DataType>>({ method: 'get', url })
}

export default tableRequest
