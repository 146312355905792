import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { SecureRoute, LoginCallback } from '@okta/okta-react'
import { OktaAuth } from '@okta/okta-auth-js'
import { withLDProvider } from 'launchdarkly-react-client-sdk'

import App from '@views/App'
import ErrorBoundary from '@atoms/ErrorBoundary'

import OktaSecurity from '@firstbase/context/okta'
import SignInForm from '@molecules/SignInForm'
import IDPAuth from '@atoms/IDPAuth'

import '@firstbase/global.css'
import BreadcrumbProvider from './context/breadcrumbs'
import Loader from '@atoms/Loader'

import { getOktaAuthConfig, getOktaSignInConfig } from '@firstbase/config/okta'
import environmentVariables, {
  getEnvironmentVariables,
  EnvironmentVariablesI,
} from './utils/environmentVariables'
import oktaAuth from './utils/oktaAuth'
import isEndToEndTest from './utils/isEndToEndTest/isEndToEndTest'
import initializeDatadog from './config/datadog'
import { ClickToComponent } from 'click-to-react-component'

const container = document.getElementById('root')

const initAppConfig = (environmentVariablesResult: EnvironmentVariablesI) => {
  environmentVariables.set(environmentVariablesResult)
  oktaAuth.set(() => new OktaAuth(getOktaAuthConfig()))
}

getEnvironmentVariables()
  .then((environmentVariablesResult) => {
    initAppConfig(environmentVariablesResult)
    if (!isEndToEndTest()) initializeDatadog()

    const AppWithLdProvider = withLDProvider({
      clientSideID: environmentVariables.get().VITE_LAUNCHDARKLY_CLIENT_ID!,
      reactOptions: {
        useCamelCaseFlagKeys: false,
      },
    })(App)

    return (
      <React.StrictMode>
        <ClickToComponent />
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            <BreadcrumbProvider>
              <ErrorBoundary>
                <OktaSecurity oktaAuth={oktaAuth.get()}>
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={() => (
                        <SignInForm config={getOktaSignInConfig()} />
                      )}
                    />

                    <Route exact path="/auth" component={IDPAuth} />

                    <Route
                      exact
                      path="/auth/callback"
                      render={(props) => {
                        const onAuthResume = async () => {
                          props.history.replace('/')
                        }

                        return (
                          <LoginCallback
                            {...props}
                            onAuthResume={onAuthResume}
                            loadingElement={<Loader />}
                          />
                        )
                      }}
                    />
                    <SecureRoute path="*">
                      <AppWithLdProvider />
                    </SecureRoute>
                  </Switch>
                </OktaSecurity>
              </ErrorBoundary>
            </BreadcrumbProvider>
          </BrowserRouter>
        </Suspense>
      </React.StrictMode>
    )
  })
  .catch((error) => {
    // eslint-disable-next-line
    console.log(error)
    return <div>Error loading the app. Please file a bug report.</div>
  })
  .then((reactElement: React.ReactElement) => {
    ReactDOM.render(reactElement, container)
  })
