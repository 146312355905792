import { gql } from '@apollo/client'

export const GET_ALL_INVENTORIES = gql`
  query getAllInventories(
    $pageNumber: Int!
    $pageSize: Int!
    $sort: [Sort]
    $searchTerm: String
  ) {
    getAllInventories(
      pagingAndSorting: {
        pageNumber: $pageNumber
        pageSize: $pageSize
        sort: $sort
      }
      searchTerm: $searchTerm
    ) {
      data {
        id
        serialNumber
        description
        warehouse {
          name
        }
        organization {
          name
          id
        }
        active
        conditionStatus
        deployStatus
        deployReason
        sku {
          vendorSku
        }
        office {
          id
          name
        }
        person {
          forename
          surname
          slug
          email
        }
        pricing {
          priceOption
        }
        firstbaseSupplied
        estimatedRenewalDate
        renewalDate
        unlockDate
        gift
      }
    }
  }
`

export const GET_INVENTORY_BY_SERIAL = gql`
  query getInventoryBySerial($serialNumber: String!) {
    getInventoryBySerial(request: { serial: $serialNumber }) {
      id
    }
  }
`
