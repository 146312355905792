import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'

import FullscreenModal from '@firstbase/components/molecules/FullscreenModal'
import {
  UPDATE_WARRANTY_SKU,
  updateWarrantySKU,
  updateWarrantySKUVariables,
} from '@firstbase/data/Warranties'

import { useFormInitialValues } from './useFormInitialState'

import { WarrantySkuForm, ReducerState } from '../WarrantySkuForm'

/**
 * Note - Only computer category are editable in this initial implementation.
 * The reason for this is that a warranty is of category warranty. However,
 * the user can, in the interface, filter by an actual category (e.g. computer).
 * Therefore, the FE does not yet have a way to see that a warranty sku is for a computer
 * or some other type. See the custom hook for getting the initial values for more details.
 */
export const EditWarrantySku = () => {
  const { skuId } = useParams<{ skuId: string }>()
  const history = useHistory()
  const closeModal = () => history.replace('/platform/warranties')

  const { enqueueSnackbar } = useSnackbar()

  const { loading, form } = useFormInitialValues()

  const [updateWarrantySkuMutation, { loading: updateLoading }] = useMutation<
    updateWarrantySKU,
    updateWarrantySKUVariables
  >(UPDATE_WARRANTY_SKU, {
    onCompleted: () => {
      enqueueSnackbar('Warranty updated', { variant: 'success' })
      closeModal()
    },
    onError: () => {
      enqueueSnackbar(
        'Error updating warranty. Please see the console for more details and share this with the team.',
        { variant: 'error' }
      )
    },
  })

  const handleSubmit = (state: ReducerState) => {
    const metdataFieldsArray = Object.entries(state.metadataFields)
      .filter(([_, { value }]) => value !== '')
      .map(([key, { value }]) => ({
        fieldId: key,
        value,
      }))

    updateWarrantySkuMutation({
      variables: {
        skuId,
        updateSKU: {
          productTitle: metdataFieldsArray
            .map((field) => field.value)
            .join(' '),
          vendorSku: state.mpn,
          partNumber: state.mpn,
          standardPricing: state.pricingDetails.rows.map(
            ({ regionCode, currencyCode, purchasePrice }) => ({
              regionCode,
              currencyCode,
              purchasePrice,
            })
          ),
          metadata: metdataFieldsArray,
          // Weird behavior in BE when including this if the same
          ...(state.imageData.url !== form?.imageData.url
            ? { skuImages: [state.imageData.url] }
            : {}),
        },
      },
    })
  }

  if (loading || !form) return <LoadingView />

  return (
    <WarrantySkuForm
      closeModal={closeModal}
      handleSubmit={handleSubmit}
      initialState={form}
      submitting={updateLoading}
      title="Edit warranty"
      skuId={skuId}
      disableCategoryBrandSelect
    />
  )
}

const LoadingView = () => (
  <FullscreenModal open={true} handleClose={() => {}} title="Edit warranty">
    <div>Loading...</div>
  </FullscreenModal>
)
