import { gql } from '@apollo/client'

export const CREATE_PRODUCT_TIER = gql`
  mutation createProductTier($params: CreateProductTierParams!) {
    createProductTier(params: $params) {
      productTier {
        id
        name
        description
        features {
          id
        }
      }
    }
  }
`

export const UPDATE_PRODUCT_TIER = gql`
  mutation updateProductTier($params: UpdateProductTierParams!) {
    updateProductTier(params: $params) {
      productTier {
        id
        name
        status
        description
        features {
          id
        }
      }
    }
  }
`
