import React from 'react'
import {
  Box,
  InputLabel,
  FormControl,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
} from '@mui/material'

import Table, { TableQuery } from '@firstbase/components/atoms/Table/Table'
import Textarea from '@firstbase/components/atoms/Textarea'

import { ConfirmMethodSection, RetrievalMethodOptionValues } from './types'
import { Product } from '../types'

interface OwnProps extends ConfirmMethodSection {
  equipment: TableQuery
  isCountryGb: boolean
  method: RetrievalMethodOptionValues | null
  onChangeMethod:
    | ((event: React.ChangeEvent<HTMLInputElement>, value: string) => void)
    | undefined
}

const EquipmentSection = ({
  comment,
  equipment,
  isCountryGb,
  method,
  onChangeComment,
  onChangeMethod,
}: OwnProps) => {
  const renderRetrievalRadioGroup = () => {
    if (isCountryGb) return null

    return (
      <FormControl fullWidth>
        <FormLabel
          id="retrieval-methods-label"
          sx={{ fontWeight: 'bold', marginBottom: 1 }}
        >
          Select the equipment retrieval method:
        </FormLabel>
        <RadioGroup
          aria-labelledby="retrieval-methods-label"
          name="retrieval-methods"
          onChange={onChangeMethod}
          value={method}
        >
          <FormControlLabel
            value={RetrievalMethodOptionValues.RETURN_KIT}
            control={<Radio />}
            label="Return kit"
          />
          <FormControlLabel
            value={RetrievalMethodOptionValues.INBOUND_PHYSICAL}
            control={<Radio />}
            label="Inbound label only (physical)"
          />
          <FormControlLabel
            value={RetrievalMethodOptionValues.INBOUND_DIGITAL}
            control={<Radio />}
            label="Inbound label only (digital)"
          />
        </RadioGroup>
      </FormControl>
    )
  }

  return (
    <Box marginTop={1.5}>
      <Table
        tableId="retrieval-table-equipment"
        query={equipment}
        columns={[
          {
            header: 'Equipment',
            id: 'sku.skuInformation.productTitle',
            cell: {
              sx: {
                maxWidth: '10rem',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              },
              value: ({ sku }: Product) => sku.skuInformation.productTitle,
            },
          },
          {
            header: 'Serial Number',
            id: 'serialNumber',
            cell: {
              sx: { width: '25%' },
              value: ({ serialNumber }: Product) => serialNumber,
            },
          },
        ]}
      />
      {renderRetrievalRadioGroup()}
      <Box marginTop={1}>
        <InputLabel
          htmlFor="retrieval-input-equipment"
          sx={{ fontWeight: 'bold', marginBottom: 1 }}
        >
          Provide an equipment retrieval note for the Ops team (optional)
        </InputLabel>
        <Textarea
          id="retrieval-input-equipment"
          onChange={onChangeComment}
          placeholder="E.g. Please include extra wrapping materials and tape"
          value={comment}
        />
      </Box>
    </Box>
  )
}

export default EquipmentSection
