import { gql } from '@apollo/client'

export const GET_CONTRACTS_BY_ORGANIZATION = gql`
  query getContractsByOrg($orgId: ID!) {
    getContractsByOrg(orgId: $orgId) {
      contracts {
        id
        revisionId
        productTiers {
          id
          name
        }
      }
    }
  }
`
