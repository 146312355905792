import { Select as MUISelect, SelectProps } from '@mui/material'
import React from 'react'

import { ReactComponent as ArrowDownIcon } from '@firstbase/assets/ArrowDown.svg'

const Select: <T>(props: SelectProps<T>) => JSX.Element = (props) => (
  <MUISelect
    {...props}
    IconComponent={(iconProps) => (
      <ArrowDownIcon
        {...iconProps}
        width={10}
        opacity={props.disabled ? '0.38' : 1}
        style={{
          marginRight: '1rem',
          top: props.size === 'small' ? '0.5rem' : '1rem',
        }}
      />
    )}
  />
)

export default Select
