import { gql } from '@apollo/client'

import {
  ORGANIZATION_WARRANTY_TYPE_ASSIGNMENT_FRAGMENT,
  WARRANTY_SKU_FRAGMENT,
} from './fragments'

export const CREATE_WARRANTY_TYPE = gql`
  mutation createWarrantyType($request: CreateWarrantyType!) {
    createWarrantyType(request: $request) {
      id
      name
      active
      vendorName
    }
  }
`

export const CREATE_ORGANIZATION_WARRANTY_TYPE_ASSIGNMENT = gql`
  ${ORGANIZATION_WARRANTY_TYPE_ASSIGNMENT_FRAGMENT}
  mutation createOrganizationWarrantyTypeAssignment(
    $request: CreateOrganizationWarrantyTypeAssignment!
  ) {
    createOrganizationWarrantyTypeAssignment(request: $request) {
      ...OrganizationWarrantyTypeAssignmentFragment
    }
  }
`

export const UPDATE_ORGANIZATION_WARRANTY_TYPE_ASSIGNMENT = gql`
  ${ORGANIZATION_WARRANTY_TYPE_ASSIGNMENT_FRAGMENT}
  mutation updateOrganizationWarrantyTypeAssignment(
    $request: UpdateOrganizationWarrantyTypeAssignment!
  ) {
    updateOrganizationWarrantyTypeAssignment(request: $request) {
      ...OrganizationWarrantyTypeAssignmentFragment
    }
  }
`

export const REMOVE_ORGANIZATION_WARRANTY_TYPE_ASSIGNMENT = gql`
  mutation removeOrganizationWarrantyTypeAssignment(
    $id: ID!
    $organizationId: ID!
  ) {
    removeOrganizationWarrantyTypeAssignment(
      id: $id
      organizationId: $organizationId
    )
  }
`

export const ADD_WARRANTY_METADATA_CONFIG_TO_CATEGORY = gql`
  mutation addWarrantyMetadataConfigToCategory(
    $fieldName: SkuMetadataFieldName!
    $categoryCode: String!
  ) {
    addFieldConfigurationForCategoryCode(
      fieldName: $fieldName
      categoryCode: $categoryCode
    ) {
      id
      fieldName
    }
  }
`

export const REMOVE_WARRANTY_METADATA_CONFIG = gql`
  mutation removeWarrantyMetadataConfig($id: ID!) {
    removeFieldConfiguration(id: $id)
  }
`

export const UPDATE_WARRANTY_SKU = gql`
  ${WARRANTY_SKU_FRAGMENT}
  mutation updateWarrantySKU($skuId: ID!, $updateSKU: UpdateSKU!) {
    updateSKU(skuId: $skuId, updateSKU: $updateSKU) {
      ...WarrantySKUFragment
      skuInformation {
        productTitle
      }
      status
    }
  }
`

export const CREATE_WARRANTY_SKU_METADATA_OPTION_VALUE = gql`
  mutation createWarrantySKUMetadataOptionValue(
    $request: CreateWarrantySKUMetadataOptionValue!
  ) {
    createWarrantySKUMetadataOptionValue(request: $request) {
      id
    }
  }
`
