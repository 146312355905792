import React, { useState } from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
} from '@mui/material'
import {
  getNewProductData,
  getNewProductData_getAllVendors_data,
} from '@firstbase/data/SKU/__generated__/getNewProductData'
import { NewVendorModal } from '@firstbase/components/molecules/NewVendorModal'

type VendorOption = {
  name: string
  code: string
}

interface OwnProps {
  data?: getNewProductData
  vendor: string
  setVendor: (vendor: string, name: string) => void
  setToInitialMetadata?: () => void
  genericCategory?: boolean
  disabled?: boolean
  hideCreateOption?: boolean
  sx?: SxProps
}

// TODO: Refactor to be a reusable component; also consider moving vendor modal outside of this component
export const VendorSelect = ({
  data,
  vendor,
  setVendor,
  setToInitialMetadata,
  genericCategory,
  disabled = false,
  sx,
  hideCreateOption,
}: OwnProps) => {
  const [newVendor, setNewVendor] = useState(false)

  const renderVendors = () => {
    if (!data) return null

    let sortedVendors: getNewProductData_getAllVendors_data[] = [
      ...data.getAllVendors.data,
    ]

    sortedVendors.sort(
      (
        a: getNewProductData_getAllVendors_data,
        b: getNewProductData_getAllVendors_data
      ) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
    )

    const vendorsList = [
      ...(hideCreateOption
        ? []
        : [{ code: 'newVendor', name: 'Add new vendor' }]),
      ...sortedVendors,
    ]
    return vendorsList.map(({ code, name }: VendorOption) => (
      <MenuItem key={code} value={code}>
        {name}
      </MenuItem>
    ))
  }
  const handleVendorChange = (value: string) => {
    setToInitialMetadata?.()
    if (value === 'newVendor') {
      return setNewVendor(true)
    }
    return setVendor(
      value,
      data?.getAllVendors.data.find((v) => v.code === value)?.name ?? ''
    )
  }

  if (genericCategory) return null

  return (
    <>
      <FormControl disabled={disabled} sx={sx}>
        <InputLabel id="vendor">Brand</InputLabel>
        <Select
          id="vendor"
          labelId="vendor-label"
          value={vendor}
          label="Brand"
          fullWidth
          data-testid={`vendorSelect?disabled=${(
            disabled ?? false
          ).toString()}`}
          disabled={disabled}
          onChange={(e) =>
            handleVendorChange((e.target as HTMLInputElement).value)
          }
        >
          {renderVendors()}
        </Select>
      </FormControl>
      <NewVendorModal
        open={newVendor}
        handleClose={() => setNewVendor(false)}
        onSuccess={(response: VendorOption) =>
          setVendor(response.code, response.name)
        }
        refetchQueries={['getNewProductData']}
      />
    </>
  )
}
