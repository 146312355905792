import { theme } from '@firstbase/muiTheme'
import { alpha, Box, Tooltip } from '@mui/material'
import React from 'react'

import { ReactComponent as AlertIcon } from '@firstbase/assets/Alert.svg'
import {
  getAllSkuMinimumInventoryStatsForOrganization_getAllSkuMinimumInventoryStatsForOrganization_data_available as Available,
  getAllSkuMinimumInventoryStatsForOrganization_getAllSkuMinimumInventoryStatsForOrganization_data_minimumInventory as MinimumInventory,
} from '@firstbase/data/Organization/__generated__/getAllSkuMinimumInventoryStatsForOrganization'

type OwnProps = {
  available: Available
  minimumInventory: MinimumInventory
  ordered: number
}

const getAvailableValue = (
  minimumInventory: MinimumInventory,
  available: Available
) => (minimumInventory.includeUsed ? available.all : available.new)

export const getAvailableCellStyle = ({
  minimumInventory,
  available,
}: {
  minimumInventory: MinimumInventory
  available: Available
}) => {
  const showValue = getAvailableValue(minimumInventory, available)

  if (showValue < minimumInventory.minimumQuantity)
    return {
      backgroundColor: alpha(theme.palette.warning.main, 0.04),
    }
}

const MinInventoryAvailableCell = ({
  available,
  minimumInventory,
  ordered,
}: OwnProps) => {
  const availableValue = getAvailableValue(minimumInventory, available)
  const isBelow = availableValue < minimumInventory.minimumQuantity
  const isBelowAfterOrders =
    availableValue + ordered < minimumInventory.minimumQuantity

  const Wrapper = isBelowAfterOrders ? Tooltip : Box
  const wrapperProps = (
    isBelowAfterOrders
      ? {
          title: `Order ${
            minimumInventory.minimumQuantity - availableValue - ordered
          } more`,
          arrow: true,
        }
      : {}
  ) as any

  return (
    <Wrapper {...wrapperProps}>
      <Box
        sx={
          isBelow
            ? {
                color: theme.palette.warning.main,
                display: 'flex',
                alignItems: 'center',
              }
            : {}
        }
      >
        {availableValue}
        {isBelowAfterOrders && (
          <AlertIcon
            style={{ marginLeft: '0.5rem' }}
            fill={theme.palette.warning.main}
          />
        )}
      </Box>
    </Wrapper>
  )
}

export default MinInventoryAvailableCell
