import { createReplacementVariables } from '@firstbase/data/Replacements/__generated__/createReplacement'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import React from 'react'

type OwnProps = {
  rows: createReplacementVariables[]
}

const BulkUpgradeReplacements = ({ rows }: OwnProps) => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(3)

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <>
      <Typography variant="h5" role="header">
        Replacements summary
      </Typography>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Row</TableCell>
              <TableCell>Asset ID</TableCell>
              <TableCell>Assignee slug</TableCell>
              <TableCell>Address line 1</TableCell>
              <TableCell>Address line 2</TableCell>
              <TableCell>City</TableCell>
              <TableCell>State/Region</TableCell>
              <TableCell>Zip/Postal code</TableCell>
              <TableCell>Country code</TableCell>
              <TableCell>Phone number</TableCell>
              <TableCell>Vendor SKU</TableCell>
              <TableCell>Vendor code</TableCell>
              <TableCell>Replacement reason</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row, i) => (
              <TableRow key={i}>
                <TableCell>{1 + i + page * rowsPerPage}</TableCell>
                <TableCell>
                  {
                    row.createReplacement.createReturn.returnItems[0]
                      .inventoryId
                  }
                </TableCell>
                <TableCell>{row.personSlug}</TableCell>
                <TableCell>
                  {
                    row.createReplacement.createOrder.shippingAddress
                      .addressLine1
                  }
                </TableCell>
                <TableCell>
                  {
                    row.createReplacement.createOrder.shippingAddress
                      .addressLine2
                  }
                </TableCell>
                <TableCell>
                  {row.createReplacement.createOrder.shippingAddress.locality}
                </TableCell>
                <TableCell>
                  {
                    row.createReplacement.createOrder.shippingAddress
                      .administrativeArea
                  }
                </TableCell>
                <TableCell>
                  {row.createReplacement.createOrder.shippingAddress.postalCode}
                </TableCell>
                <TableCell>
                  {
                    row.createReplacement.createOrder.shippingAddress
                      .countryCode
                  }
                </TableCell>
                <TableCell>
                  {
                    row.createReplacement.createOrder.shippingAddress
                      .phoneNumber
                  }
                </TableCell>
                <TableCell>
                  {row.createReplacement.createOrder.products[0].vendorSku}
                </TableCell>
                <TableCell>
                  {row.createReplacement.createOrder.products[0].vendorCode}
                </TableCell>
                <TableCell>
                  {
                    row.createReplacement.createReturn.returnItems[0]
                      .returnReason
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[3, 10, 25, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
}

export default BulkUpgradeReplacements
