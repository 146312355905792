import { useFlags } from 'launchdarkly-react-client-sdk'
import StatusChip from '@firstbase/components/atoms/StatusChip'
import Table from '@firstbase/components/atoms/Table'
import { getReplacementByIdForOrganization_getReplacementByIdForOrganization } from '@firstbase/data/Order/__generated__/getReplacementByIdForOrganization'
import { getOrganizationById } from '@firstbase/data/Organization/__generated__/getOrganizationById'
import { theme } from '@firstbase/muiTheme'
import FLAGS from '@firstbase/constants/featureFlags'
import { Box, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import { getShippingAddress } from './detailUtils'
import EditReturnOrder from './EditReturnOrder'
import ReturnRetrievals from './ReturnRetrievals/ReturnRetrievals'
import { ORDER_COLUMNS, RETURN_COLUMNS } from './orderDetailColumns'

const {
  palette: {
    secondary: { main: secondaryColor },
    grey: { '500': grey500 },
  },
} = theme

type OwnProps = {
  organization: getOrganizationById
  replacement: getReplacementByIdForOrganization_getReplacementByIdForOrganization
}

const ReplacementDetail = ({ organization, replacement }: OwnProps) => {
  const { order, returnOrder, person } = replacement
  const [shippingAddress, returnShippingAddress] = [order, returnOrder].map(
    getShippingAddress
  )

  const flags = useFlags()
  const showRetrievalsFlag = flags[FLAGS.SE_3854_SHOW_CONFIRMED_RETRIEVALS]

  return (
    <>
      <Stack gap={2}>
        <Stack sx={{ mb: 2, gap: 0.5 }}>
          <Typography>
            Replacement for {person.forename} {person.surname} with{' '}
            {organization.getOrganizationById.name}
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Typography fontWeight="bold">Status</Typography>
            <StatusChip type="order" status={replacement.status} />
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Typography fontWeight="bold">Replacement Order ID:</Typography>
            <Typography>{replacement.id}</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Typography fontWeight="bold">Shipping Address:</Typography>
            <Typography>{shippingAddress}</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Typography fontWeight="bold">Return Address:</Typography>
            <Typography>
              {returnShippingAddress === shippingAddress
                ? 'Same as shipping'
                : returnShippingAddress}
            </Typography>
          </Box>
        </Stack>
        {showRetrievalsFlag && (
          <Box sx={{ marginBottom: 2 }}>
            <ReturnRetrievals returnOrderId={returnOrder.id} />
          </Box>
        )}
        <Paper sx={{ overflow: 'hidden' }}>
          <Box
            sx={{
              p: 1.5,
              backgroundColor: grey500,
              color: secondaryColor,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Box>
                <Typography color="inherit" fontWeight="bold">
                  What we’re collecting from {person.forename}
                </Typography>
                <Typography color="inherit">
                  Return Order ID: {returnOrder.id}
                </Typography>
              </Box>
              <EditReturnOrder
                isReplacement
                returnOrder={returnOrder}
                person={person}
              />
            </Stack>
          </Box>
          <Table
            tableId="return-detail-table"
            columns={RETURN_COLUMNS}
            wrapper="Box"
            query={{ data: { data: returnOrder.products || [] } }}
          />
        </Paper>
        <Paper sx={{ overflow: 'hidden' }}>
          <Box
            sx={{
              p: 1.5,
              backgroundColor: grey500,
              color: secondaryColor,
            }}
          >
            <Typography color="inherit" fontWeight="bold">
              What we’re sending {person.forename}
            </Typography>
            <Typography color="inherit">Order ID: {order.id}</Typography>
          </Box>
          <Table
            tableId="order-detail-table"
            columns={ORDER_COLUMNS}
            wrapper="Box"
            query={{ data: { data: order.products || [] } }}
          />
        </Paper>
      </Stack>
    </>
  )
}

export default ReplacementDetail
