import { datadogRum } from '@datadog/browser-rum'
import { UserClaims } from '@okta/okta-auth-js'

const datadogUserSession = (userData: UserClaims) => {
  datadogRum.setUser({
    id: userData.personId,
    email: userData.email || '',
  })
}

export default datadogUserSession
