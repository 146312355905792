import { theme } from '@firstbase/muiTheme'
import { Typography } from '@mui/material'
import React from 'react'

type OwnProps = {
  children?: JSX.Element | string | boolean
  labels?: [trueLabel: string, trueLabel: string]
}

const {
  palette: { success, error },
} = theme

const Boolean = ({ children, labels = ['YES', 'NO'] }: OwnProps) => {
  const [trueLabel, falseLabel] = labels

  const label = children ? trueLabel : falseLabel
  const color = children ? success : error

  return <Typography color={color.light}>{label}</Typography>
}

export default Boolean
