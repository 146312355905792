import { helpers } from '../BulkUpload'

export default function (data: any) {
  return data.map((dataRow: any) => {
    Object.keys(dataRow).forEach((key: string) => {
      if (!dataRow[key]) delete dataRow[key]
    })

    return {
      ...dataRow,
      firstbaseSupplied: helpers.isStringTrue(dataRow.firstbaseSupplied),
    }
  })
}
