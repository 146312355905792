import { useQuery } from '@apollo/client'
import Page from '@firstbase/components/atoms/Page'
import TabGroup from '@firstbase/components/molecules/TabGroup'
import { useBreadcrumbs } from '@firstbase/context/breadcrumbs/BreadcrumbsProvider'
import { GET_PACKAGE_BY_SLUG } from '@firstbase/data/Package/queries'
import {
  getPackageSelectionsBySlug,
  getPackageSelectionsBySlugVariables,
} from '@firstbase/data/Package/__generated__/getPackageSelectionsBySlug'
import { PackagePageParams } from '@firstbase/types/Pages'
import { Alert } from '@mui/material'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import AssignedPeople from './AssignedPeople'
import Selections from './Selections'

const Package = () => {
  const params: PackagePageParams = useParams()
  const { setBreadcrumbs } = useBreadcrumbs()
  const { data, loading, error } = useQuery<
    getPackageSelectionsBySlug,
    getPackageSelectionsBySlugVariables
  >(GET_PACKAGE_BY_SLUG, {
    variables: { packageSlug: params.packageSlug },
  })

  useEffect(
    () =>
      setBreadcrumbs([
        { label: 'Clients', pathId: 'clients' },
        {
          isLoading: loading,
          label: data?.getPackageBySlug.organization.name,
          pathId: 'client',
          searchParams: { tab: 'packages' },
        },
        {
          isLoading: loading,
          label: data?.getPackageBySlug.name,
        },
      ]),
    [
      data?.getPackageBySlug.name,
      data?.getPackageBySlug.organization.name,
      loading,
      setBreadcrumbs,
    ]
  )

  return (
    <Page
      breadcrumb
      error={error as Error}
      isLoading={loading}
      title={data?.getPackageBySlug.name}
      subtitle={`${data?.getPackageBySlug.organization.name} package`}
    >
      {!data?.getPackageBySlug.active ? (
        <Alert severity="warning" sx={{ mb: 2 }}>
          This package is no longer active on platform
        </Alert>
      ) : (
        <TabGroup queryParam>
          <TabGroup.Page label="Selections" id="selections">
            <Selections skus={data?.getPackageBySlug.skus} loading={loading} />
          </TabGroup.Page>
          <TabGroup.Page label="Assigned People" id="people">
            <AssignedPeople
              people={data?.getPackageBySlug.people}
              loading={loading}
            />
          </TabGroup.Page>
        </TabGroup>
      )}
    </Page>
  )
}

export default Package
