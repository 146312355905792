type SetCallback<T> = () => T

/**
 * Utility class for instantiating an object that is only supposed
 * to be instantiated once.  As of writing, this is just a useful wrapper
 * for setting up objects that rely on environment variables,
 * which are loaded at run time.  This one instance, then needs to be
 * passed around the app.  This class just provides methods that protect
 * against premature accessing and double setting.  TS is also useful here
 * to help minimize bugs and know what object you are working with, given the
 * typing of the instance.  This helps avoid having to write tests, since TS
 * is already asserting objects are of a certain type.
 */
class Singleton<T> {
  private instance: T | undefined

  private instanceSet = false

  set(cb: SetCallback<T>) {
    if (this.instanceSet) {
      throw new Error('Instance already set!')
    }
    this.instance = cb()
    this.instanceSet = true
  }

  get() {
    if (!this.instanceSet) {
      throw new Error('Instance has not been set yet!')
    }
    return this.instance as T
  }

  hasBeenSet() {
    return this.instanceSet
  }
}

export default Singleton
