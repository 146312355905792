import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'

import { CREATE_VENDOR } from '@firstbase/data/Vendor/mutations'

type VendorOption = {
  name: string
  code: string
  fpnSegment: string
}

type OwnProps = {
  open: boolean
  handleClose: () => void
  refetchQueries?: [string]
  onSuccess?: (vendor: VendorOption) => void
  onError?: (error: string) => void
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
}

export function NewVendorModal({
  refetchQueries,
  onSuccess,
  handleClose,
  open,
}: OwnProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm()
  const { enqueueSnackbar } = useSnackbar()

  const [createVendor] = useMutation(CREATE_VENDOR)

  const watchedCode = watch('code')
  const watchedFpnSegment = watch('fpnSegment')

  useEffect(() => {
    setValue(
      'fpnSegment',
      watchedCode
        ?.replace(/[^A-Za-z]/g, '')
        .slice(0, 3)
        .toUpperCase(),
      { shouldDirty: true }
    ) // auto-populate fpnSegment with the value of code
  }, [watchedCode, setValue])

  const onSubmit = async (fields: any) => {
    await createVendor({
      variables: {
        vendorName: fields.name,
        vendorCode: fields.code,
        fpnSegment: fields.fpnSegment,
      },
      refetchQueries,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess({
            code: response?.data?.createVendor?.code,
            name: response?.data?.createVendor?.name,
            fpnSegment: response?.data?.createVendor?.fpnSegment,
          })
        }

        reset()

        handleClose()

        enqueueSnackbar(`Created vendor "${fields.name}"`)

        return response
      })
      .catch((error) => {
        enqueueSnackbar(error.message)
      })
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={style}>
        <h2>Add new vendor</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            id="name"
            label="Name"
            fullWidth
            margin="normal"
            inputProps={register('name', {})}
          />

          <TextField
            id="code"
            label="Code"
            fullWidth
            margin="normal"
            error={!!errors.code?.message}
            helperText={errors.code?.message}
            inputProps={register('code', {
              required: true,
              minLength: {
                value: 3,
                message: 'Code must be at least 3 characters long',
              },
              pattern: {
                value: /^[A-Z|_)]*$/,
                message:
                  'Code must be uppercase and only use underscore - e.g. VENDOR_CODE_HERE',
              },
            })}
          />

          <TextField
            id="fpnSegment"
            label="FPN Identifier"
            fullWidth
            margin="normal"
            error={!!errors.fpnSegment?.message}
            helperText={errors.fpnSegment?.message}
            InputLabelProps={{
              shrink:
                !!watchedFpnSegment || (!!watchedFpnSegment && !!watchedCode),
            }}
            inputProps={{
              ...register('fpnSegment', {
                required: true,
                minLength: {
                  value: 3,
                  message: 'Code must be 3 characters long',
                },
                maxLength: {
                  value: 3,
                  message: 'Code must be 3 characters long',
                },
                pattern: {
                  value: /^[A-Z)]*$/,
                  message: 'Code must be uppercase e.g. ALD',
                },
              }),
              maxLength: 3,
            }}
          />
          <Button color="primary" type="submit" variant="contained">
            Add vendor
          </Button>
        </form>
      </Box>
    </Modal>
  )
}
