import React from 'react'
import { Button, Typography } from '@mui/material'
import { ApolloError } from '@apollo/client'

import { SkuProductCard } from '@firstbase/components/molecules/SkuProductCard'
import { getAllSKUs_getAllSKUs_data } from '@firstbase/data/SKU/__generated__/getAllSKUs'

interface OwnProps {
  filteredSkus: getAllSKUs_getAllSKUs_data[] | []
  filteredSKUsError: ApolloError | undefined
  filteredSKUsLoading: boolean
  handleLoadMore: () => void
  hasMore: boolean
  getSkuTitle: (sku: getAllSKUs_getAllSKUs_data) => string
  getSkuChips?: (sku: getAllSKUs_getAllSKUs_data) => string[]
}

export const FilteredSkuCardList = ({
  filteredSkus,
  filteredSKUsError,
  filteredSKUsLoading,
  handleLoadMore,
  hasMore,
  getSkuTitle,
  getSkuChips,
}: OwnProps) => {
  if (filteredSKUsError) {
    return (
      <Typography color="error" sx={{ marginBottom: 1, paddingTop: 2 }}>
        Error loading existing computers in platform catalog
      </Typography>
    )
  }

  return (
    <div style={{ marginTop: 15, height: 600, overflow: 'auto' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {filteredSkus.map((sku, index) => (
          <SkuProductCard
            key={`${sku.id} - ${index}`}
            marginBottom
            image={sku.skuImages?.[0]}
            vendor={sku.vendor.name}
            skuTitle={getSkuTitle(sku)}
            skuChips={getSkuChips?.(sku) || []}
            skuId={sku.id}
          />
        ))}
        {hasMore && (
          <Button onClick={handleLoadMore} disabled={filteredSKUsLoading}>
            {filteredSKUsLoading ? 'Loading...' : 'Load More'}
          </Button>
        )}
      </div>
    </div>
  )
}
