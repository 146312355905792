import { NOT_SUPPORTED } from '@firstbase/constants/contract'
import { ContractResponse } from '@firstbase/types/Contract'
import {
  contractV3ServiceFeeTypes,
  contractV3ServiceFeeTypesMap,
  FeesRow,
  FeeType,
  FormFeesByRegionByType,
  serviceFeeTypes,
  V3FeeType,
} from '@firstbase/types/Fee'
import { PostContract } from '@firstbase/views/Home/Client/Settings/ContractSettingsForm/SettingsForm'
import { useQuery } from '@apollo/client'
import {
  getAllRegions,
  getAllRegions_getAllRegions_defaultCurrency,
} from '@firstbase/data/Geo/__generated__/getAllRegions'
import { GET_ALL_REGIONS } from '@firstbase/data/Geo/queries'
import { useMemo } from 'react'

export function constructFeesByRegionByType(
  contractResponse?: ContractResponse
) {
  const feesByCountry: FormFeesByRegionByType = {}
  if (!contractResponse || !contractResponse?.fees?.length) {
    return feesByCountry
  }

  contractResponse?.fees.forEach((feesRow: FeesRow) => {
    if (!feesByCountry[feesRow.regionCode]) {
      feesByCountry[feesRow.regionCode] = {}
    }
    feesByCountry[feesRow.regionCode][feesRow.feeType] = feesRow
  })

  return feesByCountry
}

export function constructNewContract(
  contractDetails: ContractResponse['contract'],
  currencyDetailsByRegion: Record<string, string>,
  feeDetailsByRegion: FormFeesByRegionByType,
  orgId: string,
  discountFlag?: boolean
): PostContract {
  // When we are editing a contract, we need to filter out the fee types that are not supported
  // while still including the management fee
  const filterListFeeTypes =
    contractDetails.version === 3 ? contractV3ServiceFeeTypes : serviceFeeTypes

  let fees = Object.keys(feeDetailsByRegion)
    .filter(
      (regionCode: string) =>
        currencyDetailsByRegion[regionCode] &&
        currencyDetailsByRegion[regionCode] !== NOT_SUPPORTED
    )
    .flatMap((countryCode: string) => {
      return Object.entries(feeDetailsByRegion[countryCode])
        .filter(([, feeRow]) => feeRow.price !== NOT_SUPPORTED)
        .map(([feeType, feeRow]) => {
          return {
            regionCode: countryCode,
            currencyCode:
              feeRow.overrideCurrencyCode ||
              currencyDetailsByRegion[countryCode],
            feeType: feeType as FeeType,
            price: parseFloat(feeRow.price as string),
            billingType: feeRow.billingType,
          }
        })
    })
    .filter(
      (fee) =>
        filterListFeeTypes.includes(fee.feeType) ||
        fee.feeType === 'Management fee'
    )

  const newContract: PostContract = {
    orgId,
    version: contractDetails.version,
    versionDescription: contractDetails.versionDescription,
    description: contractDetails.description,
    contractEditedRevision: contractDetails.contractEditedRevision + 1,
    haasBillingFrequency: contractDetails.haasBillingFrequency,
    feesBillingFrequency: contractDetails.feesBillingFrequency,
    contractLengthMonths: contractDetails.contractLengthMonths,
    contractStartDate: contractDetails.contractStartDate,
    defaultsToRegion: contractDetails.defaultsToRegion,
    prepaidSeats: contractDetails.prepaidSeats,
    currencies: Object.entries(currencyDetailsByRegion)
      .filter(([, currencyCode]: string[]) => currencyCode !== NOT_SUPPORTED)
      .map(([regionCode, currencyCode]: string[]) => {
        return {
          regionCode: regionCode,
          currency: currencyCode,
        }
      }),
    fees,
    serviceFeeDiscount: getServiceFeeDiscountValue(
      contractDetails,
      discountFlag
    ),
  }

  return newContract
}
export const sortByReverseAlpha = (a: string, b: string) =>
  a.split('').reverse().join('') > b.split('').reverse().join('') ? 1 : -1

export const getPreviousVersionFromV3Type = (type: V3FeeType): FeeType =>
  contractV3ServiceFeeTypesMap[type]

export const getV3TypeFromPreviousVersion = (type: FeeType): V3FeeType =>
  Object.keys(contractV3ServiceFeeTypesMap).find(
    (key) => contractV3ServiceFeeTypesMap[key] === type
  ) as FeeType

export const getServiceFeeTypes = (contractVersion?: number): FeeType[] => {
  if (contractVersion === 3) return contractV3ServiceFeeTypes
  return serviceFeeTypes
}

export function useSupportedRegions() {
  const { data: regionsData } = useQuery<getAllRegions>(GET_ALL_REGIONS)

  return useMemo<Record<string, string>>(() => {
    if (!regionsData) return {}

    return regionsData.getAllRegions.reduce<Record<string, string>>(
      (obj, region) => {
        obj[region.regionCode] = region.name
        return obj
      },
      {}
    )
  }, [regionsData])
}

export function useSupportedCurrencies() {
  const { data: regionsData } = useQuery<getAllRegions>(GET_ALL_REGIONS)

  return useMemo<Record<string, string>>(() => {
    if (!regionsData) return {}

    return regionsData.getAllRegions.reduce<Record<string, string>>(
      (obj, region) => {
        obj[region.defaultCurrency.acronym] = region.defaultCurrency.symbol
        return obj
      },
      { [NOT_SUPPORTED]: '' }
    )
  }, [regionsData])
}

export function useRegionCurrency() {
  const { data: regionsData } = useQuery<getAllRegions>(GET_ALL_REGIONS)

  return useMemo<
    Record<string, getAllRegions_getAllRegions_defaultCurrency>
  >(() => {
    if (!regionsData) return {}

    return regionsData.getAllRegions.reduce<
      Record<string, getAllRegions_getAllRegions_defaultCurrency>
    >((obj, region) => {
      obj[region.regionCode] = region.defaultCurrency
      return obj
    }, {})
  }, [regionsData])
}

const getServiceFeeDiscountValue = (
  contractDetails: ContractResponse['contract'],
  discountFlag: boolean = false
): ContractResponse['contract']['serviceFeeDiscount'] => {
  const { serviceFeeDiscount } = contractDetails

  if (!discountFlag || !serviceFeeDiscount?.percentageDiscount) return null

  return { percentageDiscount: serviceFeeDiscount.percentageDiscount }
}
