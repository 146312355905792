import React, { useEffect, useCallback, useMemo } from 'react'

import usStates from './data/usStates.json'
import ukCounties from './data/ukCounties.json'
import caProvinces from './data/caProvinces.json'

import { DropdownOption } from '@firstbase/utils/country'
import { MenuItem, TextField } from '@mui/material'

type AdministrativeAreaOptionsMapI = {
  [key: string]: DropdownOption[] | undefined
}

const administrativeAreaOptionsMap: AdministrativeAreaOptionsMapI = {
  CA: caProvinces,
  GB: ukCounties,
  US: usStates,
}

type AdministrativeAreaFieldProps = {
  countryCode?: string
  administrativeArea?: string
  setAdministrativeArea: (a: string) => void
  disabled?: boolean
}

const AdministrativeAreaField = ({
  countryCode = '',
  administrativeArea = '',
  setAdministrativeArea,
  disabled = false,
}: AdministrativeAreaFieldProps) => {
  const isDropdown = useMemo(() => {
    return countryCode === 'US' || countryCode === 'CA'
  }, [countryCode])
  const administrativeAreaOptions = useMemo(
    () => administrativeAreaOptionsMap[countryCode],
    [countryCode]
  )

  const shouldResetField = useCallback(() => {
    const isAdministrativeAreaInDataset = !!administrativeAreaOptions?.find(
      (a) => a.value === administrativeArea
    )
    const administrativeAreaIsNotEmpty = !!administrativeArea

    return (
      isDropdown &&
      administrativeAreaIsNotEmpty &&
      !isAdministrativeAreaInDataset
    )
  }, [isDropdown, administrativeArea, administrativeAreaOptions])

  // When existing value is not part of existing data options, erase it
  // to force the user to select a new normalized value
  useEffect(() => {
    if (shouldResetField()) {
      setAdministrativeArea('')
    }
  }, [shouldResetField, setAdministrativeArea])

  const getAdministrativeAreaLabel = () => {
    switch (countryCode) {
      case 'US':
        return 'State'
      case 'CA':
        return 'Province'
      case 'GB':
        return 'County'
      default:
        return 'Region'
    }
  }

  return (
    <>
      <TextField
        fullWidth
        select={isDropdown}
        size="small"
        id="administrativeAreaTextField"
        label={getAdministrativeAreaLabel()}
        value={administrativeArea}
        onChange={(e) => setAdministrativeArea(e.target.value)}
        disabled={disabled}
        defaultValue={administrativeArea}
      >
        {isDropdown &&
          administrativeAreaOptions?.map((administrativeAreaOption) => (
            <MenuItem
              key={administrativeAreaOption.value}
              value={administrativeAreaOption.value}
            >
              {administrativeAreaOption.label}
            </MenuItem>
          ))}
      </TextField>
    </>
  )
}

export default AdministrativeAreaField
