import React, { useCallback, useEffect, useState } from 'react'
import { NOT_SUPPORTED } from '@firstbase/constants/contract'
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { FeeType } from '@firstbase/types/Fee'
import FLAGS from '@firstbase/constants/featureFlags'

type Props = {
  overrideCurrencies: string[]
  override: { fees: Record<string, string> } | null
  setOverride: React.Dispatch<
    React.SetStateAction<{
      fees: Record<string, string>
    } | null>
  >
  updateServiceFees: (
    feePrice: string | null,
    currencyCode?: string,
    singleFeeType?: FeeType
  ) => void
  orgServiceFees: string[]
  overrideWithDifferentPrices: boolean
  setOverrideWithDifferentPrices: React.Dispatch<React.SetStateAction<boolean>>
}

type CheckboxValues = 'a' | 'b'

const OverrideServiceFeesForm = ({
  override,
  overrideCurrencies,
  setOverride,
  updateServiceFees,
  orgServiceFees,
  overrideWithDifferentPrices,
  setOverrideWithDifferentPrices,
}: Props) => {
  const flags = useFlags()
  const updatedOverridesFlag = flags[FLAGS.SE_3532_OVERRIDE_DIFFERENT_PRICES]

  const initailizeValue = () => {
    if (override) return 'a'
    if (overrideWithDifferentPrices) return 'b'
    return null
  }
  const [value, setValue] = useState<CheckboxValues | null>(initailizeValue())

  const handleToggleOverride = useCallback(
    (optionValue: CheckboxValues | null) =>
      setOverride((previousValue) => {
        if (optionValue !== 'a') return null

        // toggled override on - trigger override with first currency
        updateServiceFees(NOT_SUPPORTED, overrideCurrencies[0])

        return {
          fees: orgServiceFees.reduce(
            (acc, feeType) => ({
              ...acc,
              [feeType]:
                previousValue?.fees && previousValue.fees[feeType]
                  ? previousValue.fees[feeType]
                  : NOT_SUPPORTED,
            }),
            {}
          ),
        }
      }),
    [setOverride, updateServiceFees, orgServiceFees, overrideCurrencies]
  )

  const handleToggleOverrideWithDifferentPrices = useCallback(
    (optionValue: CheckboxValues | null) =>
      setOverrideWithDifferentPrices(optionValue === 'b'),
    [setOverrideWithDifferentPrices]
  )

  // Wrapper to help when clicking on active one
  const toggleCheckbox = (optionValue: CheckboxValues) => {
    setValue((previousValue) =>
      previousValue === optionValue ? null : optionValue
    )
  }

  useEffect(() => {
    handleToggleOverride(value)
    if (updatedOverridesFlag) {
      handleToggleOverrideWithDifferentPrices(value)
    }
  }, [
    value,
    handleToggleOverrideWithDifferentPrices,
    handleToggleOverride,
    updatedOverridesFlag,
  ])

  return (
    <Box sx={{ mb: 1 }} data-testid="service-override-section">
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={value === 'a'}
              onChange={() => toggleCheckbox('a')}
              data-testid={`override-service?override=${value === 'a'}`}
            />
          }
          label={
            <Typography>
              <Typography component="span" sx={{ fontWeight: 'bold' }}>
                Override:{' '}
              </Typography>
              Charge service fees in one currency
            </Typography>
          }
        />
      </Box>
      {updatedOverridesFlag ? (
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={value === 'b'}
                onChange={() => toggleCheckbox('b')}
              />
            }
            label={
              <Typography>
                <Typography component="span" sx={{ fontWeight: 'bold' }}>
                  Override:{' '}
                </Typography>
                Charge service fees in one currency but different prices per
                region
              </Typography>
            }
          />
        </Box>
      ) : null}
    </Box>
  )
}

export default OverrideServiceFeesForm
