import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Card,
  Skeleton,
  TableFooter,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Box,
} from '@mui/material'
import { useQuery } from '@apollo/client'
import DeleteIcon from '@mui/icons-material/Delete'

import Page from '@firstbase/components/atoms/Page'
import { theme } from '@firstbase/muiTheme'
import { GET_ALL_REGIONS } from '@firstbase/data/Geo/queries'
import {
  getAllRegions,
  getAllRegions_getAllRegions,
} from '@firstbase/data/Geo/__generated__/getAllRegions'
import { CurrencySelect } from '@firstbase/components/molecules/CurrencySelect'

import { CommonProps } from './types'

const {
  palette: {
    secondary: { main: secondaryColor },
    grey: { '300': grey300, '500': grey500 },
  },
} = theme

const tableSx = {
  backgroundColor: grey300,
  color: secondaryColor,
}

const columns = ['Supported region', 'Charged Currency', 'Purchase price']

interface OwnProps extends CommonProps {}

export const PricingDetailsTable = ({ state, dispatch }: OwnProps) => {
  const { regionCodeSelect, currencyCodeSelect } = state.pricingDetails

  const { data: regions, loading: regionsLoading } =
    useQuery<getAllRegions>(GET_ALL_REGIONS)

  const findRegion = (
    regionCode: string
  ): getAllRegions_getAllRegions | undefined =>
    regions?.getAllRegions.find((r) => r.regionCode === regionCode)

  const atLeastOneRow = state.pricingDetails.rows.length > 0

  /**
   * Use render.. functions to keep the component clean and easy to read.
   */

  const renderTableHead = () => {
    if (!atLeastOneRow) return null

    return (
      <TableHead sx={tableSx}>
        <TableRow sx={{ borderBottom: `1px solid ${grey500}`, padding: 1 }}>
          {columns.map((column) => (
            <TableCell
              sx={{ borderBottom: `1px solid ${grey500}`, padding: 1 }}
              key={column}
              colSpan={2}
            >
              <Typography variant="inherit" sx={{ fontWeight: 'bold' }}>
                {column}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }

  const renderTableBody = () => {
    if (!atLeastOneRow) return null

    return (
      <TableBody>
        {state.pricingDetails.rows.map((row, index) => (
          <TableRow key={`${row.regionCode}-${row.currencyCode}`}>
            <TableCell colSpan={2}>
              {findRegion(row.regionCode)?.name ?? row.regionCode}
            </TableCell>
            <TableCell colSpan={2}>{row.currencyCode}</TableCell>
            <TableCell colSpan={2}>
              <Box display="flex" alignItems="center">
                <TextField
                  label="Purchase price"
                  inputProps={{
                    min: 0,
                    step: '0.01',
                  }}
                  type="number"
                  value={row.purchasePrice}
                  onChange={(e) =>
                    dispatch({
                      type: 'SET_PRICING_DETAILS_PRICE',
                      payload: {
                        index: index,
                        purchasePrice: parseFloat(e.target.value),
                      },
                    })
                  }
                  fullWidth
                  size="small"
                />
                <IconButton
                  id="deleteIcon"
                  color="inherit"
                  aria-label="delete-row"
                  onClick={() =>
                    dispatch({
                      type: 'REMOVE_PRICING_DETAILS_ROW',
                      payload: { index },
                    })
                  }
                  size="medium"
                >
                  <DeleteIcon fontSize="medium" sx={{ color: grey500 }} />
                </IconButton>
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  }

  const renderTableFooter = () => {
    return (
      <TableFooter sx={tableSx}>
        <TableRow>
          <TableCell colSpan={2}>
            <RegionSelect
              selectedRegionCode={state.pricingDetails.regionCodeSelect}
              setSelectedRegionCode={(code: string) =>
                dispatch({
                  type: 'SET_PRICING_DETAILS_REGION_CODE',
                  payload: code,
                })
              }
              regions={regions?.getAllRegions || []}
            />
          </TableCell>
          <TableCell colSpan={2}>
            <CurrencySelect
              setSelectedCurrencyCode={(code: string) =>
                dispatch({
                  type: 'SET_PRICING_DETAILS_CURRENCY_CODE',
                  payload: code,
                })
              }
              selectedCurrencyCode={state.pricingDetails.currencyCodeSelect}
              filterCurrencies={(overrideCurrencies) =>
                overrideCurrencies.filter(
                  (currencyCode) =>
                    !state.pricingDetails.rows.some(
                      (pricing) =>
                        pricing.regionCode === regionCodeSelect &&
                        pricing.currencyCode === currencyCode
                    )
                )
              }
            />
          </TableCell>
          <TableCell colSpan={2}>
            <Button
              variant="contained"
              disabled={!regionCodeSelect || !currencyCodeSelect}
              onClick={() =>
                dispatch({
                  type: 'ADD_PRICING_DETAILS_ROW',
                  payload: {
                    regionCode: regionCodeSelect,
                    currencyCode: currencyCodeSelect,
                    purchasePrice: undefined,
                  },
                })
              }
            >
              Add
            </Button>
          </TableCell>
        </TableRow>
      </TableFooter>
    )
  }

  if (regionsLoading) return <Skeleton variant="rectangular" height={300} />

  return (
    <>
      <Page.TitleSection
        sectionTitle="Pricing details"
        sectionSubtitle="Purchase or subscription pricing per region is only required for Firstbase standard catalog SKUs. Please select a region to add pricing details to it."
      />
      <Card variant="outlined" sx={{ marginBottom: 4 }}>
        <TableContainer>
          <Table aria-label="pricing details table">
            {renderTableHead()}
            {renderTableBody()}
            {renderTableFooter()}
          </Table>
        </TableContainer>
      </Card>
    </>
  )
}

interface RegionSelectProps {
  selectedRegionCode: string
  setSelectedRegionCode: (code: string) => void
  regions: getAllRegions_getAllRegions[]
}

const RegionSelect = ({
  selectedRegionCode,
  setSelectedRegionCode,
  regions,
}: RegionSelectProps) => (
  <FormControl sx={{ width: '100%' }}>
    <InputLabel size="small" id="mgmt-standard-pricing-add-region-select-label">
      Select region
    </InputLabel>
    <Select
      sx={{ borderColor: grey300 }}
      label="Select region"
      placeholder="Select Region"
      id="mgmt-standard-pricing-add-region-select"
      labelId="mgmt-standard-pricing-add-region-select-label"
      size="small"
      onChange={(e) => setSelectedRegionCode(e.target.value)}
      value={selectedRegionCode}
    >
      {regions.map((region) => (
        <MenuItem key={region.regionCode} value={region.regionCode}>
          {region.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)
