import React, { useCallback, useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { FormControl, InputLabel, MenuItem, Select, Box } from '@mui/material'

import { CreatePerson } from '@globalTypes'
import UploadStep from '@firstbase/views/Home/Platform/BulkUpload/UploadStep'
import { CREATE_PERSON_FOR_ORGANIZATION } from '@firstbase/data/Organization/mutations'
import BulkUpload from '@firstbase/views/Home/Platform/BulkUpload/BulkUpload'
import { GET_ALL_ORGANIZATIONS } from '@firstbase/data/Organization/queries'
import {
  getAllOrganizations as getAllOrganizationsType,
  getAllOrganizationsVariables,
  getAllOrganizations_getAllOrganizations_data,
} from '@firstbase/data/Organization/__generated__/getAllOrganizations'
import ErrorMessagesAccordion from '@firstbase/components/molecules/ErrorMessagesAccordion'
import {
  CreatePersonForOrganization,
  CreatePersonForOrganizationVariables,
} from '@firstbase/data/Organization/__generated__/CreatePersonForOrganization'

import { parseForMutation } from './parsePeople'
import getUploadValidationErrors from './getUploadValidationErrors'

const BulkUploadPeople = () => {
  const [slug, setSlug] = useState('')
  const [data, setData] = useState<CreatePerson[]>([])
  const [uploadValidationErrors, setUploadValidationErrors] = useState<
    string[] | null
  >(null)
  const [validatingUpload, setValidatingUpload] = useState(false)

  const [createPersonForOrganization] = useMutation<
    CreatePersonForOrganization,
    CreatePersonForOrganizationVariables
  >(CREATE_PERSON_FOR_ORGANIZATION)

  const { data: { getAllOrganizations } = {}, loading } = useQuery<
    getAllOrganizationsType,
    getAllOrganizationsVariables
  >(GET_ALL_ORGANIZATIONS, {
    variables: {
      pageNumber: 1,
      pageSize: 1000,
    },
  })

  useEffect(() => {
    if (data && data.length > 0) {
      setValidatingUpload(true)
      setUploadValidationErrors(getUploadValidationErrors(data))
      setValidatingUpload(false)
    }
  }, [data])

  const renderValidationErrors = useCallback(() => {
    if (!uploadValidationErrors || uploadValidationErrors.length === 0)
      return null

    return (
      <Box sx={{ marginBottom: 2 }}>
        <ErrorMessagesAccordion errors={uploadValidationErrors} />
      </Box>
    )
  }, [uploadValidationErrors])

  const renderClients = useCallback(() => {
    if (!getAllOrganizations) return null

    let sortedClients = [...getAllOrganizations.data]

    sortedClients.sort(
      (
        a: getAllOrganizations_getAllOrganizations_data,
        b: getAllOrganizations_getAllOrganizations_data
      ) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
    )

    return sortedClients.map(
      ({
        slug: orgSlug,
        name,
        status,
      }: getAllOrganizations_getAllOrganizations_data) => (
        <MenuItem
          data-testid={`org-select-${orgSlug}`}
          key={orgSlug}
          value={orgSlug}
        >
          {name} - ({status})
        </MenuItem>
      )
    )
  }, [getAllOrganizations])

  const handleCreatePerson = (person: any, logStatus: React.Dispatch<any>) => {
    createPersonForOrganization({
      variables: { slug, createPerson: person },
    })
      .then((r) => {
        logStatus({
          type: 'success',
          message: `Successfully added ${r?.data?.createPersonForOrganization?.email}`,
        })
      })
      .catch((error: any) => {
        logStatus({
          type: 'error',
          message: error.message,
        })
      })
  }

  return (
    <BulkUpload
      addMessage={`Add ${data.length} ${data.length > 1 ? 'people' : 'person'}`}
      handleCreateRow={handleCreatePerson}
      data={data}
      continueDisabled={
        !data ||
        !slug ||
        validatingUpload ||
        uploadValidationErrors?.length !== 0
      }
      uploadTitle="Upload people"
    >
      <UploadStep
        dataParser={parseForMutation}
        templateHref="/static/peopleUploadTemplate.csv"
        setData={setData}
        uploadTitle="Bulk upload list of people"
        uploadSubtitle="The file must be a CSV. Please use the provided template for the upload. This upload will only be for person data. You may assign inventory to each person once they are uploaded onto the platform."
        loading={loading}
      >
        <>
          <FormControl sx={{ width: '200px', marginBottom: 2 }}>
            <InputLabel id="organization">Organization</InputLabel>
            <Select
              id="organization"
              labelId="organization-label"
              value={slug}
              label="Organization"
              onChange={(e) => setSlug((e.target as HTMLInputElement).value)}
            >
              {renderClients()}
            </Select>
          </FormControl>
          {renderValidationErrors()}
        </>
      </UploadStep>
    </BulkUpload>
  )
}

export default BulkUploadPeople
