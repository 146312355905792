import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { sentenceCase } from 'change-case'

import Link from '@firstbase/components/atoms/Link'
import StatusChip from '@firstbase/components/atoms/StatusChip'

import { RetrievalMethodMaterialType } from '@globalTypes'

import RetrievalContainerField from './RetrievalContainerField'
import { RetrievalSummary, RetrievalType } from '../types'

interface OwnProps {
  data: RetrievalSummary
  retrievalType: RetrievalType
}

const RetrievalContainer = ({ data, retrievalType }: OwnProps) => {
  const getRetrievalMethodName = () => {
    switch (data.methodName) {
      case 'INBOUND':
        return `Inbound - ${sentenceCase(data.type || '')}`
      case 'RETURN_KIT':
        return 'Return Kit'
      default:
        return '3PL'
    }
  }

  const renderOutbound = () => {
    if (
      (data.methodName !== 'RETURN_KIT' &&
        data.type !== RetrievalMethodMaterialType.PHYSICAL) ||
      !data.outboundDetails?.trackingLink
    )
      return null

    return (
      <RetrievalContainerField
        label="Outbound"
        value={
          <Link
            href={data.outboundDetails.trackingLink}
            target="_blank"
            rel="noopener"
          >
            {data.outboundDetails.trackingCode}
          </Link>
        }
      />
    )
  }

  const renderInbound = () => {
    if (data.methodName === 'PL' || !data.inboundDetails?.trackingLink)
      return null

    return (
      <RetrievalContainerField
        label="Inbound"
        value={
          <Link
            to=""
            href={data.inboundDetails.trackingLink}
            target="_blank"
            rel="noopener"
          >
            {data.inboundDetails.trackingCode}
          </Link>
        }
      />
    )
  }

  return (
    <Box
      sx={{
        backgroundColor: '#ededed',
        padding: 1,
        mb: 0.5,
        borderRadius: '0.5rem',
      }}
    >
      <Stack sx={{ gap: 0.5 }}>
        <Typography fontWeight="bold" variant="subtitle1">
          Retrieval for {sentenceCase(retrievalType)}
        </Typography>
        <RetrievalContainerField
          label="Method"
          value={getRetrievalMethodName()}
        />
        <RetrievalContainerField
          label="Retrieval Method ID"
          value={data.code}
        />
        <RetrievalContainerField
          label="Status"
          value={<StatusChip type="retrieval" status={data.status} />}
        />
        {renderOutbound()}
        {renderInbound()}
      </Stack>
    </Box>
  )
}

export default RetrievalContainer
