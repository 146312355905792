import { OrderStatus, SKUType, DeployReason, DeployStatus } from '@globalTypes'

import { Product } from './types'

const TERMINAL_ORDER_STATUSES = [
  OrderStatus.RETURNED,
  OrderStatus.NOT_RETURNED,
  OrderStatus.CANCELLED,
]

export const nonFurnitureCanBeEdited = (
  allReturnOrderProducts: Product[] | null
) => {
  if (!allReturnOrderProducts) return false

  const allReturnOrderNonFurniture = allReturnOrderProducts.filter(
    ({ sku }) => sku.category.skuType !== SKUType.FURNITURE
  )

  const isProductPendingStatus = allReturnOrderNonFurniture.some(
    ({ status }) => status && status === OrderStatus.PENDING
  )
  const isAllProductsNotReturnedOrPendingStatus =
    allReturnOrderNonFurniture.every(
      ({ status }) =>
        status &&
        [
          OrderStatus.NOT_RETURNED,
          OrderStatus.PENDING,
          OrderStatus.CANCELLED,
        ].includes(status)
    )

  return isProductPendingStatus && isAllProductsNotReturnedOrPendingStatus
}

export const canEditReturnOrder = (returnOrderProducts: Product[] | null) => {
  if (!returnOrderProducts) return false

  const furnitureCanBeEdited = returnOrderProducts?.some(
    ({ status, sku }) =>
      sku.category.skuType === SKUType.FURNITURE &&
      status &&
      !TERMINAL_ORDER_STATUSES.includes(status)
  )

  return furnitureCanBeEdited || nonFurnitureCanBeEdited(returnOrderProducts)
}

export const GENERIC_VENDOR_CODE = 'GENERIC_VENDOR'

export const sortGenericToEnd = (allOrderProducts: Product[]) =>
  [...allOrderProducts].sort(({ sku: skuA }, { sku: skuB }) => {
    if (skuA.vendor.code === GENERIC_VENDOR_CODE) return 1
    if (skuB.vendor.code === GENERIC_VENDOR_CODE) return -1
    return 0
  })

export const getActiveValueForInventoryUpdate = (
  deployStatus?: DeployStatus,
  deployReason?: DeployReason
) => {
  let active = true

  if (
    deployStatus === DeployStatus.ARCHIVED &&
    !!deployReason &&
    deployReason !== DeployReason.SENT_TO_OFFICE
  )
    active = false

  return active
}

export const hasPendingReturnOrderProductStatus = (
  orderProducts: Product[]
) => {
  return orderProducts.some((product) => product.status === OrderStatus.PENDING)
}
