import { gql } from '@apollo/client'
import { SKU_BASICS } from '@firstbase/data/SKU/fragments'

export const GET_ALL_SKUS = gql`
  ${SKU_BASICS}
  query getAllSKUs(
    $pageNumber: Int!
    $pageSize: Int!
    $filter: SKUFilter
    $sort: [Sort]
    $searchTerm: String
  ) {
    getAllSKUs(
      pagingAndSorting: {
        pageNumber: $pageNumber
        pageSize: $pageSize
        sort: $sort
      }
      filter: $filter
      searchTerm: $searchTerm
    ) {
      totalElements
      data {
        ...SkuBasics
      }
    }
  }
`

export const GET_NEW_PRODUCT_DATA = gql`
  query getNewProductData($pagingAndSorting: PagingAndSorting!) {
    getAllVendors(pagingAndSorting: $pagingAndSorting) {
      data {
        id
        name
        code
      }
    }
    getAllCategories(pagingAndSorting: $pagingAndSorting) {
      data {
        id
        code
        nameSingular
        skuType
        visible
      }
    }
  }
`

export const GET_ALL_GENERIC_VENDOR_SKUS = gql`
  query getAllGenericVendorSkus {
    getAllGenericSkus(pagingAndSorting: { pageNumber: 1, pageSize: 1000 }) {
      data {
        id
        skuInformation {
          productTitle
        }
      }
    }
  }
`

export const GET_SKU = gql`
  ${SKU_BASICS}
  query getSKU($id: ID!) {
    getSKU(id: $id) {
      ...SkuBasics
    }
  }
`

export const GET_ACTIVE_ASSIGNMENTS_FOR_SKU = gql`
  query getActiveAssignmentsForSku(
    $skuId: ID!
    $pagingAndSorting: PagingAndSorting!
  ) {
    getActiveAssignmentsForSku(
      skuId: $skuId
      pagingAndSorting: $pagingAndSorting
    ) {
      totalElements
      data {
        organization {
          name
        }
      }
    }
  }
`

export const GET_ALL_SKU_METADATA_OPTIONS_VALUES = gql`
  query getAllSKUMetadataOptionValues(
    $categoryCode: String!
    $vendorCode: String!
  ) {
    getAllSKUMetadataOptionValues(
      categoryCode: $categoryCode
      vendorCode: $vendorCode
    ) {
      id
      name
      hasSpecificVendorValues
      required
      values {
        id
        value
      }
    }
  }
`
