import ProtectedChildren from '@firstbase/components/atoms/ProtectedChildren'
import { Role } from '@firstbase/constants/roles'
import { Button } from '@mui/material'
import React, { useState } from 'react'
import EditReturnOrderModal, {
  ReturnOrder,
  ReturnOrderPerson,
} from './EditReturnOrderModal'

type OwnProps = {
  isReplacement?: boolean
  returnOrder: ReturnOrder
  person: ReturnOrderPerson
}

const EditReturnOrder = ({
  isReplacement = false,
  returnOrder,
  person,
}: OwnProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleIsModalOpen = () =>
    setIsModalOpen((currentIsOpen) => !currentIsOpen)

  const renderActionButton = () => (
    <Button color="secondary" variant="contained" onClick={toggleIsModalOpen}>
      Edit Return
    </Button>
  )

  return (
    <ProtectedChildren hasRole={[Role.CX, Role.Admin]}>
      {renderActionButton()}
      {isModalOpen && (
        <EditReturnOrderModal
          handleClose={toggleIsModalOpen}
          returnOrder={returnOrder}
          person={person}
          isReplacement={isReplacement}
        />
      )}
    </ProtectedChildren>
  )
}

export default EditReturnOrder
