import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Switch, Typography, Box } from '@mui/material'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useFlags } from 'launchdarkly-react-client-sdk'

import FLAGS from '@firstbase/constants/featureFlags'
import { Role, useAuthState } from '@firstbase/context/auth/AuthProvider'
import {
  GET_ORGANIZATION_CATEGORIES,
  GET_ORGANIZATION_CATEGORIES_WITH_REFRESH_MONTHS,
} from '@firstbase/data/Organization/queries'
import { UPDATE_CATEGORY_RETURN_REQUIRED } from '@firstbase/data/Organization/mutations'
import {
  updateCategoryReturnRequired,
  updateCategoryReturnRequiredVariables,
} from '@firstbase/data/Organization/__generated__/updateCategoryReturnRequired'

interface OwnProps {
  categoryCode: string
  initialValue: boolean
  orgSlug: string
}

const ClientCategoriesGiftedSwitch = ({
  categoryCode,
  initialValue,
  orgSlug,
}: OwnProps) => {
  const [enabled, setEnabled] = useState(initialValue)
  const isInitializedRef = useRef(false)
  const { hasRole } = useAuthState()
  const { enqueueSnackbar } = useSnackbar()

  const flags = useFlags()
  const refreshRateFlagOn = flags[FLAGS.SE_4230_REFRESH_RATES]

  const getVariablesForMutation = useCallback(
    (giftingEnabled: boolean): updateCategoryReturnRequiredVariables => {
      const noReturnRequiredCategoryCodes: string[] = []
      const returnRequiredCategoryCodes: string[] = []

      if (giftingEnabled) {
        noReturnRequiredCategoryCodes.push(categoryCode)
      } else {
        returnRequiredCategoryCodes.push(categoryCode)
      }

      return {
        noReturnRequiredCategoryCodes,
        returnRequiredCategoryCodes,
        slug: orgSlug,
      }
    },
    [orgSlug, categoryCode]
  )

  const onError = () => {
    enqueueSnackbar(
      `Failed to update no return required for ${categoryCode}.  Please refresh the window and try again or share this error with the team.`
    )
  }
  const [updateCategoryReturnRequiredMutation] = useMutation<
    updateCategoryReturnRequired,
    updateCategoryReturnRequiredVariables
  >(UPDATE_CATEGORY_RETURN_REQUIRED, {
    onError,
    refetchQueries: [
      refreshRateFlagOn
        ? GET_ORGANIZATION_CATEGORIES_WITH_REFRESH_MONTHS
        : GET_ORGANIZATION_CATEGORIES,
    ],
  })

  useEffect(() => {
    if (isInitializedRef.current) {
      updateCategoryReturnRequiredMutation({
        variables: getVariablesForMutation(enabled),
      })
    } else {
      isInitializedRef.current = true
    }
  }, [enabled, getVariablesForMutation, updateCategoryReturnRequiredMutation])

  const onChange = () => {
    setEnabled((previousValue) => !previousValue)
  }

  const renderSwitchValueText = () => (enabled ? 'YES' : 'NO')

  const isSwitchDisabled = () => !hasRole(Role.Admin)

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Switch
        checked={enabled}
        onChange={onChange}
        disabled={isSwitchDisabled()}
      />
      <Typography>{renderSwitchValueText()}</Typography>
    </Box>
  )
}

export default ClientCategoriesGiftedSwitch
