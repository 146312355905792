import React, { useEffect, useRef, useState } from 'react'
import {
  TableCell as MUITableCell,
  TableCellProps,
  Tooltip,
} from '@mui/material'

const TableCell = ({ children, ...props }: TableCellProps) => {
  const cellRef = useRef<HTMLElement | null>(null)
  const [isOverflowing, setIsOverflowing] = useState(false)

  useEffect(() => {
    if (cellRef.current) {
      if (cellRef.current.offsetWidth < cellRef.current.scrollWidth) {
        setIsOverflowing(true)
      }
    }
  }, [])

  const renderChildren = () => {
    if (!children) return null

    if (isOverflowing)
      return (
        <Tooltip title={children}>
          <span>{children}</span>
        </Tooltip>
      )

    return children
  }

  return (
    <MUITableCell {...props} ref={cellRef}>
      {renderChildren()}
    </MUITableCell>
  )
}

export default TableCell
