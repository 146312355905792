import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Security } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'

interface OwnProps {
  children: React.ReactNode
  oktaAuth: OktaAuth
}

const Auth = ({ children, oktaAuth }: OwnProps) => {
  const history = useHistory()

  const customAuthHandler = () => history.push('/')

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string | null
  ) =>
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin))

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      {children}
    </Security>
  )
}

Auth.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Auth
