import React, { useState, useReducer } from 'react'
import { Box, TextField, Typography, Grid } from '@mui/material'

import FullscreenModal from '@firstbase/components/molecules/FullscreenModal'
import Page from '@firstbase/components/atoms/Page'
import ImageUploader from '@firstbase/views/Home/Platform/Products/ImageUploader'

import { reducer } from './utils'
import { ReducerState, ReducerActions } from './types'
import { CategoryBrandSelectGroup } from './CategoryBrandSelectGroup'
import { WarrantySkuMetadataFields } from './WarrantySkuMetadataFields'
import { PricingDetailsTable } from './PricingDetailsTable'
import { ExistingSkusPane } from './ExistingSkusPane'

interface OwnProps {
  closeModal: () => void
  disableCategoryBrandSelect?: boolean
  handleSubmit: (state: ReducerState) => void
  initialState: ReducerState
  submitting: boolean
  title: string
  skuId?: string // used for the editing use case
}

export const WarrantySkuForm = ({
  closeModal,
  disableCategoryBrandSelect = false,
  initialState,
  handleSubmit,
  submitting,
  title,
  skuId,
}: OwnProps) => {
  /**
   * Use reducer for the initial implementation to manage state for
   * dynamic metadata fields and pricing details a bit more explicitly.
   * Feel free to refactor this to use react-hook-form as we go along,
   * especially if we run into performance issues.
   */
  const [state, dispatch] = useReducer<
    React.Reducer<ReducerState, ReducerActions>
  >(reducer, initialState)

  const [requiredMetadataFields, setRequiredMetadataFields] = useState<
    string[]
  >([])
  const [matchingSkusFound, setMatchingSkusFound] = useState(true)

  // TODO - Extract validation logic into a custom function(s) that could be tested
  const categoryAndBrandSelected = !!(state.category && state.vendor.code)
  const imageIsValid = !!(state.imageData.url && state.imageData.isValid)
  const requiredMetadataFieldsAreSelected =
    requiredMetadataFields.length > 0 &&
    requiredMetadataFields.every((key) =>
      Object.keys(state.metadataFields).includes(key)
    )
  const hasPricing =
    state.pricingDetails.rows.length > 0 &&
    state.pricingDetails.rows.every((row) => !!row.purchasePrice)

  const isFormValid =
    categoryAndBrandSelected &&
    imageIsValid &&
    requiredMetadataFieldsAreSelected &&
    !matchingSkusFound &&
    hasPricing

  return (
    <FullscreenModal
      title={title}
      open={true}
      handleClose={closeModal}
      continueProps={{
        loading: submitting,
        onClick: () => handleSubmit(state),
        disabled: !isFormValid,
      }}
    >
      <Page.TitleSection sectionTitle={title} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <CategoryBrandSelectGroup
              state={state}
              dispatch={dispatch}
              disabled={disableCategoryBrandSelect}
            />
            {categoryAndBrandSelected && (
              <>
                <Typography variant="body1">Required Specs</Typography>
                <WarrantySkuMetadataFields
                  state={state}
                  dispatch={dispatch}
                  setRequiredMetadataFields={setRequiredMetadataFields}
                />
                <ImageUploader
                  setImage={(url: string) =>
                    dispatch({ type: 'SET_IMAGE_DATA', payload: { url } })
                  }
                  showImageError={false}
                  image={state.imageData.url}
                  setIsValidImage={(valid: boolean) =>
                    dispatch({
                      type: 'SET_IMAGE_DATA',
                      payload: { isValid: valid },
                    })
                  }
                  isValidImage={state.imageData.isValid}
                />
                <Typography variant="body1">Optional Specs</Typography>
                <TextField
                  fullWidth
                  id="mpn"
                  label="Manufacturer Part Number (MPN)"
                  inputProps={{
                    value: state.mpn,
                    onChange: (e) =>
                      dispatch({
                        type: 'SET_MPN',
                        payload: (e.target as HTMLInputElement).value,
                      }),
                  }}
                />
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
          {categoryAndBrandSelected && (
            <ExistingSkusPane
              setMatchingSkusFound={setMatchingSkusFound}
              state={state}
              skuId={skuId}
            />
          )}
        </Grid>
      </Grid>
      {categoryAndBrandSelected && (
        <Box sx={{ margin: '2rem auto' }}>
          <PricingDetailsTable state={state} dispatch={dispatch} />
        </Box>
      )}
    </FullscreenModal>
  )
}
