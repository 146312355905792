import React from 'react'
import { Grid, FormControlLabel, Typography, Checkbox } from '@mui/material'

import AddressForm from '@firstbase/components/molecules/AddressForm'
import { AddressI } from '@firstbase/types/Address'

interface OwnProps {
  clientId: string
  addressFormValues: AddressI
  personForename: string
  setAddressFormValues: React.Dispatch<React.SetStateAction<AddressI>>
  setUpdatedUserAddressChecked: React.Dispatch<React.SetStateAction<boolean>>
  updateUserAddressChecked: boolean
}

function EditReturnOrderAddressForm({
  clientId,
  addressFormValues,
  personForename,
  updateUserAddressChecked,
  setAddressFormValues,
  setUpdatedUserAddressChecked,
}: OwnProps) {
  return (
    <Grid
      container
      xs={12}
      md={6}
      spacing={2}
      marginBottom={4}
      bgcolor="white"
      p="0 1rem 1rem 1rem"
      borderRadius={1}
      boxShadow="inset 1px 0px 0 #CACACB"
      marginTop={1}
    >
      <AddressForm
        header="Return Address"
        addressFormValues={addressFormValues}
        orgId={clientId}
        setAddressFormValues={setAddressFormValues}
      />
      <Grid container item>
        <FormControlLabel
          control={
            <Checkbox
              checked={updateUserAddressChecked}
              onChange={() =>
                setUpdatedUserAddressChecked((previousState) => !previousState)
              }
            />
          }
          label={
            <Typography>Set as {personForename}'s primary address</Typography>
          }
        />
      </Grid>
    </Grid>
  )
}

export default EditReturnOrderAddressForm
