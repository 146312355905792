import React, { useState } from 'react'

import Page from '@atoms/Page'
import Table, { SortDirections } from '@firstbase/components/atoms/Table'

import { GET_ALL_VENDORS } from '@firstbase/data/Vendor/queries'

import AddIcon from '@mui/icons-material/Add'
import useTableGraphqlQuery from '@firstbase/hooks/useTableGraphqlQuery'
import { getAllVendors as getAllVendorsType } from '@firstbase/data/Vendor/__generated__/getAllVendors'
import { NewVendorModal } from '@firstbase/components/molecules/NewVendorModal'
import { Button } from '@mui/material'
import { Role } from '@firstbase/context/auth/AuthProvider'
import ProtectedChildren from '@firstbase/components/atoms/ProtectedChildren'
import { cellComponents } from '@firstbase/components/atoms/Table/cellComponents/cellComponents'

const columns = [
  {
    header: ' ID',
    id: '',
    cell: {
      as: cellComponents.ID,
      value: ({ id }: any) => id,
    },
  },
  {
    header: 'Name',
    defaultSort: SortDirections.asc,
    id: 'name',
    cell: {
      value: ({ name }: any) => name,
    },
  },
  {
    header: 'Code',
    id: 'code',
    cell: {
      value: ({ code }: any) => code,
    },
  },
]

const Vendors = () => {
  const [newVendor, setNewVendor] = useState<boolean>(false)

  const query = useTableGraphqlQuery<getAllVendorsType, any>(
    GET_ALL_VENDORS,
    {},
    ({ getAllVendors }) => getAllVendors
  )

  const renderTitleAction = () => (
    <ProtectedChildren hasRole={Role.Admin}>
      <Button variant="contained" onClick={() => setNewVendor(true)}>
        <AddIcon /> Add Vendor
      </Button>
    </ProtectedChildren>
  )

  return (
    <>
      <Page.TitleSection
        sectionTitle="Vendors"
        sectionSubtitle="List of vendors that are available on the platform"
        subtitleAction={renderTitleAction}
      />
      <Table
        query={query}
        tableId="vendors"
        columns={columns}
        pagination
        customRowsPerPageOptions={[100, 200, 500]}
      />
      <NewVendorModal
        open={newVendor}
        handleClose={() => setNewVendor(false)}
        refetchQueries={['getAllVendors']}
      />
    </>
  )
}

export default Vendors
