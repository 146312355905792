import { Box, Typography } from '@mui/material'
import React from 'react'

type Props = {
  sectionTitle: string | JSX.Element
  sectionSubtitle?: string | JSX.Element
  subtitleAction?: () => JSX.Element | null
}

function TitleSection({
  sectionTitle,
  sectionSubtitle,
  subtitleAction,
}: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 0.75,
        marginBottom: 2,
      }}
    >
      <Box>
        <Typography variant="h5" role="header">
          {sectionTitle}
        </Typography>
        <Typography variant="body1">{sectionSubtitle}</Typography>
      </Box>
      {subtitleAction && subtitleAction()}
    </Box>
  )
}

export default TitleSection
