import React from 'react'
import { Container, useMediaQuery } from '@mui/material'

import { theme } from '@firstbase/muiTheme'

import FirstbaseAppBar from '@atoms/FirstbaseAppBar'

interface OwnProps {
  children: React.ReactNode
}

function PageWrapper({ children }: OwnProps) {
  return (
    <>
      <FirstbaseAppBar>
        <Container
          disableGutters={useMediaQuery(theme.breakpoints.up('lg'))}
          maxWidth={false}
          sx={{
            paddingBottom: '24px',
            maxWidth: 1440,
            minHeight: 'calc(100vh - 64px)',
          }}
        >
          {children}
        </Container>
      </FirstbaseAppBar>
    </>
  )
}

export default PageWrapper
