import {
  ApolloClient,
  ApolloLink,
  ServerError,
  HttpLink,
  Observable,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { OktaAuth } from '@okta/okta-auth-js'

import environmentVariables from '@firstbase/utils/environmentVariables'

import cache from './cache'
import { HttpOptions } from '@apollo/client/link/http/selectHttpOptionsAndBody'

// Create a temporary mock link to handle mutation response
const mockLink = new ApolloLink((operation, forward) => {
  if (operation.operationName === 'addSKUMetadataOptionValue') {
    return new Observable((observer) => {
      setTimeout(() => {
        observer.next({
          data: {
            addSKUMetadataOptionValue: {
              id: 'some-fake-id',
            },
          },
        })
        observer.complete()
      }, 300)
    })
  }
  return forward(operation)
})

const httpLink = (opts: HttpOptions | undefined) => new HttpLink(opts)

const getTerminatingLink = (uri: string) =>
  httpLink({
    uri: `${uri}/graphql`,
  })

const errorLink = (oktaAuth: OktaAuth) =>
  onError(({ graphQLErrors, networkError }) => {
    if (networkError && (networkError as ServerError).statusCode === 401) {
      // eslint-disable-next-line no-console
      console.log('networkError', networkError)
      oktaAuth.tokenManager.clear()
      oktaAuth?.signOut({ postLogoutRedirectUri: '/?session-expired' })
    }

    if (graphQLErrors) {
      // eslint-disable-next-line
      console.log('graphQLErrors', graphQLErrors)
    }
  })

const authLink = (oktaAuth: OktaAuth) =>
  setContext(async (_, { headers }) => {
    const additionalHeaders = {
      'X-Job-Title': `app-client:${window.location.pathname}`,
    }

    if (await oktaAuth.isAuthenticated()) {
      const token = oktaAuth.getAccessToken()
      return {
        headers: {
          ...headers,
          ...additionalHeaders,
          authorization: token ? `Bearer ${token}` : undefined,
        },
      }
    }

    return {
      headers: {
        ...headers,
        ...additionalHeaders,
      },
    }
  })

const client = (oktaAuth: OktaAuth) =>
  new ApolloClient({
    link: ApolloLink.from([
      mockLink,
      errorLink(oktaAuth),
      authLink(oktaAuth),
      getTerminatingLink(environmentVariables.get().VITE_API_URI),
    ]),
    connectToDevTools: environmentVariables.get().MODE === 'development',
    cache,
  })

export default client
