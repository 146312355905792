import React from 'react'
import { cellComponents } from '@firstbase/components/atoms/Table/cellComponents/cellComponents'
import { sentenceCase } from 'change-case'
import { SortDirections } from '@firstbase/components/atoms/Table'
import { interpolateQueryParamsInPath } from '@firstbase/routes/routeUtils'
import { ColumnI } from '@firstbase/components/atoms/Table/types'
import { getInventoryDeployStatusColor } from '@firstbase/components/atoms/StatusChip/getColors'
import { getAllInventories_getAllInventories_data as AllInventoryI } from '@firstbase/data/Inventory/__generated__/getAllInventories'
import { getOrganizationInventoriesById_getOrganizationById_inventories_data as OrgInventoriesI } from '@firstbase/data/Organization/__generated__/getOrganizationInventoriesById'
import { getPersonBySlugAndOrganizationSlug_getPersonBySlugAndOrganizationSlug_inventories as PersonInventoriesI } from '@firstbase/data/Person/__generated__/getPersonBySlugAndOrganizationSlug'

import { parseISO, format } from 'date-fns'
import { Link } from 'react-router-dom'

type InventoryI = AllInventoryI | OrgInventoriesI | PersonInventoriesI
type OrgColumn = (orgId: string) => ColumnI

const inventoryIdColumn: ColumnI = {
  header: 'Inventory ID',
  id: 'id',
  cell: { as: cellComponents.ID, value: ({ id }: InventoryI) => id },
}

const productTitleColumn: ColumnI = {
  header: 'Product Title',
  id: 'sku.skuInformation.productTitle',
  defaultSort: SortDirections.asc,
  cell: {
    value: ({ sku, description }: OrgInventoriesI | PersonInventoriesI) =>
      description || sku.skuInformation.productTitle,
  },
}

const conditionColumn: ColumnI = {
  header: 'Condition',
  id: 'conditionStatus',
  cell: {
    value: ({ conditionStatus }: InventoryI) =>
      sentenceCase(conditionStatus || '-'),
  },
}

const statusColumn: ColumnI = {
  header: 'Status',
  id: 'deployStatus',
  cell: {
    as: cellComponents.Chip,
    asProps: ({ deployStatus }: InventoryI) => ({
      color: getInventoryDeployStatusColor(deployStatus),
    }),
    value: ({ deployStatus }: InventoryI) => sentenceCase(deployStatus),
  },
}

const serialColumn: ColumnI = {
  header: 'Serial #',
  defaultSort: SortDirections.asc,
  id: 'serialNumber',
  cell: { value: ({ serialNumber }: InventoryI) => serialNumber || '-' },
}

const renewalColumnNewType: ColumnI = {
  header: 'Renewal date',
  id: 'renewalDate',
  cell: {
    value: ({ renewalDate }: InventoryI) =>
      renewalDate ? new Date(renewalDate).toLocaleDateString() : '-',
  },
}

const reasonColumn: ColumnI = {
  header: 'Reason',
  id: 'deployReason',
  cell: {
    value: ({ deployReason }: InventoryI) => sentenceCase(deployReason || ''),
  },
}

const activeColumn: ColumnI = {
  header: 'Active',
  id: 'active',
  cell: {
    as: cellComponents.Chip,
    asProps: ({ active }: InventoryI) => ({
      color: active ? 'success' : 'warning',
    }),
    value: ({ active }: InventoryI) => (active ? 'Active' : 'Inactive'),
  },
}

const suppliedByFirstbaseColumn: ColumnI = {
  header: 'Supplied by',
  id: 'firstbaseSupplied',
  cell: {
    as: cellComponents.Chip,
    asProps: ({ firstbaseSupplied }: InventoryI) => ({
      color: firstbaseSupplied ? 'primary' : 'secondary',
    }),
    value: ({ firstbaseSupplied, pricing }: InventoryI) => {
      const priceOption = pricing ? `${pricing.priceOption}` : ''
      return `${firstbaseSupplied ? 'Firstbase' : 'Customer'} ${priceOption}`
    },
  },
}

const unlockDateColumn: ColumnI = {
  header: 'Unlock Date',
  id: 'unlockDate',
  cell: {
    value: ({ unlockDate }: InventoryI) =>
      unlockDate ? format(parseISO(unlockDate), 'dd MMM yyyy') : '-',
  },
}

const giftedColumn: ColumnI = {
  header: 'Gifted',
  id: 'gift',
  cell: {
    as: cellComponents.Boolean,
    value: ({ gift }: AllInventoryI) => gift,
  },
}

const organizationColumn: ColumnI = {
  header: 'Organization',
  defaultSort: SortDirections.asc,
  id: 'organization',
  cell: {
    value: ({ organization }: AllInventoryI) => (
      <Link
        to={interpolateQueryParamsInPath('client', {
          clientId: organization?.id || '',
        })}
      >
        {organization?.name}
      </Link>
    ),
  },
}

const wareHouseColumn: ColumnI = {
  header: 'Warehouse',
  defaultSort: SortDirections.asc,
  id: 'warehouse.name',
  cell: { value: ({ warehouse }: AllInventoryI) => warehouse?.name },
}

const officeColumn: ColumnI = {
  header: 'Office',
  defaultSort: SortDirections.asc,
  id: 'office.id',
  cell: { value: ({ office }: AllInventoryI) => office?.name },
}

const vendorSkuColumn: ColumnI = {
  header: 'Vendor SKU',
  id: 'vendorSku',
  cell: {
    as: cellComponents.ID,
    value: ({ sku }: AllInventoryI) => sku.vendorSku,
  },
}

const personSlugColumn: ColumnI = {
  header: 'Person Slug',
  id: 'personSlug',
  cell: { value: ({ person }: AllInventoryI) => person?.slug },
}

const personNameColumn: ColumnI = {
  header: 'Person  Name',
  id: 'personName',
  cell: {
    value: ({ person, organization }: AllInventoryI) => {
      if (!person) return null

      return (
        <Link
          to={interpolateQueryParamsInPath('person', {
            clientId: organization?.id || '',
            personSlug: person?.slug || '',
          })}
        >
          {person?.forename || ''} {person?.surname || ''}
        </Link>
      )
    },
  },
}

const personEmailColumn: ColumnI = {
  header: 'Person Email',
  id: 'personEmail',
  cell: { value: ({ person }: AllInventoryI) => person?.email },
}

const productIdColumn: OrgColumn = (orgId) => ({
  header: 'Product ID',
  id: 'sku.id',
  cell: {
    asProps: ({ sku }: OrgInventoriesI | PersonInventoriesI) => ({
      to: interpolateQueryParamsInPath('product', {
        clientId: orgId,
        productId: sku.id,
      }),
    }),
    as: cellComponents.Link,
    value: ({ sku }: OrgInventoriesI) => sku.id,
  },
})

const assignedToColumn: OrgColumn = (orgId) => ({
  header: 'Assigned to',
  id: 'assignedTo',
  cell: {
    as: cellComponents.Link,
    asProps: ({ person }: OrgInventoriesI) => {
      if (person)
        return {
          to: interpolateQueryParamsInPath('person', {
            clientId: orgId,
            personSlug: person?.slug!,
          }),
        }
    },
    value: ({ warehouse, person }: OrgInventoriesI) => {
      if (person) return `${person.forename} ${person.surname}`
      if (warehouse) return warehouse.name
      return '-'
    },
  },
})

const stateColumnsNewRenewalType: ColumnI[] = [
  activeColumn,
  conditionColumn,
  statusColumn,
  reasonColumn,
  suppliedByFirstbaseColumn,
  serialColumn,
  renewalColumnNewType,
]

const personColumns: ColumnI[] = [
  personSlugColumn,
  personNameColumn,
  personEmailColumn,
]

export const ALL_INVENTORY_COLUMNS = (): ColumnI[] => [
  inventoryIdColumn,
  organizationColumn,
  wareHouseColumn,
  officeColumn,
  ...personColumns,
  vendorSkuColumn,
  ...stateColumnsNewRenewalType,
  unlockDateColumn,
  giftedColumn,
]

export const PERSON_INVENTORY_COLUMNS = (orgId: string): ColumnI[] => [
  inventoryIdColumn,
  productTitleColumn,
  ...stateColumnsNewRenewalType,
  productIdColumn(orgId),
]

export const ORGANIZATION_INVENTORY_COLUMNS = (orgId: string): ColumnI[] => [
  assignedToColumn(orgId),
  inventoryIdColumn,
  productTitleColumn,
  ...stateColumnsNewRenewalType,
  productIdColumn(orgId),
]
