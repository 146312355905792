import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

interface OwnProps {
  user: string
  children: React.ReactNode
}

const Message = ({ user, children }: OwnProps) => {
  return (
    <Box display="flex" alignItems="flex-start" gap="0.5rem" padding="0.5rem">
      <Typography variant="body1" fontWeight="bold">
        {user}:
      </Typography>
      {children}
    </Box>
  )
}

export default Message
