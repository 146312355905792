import React, { useState } from 'react'

import FullscreenModal from '@firstbase/components/molecules/FullscreenModal'
import Page from '@firstbase/components/atoms/Page'
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import Loader from '@firstbase/components/atoms/Loader'
import { getPersonBySlugAndOrganizationSlug_getPersonBySlugAndOrganizationSlug as Person } from '@firstbase/data/Person/__generated__/getPersonBySlugAndOrganizationSlug'
import { PersonStatus } from '__generated__/globalTypes'
import { useMutation } from '@apollo/client'
import { UPDATE_PERSON_BY_SLUG } from '@firstbase/data/Person/mutations'
import { updatePersonBySlugVariables } from '@firstbase/data/Person/__generated__/updatePersonBySlug'
import useIsMounted from '@firstbase/hooks/useIsMounted/useIsMounted'
import Select from '@firstbase/components/atoms/FBSelect'
import { useParams } from 'react-router-dom'
import { validateAddress } from '@firstbase/utils/address'
import { ClientPageParams } from '@firstbase/types/Pages'
import AddressForm from '@firstbase/components/molecules/AddressForm'
import { AddressI } from '@firstbase/types/Address'

type OwnProps = {
  open: boolean
  handleClose: (flushData?: boolean) => void
  person: Person
}

function EditPerson({ open, handleClose, person }: OwnProps) {
  const params: ClientPageParams = useParams()

  const isMounted = useIsMounted()
  const [forename, setForename] = useState<string>(person.forename || '')
  const [surname, setSurname] = useState<string>(person.surname || '')
  const [email, setEmail] = useState<string>(person.email || '')
  const [secondaryEmail, setSecondaryEmail] = useState<string>(
    person.secondaryEmail || ''
  )
  const [startDate, setStartDate] = useState(person.startDate || '')
  const [status, setStatus] = useState<PersonStatus | null>(person.status)

  const [addressFormValues, setAddressFormValues] = useState<AddressI>({
    addressLine1: person.address?.addressLine1,
    addressLine2: person.address?.addressLine2,
    locality: person.address?.locality,
    administrativeArea: person.address?.administrativeArea,
    postalCode: person.address?.postalCode,
    countryCode: person.address?.countryCode,
    phoneNumber: person.address?.phoneNumber,
  })
  const {
    addressLine1,
    addressLine2,
    locality,
    administrativeArea,
    postalCode,
    countryCode,
    phoneNumber,
  } = addressFormValues

  const [workatoUserId, setWorkatoUserId] = useState<number | null>(
    person.integrations?.workatoUserId || null
  )
  const [submitError, setSubmitError] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const [updatePersonMutation] = useMutation<updatePersonBySlugVariables>(
    UPDATE_PERSON_BY_SLUG
  )

  const onSubmit = async () => {
    try {
      setIsSubmitting(true)
      const validatedAddress: AddressI = await validateAddress(
        addressFormValues
      )
      const hasWorkatoId = person.integrations?.workatoUserId
      await updatePersonMutation({
        variables: {
          slug: person?.slug,
          updatePerson: {
            forename,
            surname,
            email,
            secondaryEmail,
            startDate: startDate || undefined,
            status: person.status !== status ? status : undefined,
            address: {
              addressLine1: validatedAddress?.addressLine1 || addressLine1,
              addressLine2: validatedAddress?.addressLine2 || addressLine2,
              administrativeArea:
                validatedAddress?.administrativeArea || administrativeArea,
              countryCode,
              locality: validatedAddress?.locality || locality,
              postalCode: validatedAddress?.postalCode || postalCode,
              phoneNumber,
            },
            ...((workatoUserId || hasWorkatoId) && {
              integrations: {
                workatoUserId: workatoUserId || 0,
              },
            }),
          },
        },
        refetchQueries: ['getPersonBySlugAndOrganizationSlug'],
      })

      handleClose()
    } catch (err: any) {
      setSubmitError(err.message)
    }

    if (isMounted()) setIsSubmitting(false)
  }

  const continueProps = {
    label: 'Save',
    disabled: isSubmitting,
    onClick: () => onSubmit(),
  }

  return (
    <FullscreenModal
      open={open}
      handleClose={handleClose}
      handleBack={handleClose}
      closeDisabled={false}
      title="Edit person"
      helperText={submitError}
      continueProps={continueProps}
    >
      {false && <Loader />}
      <Grid container xs={12} md={6}>
        <Grid item>
          <Page.TitleSection
            sectionTitle={`Edit ${person.forename} ${person.surname}'s details`}
          />
        </Grid>
        <Grid container item spacing={2} rowSpacing={2}>
          <Grid container item xs={6}>
            <TextField
              fullWidth
              label="First name"
              size="small"
              value={forename}
              onChange={(event) => setForename(event.target.value)}
            />
          </Grid>
          <Grid container item xs={6}>
            <TextField
              fullWidth
              label="Last name"
              size="small"
              value={surname}
              onChange={(event) => setSurname(event.target.value)}
            />
          </Grid>

          <Grid container item>
            <TextField
              label="Primary email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              size="small"
            />
          </Grid>

          <Grid container item>
            <TextField
              label="Secondary email address"
              value={secondaryEmail}
              onChange={(e) => setSecondaryEmail(e.target.value)}
              fullWidth
              size="small"
            />
          </Grid>

          <Grid container item>
            <FormControl fullWidth>
              <InputLabel size="small" id="status-label">
                Status
              </InputLabel>
              <Select
                label="Status"
                labelId="status-label"
                inputProps={{
                  'data-testid': 'Status',
                }}
                id="status-select"
                size="small"
                onChange={(event) =>
                  setStatus(event.target.value as PersonStatus)
                }
                value={status}
                disabled
              >
                <MenuItem value="ACTIVE">Active</MenuItem>
                <MenuItem value="DELETED">Deleted</MenuItem>
                <MenuItem value="OFFBOARDING">Offboarding</MenuItem>
                <MenuItem value="INACTIVE" disabled={true}>
                  Inactive
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid container item>
            <TextField
              fullWidth
              label="Start date"
              size="small"
              onChange={(event) => setStartDate(event.target.value)}
              type="date"
              value={startDate}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <AddressForm
            header="Address"
            addressFormValues={addressFormValues}
            orgId={params.clientId}
            setAddressFormValues={setAddressFormValues}
          />

          <Grid item>
            <Typography variant="h6">Integrations</Typography>
          </Grid>

          <Grid container item>
            <TextField
              label="Workato User ID"
              value={workatoUserId}
              onChange={(e) => setWorkatoUserId(parseInt(e.target.value))}
              fullWidth
              type="number"
              size="small"
            />
          </Grid>
        </Grid>
      </Grid>
    </FullscreenModal>
  )
}

export default EditPerson
