import routes from './routes'

type Params = Record<string, string>

export const getPathById = (pathId: string) =>
  routes.find(({ id }) => id === pathId)?.path! || ''

export const interpolateQueryParamsInPath: (
  pathId: string,
  params?: Params,
  search?: Params
) => string = (pathId, params = {}, search = undefined) => {
  // will not interpolate if some params are falsey
  if (Object.values(params).some((param) => !param)) return ''

  const resourcePath = Object.entries(params).reduce(
    (acc, [paramKey, paramVal]) =>
      acc.replace(`:${paramKey}`, paramVal.toString()),
    getPathById(pathId)
  )

  if (!search) return resourcePath

  const searchParams = new URLSearchParams(search)

  return `${resourcePath}?${searchParams.toString()}`
}
