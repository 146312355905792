import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  InputAdornment,
} from '@mui/material'
import { NOT_SUPPORTED } from '@firstbase/constants/contract'
import { FeeType } from '@firstbase/types/Fee'
import { useSupportedCurrencies } from '@utils/contractUtils'

type Props = {
  override: {
    fees: Record<string, string>
  }
  setOverride: React.Dispatch<
    React.SetStateAction<{
      fees: Record<string, string>
    } | null>
  >
  updateServiceFees: (
    feePrice: string | null,
    currencyCode?: string,
    singleFeeType?: FeeType
  ) => void
  orgServiceFees: string[]
  currencyCode: string
}

const OverrideServiceFeesTableForm = ({
  override,
  setOverride,
  updateServiceFees,
  orgServiceFees,
  currencyCode,
}: Props) => {
  const handleOverrideFeeBlur =
    (feeType: FeeType) => (e: React.FocusEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = e

      if (!value) {
        setOverride((prevOverride) => {
          if (!prevOverride) return prevOverride

          return {
            ...prevOverride,
            fees: { ...prevOverride.fees, [feeType]: NOT_SUPPORTED },
          }
        })
        e.target.value = ''

        return
      }

      if (!Number.isNaN(Number(value))) {
        const newPrice = String(parseFloat(value).toFixed(2))
        updateServiceFees(newPrice, currencyCode, feeType)
        e.target.value = newPrice

        setOverride((prevOverride) => {
          if (!prevOverride) return prevOverride

          return {
            ...prevOverride,
            fees: { ...prevOverride.fees, [feeType]: newPrice },
          }
        })
      } else {
        e.target.value = override?.fees[feeType] || ''
      }
    }

  const getDefaultValue = (feeType: FeeType) => {
    const feeValue = override.fees[feeType]?.toString()

    return feeValue !== NOT_SUPPORTED ? feeValue : undefined
  }
  const supportedCurrencies = useSupportedCurrencies()
  return (
    <Table
      aria-label="override service fees table"
      data-testid="override service fees table"
    >
      <TableHead>
        <TableRow>
          <TableCell sx={{ padding: 1 }}>
            <Typography variant="inherit" sx={{ fontWeight: 'bold' }}>
              Fees
            </Typography>
          </TableCell>
          <TableCell sx={{ padding: 1 }} key={`override - service fees`}>
            <Typography variant="inherit" sx={{ fontWeight: 'bold' }}>
              Prices charged in {currencyCode}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orgServiceFees.map((feeType: FeeType) => (
          <TableRow key={feeType} data-testid={feeType}>
            <TableCell sx={{ padding: 1 }}>
              <Typography variant="inherit">{feeType}</Typography>
            </TableCell>

            <TableCell sx={{ p: 1 }} key={`override - ${feeType}`}>
              <TextField
                id={`${feeType} - override`}
                label="Price"
                size="small"
                fullWidth
                defaultValue={getDefaultValue(feeType)}
                placeholder={
                  !getDefaultValue(feeType) ? NOT_SUPPORTED : undefined
                }
                onBlur={handleOverrideFeeBlur(feeType)}
                inputProps={{
                  'data-testid': `${feeType} - override`,
                }}
                InputProps={{
                  startAdornment: (
                    <>
                      {override.fees[feeType] !== undefined &&
                        override.fees[feeType] !== NOT_SUPPORTED && (
                          <InputAdornment position="start">
                            {supportedCurrencies[currencyCode]}
                          </InputAdornment>
                        )}
                    </>
                  ),
                }}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default OverrideServiceFeesTableForm
