import { SkuMetadataFieldName } from '@globalTypes'

const specialCases: Partial<Record<SkuMetadataFieldName, string>> = {
  [SkuMetadataFieldName.SSD_CAPACITY]: 'SSD',
  [SkuMetadataFieldName.RAM_CAPACITY]: 'RAM',
  [SkuMetadataFieldName.CPU_CORES]: 'CPU Cores',
  [SkuMetadataFieldName.GPU_CORES]: 'GPU Cores',
  [SkuMetadataFieldName.POWER_PLUG_TYPE]: 'Plug Type',
}

export const formatSkuMetadataFieldLabel = (
  name: SkuMetadataFieldName
): string => {
  if (specialCases[name]) {
    return specialCases[name]!
  }

  return name
    .split('_')
    .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
    .join(' ')
}
