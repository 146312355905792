import React, { FC } from 'react'
import { Alert } from '@mui/material'

import ConfirmModal from '@firstbase/components/molecules/ConfirmModal/ConfirmModal'
import { concatenateLongList } from '@firstbase/utils/string'

interface OwnProps {
  open: boolean
  organizationNames: string[]
  totalOrganizations: number
  onContinue: () => void
  onCancel: () => void
}

const CustomerCatalogWarningModal: FC<OwnProps> = ({
  open,
  organizationNames,
  totalOrganizations,
  onContinue,
  onCancel,
}) => {
  return (
    <ConfirmModal
      titleText="Are you sure you want to update pricing?"
      descText="Are you sure you want to update pricing?"
      cancelText="Cancel"
      confirmText="Yes, update pricing"
      open={open}
      onConfirm={onContinue}
      onCancel={onCancel}
      onClose={onCancel}
      maxWidth={600}
    >
      <>
        <Alert severity="warning">
          The following customers have this SKU in their catalog and their price
          will not be changed unless done so manually:{' '}
          {concatenateLongList(organizationNames, totalOrganizations)}
        </Alert>
      </>
    </ConfirmModal>
  )
}

export default CustomerCatalogWarningModal
