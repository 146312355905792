import React from 'react'

import AddGlobalProduct from './AddGlobalProduct'
import EditGlobalProduct from './EditGlobalProduct'
import { SKU } from '@firstbase/types/Product'

export type GlobalProductActionProps = {
  open: boolean
  handleClose: (flush?: boolean) => void
  product?: SKU
  staticGenericCategory?: boolean
}

const GlobalProductAction = (props: GlobalProductActionProps) => {
  const { product } = props

  if (product) {
    return <EditGlobalProduct {...props} product={product} />
  }

  return <AddGlobalProduct {...props} />
}

export default GlobalProductAction
