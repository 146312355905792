import { useRef, useCallback, useState, useEffect, useMemo } from 'react'
import { useQuery } from '@apollo/client'

import { GET_ALL_SKUS } from '@firstbase/data/SKU/queries'
import {
  getAllSKUs,
  getAllSKUsVariables,
  getAllSKUs_getAllSKUs_data,
} from '@firstbase/data/SKU/__generated__/getAllSKUs'

import { ReducerState } from './types'

const PAGE_SIZE = 25

interface HookProps {
  metadataFields: ReducerState['metadataFields']
  vendorCode: string
  skuId?: string
}

export const useFilteredSkuListData = ({
  metadataFields,
  vendorCode,
  skuId,
}: HookProps) => {
  const metadata = useMemo(
    () =>
      Object.entries(metadataFields).map(([_, { value, name }]) => ({
        field: name,
        value,
      })),
    [metadataFields]
  )

  const { data, error, loading, fetchMore } = useQuery<
    getAllSKUs,
    getAllSKUsVariables
  >(GET_ALL_SKUS, {
    variables: {
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      filter: {
        categories: ['WARRANTY'],
        brands: [vendorCode],
        metadata,
      },
    },
  })

  const [hasMore, setHasMore] = useState(true)
  const [filteredSkus, setFilteredSkus] = useState<
    getAllSKUs_getAllSKUs_data[]
  >([])

  const pageNumberRef = useRef(1)

  const handleLoadMore = useCallback(async () => {
    try {
      const { data: fetchMoreData } = await fetchMore({
        variables: {
          pageNumber: pageNumberRef.current + 1,
          pageSize: PAGE_SIZE,
        },
      })
      const newFilteredSkus = filterSkusById(
        fetchMoreData.getAllSKUs.data || [],
        skuId
      )

      pageNumberRef.current += 1

      setHasMore(
        hasMoreMatches(
          newFilteredSkus,
          fetchMoreData?.getAllSKUs.totalElements ?? 0,
          skuId
        )
      )
      setFilteredSkus((prevSkus) => [...prevSkus, ...newFilteredSkus])
    } catch (fetchMoreError) {
      // eslint-disable-next-line no-console
      console.error(fetchMoreError)
    }
  }, [fetchMore, skuId])

  /**
   * Handle updates to the data list from the use query hook,
   * which happens when the user updates the metadata field
   * selections or the category is changed.
   */
  useEffect(() => {
    const newFilteredSkus = filterSkusById(data?.getAllSKUs.data || [], skuId)

    setHasMore(
      newFilteredSkus.length > 0 &&
        hasMoreMatches(
          newFilteredSkus,
          data?.getAllSKUs.totalElements ?? 0,
          skuId
        )
    )
    setFilteredSkus(newFilteredSkus)

    pageNumberRef.current = 1
  }, [data?.getAllSKUs.data, skuId])

  return {
    filteredSkus,
    filteredSKUsError: error,
    filteredSKUsLoading: loading,
    totalElements: getAdjustedTotalElements(
      data?.getAllSKUs.totalElements ?? 0,
      filteredSkus,
      skuId
    ),
    hasMore,
    handleLoadMore,
  }
}

// Wrapper for handling the case when a user is editing a warranty skus
const getAdjustedTotalElements = (
  totalElements: number,
  skus: getAllSKUs_getAllSKUs_data[],
  skuId?: string
) => {
  if (skuId) {
    if (skus.length < PAGE_SIZE) return skus.length
    return totalElements - 1
  }

  return totalElements
}

const hasMoreMatches = (
  skus: getAllSKUs_getAllSKUs_data[],
  totalElements: number,
  skuId?: string
) => {
  if (skus.length < PAGE_SIZE) return false

  // HACK - For edit case
  if (skuId) return skus.length < totalElements - 1

  return skus.length < totalElements
}

const filterSkusById = (skus: getAllSKUs_getAllSKUs_data[], skuId?: string) => {
  if (skuId) {
    return skus.filter((sku) => sku.id !== skuId)
  }

  return skus
}
