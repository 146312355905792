import { gql } from '@apollo/client'

export const UPDATE_CONTRACT_PRODUCTS = gql`
  mutation updateContractProducts($params: UpdateContractProductsParams!) {
    updateContractProducts(params: $params) {
      contract {
        id
        revisionId
        productTiers {
          id
          name
        }
      }
    }
  }
`
