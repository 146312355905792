import { Box, Card, Skeleton } from '@mui/material'
import React from 'react'

type OwnProps = Partial<{
  logoUrl: string | null
  accent: string | null
  isLoading: boolean
}>

const WhiteLabelView = ({ logoUrl, accent, isLoading }: OwnProps) => {
  const renderLogoAndColor = () => {
    if (isLoading) {
      return (
        <>
          <Skeleton width="10rem" height="4rem" />
          <Skeleton width="1rem" height="2rem" />
        </>
      )
    }

    return (
      <>
        {logoUrl ? (
          <img src={logoUrl} style={{ maxWidth: '10rem' }} />
        ) : (
          <label>No Logo</label>
        )}

        {accent ? (
          <Box
            sx={{
              width: '1rem',
              height: '1rem',

              backgroundColor: accent,
            }}
          />
        ) : (
          <label>No Accent color</label>
        )}
      </>
    )
  }

  return (
    <Card
      sx={{
        p: 2,
        mb: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 2,
        }}
      >
        {renderLogoAndColor()}
      </Box>
    </Card>
  )
}

export default WhiteLabelView
