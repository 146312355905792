import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useQuery as useGQLQuery } from '@apollo/client'
import { useFlags } from 'launchdarkly-react-client-sdk'

import Page from '@atoms/Page'
import { useBreadcrumbs } from '@firstbase/context/breadcrumbs/BreadcrumbsProvider'

import TabGroup from '@firstbase/components/molecules/TabGroup'
import { GET_ORGANIZATION_BY_ID } from '@firstbase/data/Organization/queries'
import StatusChip from '@firstbase/components/atoms/StatusChip'
import { ClientPageParams } from '@firstbase/types/Pages'
import FLAGS from '@firstbase/constants/featureFlags'

import Settings from './Settings'
import ClientPeople from './ClientPeople'
import ClientInventoryOrders from './ClientInventoryOrders'
import ClientInventory from './Inventory/ClientInventory'
import ClientApiTokens from './ApiTokens/ClientApiTokens'
import MinInventory from './MinInventory'
import ClientPackages from './ClientPackages'
import ClientCategories from './ClientCategories'
import ClientProducts from './ClientProducts'
import BulkActions from './BulkActions'
import { ClientWarranties } from './ClientWarranties'

function Client() {
  const { clientId }: ClientPageParams = useParams()
  const { setBreadcrumbs } = useBreadcrumbs()
  const { loading: isLoading, data: { getOrganizationById: client } = {} } =
    useGQLQuery(GET_ORGANIZATION_BY_ID, {
      variables: { orgId: clientId },
    })

  const flags = useFlags()

  useEffect(() => {
    setBreadcrumbs([
      {
        label: 'Clients',
        pathId: 'clients',
      },
      {
        isLoading,
        label: client?.name!,
      },
    ])
  }, [client?.name, isLoading, setBreadcrumbs])

  const renderPageTitle = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <>{client?.name}</>
      <StatusChip
        type="organization"
        size="medium"
        status={client?.status}
        isLoading={isLoading}
      />
    </Box>
  )

  const getTabs = () => {
    const categoriesTabEnabled =
      flags[FLAGS.SE_2632_SETTING_GIFTED_FOR_CATEGORY]
    const warrantiesEnabled = flags[FLAGS.WARRANTIES_MGMT]

    return [
      { label: 'Catalog', Component: ClientProducts },
      { label: 'People', Component: ClientPeople },
      { label: 'Packages', Component: ClientPackages },
      { label: 'Inventory', Component: ClientInventory },
      { label: 'Inventory orders', Component: ClientInventoryOrders },
      { label: 'Minimum Inventory', Component: MinInventory },
      { label: 'Api Tokens', Component: ClientApiTokens },
      ...(categoriesTabEnabled
        ? [{ label: 'Categories', Component: ClientCategories }]
        : []),
      ...(warrantiesEnabled
        ? [{ label: 'Warranty Settings', Component: ClientWarranties }]
        : []),
      { label: 'Settings', Component: Settings },
      { label: 'Bulk Operations', Component: BulkActions },
    ]
  }

  return (
    <Page title={renderPageTitle()} breadcrumb isLoading={isLoading}>
      <TabGroup queryParam>
        {getTabs().map(({ label, Component }) => (
          <TabGroup.Page
            key={label}
            label={label}
            id={label.toLowerCase().replace(' ', '-')}
          >
            <Component />
          </TabGroup.Page>
        ))}
      </TabGroup>
    </Page>
  )
}

export default Client
