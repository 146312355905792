import React, { useMemo } from 'react'
import {
  Autocomplete,
  AutocompleteProps,
  createFilterOptions,
} from '@mui/material'

interface OptionType {
  id: string
  inputValue?: string
  label?: string
}

interface OwnProps
  extends AutocompleteProps<OptionType, boolean, boolean, boolean, 'div'> {
  shouldIncludeOption?: () => boolean
}

/**
 * Wrapper for using the select component with the ability to create new options.
 * It's mainly used as a wrapper for discovering the API for implementing one of these.
 * Material UI's configuration needed can be a bit tricky to get right and this acts
 * as the common ground for the api for this.
 */
export const CreateableSelect = ({
  shouldIncludeOption = () => true,
  ...rest
}: OwnProps) => {
  const filter = useMemo(
    () =>
      createFilterOptions({
        stringify: (option: OptionType) => option.label?.toLowerCase() || '',
        matchFrom: 'any',
        trim: true,
      }),
    []
  )

  return (
    <Autocomplete
      filterOptions={(unfilteredOptions, params) => {
        const filtered = filter(unfilteredOptions, params)
        const { inputValue } = params
        const isExactMatch = unfilteredOptions.some(
          (option) =>
            option.label?.toLowerCase() === inputValue.toLowerCase().trim()
        )

        if (inputValue !== '' && !isExactMatch && shouldIncludeOption()) {
          filtered.push({
            inputValue,
            label: `Add "${inputValue}"`,
            id: 'temp-id',
          })
        }

        return filtered
      }}
      getOptionLabel={getOptionLabel}
      freeSolo
      handleHomeEndKeys
      disablePortal
      clearOnBlur
      selectOnFocus
      {...rest}
    />
  )
}

const getOptionLabel = (option: string | OptionType) => {
  if (typeof option === 'string') return option
  return option.inputValue || option.label || ''
}
