import { useQuery } from '@apollo/client'
import Table from '@firstbase/components/atoms/Table'
import { Align } from '@firstbase/components/atoms/Table/types'
import { getSKUMinimumInventoryForOrganization_getSKUMinimumInventoryForOrganization_minimumInventory } from '@firstbase/data/MinimumInventory/__generated__/getSKUMinimumInventoryForOrganization'
import { getAllOrganizations_getAllOrganizations_data } from '@firstbase/data/Organization/__generated__/getAllOrganizations'
import { GET_ORGANIZATION_WAREHOUSES } from '@firstbase/data/Warehouse/queries'
import { getWarehousesForOrgId } from '@firstbase/data/Warehouse/__generated__/getWarehousesForOrgId'
import { Checkbox, TextField, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { MinimumInventoryWithUpdatedQuantity } from './productTypes'

interface MinimumInventoryTableRow {
  warehouse: {
    id: string
    name: string
  }
  minimumQuantity: string | null
  includeUsed: boolean | null
}

const getColumns = (
  handleQuantityChange: (
    changedWarehouseId: string
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void,
  handleIncludeUsedChange: (changedWarehouseId: string) => () => void,
  disabled: boolean
) => [
  {
    header: 'Warehouse',
    id: 'warehouse',
    cell: {
      value: ({ warehouse }: MinimumInventoryTableRow) => warehouse!.name,
    },
  },
  {
    header: 'Minimum inventory',
    id: 'minimumInventory',
    cell: {
      sx: { width: '450px' },
      value: ({
        minimumQuantity,
        includeUsed,
        warehouse,
      }: MinimumInventoryTableRow) => {
        const errorForMinimumInventory = !!includeUsed && !minimumQuantity
        return (
          <TextField
            label="Minimum Inventory"
            aria-label="Minimum Inventory"
            type="number"
            size="small"
            value={minimumQuantity || ''}
            onChange={handleQuantityChange(warehouse!.id)}
            placeholder="Quantity"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            disabled={disabled}
            error={errorForMinimumInventory}
            FormHelperTextProps={{ sx: { margin: 0 } }}
            helperText={
              errorForMinimumInventory &&
              'Minimum inventory is required when "include used" is checked'
            }
          />
        )
      },
    },
  },
  {
    header: 'Include Used',
    id: 'includeUsed',
    align: 'center' as Align,
    cell: {
      value: ({ includeUsed, warehouse }: MinimumInventoryTableRow) => (
        <Checkbox
          onChange={handleIncludeUsedChange(warehouse!.id)}
          checked={includeUsed ?? false}
        />
      ),
    },
  },
]

type OwnProps = {
  skuName: string
  org: Pick<getAllOrganizations_getAllOrganizations_data, 'name' | 'id'>
  isLoading?: boolean
  error?: Error
  initialMinimumInventory?: getSKUMinimumInventoryForOrganization_getSKUMinimumInventoryForOrganization_minimumInventory[]
  setMinInventory: React.Dispatch<
    React.SetStateAction<MinimumInventoryWithUpdatedQuantity[] | null>
  >
  firstbaseSupplied: boolean
}

const MinInventoryForm = ({
  skuName,
  org,
  isLoading: minInventoryLoading,
  error: minInventoryError,
  initialMinimumInventory = [],
  setMinInventory,
  firstbaseSupplied,
}: OwnProps) => {
  const [minInventoryTableData, setMinInventoryTableData] = useState<
    MinimumInventoryTableRow[] | null
  >(null)
  const {
    loading: loadingWarehouses,
    data: warehousesResponse,
    error: errorWarehouses,
  } = useQuery<getWarehousesForOrgId>(GET_ORGANIZATION_WAREHOUSES, {
    variables: { orgId: org.id },
  })
  const { getWarehousesForOrgId: warehouses = null } = warehousesResponse || {}
  const isLoading = loadingWarehouses || minInventoryLoading
  const error = errorWarehouses || minInventoryError

  useEffect(() => {
    if (!minInventoryTableData && warehouses) {
      setMinInventoryTableData(
        warehouses
          ?.filter(({ active }) => active)
          .map(({ id, name }) => {
            const providedRegionData = initialMinimumInventory?.find(
              ({ warehouse }) => warehouse?.id === id
            )

            return {
              warehouse: {
                id,
                name,
              },
              minimumQuantity:
                providedRegionData?.minimumQuantity?.toString() ?? null,
              includeUsed: providedRegionData?.includeUsed ?? null,
            }
          })
      )
    }
  }, [minInventoryTableData, initialMinimumInventory, warehouses])

  const handleQuantityChange =
    (changedWarehouseId: string) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = e

      if ((value && Number(value) < 0) || value.toString().includes('.')) {
        return null
      }

      setMinInventoryTableData(
        (prevMinInventory) =>
          prevMinInventory?.map((minInventoryRow) =>
            minInventoryRow.warehouse?.id === changedWarehouseId
              ? {
                  ...minInventoryRow,
                  minimumQuantity: value?.toString(),
                  includeUsed: minInventoryRow.includeUsed,
                }
              : minInventoryRow
          ) || null
      )
    }

  const handleIncludeUsedChange = (changedWarehouseId: string) => () =>
    setMinInventoryTableData(
      (prevMinInventory) =>
        prevMinInventory?.map((minInventoryRow) =>
          minInventoryRow.warehouse?.id === changedWarehouseId
            ? { ...minInventoryRow, includeUsed: !minInventoryRow.includeUsed }
            : minInventoryRow
        ) || null
    )

  useEffect(() => {
    setMinInventory(
      minInventoryTableData
        ?.map(({ minimumQuantity, warehouse, includeUsed }) => ({
          minimumQuantity: minimumQuantity ? Number(minimumQuantity) : -1,
          warehouseId: warehouse?.id,
          includeUsed: Boolean(includeUsed),
        }))
        .filter(
          ({ minimumQuantity, includeUsed }) =>
            minimumQuantity > -1 || includeUsed
        )!
    )
  }, [minInventoryTableData, setMinInventory])

  const columns = useMemo(
    () =>
      getColumns(
        handleQuantityChange,
        handleIncludeUsedChange,
        !firstbaseSupplied
      ),
    [firstbaseSupplied]
  )

  return (
    <>
      <Typography variant="h5">Set minimum inventory (optional)</Typography>
      <Typography variant="subtitle2" sx={{ mb: 2 }}>
        Set requirements for {skuName} for {org.name}. Applies to
        Firstbase-supplied only.
      </Typography>
      <Table
        skeletonHeight="24rem"
        query={{
          isLoading,
          error,
          data: {
            data: minInventoryTableData || [],
          },
        }}
        dataKey="warehouse.name"
        tableId="edit minimum inventory table"
        columns={columns}
        noDataMessage={`No warehouses available for ${org.name!}`}
      />
    </>
  )
}

export default MinInventoryForm
