import { useMutation } from '@apollo/client'

import {
  SEND_MESSAGE,
  GET_CHAT_MESSAGES,
  sendMessage as sendMessageType,
  sendMessageVariables,
  getChatMessages,
  getChatMessagesVariables,
} from '@firstbase/data/Assistant'

/**
 * Wrapper used for keeping the component readable
 */
export const useSendMessage = () => {
  const [sendMessageMutation, { loading: sendingMessage }] = useMutation<
    sendMessageType,
    sendMessageVariables
  >(SEND_MESSAGE, {
    update: (cache, data) => {
      const chatId = data.data?.sendMessage.message?.chatId ?? ''

      const existingCacheData = cache.readQuery<
        getChatMessages,
        getChatMessagesVariables
      >({
        query: GET_CHAT_MESSAGES,
        variables: { chatId },
      })

      if (data.data && existingCacheData) {
        const { message, response } = data.data.sendMessage
        if (message && response) {
          const updatedChat: getChatMessages = {
            chat: {
              ...existingCacheData.chat,
              messages: [
                ...(existingCacheData.chat.messages ?? []),
                {
                  id: message.id,
                  content: message.content,
                  isBotResponse: false,
                  __typename: 'Message',
                },
                {
                  id: response.id,
                  content: response.content,
                  isBotResponse: true,
                  __typename: 'Message',
                },
              ],
            },
          }

          cache.writeQuery<getChatMessages, getChatMessagesVariables>({
            query: GET_CHAT_MESSAGES,
            data: updatedChat,
            variables: { chatId },
          })
        }
      }
    },
  })

  /**
   * Wrap to abstract the optimistic response
   */
  const sendMessage = (variables: sendMessageVariables) => {
    sendMessageMutation({
      variables,
      optimisticResponse: {
        sendMessage: {
          __typename: 'MessageResponsePair',
          message: {
            __typename: 'Message',
            id: 'temp-id-1',
            chatId: variables.chatId,
            content: variables.message,
            personId: variables.personId,
          },
          response: {
            __typename: 'Message',
            id: 'temp-id-2',
            content: '',
            chatId: variables.chatId,
            personId: 'bot',
          },
        },
      },
    })
  }

  return { sendMessage, sendingMessage }
}
