import { gql } from '@apollo/client'

export const GET_ALL_FEATURES = gql`
  query getAllFeatures(
    $pagingAndSorting: PagingAndSorting!
    $filter: FeatureFilter
  ) {
    getAllFeatures(pagingAndSorting: $pagingAndSorting, filter: $filter) {
      features {
        totalElements
        data {
          id
          name
          status
          category
          description
        }
      }
    }
  }
`
