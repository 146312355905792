import { StandardPricingFormRow } from '@firstbase/views/Home/Platform/Products/StandardPricingTable'
import {
  StandardPricingInput,
  SubscriptionInterval,
  SubscriptionPriceInput,
} from '@globalTypes'

/**
 * Calls callback if conditions are met
 * - If validate is true, calls callback with a string that is a valid number
 * with no more than two decimal places
 * - If validate is false, must be valid number or empty string
 */
export const updatePrice = (
  value: string,
  validate: boolean,
  callback: (newValue: string) => void
) => {
  let newValue = value
  let validPrice = new RegExp(/^([0-9]+\.?[0-9]{0,2}|\.[0-9]{0,2})?$/m).test(
    newValue
  )

  if (!validPrice) return

  if (validate) {
    callback(`${Number(Number(value).toFixed(2))}`)
  } else {
    callback(newValue)
  }
}

export const standardPricingToFormValues = (
  pricingItems: StandardPricingInput[]
): StandardPricingFormRow[] => {
  const getSubscriptionPriceValue = (
    length: number,
    pricingInput: StandardPricingInput
  ) => {
    const { subscriptionPricing } = pricingInput
    if (!subscriptionPricing) {
      return ''
    }

    const subscriptionPrice = subscriptionPricing.find(
      (pricing) => pricing.length === length
    )

    if (!subscriptionPrice) {
      return ''
    }

    return String(subscriptionPrice.price)
  }

  return pricingItems.map((item) => ({
    regionCode: item.regionCode,
    currencyCode: item.currencyCode,
    purchasePrice: String(item.purchasePrice),
    subscriptionPrice24: getSubscriptionPriceValue(24, item),
    subscriptionPrice36: getSubscriptionPriceValue(36, item),
  }))
}

/**
 * Creates subscriptionPricing from form values
 *
 * - If a subscription price is 0, it is not included in the output
 * - If no subscription prices are set, returns an empty array
 */
const createSubscriptionPricing = (item: StandardPricingFormRow) => {
  const { subscriptionPrice24, subscriptionPrice36 } = item

  const subscriptionPricing: SubscriptionPriceInput[] = []

  if (Number(subscriptionPrice24) > 0) {
    subscriptionPricing.push({
      length: 24,
      interval: SubscriptionInterval.MONTHLY,
      price: Number(subscriptionPrice24),
    })
  }

  if (Number(subscriptionPrice36) > 0) {
    subscriptionPricing.push({
      length: 36,
      interval: SubscriptionInterval.MONTHLY,
      price: Number(subscriptionPrice36),
    })
  }

  return subscriptionPricing
}

export const formValuesToStandardPricing = (
  pricingItems: StandardPricingFormRow[]
) => {
  return pricingItems.map((item) => ({
    regionCode: item.regionCode,
    currencyCode: item.currencyCode,
    purchasePrice: Number(item.purchasePrice),
    subscriptionPricing: createSubscriptionPricing(item),
  }))
}

/**
 * Validates standardPricing is set properly
 *
 * - All pricing rows that have subscription price set must have purchase price set.
 * - If standard catalog, must have at least one pricing row that has purchase price set.
 * - Must have currency code set
 */
export const validateStandardPricing = (
  standardPricingInputs: StandardPricingInput[],
  isStandardCatalogSKU: boolean
): boolean => {
  if (standardPricingInputs.length === 0) return !isStandardCatalogSKU

  let hasOnePurchasePrice = false

  for (const {
    purchasePrice,
    subscriptionPricing,
    currencyCode,
  } of standardPricingInputs) {
    const areSubscriptionPricesSet = subscriptionPricing?.some(
      (pricing) => Number(pricing.price) > 0
    )

    if (areSubscriptionPricesSet && Number(purchasePrice) === 0) {
      return false
    }

    if (!currencyCode) {
      return false
    }

    if (Number(purchasePrice) > 0) {
      hasOnePurchasePrice = true
    }
  }
  return hasOnePurchasePrice
}

export const pricingHasChanged = (
  currentPricing: StandardPricingInput[],
  formPricing: StandardPricingFormRow[]
): boolean => {
  if (currentPricing.length !== formPricing.length) return true

  const serializedCurrentPricing = standardPricingToFormValues(currentPricing)

  // Look for any rows that don't match
  return serializedCurrentPricing.some((currRow) => {
    const formRow = formPricing.find((i) => i.regionCode === currRow.regionCode)

    if (!formRow) return true

    return (
      currRow.purchasePrice !== formRow.purchasePrice ||
      currRow.subscriptionPrice24 !== formRow.subscriptionPrice24 ||
      currRow.subscriptionPrice36 !== formRow.subscriptionPrice36
    )
  })
}
