import React, { useState } from 'react'

import Table from '@atoms/Table'
import { generateColumns } from '../../Client/productsTableColumns'
import { Product } from './AddProduct'
import useLazyQuery from '@firstbase/hooks/useLazyQuery'
import { PageQueryParams } from '@firstbase/components/atoms/Table/Table'
import tableRequest from '@firstbase/components/atoms/Table/tableRequest'
import { QueryOptions } from 'react-query'
import SearchBox from '@firstbase/components/atoms/SearchBox'
import { Box } from '@mui/material'

type OwnProps = {
  clientId: string
  setSelectedProduct: (product: Product) => void
  existingSelectedProductId?: string
}

const TABLE_ID = 'all products table'

function AllProductsTable({
  clientId,
  setSelectedProduct,
  existingSelectedProductId,
}: OwnProps) {
  const [skuIdentifierFilter, setSkuIdentifierFilter] = useState('')
  const [selectedProductId, setSelectedProductId] = useState<string>(
    existingSelectedProductId || ''
  )
  const rowIsSelected = (product: Product) => {
    return product.id === selectedProductId
  }

  const handleProductSelect = (product: Product) => {
    setSelectedProductId(product.id)
    setSelectedProduct(product)
  }

  const handleSkuIdentifierChange = (newSearch: string) =>
    setSkuIdentifierFilter(newSearch)

  const query = useLazyQuery<
    PageQueryParams,
    { data: Product[]; totalElements: number }
  >(
    [TABLE_ID, { clientId, skuIdentifierFilter }],
    ({
      pageIndex,
      rowsPerPage,
      activeSortId,
      activeSortDirection,
    }: PageQueryParams) =>
      tableRequest(`/organizations/${clientId}/products`, {
        page: pageIndex + 1,
        rowsPerPage,
        activeSortId,
        activeSortDirection,
        assigned: false,
        skuIdentifierFilter,
      }),
    {
      keepPreviousData: true,
      cacheTime: 0, // don't cache to ensure complete freshness
    } as QueryOptions
  )

  return (
    <Box sx={{ pt: 1 }}>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <SearchBox
          placeholder="Product name, MPN, UPC, EAN or Product ID"
          label="Search by product name, MPN, UPC, EAN or Product ID"
          onChange={handleSkuIdentifierChange}
          disabled={query.error || (query.isLoading && !query.isPreviousData)}
        />
      </Box>
      <Table
        tableId={TABLE_ID}
        pagination
        query={query}
        columns={generateColumns({
          clientId,
          productIsLink: false,
          displayStatus: true,
        })}
        customRowsPerPage={100}
        customRowsPerPageOptions={[50, 100, 200]}
        showHover={true}
        rowIsSelected={rowIsSelected}
        handleRowClick={handleProductSelect}
      />
    </Box>
  )
}

export default AllProductsTable
