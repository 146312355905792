import React from 'react'
import { Typography, Box } from '@mui/material'

import Chip, { OwnProps as ChipProps } from './Chip'

interface ExtendedChipProps extends ChipProps {
  text: string
}

interface OwnProps {
  chip: ExtendedChipProps
  text: string
}

const ChipWithHelperText = ({ chip, text }: OwnProps) => {
  const { text: chipText, ...rest } = chip
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Chip {...rest}>{chipText}</Chip>
      {text && (
        <Typography sx={{ marginLeft: 2, fontSize: '0.875rem' }}>
          {text}
        </Typography>
      )}
    </Box>
  )
}

export default ChipWithHelperText
