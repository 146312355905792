import React, { useRef, useState } from 'react'
import { TextField, Button, Box, CircularProgress } from '@mui/material'

interface OwnProps {
  loading?: boolean
  submit: (message: string) => void
}

const SendMessageForm = ({ loading, submit }: OwnProps) => {
  const [message, setMessage] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setMessage('')
    submit(message)

    if (inputRef.current) {
      inputRef.current.focus() // Set focus back to the input after sending message
    }
  }

  return (
    <Box
      component="form"
      onSubmit={submitForm}
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '60px',
        gap: '0.5rem',
      }}
    >
      <TextField
        id="message-assistant"
        placeholder="Message Firstbase Assistant here"
        onChange={(event) => setMessage(event.target.value)}
        value={message}
        inputRef={inputRef}
        fullWidth
      />
      <Button
        variant="contained"
        type="submit"
        disabled={!message.trim() || loading}
        sx={{ height: '56px' }}
      >
        {loading ? <CircularProgress size={20} /> : 'Submit'}
      </Button>
    </Box>
  )
}

export default SendMessageForm
