import { AxiosRequestConfig } from 'axios'

import { pricingAxiosClient, graphqlAxiosClient } from './axiosClients'

export type Request<ResponseType> = Promise<ResponseType>

const request = <ResponseType>({
  ...options
}: AxiosRequestConfig<any>): Request<ResponseType> => {
  const onSuccess = (response: { data: ResponseType }) => response.data
  const onError = (error: Error) => {
    // optionally catch errors and add some additional logging here
    throw error
  }

  return pricingAxiosClient
    .get()({
      ...options,
    })
    .then(onSuccess)
    .catch(onError)
}

export const graphqlRequest = <ResponseType>({
  ...options
}: AxiosRequestConfig<any>): Request<ResponseType> => {
  const onSuccess = (response: { data: ResponseType }) => response.data
  const onError = (error: Error) => {
    // optionally catch errors and add some additional logging here
    throw error
  }

  return graphqlAxiosClient
    .get()({
      ...options,
    })
    .then(onSuccess)
    .catch(onError)
}

export default request
