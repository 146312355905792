import React from 'react'
import Markdown from 'markdown-to-jsx'

const CustomLink = ({
  children,
  ...props
}: {
  [x: string]: any
  children: any
}) => (
  <a
    {...props}
    style={{ textDecoration: 'underline' }}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
)

interface OwnProps {
  text: string
}

const MarkdownRenderer = ({ text }: OwnProps) => (
  <Markdown
    options={{
      overrides: {
        a: { component: CustomLink },
      },
    }}
  >
    {text}
  </Markdown>
)

export default MarkdownRenderer
