import { gql } from '@apollo/client'

export const GET_PACKAGE_BY_SLUG = gql`
  query getPackageSelectionsBySlug($packageSlug: String!) {
    getPackageBySlug(packageSlug: $packageSlug) {
      id
      name
      organization {
        name
        id
      }
      active
      people {
        forename
        surname
        slug
        id
        email
      }
      skus {
        id
        vendor {
          name
        }
        skuInformation {
          productTitle
        }
        category {
          nameSingular
        }
      }
    }
  }
`
