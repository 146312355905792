import React from 'react'

import Page from '@atoms/Page'
import Table, { SortDirections } from '@firstbase/components/atoms/Table'

import { GET_PAGED_WAREHOUSES } from '@firstbase/data/Warehouse/queries'

import useTableGraphqlQuery from '@firstbase/hooks/useTableGraphqlQuery'
import { getAllWarehouses as GetAllWarehouses } from '@firstbase/data/Warehouse/__generated__/getAllWarehouses'
import { cellComponents } from '@firstbase/components/atoms/Table/cellComponents/cellComponents'

const columns = [
  {
    header: ' ID',
    id: '',
    cell: { as: cellComponents.ID, value: ({ id }: any) => id },
  },
  {
    header: 'Name',
    defaultSort: SortDirections.asc,
    id: 'name',
    cell: {
      value: ({ name }: any) => name,
    },
  },
  {
    header: 'Code',
    defaultSort: SortDirections.asc,
    id: 'code',
    cell: {
      value: ({ code }: any) => code,
    },
  },
  {
    header: 'Country Code',
    defaultSort: SortDirections.asc,
    id: 'address.countryCode',
    cell: {
      value: ({ address }: any) => address.countryCode,
    },
  },
]

const Warehouses = () => {
  const query = useTableGraphqlQuery<GetAllWarehouses, any>(
    GET_PAGED_WAREHOUSES,
    {},
    ({ getAllWarehouses }) => getAllWarehouses
  )

  return (
    <>
      <Page.TitleSection
        sectionTitle="Warehouses"
        sectionSubtitle="List of warehouses that are available on the platform"
      />
      <Table
        query={query}
        tableId="warehouses"
        columns={columns}
        pagination
        customRowsPerPageOptions={[100, 200, 500]}
      />
    </>
  )
}

export default Warehouses
