import Select from '@firstbase/components/atoms/FBSelect'
import { OrderStatus } from '@globalTypes'
import {
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material'
import { sentenceCase } from 'change-case'
import React, { useRef } from 'react'

type OwnProps = {
  status: OrderStatus
  onUpdateProductStatus: (event: SelectChangeEvent) => void
}

export const SELECTABLE_STATUS_OPTIONS = [
  OrderStatus.CANCELLED,
  OrderStatus.PENDING,
  OrderStatus.NOT_RETURNED,
]

const ReturnOrderItemStatusSelect = ({
  status,
  onUpdateProductStatus,
}: OwnProps) => {
  const initialStatus = useRef(status)
  const isSelectableStatus = SELECTABLE_STATUS_OPTIONS.includes(status)

  const renderStatusOptions = () =>
    [...new Set([status, ...SELECTABLE_STATUS_OPTIONS])].map((optionStatus) => (
      <MenuItem
        value={optionStatus}
        key={optionStatus}
        disabled={!isSelectableStatus && optionStatus === status}
      >
        {sentenceCase(optionStatus)}
      </MenuItem>
    ))

  return (
    <FormControl
      disabled={initialStatus?.current === OrderStatus.NOT_RETURNED}
      sx={{ width: '100%' }}
    >
      <InputLabel size="small" id="status">
        Order item status
      </InputLabel>
      <Select
        disabled={initialStatus?.current === OrderStatus.NOT_RETURNED}
        fullWidth
        onChange={onUpdateProductStatus}
        label="Order item status"
        aria-labelledby="status"
        size="small"
        value={status}
      >
        {renderStatusOptions()}
      </Select>
    </FormControl>
  )
}

export default ReturnOrderItemStatusSelect
