import React from 'react'
import { CircularProgress, Button } from '@mui/material'

import { ContinueProps } from './types'

function BottomBarContinueButton({
  loading,
  label = 'Continue',
  ...rest
}: ContinueProps) {
  return (
    <Button {...rest}>
      {loading ? <CircularProgress color="secondary" size="1.5rem" /> : label}
    </Button>
  )
}

export default BottomBarContinueButton
