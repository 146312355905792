import React from 'react'
import { Box, InputLabel } from '@mui/material'

import Table, { TableQuery } from '@firstbase/components/atoms/Table/Table'
import Textarea from '@firstbase/components/atoms/Textarea'

import { ConfirmMethodSection } from './types'
import { Product } from '../types'

interface OwnProps extends ConfirmMethodSection {
  furniture: TableQuery
}

const FurnitureSection = ({
  comment,
  furniture,
  onChangeComment,
}: OwnProps) => {
  return (
    <Box marginTop={1.5}>
      <Table
        tableId="retrieval-table-furniture"
        query={furniture}
        columns={[
          {
            header: 'Furniture',
            id: 'sku.skuInformation.productTitle',
            cell: {
              sx: {
                width: '100%',
              },
              value: ({ sku }: Product) => sku.skuInformation.productTitle,
            },
          },
        ]}
      />
      <Box marginTop={1}>
        <InputLabel
          htmlFor="retrieval-input-furniture"
          sx={{ fontWeight: 'bold', marginBottom: 1 }}
        >
          Provide a furniture retrieval note for the Ops team (optional)
        </InputLabel>
        <Textarea
          id="retrieval-input-furniture"
          placeholder="E.g. This desk is scheduled to be delivered on April 23rd, 2023"
          onChange={onChangeComment}
          value={comment}
        />
      </Box>
    </Box>
  )
}

export default FurnitureSection
