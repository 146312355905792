import React from 'react'
import { Container, Dialog, useMediaQuery } from '@mui/material'

import { theme } from '@firstbase/muiTheme'

import TopBar from './TopBar'
import BottomBar from './BottomBar'
import { FullScreenModalProps } from './types'

const FullscreenModal = ({
  handleClose: handleCloseCb,
  handleBack,
  children,
  continueProps,
  secondaryContinueProps,
  title,
  open,
  closeDisabled,
  helperText,
  errorText,
  closeLabel,
}: FullScreenModalProps) => {
  const handleClose = () => {
    if (closeDisabled) return

    return handleCloseCb()
  }

  return (
    <Dialog
      PaperProps={{
        sx: { backgroundColor: '#fafafa' },
      }}
      fullScreen
      open={open}
      onClose={handleClose}
    >
      <Container data-testid="fullscreenModal">
        <TopBar handleBack={handleBack || handleClose} title={title} />
        <Container
          disableGutters={useMediaQuery(theme.breakpoints.up('lg'))}
          maxWidth={false}
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            height: '100vh',
            overflow: 'auto',
            maxWidth: 1154,
            paddingTop: 7,
            paddingBottom: 7,
          }}
        >
          {children}
        </Container>
        {continueProps && (
          <BottomBar
            helperText={helperText}
            errorText={errorText}
            handleClose={handleClose}
            closeDisabled={closeDisabled}
            continueProps={continueProps}
            secondaryContinueProps={secondaryContinueProps}
            closeLabel={closeLabel}
          />
        )}
      </Container>
    </Dialog>
  )
}

export default FullscreenModal
