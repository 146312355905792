import { useMutation } from '@apollo/client'
import Page from '@firstbase/components/atoms/Page'
import FLAGS from '@firstbase/constants/featureFlags'
import { getPersonBySlugAndOrganizationSlug_getPersonBySlugAndOrganizationSlug as PersonI } from '@firstbase/data/Person/__generated__/getPersonBySlugAndOrganizationSlug'
import { updatePersonBySlugVariables } from '@firstbase/data/Person/__generated__/updatePersonBySlug'
import { PersonStatus } from '@globalTypes'
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { UPDATE_PERSON_BY_SLUG } from '@firstbase/data/Person/mutations'
import { format } from 'date-fns'

interface OwnProps {
  person?: PersonI
}

const DeactivatePerson = ({ person }: OwnProps) => {
  const flags = useFlags()
  const { enqueueSnackbar } = useSnackbar()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [updatePersonMutation, { loading: updating }] =
    useMutation<updatePersonBySlugVariables>(UPDATE_PERSON_BY_SLUG, {
      refetchQueries: ['getPersonBySlugAndOrganizationSlug'],
    })

  const deactivatePerson = async () => {
    try {
      await updatePersonMutation({
        variables: {
          slug: person?.slug,
          updatePerson: {
            status: PersonStatus.OFFBOARDING,
            offboardingInfo: {
              lastDate: format(new Date(), 'yyyy-MM-dd'),
            },
          },
        },
      })
      enqueueSnackbar(
        `Successfully deactivated ${person?.forename} ${person?.surname}'s account`
      )
    } catch (err) {
      enqueueSnackbar('Failed to deactivate user', {
        variant: 'error',
      })
    }
  }

  if (
    (person?.status !== PersonStatus.INVITED &&
      person?.status !== PersonStatus.ACTIVE) ||
    !flags[FLAGS.SE_3043_DELETE_USER]
  )
    return null

  const cancelDeactivation = () => setShowConfirmation(false)

  const getConfirmationMessage = () => {
    if (person?.inventories) {
      return "This person has inventory assigned to them. If you deactivate them, they'll receive an offboarding email with an attempt to retrieve their inventory."
    }

    return "This person doesn't have any inventory assigned to them. If you deactivate them, they'll be marked as inactive."
  }

  const renderConfirmation = () =>
    showConfirmation && (
      <Dialog onClose={cancelDeactivation} maxWidth="sm" fullWidth open>
        <DialogTitle>
          <Page.TitleSection
            sectionTitle={`Are you sure you want to deactivate ${person.forename} ${person.surname}’s account?`}
            sectionSubtitle={getConfirmationMessage()}
          />
        </DialogTitle>
        <DialogActions>
          <Button variant="text" color="secondary" onClick={cancelDeactivation}>
            Cancel
          </Button>

          <Button variant="contained" onClick={deactivatePerson}>
            Yes, deactivate them
          </Button>
        </DialogActions>
      </Dialog>
    )

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => setShowConfirmation(true)}
        disabled={updating}
      >
        Deactivate
      </Button>
      {renderConfirmation()}
    </>
  )
}

export default DeactivatePerson
