import React, { useCallback } from 'react'

import Table from '@firstbase/components/atoms/Table'
import { PageQueryParams } from '@firstbase/components/atoms/Table/Table'
import { getComparator } from '@firstbase/utils/tableUtils'
import { editPricingTableColumns } from '../pricingTableColumns'
import { BillingType, PricingRow } from '../Product'
import { PriceData } from './EditProduct'
import {
  CustomerSuppliedCheckbox,
  FirstbaseSuppliedCheckbox,
} from '../SupplierCheckboxes'

type Props = {
  billType: BillingType
  setBillType: React.Dispatch<React.SetStateAction<BillingType>>
  priceData: PriceData
  setPriceData: React.Dispatch<React.SetStateAction<PriceData>>
  firstbaseSupplied: boolean
  setFirstbaseSupplied: (newValue: boolean) => void
  allowSubscription: boolean
}

const EditPricing = ({
  priceData,
  setPriceData,
  firstbaseSupplied,
  setFirstbaseSupplied,
  allowSubscription,
}: Props) => {
  const fetch = useCallback(
    ({ activeSortId, activeSortDirection }: PageQueryParams) =>
      setPriceData((prevPriceData) =>
        prevPriceData
          .map(({ isDirty: _, ...rest }) => ({ ...rest }))
          .sort(getComparator(activeSortDirection, activeSortId))
      ),
    [setPriceData]
  )

  const handleBillTypeChange = (pricingRow: PricingRow) => {
    return (newBillingType: any) => {
      setPriceData((prevPriceData) =>
        prevPriceData.reduce(
          (newPrices, currentPrice) => [
            ...newPrices,
            {
              ...currentPrice,
              ...(currentPrice.regionCode === pricingRow.regionCode
                ? {
                    billingType: newBillingType,
                    isDirty: true,
                  }
                : {}),
            },
          ],
          [] as PricingRow[]
        )
      )
    }
  }

  const handlePriceChange =
    (pricingRow: PricingRow, validate = false) =>
    (e: any) => {
      const {
        target: { value: priceValue },
      } = e
      let cleanValue = priceValue

      // prevent more than 2 decimal places
      let validPrice = new RegExp(
        /^([0-9]+\.?[0-9]{0,2}|\.[0-9]{0,2})?$/m
      ).test(priceValue)

      if (validate && priceValue && validPrice) {
        cleanValue = Number(priceValue).toFixed(2)
      } else {
        if (!validPrice) {
          return
        }
      }

      setPriceData((prevPriceData) =>
        prevPriceData.reduce(
          (newPrices, currentPrice) => [
            ...newPrices,
            {
              ...currentPrice,
              ...(currentPrice.regionCode === pricingRow.regionCode
                ? {
                    price: validate ? Number(cleanValue) : cleanValue,
                    isDirty: true,
                  }
                : {}),
            },
          ],
          [] as PricingRow[]
        )
      )
    }

  const toggleFirstbaseSupplied = () => setFirstbaseSupplied(!firstbaseSupplied)

  return (
    <>
      <CustomerSuppliedCheckbox
        checked={!firstbaseSupplied}
        onChange={toggleFirstbaseSupplied}
      />
      <FirstbaseSuppliedCheckbox
        checked={firstbaseSupplied}
        onChange={toggleFirstbaseSupplied}
      />
      <Table
        noDataMessage="No billing regions available"
        dataKey="regionCode"
        query={{ fetch, data: { data: priceData } }}
        tableId="edit prices table"
        columns={editPricingTableColumns(
          handlePriceChange,
          handleBillTypeChange,
          allowSubscription,
          !firstbaseSupplied
        )}
      />
    </>
  )
}

export default EditPricing
