import { useMemo } from 'react'

import { SKUType } from '@globalTypes'

import { Product } from '../EditReturnOrder/types'
import { sortGenericToEnd } from '../EditReturnOrder/editReturnOrderUtils'

function useEquipmentFurnitureTableQueries(orderProducts: Product[]) {
  const allExistingProducts = useMemo(
    () => sortGenericToEnd(orderProducts),
    [orderProducts]
  )

  const equipmentTableQuery = useMemo(
    () => ({
      data: {
        data: allExistingProducts.filter(
          ({ sku }) => sku?.category.skuType !== SKUType.FURNITURE
        ),
      },
    }),
    [allExistingProducts]
  )
  const furnitureTableQuery = useMemo(
    () => ({
      data: {
        data: allExistingProducts.filter(
          ({ sku }) => sku?.category.skuType === SKUType.FURNITURE
        ),
      },
    }),
    [allExistingProducts]
  )

  return { equipmentTableQuery, furnitureTableQuery }
}

export default useEquipmentFurnitureTableQueries
