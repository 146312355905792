import FLAGS from '@firstbase/constants/featureFlags'
import { useFlags } from 'launchdarkly-react-client-sdk'

type FlagKey = keyof typeof FLAGS
type FeatureName = typeof FLAGS[FlagKey]

export function useFeatureFlag(flagName: FeatureName): boolean {
  const flags = useFlags()

  return !!flags[flagName]
}

export function useStringFeatureFlag(flagName: FeatureName): string {
  const flags = useFlags()

  return flags[flagName]
}

export function useArrayFeatureFlag(
  flagName: FeatureName,
  delimiter: string = ','
): Array<string> {
  const value = useStringFeatureFlag(flagName)
  if (value.length != 0) {
    return value.split(delimiter).map((val) => val.trim())
  }
  return new Array<string>()
}
