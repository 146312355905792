import { useQuery } from '@apollo/client'
import {
  getContractsByOrg as GetContractsByOrg,
  getContractsByOrg_getContractsByOrg_contracts,
  getContractsByOrgVariables,
} from '@firstbase/data/ProductTier/__generated__/getContractsByOrg'
import { GET_CONTRACTS_BY_ORGANIZATION } from '@firstbase/data/ProductTier/contractQueries'
import { useMemo } from 'react'

export const useGetOrganizationContract = (orgId?: string) => {
  const contractsQuery = useQuery<
    GetContractsByOrg,
    getContractsByOrgVariables
  >(GET_CONTRACTS_BY_ORGANIZATION, {
    variables: {
      orgId: orgId ?? '',
    },
    skip: !orgId,
  })

  const { latestContract, latestRevisionId } = getLatestContractAndRevisionId(
    contractsQuery.data?.getContractsByOrg.contracts
  )

  const contractProductTiers = useMemo(() => {
    // latestContract has the latest revisionId (number)
    const productTiers = latestContract?.productTiers

    if (!productTiers || productTiers.length === 0) {
      return []
    }

    return productTiers
  }, [latestContract?.productTiers])

  return {
    contractProductTiers,
    latestContract,
    latestRevisionId,
    ...contractsQuery,
  }
}

const getLatestContractAndRevisionId = (
  contracts: getContractsByOrg_getContractsByOrg_contracts[] | null | undefined
) => {
  const latestContract = contracts?.reduce((latest, contract) => {
    if (contract.revisionId > latest.revisionId) {
      return contract
    }
    return latest
  }, contracts[0])

  return {
    latestContract,
    latestRevisionId: latestContract?.revisionId,
  }
}
