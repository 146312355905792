import { NOT_SUPPORTED } from '@firstbase/constants/contract'
import { FeesRow, FeeType } from '@firstbase/types/Fee'

export const isOverridden = (
  relevantFeesByActiveCountryByName: FeesRow[][],
  providedRelevantFees?: FeesRow[]
) => {
  const activeRegions = Object.keys(relevantFeesByActiveCountryByName)
  const flatRelevantFees =
    providedRelevantFees || relevantFeesByActiveCountryByName.flat()

  // if only one active region or no fees, then not overridden
  if (activeRegions.length <= 1 || !flatRelevantFees.length) return null

  // if not same number of fees in all regions, then not overridden
  const feesByRegion = Object.values(relevantFeesByActiveCountryByName)
  const comparatorFeeLength = Object.keys(feesByRegion[0]).length

  if (
    feesByRegion.some(
      (feeList) => Object.keys(feeList).length !== comparatorFeeLength
    )
  ) {
    return null
  }

  const typesInRegion = flatRelevantFees.reduce(
    (acc, { regionCode, feeType }) => {
      const newRegion = acc[feeType]
        ? [...acc[feeType], regionCode]
        : [regionCode]

      return { ...acc, [feeType]: newRegion }
    },
    {} as Record<string, string[]>
  )

  // if missing a type for any region, the not overridden
  if (
    Object.values(typesInRegion).some(
      (types) => types.length !== activeRegions.length
    )
  ) {
    return null
  }

  const { currencyCode: comparatorCurrencyCode } = flatRelevantFees[0]

  // if all fees not in same currency
  if (
    flatRelevantFees.some(
      ({ currencyCode }) => currencyCode !== comparatorCurrencyCode
    )
  ) {
    return null
  }

  const feePricesGroupedByType = flatRelevantFees.reduce((acc, fee) => {
    if (fee.price === NOT_SUPPORTED) return acc

    const updatedFeeType = acc[fee.feeType]
      ? [...new Set([...acc[fee.feeType], fee.price])]
      : [fee.price]

    return { ...acc, [fee.feeType]: updatedFeeType } as unknown as {
      [type in FeeType]: string
    }
  }, {} as { [type in FeeType]: string })

  // if a type has multiple prices
  if (
    Object.values(feePricesGroupedByType).some(
      (distinctValues) => distinctValues.length > 1
    )
  ) {
    return null
  }

  // otherwise, is overriddden
  return {
    fees: Object.fromEntries(
      Object.entries(feePricesGroupedByType).map(([feeType, prices]) => [
        feeType,
        prices[0],
      ])
    ),
  } as {
    fees: Record<FeeType, string>
  }
}

/**
 * Traditional override just looks to see if all currencies are the same
 * This one checks for whether or not same currency, but different prices
 * Used to make different decisions in the UI
 */
export const isOverriddenWithDifferentPrices = (
  fees: FeesRow[][],
  contractCurrencies: Record<string, string>
) => {
  const moreThanOneCurrencyUsed =
    new Set(Object.values(contractCurrencies)).size > 1
  const flatOnDemandFees = fees.flat()

  if (
    isOverridden(fees) ||
    !moreThanOneCurrencyUsed ||
    flatOnDemandFees.length === 0
  )
    return false

  const firstOnDemandFeeCurrency = flatOnDemandFees[0]
    ? flatOnDemandFees[0].currencyCode
    : ''

  return !flatOnDemandFees.some(
    (fee) => fee.currencyCode !== firstOnDemandFeeCurrency
  )
}
