import * as React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { Box, Button, Toolbar } from '@mui/material'

import { useOktaAuth } from '@okta/okta-react'

import { ReactComponent as FirstbaseLogo } from '@firstbase/assets/FirstbaseLogoWhite.svg'
import Loader from '@atoms/Loader'

import { AppBar, DrawerHeader } from './menuStyles'
import MenuDrawer from './MenuDrawer'
import useLocalStorage from '@firstbase/hooks/useLocalStorage/useLocalStorage'

type OwnProps = {
  children: React.ReactChild
}

export default function FirstbaseAppBar({ children }: OwnProps) {
  const [open, setOpen] = useLocalStorage('menuDrawerOpen', false)
  const { oktaAuth } = useOktaAuth()
  const [isLoggingOut, setIsLoggingOut] = React.useState(false)

  const logout = async () => {
    setIsLoggingOut(true)
    oktaAuth.tokenManager.clear()
    await oktaAuth.signOut()
  }

  const toggleDrawerOpen = () => setOpen((prevOpen) => !prevOpen)

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" color="secondary" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open menu"
            onClick={toggleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}>
            <FirstbaseLogo
              aria-label="Logo"
              width={181}
              height={32}
              fillOpacity={1}
            />
          </Box>
          <Button color="inherit" onClick={logout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <MenuDrawer open={open} toggleDrawerOpen={toggleDrawerOpen} />
      {isLoggingOut && <Loader />}
      <Box
        component="main"
        sx={{ flexGrow: 1, px: 2, backgroundColor: '#fafafa' }}
      >
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  )
}
