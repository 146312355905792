import React from 'react'
import { Box, Typography } from '@mui/material'

interface OwnProps {
  label: string
  value: string | React.ReactNode
}

const RetrievalContainerField = ({ label, value }: OwnProps) => {
  const renderFieldValue = () => {
    if (typeof value === 'string') return <Typography>{value}</Typography>

    return value
  }

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Typography fontWeight="bold">{label}:</Typography>
      {renderFieldValue()}
    </Box>
  )
}

export default RetrievalContainerField
