import { SortDirections } from '@firstbase/components/atoms/Table'
import get from 'lodash.get'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T | undefined) {
  if (!orderBy) return 0
  const valueA = get(a, orderBy)
  const valueB = get(b, orderBy)

  if (valueB < valueA) {
    return -1
  }
  if (valueB > valueA) {
    return 1
  }
  return 0
}

export function getComparator<Key extends keyof any>(
  order: SortDirections,
  orderBy: Key | undefined
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === SortDirections.desc
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}
