import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useReducer,
} from 'react'
import { AxiosError } from 'axios'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import Loader from '@firstbase/components/atoms/Loader'
import FullscreenModal from '@firstbase/components/molecules/FullscreenModal'
import request from '@firstbase/utils/request'
import { FormControl, Typography, Divider, TextField } from '@mui/material'
import { ClientResponse } from '@firstbase/types/Client'
import EditContractDetails from './ContractDetailsForm'
import EditCurrencies from './CurrenciesTableForm'
import EditManagementFees from './ManagementFeesTableForm/ManagementFeesTableForm'
import EditServiceFees from './ServiceFeesTableForm/ServiceFeesForm'
import {
  constructFeesByRegionByType,
  constructNewContract,
} from '@firstbase/utils/contractUtils'
import { NOT_SUPPORTED } from '@firstbase/constants/contract'
import SectionError from '@firstbase/components/atoms/SectionError'
import { CurrencyRow } from '@firstbase/types/Currency'
import { FeeBillingType, FeesRow, FeeType } from '@firstbase/types/Fee'
import { ContractResponse, Contract } from '@firstbase/types/Contract'
import { ClientPageParams } from '@firstbase/types/Pages'
import FLAGS from '@firstbase/constants/featureFlags'

import feesReducer, { FeeActionTypes } from './feesReducer'
import { getInitialContractDetails } from './getInitialContractDetails'

type OwnProps = {
  contract: ContractResponse
  isLoading?: boolean
  error?: AxiosError | null
  createNewContract?: boolean
  open: boolean
  handleClose: (hasCancelled?: boolean) => void
}

type PostFee = {
  regionCode: string
  currencyCode: string
  feeType: FeeType
  billingType: FeeBillingType
  price: number | string
}

type PostCurrency = {
  regionCode: string
  currency: string
}

export type PostContract = Contract & {
  orgId: string
  version: number
  fees: PostFee[]
  currencies: PostCurrency[]
}

const SettingsForm = ({
  contract,
  isLoading: isLoadingContract,
  error: contractError,
  open,
  createNewContract,
  handleClose,
}: OwnProps) => {
  const flags = useFlags()
  const discountFlag = flags[FLAGS.DISCOUNTS_MGMT]

  const [formContractDetails, setFormContractDetails] =
    useState<ContractResponse['contract']>()
  const [formCurrenciesByRegion, setFormCurrenciesByRegion] = useState<
    Record<string, string>
  >({})
  const [formFeesByCountryByType, dispatchFormFeesUpdate] = useReducer(
    feesReducer,
    constructFeesByRegionByType(contract)
  )

  const { clientId }: ClientPageParams = useParams()
  const {
    data: orgResponse,
    isLoading: isLoadingOrg,
    error: orgError,
  } = useQuery(['client', { clientId }], () =>
    request<ClientResponse>({ url: `/organizations/${clientId}` })
  )

  const defaultsToRegion = contract?.contract.defaultsToRegion!

  const hasFees =
    createNewContract ||
    isLoadingContract ||
    isLoadingOrg ||
    !!contract?.fees.length

  const isLoading = useMemo(
    () => isLoadingContract || isLoadingOrg,
    [, isLoadingContract, isLoadingOrg]
  )
  const isError = useMemo(
    () => contractError || orgError,
    [contractError, orgError]
  )

  const { isLoading: isSavingSettings, mutate: saveSettings } = useMutation(
    () =>
      request({
        method: 'post',
        url: '/contracts',
        data: constructNewContract(
          formContractDetails!,
          formCurrenciesByRegion,
          formFeesByCountryByType,
          clientId,
          discountFlag
        ),
      }),
    {
      onSuccess: () => handleClose(),
    }
  )

  const isPrepaidFieldVisible = () =>
    flags[FLAGS.SE_4562_USAGE_REPORT_AND_FEES_MVP] &&
    formContractDetails &&
    formContractDetails.version === 3

  const isPrepaidFieldValid = () => {
    if (!isPrepaidFieldVisible() || !formContractDetails) return true

    const { prepaidSeats } = formContractDetails

    if (prepaidSeats === null) return true

    return typeof prepaidSeats === 'number' && prepaidSeats >= 0
  }

  const isSaveDisabled = () => {
    const noRegionsWithCurrency = Object.values(formCurrenciesByRegion).every(
      (currency: string) => currency === NOT_SUPPORTED
    )

    const noMgmtFees =
      hasFees &&
      Object.values(formFeesByCountryByType)
        .flat()
        .every(
          (countryFees) =>
            !countryFees['Management fee'] ||
            countryFees['Management fee']?.price === NOT_SUPPORTED
        )

    return noRegionsWithCurrency || noMgmtFees || !isPrepaidFieldValid()
  }

  const continueProps = {
    label: 'Save',
    disabled: isSaveDisabled(),
    onClick: () => saveSettings(),
  }

  const currenciesByRegion: Record<string, string> = useMemo(() => {
    const currencies: Record<string, string> = {}
    if (!contract || !contract.currencies) return currencies

    contract.currencies.forEach((currency: CurrencyRow) => {
      currencies[currency.regionCode] = currency.currency
    })

    return currencies
  }, [contract])

  useEffect(() => {
    setFormCurrenciesByRegion(currenciesByRegion)
  }, [currenciesByRegion])

  useEffect(() => {
    if (contract && contract.contract) {
      setFormContractDetails(getInitialContractDetails(contract))
    }
  }, [contract])

  /**
   * Handle case for resetting prepaid seats
   * when switching to a contract version that is not 3
   */
  useEffect(() => {
    if (formContractDetails?.version) {
      setFormContractDetails((previousState) => {
        if (!previousState) return undefined

        return {
          ...previousState,
          prepaidSeats:
            formContractDetails.version === 3
              ? previousState.prepaidSeats
              : null,
        }
      })
    }
  }, [formContractDetails?.version])

  const regionIsSupported: (countryCode: string) => boolean = useCallback(
    (regionCode: string): boolean => {
      return !(
        !formCurrenciesByRegion[regionCode] ||
        formCurrenciesByRegion[regionCode] === NOT_SUPPORTED
      )
    },
    [formCurrenciesByRegion]
  )

  const getFeeForCountryCode: (
    countryCode: string,
    feeType: FeeType
  ) => number | string = useCallback(
    (countryCode: string, feeType: FeeType) => {
      const feesForCountry: Record<FeeType, FeesRow> =
        formFeesByCountryByType[countryCode]

      if (
        !feesForCountry ||
        !feesForCountry[feeType] ||
        !regionIsSupported(countryCode)
      )
        return NOT_SUPPORTED

      return feesForCountry[feeType].price
    },
    [formFeesByCountryByType, regionIsSupported]
  )

  const updateRegionCurrency = (regionCode: string, currency: string) => {
    setFormCurrenciesByRegion({
      ...formCurrenciesByRegion,
      [regionCode]: currency,
    })
  }

  const updateFeePrice = useCallback(
    (
      event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
      regionCode: string,
      feeType: FeeType,
      defaultValue?: any,
      currencyCode?: string
    ) => {
      // Note: This function is far too expensive to call onChange because of the deep copy of formFeesByCountryByType
      // This means it's best to use this with a combination of defaultValue, onBlur, and manual setting of event.target.value
      // This is done with awareness that these are somewhat antipatterns, but a necessary tradeoff - Jordan Bourne 2022-06-24
      const upToTwoDecimals = /^[0-9]*(\.[0-9]{0,})?$/

      if (
        event.target.value === '' ||
        upToTwoDecimals.test(event.target.value) ||
        event.target.value === NOT_SUPPORTED
      ) {
        const parsedValue =
          event.target.value === NOT_SUPPORTED || event.target.value === ''
            ? NOT_SUPPORTED
            : parseFloat(event.target.value).toFixed(2)
        event.target.value = parsedValue
        dispatchFormFeesUpdate({
          type: FeeActionTypes.UPDATE_FEE,
          payload: {
            regionCode: regionCode,
            feeType,
            price: parsedValue,
            overrideCurrencyCode: currencyCode,
            currencyCodeMap: formCurrenciesByRegion,
          },
        })
      } else {
        event.target.value = defaultValue
      }

      if (event.target.value === NOT_SUPPORTED) {
        event.target.value = ''
      }
    },
    [formCurrenciesByRegion]
  )

  const updateAllOverrideCurrencyCode = useCallback(
    (newCurrencyCode: string | undefined, billingType?: FeeBillingType) =>
      dispatchFormFeesUpdate({
        type: FeeActionTypes.OVERRIDE_FEES_CURRENCY,
        payload: {
          currencyCode: newCurrencyCode,
          billingType,
        },
      }),
    []
  )

  const updateServiceFeeDiscount = useCallback(
    (serviceFeeDiscount: number | null) => {
      setFormContractDetails((previousState) =>
        previousState
          ? {
              ...previousState,
              serviceFeeDiscount: serviceFeeDiscount
                ? {
                    percentageDiscount: serviceFeeDiscount,
                  }
                : null,
            }
          : previousState
      )
    },
    []
  )

  const handleModalCancel = useCallback(() => handleClose(true), [handleClose])

  const isDataError = !isLoading && (isError || !contract || !orgResponse)

  const { current: action } = useRef(createNewContract ? 'Create' : 'Edit')

  const renderPrepaidSeats = () => {
    if (!isPrepaidFieldVisible()) return null

    return (
      <TextField
        label="Max number of seats"
        size="small"
        type="number"
        value={
          formContractDetails?.prepaidSeats === null
            ? ''
            : formContractDetails?.prepaidSeats
        }
        onChange={(e) =>
          setFormContractDetails((previousState) =>
            previousState
              ? {
                  ...previousState,
                  prepaidSeats: e.target.value.trim()
                    ? parseInt(e.target.value)
                    : null,
                }
              : previousState
          )
        }
        error={!isPrepaidFieldValid()}
        helperText={
          !isPrepaidFieldValid() &&
          'Please enter a number equal to or greater than 0'
        }
        sx={{ width: '350px' }}
      />
    )
  }

  return (
    <FullscreenModal
      open={open}
      title={`${action} settings`}
      continueProps={continueProps}
      closeDisabled={isSavingSettings}
      handleClose={handleModalCancel}
    >
      {(isLoading || isSavingSettings) && <Loader />}
      {isDataError && (
        <SectionError error={(contractError || orgError) as Error} />
      )}
      {!isDataError && (
        <FormControl size="small" sx={{ minWidth: '100%' }}>
          <Typography sx={{ fontSize: '24px', marginBottom: '24px' }}>
            {action} {orgResponse?.name} settings
          </Typography>
          <EditContractDetails
            formContractDetails={formContractDetails}
            setFormContractDetails={setFormContractDetails}
            hasFees={hasFees}
            dispatchContractChange={(version: number) => {
              dispatchFormFeesUpdate({
                type: FeeActionTypes.UPDATE_CONTRACT_VERSION,
                payload: {
                  version,
                },
              })
            }}
          />
          <Divider sx={{ marginBottom: '36px' }} />
          <Typography sx={{ fontSize: '24px', marginBottom: '24px' }}>
            Pricing
          </Typography>
          <EditCurrencies
            formCurrenciesByRegion={formCurrenciesByRegion}
            updateRegionCurrency={updateRegionCurrency}
          />
          {hasFees && (
            <>
              <EditManagementFees
                formCurrenciesByCountry={formCurrenciesByRegion}
                getFeeForRegionCode={getFeeForCountryCode}
                updateFeePrice={updateFeePrice}
                regionIsSupported={regionIsSupported}
                formFeesByRegionByType={formFeesByCountryByType}
                defaultsToRegion={defaultsToRegion}
                updateAllOverrideCurrencyCode={updateAllOverrideCurrencyCode}
                renderPrepaidSeats={renderPrepaidSeats}
              />
              <EditServiceFees
                formCurrenciesByRegion={formCurrenciesByRegion}
                getFeeForRegionCode={getFeeForCountryCode}
                updateFeePrice={updateFeePrice}
                regionIsSupported={regionIsSupported}
                formFeesByRegionByType={formFeesByCountryByType}
                contractVersion={formContractDetails?.version}
                updateAllOverrideCurrencyCode={updateAllOverrideCurrencyCode}
                serviceFeeDiscount={
                  formContractDetails?.serviceFeeDiscount?.percentageDiscount ??
                  null
                }
                updateServiceFeeDiscount={updateServiceFeeDiscount}
              />
            </>
          )}
        </FormControl>
      )}
    </FullscreenModal>
  )
}

export default SettingsForm
