import React from 'react'
import { useMutation } from '@apollo/client'
import {
  TextField,
  Button,
  Stack,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useForm, Controller } from 'react-hook-form'

import {
  EXPORT_CHAT_DATA,
  exportChatData as exportChatDataTypes,
  exportChatDataVariables,
} from '@firstbase/data/Assistant'

import { downloadFile } from './downloadFile'

interface ExportChatFormData {
  organizationId: string
  format: 'csv' | 'jsonl'
}

export const ExportChats = () => {
  const { control, handleSubmit } = useForm<ExportChatFormData>({
    defaultValues: {
      organizationId: '',
      format: 'csv',
    },
  })

  const { enqueueSnackbar } = useSnackbar()

  const [exportChatMutation, { loading }] = useMutation<
    exportChatDataTypes,
    exportChatDataVariables
  >(EXPORT_CHAT_DATA, {
    onCompleted: (data) => {
      const { fileUrl } = data.exportChatData

      if (!fileUrl)
        return enqueueSnackbar('No file URL found', { variant: 'error' })

      return downloadFile(fileUrl)
    },
    onError: () =>
      enqueueSnackbar('The export process failed', { variant: 'error' }),
  })

  const handleExportChat = ({ organizationId, format }: ExportChatFormData) => {
    exportChatMutation({ variables: { organizationId, format } })
  }

  return (
    <Stack
      spacing={2}
      sx={{ padding: '1rem', maxWidth: '400px' }}
      component="form"
      onSubmit={handleSubmit(handleExportChat)}
    >
      <Controller
        name="organizationId"
        control={control}
        render={({ field }) => (
          <TextField
            label="Organization ID (optional)"
            variant="outlined"
            fullWidth
            {...field}
          />
        )}
      />
      <Controller
        name="format"
        control={control}
        render={({ field }) => (
          <FormControl fullWidth>
            <InputLabel id="format-label">Select Export Format</InputLabel>
            <Select
              labelId="format-label"
              label="Select Export Format"
              {...field}
            >
              <MenuItem value="csv">CSV</MenuItem>
              <MenuItem value="jsonl">JSONL</MenuItem>
            </Select>
          </FormControl>
        )}
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        type="submit"
        disabled={loading}
      >
        Export Data
      </Button>
    </Stack>
  )
}
