import React from 'react'
import { Button, FormHelperText } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useMutation } from '@apollo/client'
import { SKUStatus } from '@globalTypes'
import { UPDATE_GLOBAL_SKU } from '@firstbase/data/SKU/mutations'
import FLAGS from '@firstbase/constants/featureFlags'
import { useFeatureFlag } from '@firstbase/hooks/useFeatureFlag'
import { SKU } from '@firstbase/types/Product'

interface OwnProps {
  isCreate: boolean
  product?: SKU
  handleClose: () => void
}

const ArchieveButton = ({ isCreate, product, handleClose }: OwnProps) => {
  const [updateSku] = useMutation(UPDATE_GLOBAL_SKU)

  const showRemoveGlobalProduct = useFeatureFlag(
    FLAGS.SE_3065_REMOVE_GLOBAL_PRODUCT
  )
  const { enqueueSnackbar } = useSnackbar()

  const handleArchive = () => {
    updateSku({
      variables: {
        skuId: product?.id,
        updateSKU: {
          status: SKUStatus.ARCHIVED,
        },
      },
    })
      .then(() => {
        enqueueSnackbar('Product successfully archived', { variant: 'success' })
        handleClose()
      })
      .catch((e) => enqueueSnackbar(e.message, { variant: 'error' }))
  }

  if (!showRemoveGlobalProduct || isCreate) return null

  return (
    <>
      <Button variant="outlined" sx={{ marginTop: 3 }} onClick={handleArchive}>
        Archive product
      </Button>
      <FormHelperText>
        Will not archive if there are sku assignments attached
      </FormHelperText>
    </>
  )
}

export default ArchieveButton
