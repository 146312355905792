import React, { useState } from 'react'
import { FetchResult, useMutation } from '@apollo/client'

import UploadStep from '../BulkUpload/UploadStep'
import BulkUpload from '../BulkUpload/BulkUpload'
import { CREATE_INVENTORY } from '@firstbase/data/Inventory/mutations'
import {
  createInventoryVariables,
  createInventory as createInventoryResponse,
} from '@firstbase/data/Inventory/__generated__/CreateInventory'
import parseInventory from './parseInventory'

const BulkUploadInventory = () => {
  const [data, setData] = useState<any>([])
  const [createInventory] = useMutation<
    createInventoryResponse,
    createInventoryVariables
  >(CREATE_INVENTORY)

  const constructSuccessMessage = (r: FetchResult<createInventoryResponse>) => {
    const createResponse = r?.data?.createInventory
    if (!createResponse) return 'Error'

    return `Successfully added ${createResponse.sku.id}`
  }

  const handleUploadInventory = (
    inventoryRow: createInventoryVariables['createInventory'],
    logStatus: React.Dispatch<any>
  ) => {
    createInventory({
      variables: { createInventory: inventoryRow },
    })
      .then((r: FetchResult<createInventoryResponse>) => {
        logStatus({
          type: 'success',
          message: constructSuccessMessage(r),
        })
      })
      .catch((error: any) => {
        logStatus({
          type: 'error',
          message: error.message,
        })
      })
  }

  return (
    <BulkUpload
      addMessage={`Add ${data.length} inventory item${
        data.length > 1 ? 's' : ''
      }`}
      handleCreateRow={handleUploadInventory}
      data={data}
      continueDisabled={!data}
      uploadTitle="Upload inventory"
    >
      <UploadStep
        dataParser={parseInventory}
        setData={setData}
        uploadTitle="Bulk upload inventory"
        uploadSubtitle={
          <>
            To get started, click{' '}
            <a
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              href="https://docs.google.com/spreadsheets/d/1hCpB_wUtBF-7r-UUFCkObb0JkynrLW7lMDl4ItqLHlw/edit#gid=0"
              target="_blank"
              rel="noreferrer noopener"
            >
              here
            </a>{' '}
            to access the inventory bulk upload template and follow the provided
            instructions.
          </>
        }
      />
    </BulkUpload>
  )
}

export default BulkUploadInventory
