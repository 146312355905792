import React from 'react'
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import capitalize from 'lodash/capitalize'

import { PercentageInput } from '@firstbase/components/atoms/PercentageInput'
import { DiscountCategoryGroup, ProductPriceBillingType } from '@globalTypes'

import {
  GetHardwareDiscountValue,
  UpdateHardwareDiscountValue,
} from './useHardwareDiscountForm'

interface OwnProps {
  getValue: GetHardwareDiscountValue
  updateValue: UpdateHardwareDiscountValue
}

export const HardwareDiscountTable = ({ getValue, updateValue }: OwnProps) => (
  <>
    <Typography variant="caption" sx={{ marginBottom: 0.25 }}>
      <b>Note:</b> Computer includes tablets and mobile phones, too.
    </Typography>
    <Card variant="outlined" sx={{ maxWidth: '600px', marginBottom: 4 }}>
      <TableContainer>
        <Table aria-label="hardware discount table">
          <TableHeader />
          <TableBody>
            {Object.entries(DiscountCategoryGroup).map(([_, groupName]) => (
              <TableRow key={groupName}>
                <TableCell>
                  <Typography variant="inherit">
                    {capitalize(groupName)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <PercentageInput
                    value={
                      getValue(groupName, ProductPriceBillingType.PURCHASED) ??
                      ''
                    }
                    onChange={(value) =>
                      updateValue(
                        groupName,
                        ProductPriceBillingType.PURCHASED,
                        value
                      )
                    }
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <PercentageInput
                    value={
                      getValue(
                        groupName,
                        ProductPriceBillingType.SUBSCRIPTION
                      ) ?? ''
                    }
                    onChange={(value) =>
                      updateValue(
                        groupName,
                        ProductPriceBillingType.SUBSCRIPTION,
                        value
                      )
                    }
                    fullWidth
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  </>
)

const TableHeader = () => (
  <TableHead>
    <TableRow>
      <TableCell>Category</TableCell>
      <TableCell>Purchase</TableCell>
      <TableCell>Subscription</TableCell>
    </TableRow>
  </TableHead>
)
