import { Box, IconButton, TablePaginationProps } from '@mui/material'
import React from 'react'

import { ReactComponent as ChevronRight } from '@firstbase/assets/ChevronRight.svg'
import { ReactComponent as ChevronLeft } from '@firstbase/assets/ChevronLeft.svg'
import { ReactComponent as DoubleChevronRight } from '@firstbase/assets/DoubleChevronRight.svg'
import { ReactComponent as DoubleChevronLeft } from '@firstbase/assets/DoubleChevronLeft.svg'

type MouseEventType = React.MouseEvent<HTMLButtonElement>

function TablePaginationActions({
  count,
  page,
  rowsPerPage,
  onPageChange,
}: TablePaginationProps) {
  const handleFirstPageButtonClick: (event: MouseEventType) => void = (event) =>
    onPageChange(event, 0)

  const handleBackButtonClick: (event: MouseEventType) => void = (event) =>
    onPageChange(event, page - 1)

  const handleNextButtonClick: (event: MouseEventType) => void = (event) =>
    onPageChange(event, page + 1)

  const handleLastPageButtonClick: (event: MouseEventType) => void = (event) =>
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))

  const getIconProps: (disabledCondition: boolean) => any = (
    disableCondition
  ) => ({
    fill: '#191A1B',
    width: '1.5rem',
    height: '1.5rem',
    opacity: disableCondition ? 0.26 : 0.54,
  })

  const isBackDisabled = page === 0
  const isForwardDisabled = page >= Math.ceil(count / rowsPerPage) - 1

  return (
    <Box sx={{ flexShrink: 0, paddingLeft: 2, paddingRight: 0.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={isBackDisabled}
        aria-label="first page"
      >
        <DoubleChevronLeft {...getIconProps(isBackDisabled)} />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={isBackDisabled}
        aria-label="previous page"
      >
        <ChevronLeft {...getIconProps(isBackDisabled)} />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={isForwardDisabled}
        aria-label="next page"
      >
        <ChevronRight {...getIconProps(isForwardDisabled)} />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={isForwardDisabled}
        aria-label="last page"
      >
        <DoubleChevronRight {...getIconProps(isForwardDisabled)} />
      </IconButton>
    </Box>
  )
}

export default TablePaginationActions
