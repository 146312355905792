import { gql } from '@apollo/client'

export const CREATE_REPLACEMENT = gql`
  mutation createReplacement(
    $createReplacement: CreateReplacement!
    $personSlug: String!
    $organizationSlug: String!
  ) {
    createReplacementForOrganization(
      createReplacement: $createReplacement
      personSlug: $personSlug
      organizationSlug: $organizationSlug
    ) {
      id
    }
  }
`
