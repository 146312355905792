import { gql } from '@apollo/client'
import { ORDER } from './fragment'

export const GET_ORDER_RETURN_BY_ID = gql`
  ${ORDER}
  query getOrderByIdForOrganisation($orderId: ID!, $orgId: ID!) {
    getOrderByIdForOrganisation(id: $orderId, orgId: $orgId) {
      ...ORDER
      person {
        slug
        forename
        surname
      }
    }
  }
`

export const GET_REPLACEMENT_BY_ID = gql`
  ${ORDER}
  query getReplacementByIdForOrganization($replacementId: ID!, $orgId: ID!) {
    getReplacementByIdForOrganization(id: $replacementId, orgId: $orgId) {
      id
      order {
        ...ORDER
      }
      returnOrder {
        ...ORDER
      }
      createdAt
      status
      person {
        slug
        forename
        surname
      }
    }
  }
`
