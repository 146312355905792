import React from 'react'
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { TextField, Switch, Stack, FormControlLabel } from '@mui/material'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'

import {
  CREATE_WARRANTY_TYPE,
  createWarrantyType,
  createWarrantyTypeVariables,
} from '@firstbase/data/Warranties'
import Page from '@firstbase/components/atoms/Page'
import FullscreenModal from '@firstbase/components/molecules/FullscreenModal'

import { BrandSelect } from './BrandSelect'

interface Form {
  active: boolean
  name: string
  vendorId: string
}

export const CreateWarrantyType = () => {
  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()
  const closeModal = () => history.replace('/platform/warranties')

  const { control, handleSubmit, watch } = useForm<Form>({
    defaultValues: {
      name: '',
      active: true,
      vendorId: '',
    },
  })

  const [createWarrantyTypeMutation, { loading: submittingForm }] = useMutation<
    createWarrantyType,
    createWarrantyTypeVariables
  >(CREATE_WARRANTY_TYPE, {
    onCompleted: () => {
      enqueueSnackbar('Warranty type has been successfully created')
      closeModal()
    },
    onError: () =>
      enqueueSnackbar(
        'Oops! Looks like there was an error when creating the type. Please share this with the team.',
        { variant: 'error' }
      ),
  })

  const nameValue = watch('name')
  const vendorValue = watch('vendorId')

  const onSubmit = (fields: Form) => {
    createWarrantyTypeMutation({
      variables: {
        request: fields,
      },
    })
  }

  return (
    <FullscreenModal
      title="Add new warranty type"
      open={true}
      handleClose={closeModal}
      continueProps={{
        disabled: !nameValue || !vendorValue,
        loading: submittingForm,
        color: 'primary',
        variant: 'contained',
        label: 'Save',
        onClick: handleSubmit(onSubmit),
      }}
    >
      <Page.TitleSection sectionTitle="Create new warranty type" />
      <Stack sx={{ width: '400px', padding: '0 1rem' }} spacing={2}>
        <Controller
          name="active"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                // Disabled for M1
                <Switch
                  onChange={field.onChange}
                  checked={field.value}
                  disabled
                />
              }
              label="Active Warranty Type"
            />
          )}
        />
        <Controller
          name="vendorId"
          control={control}
          render={({ field }) => <BrandSelect field={field} />}
        />
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              placeholder="AppleCare+"
              {...field}
            />
          )}
        />
      </Stack>
    </FullscreenModal>
  )
}
