import cliEnvironmentVariables from './cliEnvironmentVariables'
import { environmentVariableJsonUrl } from './constants'
import { EnvironmentVariablesI } from './types'

/**
 * Wrapper for getting the environment variables.  The algorithm
 * for getting the environment variables assumes that the CLI
 * environment variables exist.  Then, if the JSON file is
 * successfully fetched, the values in that file overwrite
 * the CLI configuration.  This is a temporary implementation,
 * one to roll out the runtime environment variables safely.
 * Given the risk with updating the app's loading process,
 * if something goes wrong, the app will just use the cli
 * env vars.  Once this is rolled out and our users are
 * successfully using runtime environment variables, we could
 * get rid of the CLI environment variables.
 */
const getEnvironmentVariables: () => Promise<EnvironmentVariablesI> =
  async () => {
    try {
      const response = await fetch(environmentVariableJsonUrl, {
        method: 'GET',
        headers: {
          'cache-control': 'no-cache',
        },
      })
      const jsonEnvironmentVariables = response.ok ? await response.json() : {}
      return { ...cliEnvironmentVariables, ...jsonEnvironmentVariables }
    } catch {
      return cliEnvironmentVariables
    }
  }

export default getEnvironmentVariables
