import { gql } from '@apollo/client'

export const GET_ALL_API_TOKENS_FOR_ORGANIZATION = gql`
  query getAllApiTokensForOrganization($organizationId: ID!) {
    getAllTokensForOrganization(organizationId: $organizationId) {
      name
      expiration
      created
      createdByFirstbase
    }
  }
`

export const CREATE_API_TOKEN_FOR_ORGANIZATION = gql`
  mutation createApiTokenForOrg($tokenName: String!, $organisationId: ID!) {
    createApiTokenForOrg(name: $tokenName, organizationId: $organisationId) {
      token
      name
    }
  }
`

export const DELETE_API_TOKEN_FOR_ORGANIZATION = gql`
  mutation deleteApiTokenForOrg($tokenName: String!, $organizationId: ID!) {
    deleteApiTokenForOrg(name: $tokenName, organizationId: $organizationId)
  }
`
