import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { theme } from '@firstbase/muiTheme'
import axios from 'axios'

import AuthProvider from '@firstbase/context/auth'
import client from '@firstbase/config/apollo/client'

import Home from '@views/Home'

import queryClientOptions from '@firstbase/config/queryClient'
import { ApolloProvider } from '@apollo/client'
import { useOktaAuth } from '@okta/okta-react'
import { SnackbarProvider } from 'notistack'

import { ReactComponent as Success } from '@firstbase/assets/Success.svg'
import { useAuthState } from '@firstbase/context/auth/AuthProvider'
import Loader from '@firstbase/components/atoms/Loader'
import { pricingAxiosClient, graphqlAxiosClient } from '@utils/axiosClients'
import environmentVariables from '@utils/environmentVariables'

import NewYear from './NewYear'
import { LDMultiKindContext } from 'launchdarkly-js-sdk-common'
import isEndToEndTest from '@firstbase/utils/isEndToEndTest/isEndToEndTest'
import datadogUserSession from '@firstbase/config/datadogUserSession'

const queryClient = new QueryClient(queryClientOptions)

function AuthApp() {
  const { oktaAuth } = useOktaAuth()
  const { authState } = useAuthState()
  const [ldReady, setLdReady] = useState(false)
  const [axiosClientsReady, setAxiosClientsReady] = useState(false)
  const ldClient = useLDClient()

  useEffect(() => {
    if (!ldReady && authState) {
      const ldContext: LDMultiKindContext = {
        kind: 'multi',
        user: {
          kind: 'user',
          key: authState?.idToken?.claims.personId || '',
          org: authState?.idToken?.claims.org || '',
        },
        organization: {
          kind: 'organization',
          key: authState?.idToken?.claims.org || '',
        },
      }

      ldClient?.identify(ldContext).then(() => setLdReady(true))
    }
    if (authState?.idToken?.claims && !isEndToEndTest()) {
      datadogUserSession(authState?.idToken?.claims)
    }
  }, [authState, ldClient, ldReady])

  useEffect(() => {
    /**
     * HACK: In some cases, the app unmounts and mounts again --
     * hence the check to see if instance has been set.
     */
    const accessToken = oktaAuth.getAccessToken()
    if (accessToken && !pricingAxiosClient.hasBeenSet()) {
      pricingAxiosClient.set(() =>
        axios.create({
          baseURL: environmentVariables.get().VITE_PRICING_API_URL,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      )
    }
    if (accessToken && !graphqlAxiosClient.hasBeenSet()) {
      graphqlAxiosClient.set(() =>
        axios.create({
          baseURL: environmentVariables.get().VITE_API_URI,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      )
    }
    setAxiosClientsReady(true)
  }, [oktaAuth])

  if (!ldReady || !axiosClientsReady) return <Loader />

  return (
    <SnackbarProvider
      maxSnack={3}
      iconVariant={{
        default: (
          <Success
            fill="#000"
            fillOpacity="1"
            style={{ marginRight: '1rem' }}
          />
        ),
      }}
      style={{
        minWidth: '50vw',
        maxWidth: '75vw',
        backgroundColor: theme.palette.secondary.main,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={client(oktaAuth)}>
          <ThemeProvider theme={theme}>
            <NewYear />
            <Home />
          </ThemeProvider>
        </ApolloProvider>
      </QueryClientProvider>
    </SnackbarProvider>
  )
}

const App = () => (
  <AuthProvider>
    <AuthApp />
  </AuthProvider>
)

export default App
