import { useLazyQuery } from '@apollo/client'
import { useQuery } from 'react-query'
import request from '@firstbase/utils/request'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GET_PERSON_BY_ORGANIZATION_SLUG_AND_PERSON_SLUG } from '@firstbase/data/Person/queries'
import Page from '@firstbase/components/atoms/Page'
import TabGroup from '@firstbase/components/molecules/TabGroup'
import { useBreadcrumbs } from '@firstbase/context/breadcrumbs/BreadcrumbsProvider'
import Overview from './Overview'
import { getPersonBySlugAndOrganizationSlug } from '@firstbase/data/Person/__generated__/getPersonBySlugAndOrganizationSlug'
import Orders from './Orders'
import PersonInventory from './Inventory/PersonInventory'
import Package from './Package'
import Button from '@mui/material/Button'
import EditPerson from './EditPerson/EditPerson'
import { Box } from '@mui/material'
import ReactivatePerson from './ReactivatePerson/ReactivatePerson'
import ProtectedChildren from '@firstbase/components/atoms/ProtectedChildren'
import { Role } from '@firstbase/constants/roles'
import { PersonPageParams } from '@firstbase/types/Pages'

import Christmas from './Christmas'
import DeletePerson from './DeletePerson'
import DeactivatePerson from './DeactivatePerson'

const Person = () => {
  const { setBreadcrumbs } = useBreadcrumbs()
  const { clientId, personSlug }: PersonPageParams = useParams()
  const [editPersonOpen, setEditPersonOpen] = useState<number>(0)
  const { isLoading: loadingClient, data: client } = useQuery(
    ['client', { clientId }],
    () => request<any>({ url: `/organizations/${clientId}` })
  )
  const [getPerson, { loading: loadingPerson, data }] =
    useLazyQuery<getPersonBySlugAndOrganizationSlug>(
      GET_PERSON_BY_ORGANIZATION_SLUG_AND_PERSON_SLUG,
      {
        variables: {
          slug: personSlug,
          organizationSlug: client?.slug,
        },
      }
    )

  useEffect(() => {
    if (client) getPerson()
  }, [client, getPerson])

  const person = data?.getPersonBySlugAndOrganizationSlug

  const personName = person
    ? `${person?.forename} ${person?.surname}`
    : undefined

  useEffect(
    () =>
      setBreadcrumbs([
        { label: 'Clients', pathId: 'clients' },
        {
          isLoading: loadingClient,
          label: client?.name,
          pathId: 'client',
          searchParams: { tab: 'people' },
        },
        { isLoading: loadingPerson, label: personName },
      ]),
    [client?.name, loadingClient, loadingPerson, personName, setBreadcrumbs]
  )

  const renderPage = () => {
    if (!person) return null

    return (
      <TabGroup queryParam>
        <TabGroup.Page id="overview" label="Overview">
          <Overview person={person} />
        </TabGroup.Page>
        <TabGroup.Page id="orders" label="Orders">
          <Orders clientSlug={client.slug} personName={person.forename!} />
        </TabGroup.Page>
        <TabGroup.Page id="inventory" label="Assigned inventory">
          <PersonInventory inventories={person?.inventories || []} />
        </TabGroup.Page>
        <TabGroup.Page id="package" label="Assigned package">
          <Package packages={person.packages} personName={person.forename!} />
        </TabGroup.Page>
      </TabGroup>
    )
  }

  const loading = (loadingClient || loadingPerson) && !person

  const renderActions = () => (
    <Box sx={{ justifyContent: 'flex-end', display: 'flex', gap: 2 }}>
      <Christmas personId={person?.id} personName={person?.forename} />
      <ProtectedChildren hasRole={[Role.CX, Role.Admin]}>
        <DeletePerson person={person} redirect={`/client/${clientId}`} />
        <DeactivatePerson person={person} />
        <ReactivatePerson loading={loading} person={person} />
        <Button
          disabled={loading || !person}
          variant="outlined"
          color="secondary"
          onClick={() => setEditPersonOpen(Math.random() + 1)}
        >
          Edit person
        </Button>
      </ProtectedChildren>
    </Box>
  )

  return (
    <>
      <Page
        breadcrumb
        isLoading={loading}
        title={personName}
        subtitle={`ID: ${person?.id}`}
        titleAction={renderActions}
      >
        {renderPage()}
      </Page>
      {person && (
        <EditPerson
          open={!!editPersonOpen}
          key={editPersonOpen}
          person={person}
          handleClose={() => setEditPersonOpen(0)}
        />
      )}
    </>
  )
}

export default Person
