import { gql } from '@apollo/client'

export const GET_ALL_INVENTORY_ORDERS = gql`
  query getInventoryOrders($pageNumber: Int!, $pageSize: Int!, $sort: [Sort]) {
    getInventoryOrdersPaginated(
      pagingAndSorting: {
        pageNumber: $pageNumber
        pageSize: $pageSize
        sort: $sort
      }
    ) {
      size
      pageNumber
      totalElements
      totalPages
      data {
        id
        status
        quantity
        createdAt
        updatedAt
        organization {
          id
          name
        }
        sku {
          id
          skuInformation {
            productTitle
          }
          vendor {
            code
          }
          category {
            code
          }
        }
        warehouse {
          name
        }
      }
    }
  }
`
