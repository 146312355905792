import React from 'react'

import Page from '@atoms/Page'
import Table, { SortDirections } from '@firstbase/components/atoms/Table'

import { GET_ALL_CATEGORIES } from '@firstbase/data/Category/queries'

import useTableGraphqlQuery from '@firstbase/hooks/useTableGraphqlQuery'
import { getAllCategories as getAllCategoriesType } from '@firstbase/data/Category/__generated__/getAllCategories'
import { ColumnI } from '@firstbase/components/atoms/Table/types'
import { cellComponents } from '@firstbase/components/atoms/Table/cellComponents/cellComponents'
import { sentenceCase } from 'change-case'

const columns = [
  {
    header: ' ID',
    id: '',
    cell: { as: cellComponents.ID, value: ({ id }) => id },
  },
  {
    header: 'Name',
    defaultSort: SortDirections.asc,
    id: 'nameSingular',
    cell: {
      value: ({ nameSingular }) => nameSingular,
    },
  },
  {
    header: 'Code',
    id: 'code',
    cell: {
      value: ({ code }) => code,
    },
  },
  {
    header: 'Type',
    id: 'skuType',
    cell: {
      value: ({ skuType }) => sentenceCase(skuType),
    },
  },
] as ColumnI[]

const Categories = () => {
  const query = useTableGraphqlQuery<getAllCategoriesType, any>(
    GET_ALL_CATEGORIES,
    {},
    ({ getAllCategories }) => getAllCategories
  )

  return (
    <>
      <Page.TitleSection
        sectionTitle="Categories"
        sectionSubtitle="List of categories that are available on the platform"
      />
      <Table
        query={query}
        tableId="categories"
        columns={columns}
        pagination
        customRowsPerPageOptions={[100, 200, 500]}
      />
    </>
  )
}

export default Categories
