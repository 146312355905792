import { gql } from '@apollo/client'

export const SEND_MESSAGE = gql`
  mutation sendMessage(
    $message: String!
    $chatId: ID!
    $personId: String!
    $organizationId: String!
  ) {
    sendMessage(
      input: {
        content: $message
        personId: $personId
        chatId: $chatId
        organizationId: $organizationId
      }
    ) {
      message {
        id
        chatId
        personId
        content
      }
      response {
        id
        chatId
        personId
        content
      }
    }
  }
`

export const CREATE_CHAT = gql`
  mutation createChat($personId: String!, $organizationId: String!) {
    createChat(
      input: { personId: $personId, title: "", organizationId: $organizationId }
    ) {
      id
      title
      referenceId
      messages {
        id
        content
      }
    }
  }
`

export const DELETE_DOCUMENT = gql`
  mutation deleteDocument($id: ID!) {
    deleteDocument(input: { id: $id }) {
      success
      errors
    }
  }
`

export const EXPORT_CHAT_DATA = gql`
  mutation exportChatData($organizationId: ID, $format: String) {
    exportChatData(
      input: { organizationId: $organizationId, format: $format }
    ) {
      id
      fileUrl
    }
  }
`
