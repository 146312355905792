import { ContractResponse, Contract } from '@firstbase/types/Contract'

export const getInitialContractDetails = (
  contract: ContractResponse
): Contract => ({
  ...contract.contract,
  serviceFeeDiscount: contract.serviceFeeDiscount?.percentageDiscount
    ? {
        percentageDiscount: contract.serviceFeeDiscount.percentageDiscount,
      }
    : null,
})
