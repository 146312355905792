import React, { useEffect, useState } from 'react'
import { Divider } from '@mui/material'
import { useMutation } from 'react-query'

import FullscreenModal from '@molecules/FullscreenModal'
import { BillingType, PricingRow, ProductResponse } from '../Product'
import request from '@utils/request'
import Loader from '@firstbase/components/atoms/Loader'
import SectionError from '@firstbase/components/atoms/SectionError'
import EditPricing from './EditPricing'
import MinInventoryForm from '../MinInventoryForm'
import { GET_MINIMUM_INVENTORY_BY_ORG_ID_SKU_ID } from '@firstbase/data/MinimumInventory/queries'
import { useQuery, useMutation as useApolloMutation } from '@apollo/client'
import { UPDATE_SKU_ASSIGNMENT_BY_ORG_BY_SKU } from '@firstbase/data/SKU/mutations'
import {
  getSKUMinimumInventoryForOrganization,
  getSKUMinimumInventoryForOrganizationVariables,
} from '@firstbase/data/MinimumInventory/__generated__/getSKUMinimumInventoryForOrganization'
import {
  updateSKUAssignmentByOrgIdSkuId,
  updateSKUAssignmentByOrgIdSkuIdVariables,
} from '@firstbase/data/SKU/__generated__/updateSKUAssignmentByOrgIdSkuId'
import ProductSettingsTitleSection from '../ProductSettingsTitleSection'

import { MinimumInventoryWithUpdatedQuantity } from '../productTypes'
import {
  filterOutEmptyMinimumInventories,
  doesEmptyMinimumInventoryExist,
} from '../productUtils'

type OwnProps = {
  open: boolean
  handleClose: (newPrices: PriceData, billType: BillingType) => void
  product: ProductResponse
  subtitle?: string
  allowSubscription: boolean
}

export type PriceData = (PricingRow & { isDirty?: boolean })[]

function EditProduct({
  open,
  handleClose,
  product,
  allowSubscription,
}: OwnProps) {
  const { orgName, productName } = product
  const [billType, setBillType] = useState<BillingType>('Subscription')
  const [priceData, setPriceData] = useState<PriceData>(product.prices)
  const [minimumInventory, setMinInventory] = useState<
    MinimumInventoryWithUpdatedQuantity[] | null
  >(null)
  const [firstbaseSupplied, setFirstbaseSupplied] = useState(
    product.firstbaseSupplied !== false
  )

  useEffect(
    () => setBillType(product.prices[0]?.billingType || 'Subscription'),
    [product.prices]
  )

  useEffect(
    () => setFirstbaseSupplied(product.firstbaseSupplied !== false),
    [product.firstbaseSupplied]
  )

  const {
    isLoading: isSaving,
    isError: savePricesError,
    mutate: savePrices,
    error,
  } = useMutation(
    (prices: Omit<PricingRow, 'id'>[]) =>
      request({
        method: 'post',
        url: '/prices',
        data: { prices },
      }),
    {
      onSuccess: () => handleClose(priceData, billType),
    }
  )
  const {
    loading,
    data: minimumInventoryResponse,
    error: minimumInventoryGetError,
  } = useQuery<
    getSKUMinimumInventoryForOrganization,
    getSKUMinimumInventoryForOrganizationVariables
  >(GET_MINIMUM_INVENTORY_BY_ORG_ID_SKU_ID, {
    variables: {
      orgId: product.orgId,
      skuId: product.skuId,
    },
  })

  useEffect(() => {
    setPriceData(product.prices)
  }, [product.prices])

  const [
    updateProduct,
    { loading: isUpdatingProduct, error: updateProductError },
  ] = useApolloMutation<
    updateSKUAssignmentByOrgIdSkuId,
    updateSKUAssignmentByOrgIdSkuIdVariables
  >(UPDATE_SKU_ASSIGNMENT_BY_ORG_BY_SKU, {
    refetchQueries: [
      'getSKUMinimumInventoryForOrganization',
      'getOrganizationById',
    ],
  })

  const handleSave = async () => {
    const newPrices = priceData
      .filter(({ isDirty }) => isDirty)
      .map(({ orgId, skuId, price, currency, regionCode, billingType }) => ({
        orgId,
        skuId,
        price,
        currency,
        regionCode: regionCode,
        billingType,
      }))

    savePrices(newPrices)

    await updateProduct({
      variables: {
        orgId: product.orgId,
        skuId: product.skuId,
        updateSKUAssignment: {
          firstbaseSupplied,
          minimums: filterOutEmptyMinimumInventories(minimumInventory || []),
        },
      },
    })
  }

  const org = { id: product.orgId, name: product.orgName }

  // check if not false, since null is valid/default input, which should be treated as fb supplied
  const disabled =
    isSaving ||
    isUpdatingProduct ||
    doesEmptyMinimumInventoryExist(minimumInventory || [])

  return (
    <FullscreenModal
      open={open}
      handleClose={handleClose}
      closeDisabled={isSaving}
      title={`Edit product details for ${orgName}`}
      continueProps={{
        disabled,
        label: 'Save',
        onClick: handleSave as any,
      }}
    >
      <ProductSettingsTitleSection
        productName={product.productName}
        organizationName={orgName}
      />
      <EditPricing
        billType={billType}
        setBillType={setBillType}
        priceData={priceData}
        setPriceData={setPriceData}
        firstbaseSupplied={firstbaseSupplied}
        setFirstbaseSupplied={setFirstbaseSupplied}
        allowSubscription={allowSubscription}
      />
      <Divider sx={{ my: 4 }} />
      <MinInventoryForm
        skuName={productName}
        org={org}
        isLoading={loading}
        error={minimumInventoryGetError}
        initialMinimumInventory={
          minimumInventoryResponse?.getSKUMinimumInventoryForOrganization
            .minimumInventory
        }
        setMinInventory={setMinInventory}
        firstbaseSupplied={firstbaseSupplied}
      />
      {(savePricesError || updateProductError) && (
        <SectionError
          sx={{ mt: 2 }}
          title="Unable to update product"
          error={(error || updateProductError) as Error}
        />
      )}
      {isSaving && <Loader />}
    </FullscreenModal>
  )
}

export default EditProduct
