import { useCallback, useEffect, useState } from 'react'

import { MetadataItem, MetadataOption } from '../types'
import { getAllSKUMetadataOptionValues_getAllSKUMetadataOptionValues } from '@firstbase/data/SKU/__generated__/getAllSKUMetadataOptionValues'

export type HandleMetadataChange = (
  field: getAllSKUMetadataOptionValues_getAllSKUMetadataOptionValues,
  newValue: MetadataOption | string | null
) => void

export const useMetadataItems = (initialMetadata: MetadataItem[]) => {
  const [metadata, setMetadata] = useState(initialMetadata)

  useEffect(() => {
    if (initialMetadata.length > 0) {
      setMetadata(initialMetadata)
    }
  }, [initialMetadata])

  const setToInitialMetadata = () => setMetadata(initialMetadata)

  const updateMetadata = (
    field: getAllSKUMetadataOptionValues_getAllSKUMetadataOptionValues,
    newValue: MetadataOption | null
  ) => {
    if (!newValue) {
      return metadata.filter((meta) => meta.fieldId !== field.id)
    }

    /**
     * Update field with value
     */
    const fieldExists = metadata.some((meta) => meta.fieldId === field.id)

    if (fieldExists) {
      return metadata.map((meta) =>
        meta.fieldId === field.id
          ? { ...meta, value: newValue.label, valueId: newValue.id }
          : meta
      )
    }

    /**
     * Add field selection to state
     */

    return [
      ...metadata,
      {
        fieldId: field.id,
        fieldName: field.name,
        value: newValue.label,
        valueId: newValue.id,
      },
    ]
  }

  const handleMetadataChange: HandleMetadataChange = (
    field: getAllSKUMetadataOptionValues_getAllSKUMetadataOptionValues,
    value: MetadataOption | string | null
  ) => {
    const isCreatingNewOption = typeof value === 'string'
    /**
     * If creating a new value, we need to create a temporary object,
     * since the object does not yet exist. This helps with allowing
     * the UI to respond immediately to the creation of the new option.
     */
    const newValue = isCreatingNewOption
      ? { id: `${value}--temp-id`, label: value }
      : value

    return setMetadata(updateMetadata(field, newValue))
  }

  const getSelectedMetadata = useCallback(
    (fieldId: string) => {
      const selectedItem = metadata.find((meta) => meta.fieldId === fieldId)
      return selectedItem
        ? { id: selectedItem.valueId, label: selectedItem.value }
        : null
    },
    [metadata]
  )
  return {
    metadata,
    handleMetadataChange,
    setToInitialMetadata,
    getSelectedMetadata,
  }
}
