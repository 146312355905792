import { OrderStatus } from '@globalTypes'
import { TextField } from '@mui/material'
import React, { ChangeEvent, useRef } from 'react'

import { Product } from './types'
import { SELECTABLE_STATUS_OPTIONS } from './ReturnOrderItemStatusSelect'

interface Props {
  serialNumberErrors: string[]
  product: Product
  handleChangeSerial: (event: ChangeEvent<HTMLInputElement>) => void
}

const SerialNumberInput = ({
  serialNumberErrors,
  product,
  handleChangeSerial,
}: Props) => {
  const { id, serialNumber, status } = product
  const isError = serialNumberErrors.includes(id)
  const initialStatus = useRef(status)

  const isDisabled = Boolean(
    initialStatus.current === OrderStatus.NOT_RETURNED ||
      (product.status && !SELECTABLE_STATUS_OPTIONS.includes(product.status))
  )

  return (
    <TextField
      disabled={isDisabled}
      error={isError}
      helperText={isError && 'This serial already exists'}
      value={serialNumber || ''}
      type="text"
      fullWidth
      size="small"
      placeholder="Serial Number"
      onChange={handleChangeSerial}
    />
  )
}

export default SerialNumberInput
