import {
  getOrganizationWarranties_getWarrantyTypes,
  getOrganizationWarranties_getOrganizationWarrantyTypeAssignments,
} from '@firstbase/data/Warranties'

import {
  OrgWarrantyAssignmentMap,
  WarrantyTypeVendorMap,
} from './clientWarrantiesTypes'

export const groupWarrantiesByVendor = (
  warrantyType: getOrganizationWarranties_getWarrantyTypes[]
) => {
  const groupedByVendor = getWarrantyTypeVendorMap(warrantyType)
  return sortWarranties(groupedByVendor)
}

export const getOrgWarrantyAssignmentMap = (
  assignments: getOrganizationWarranties_getOrganizationWarrantyTypeAssignments[]
) =>
  assignments.reduce<OrgWarrantyAssignmentMap>(
    (acc, obj) => ({ ...acc, [obj.warrantyType.id]: { ...obj } }),
    {}
  )

const getWarrantyTypeVendorMap = (
  warrantyType: getOrganizationWarranties_getWarrantyTypes[]
) =>
  warrantyType.reduce<WarrantyTypeVendorMap>((acc, obj) => {
    const { vendorName } = obj

    if (!acc[vendorName]) {
      acc[vendorName] = []
    }

    acc[vendorName].push(obj)

    return acc
  }, {})

const sortWarranties = (warrantyTypeVendorMap: WarrantyTypeVendorMap) =>
  Object.keys(warrantyTypeVendorMap)
    .sort() // Sort the vendor names alphabetically
    .map((vendorName) => {
      // Sort the objects within each vendor group by the name property
      const sortedObjects = warrantyTypeVendorMap[vendorName].sort((a, b) =>
        a.name.localeCompare(b.name)
      )
      return sortedObjects
    })
