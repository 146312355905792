import React from 'react'
import { Box, Checkbox, CheckboxProps, Typography } from '@mui/material'

interface OwnProps extends CheckboxProps {
  title: string
  description: string
}

const SupplierCheckbox = ({ title, description, ...rest }: OwnProps) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
    marginBottom={1}
  >
    <Box>
      <Typography variant="h5" role="header">
        {title}
      </Typography>
      <Typography variant="body1">{description}</Typography>
    </Box>
    <Checkbox {...rest} />
  </Box>
)

export default SupplierCheckbox
