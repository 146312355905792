import { getAllSKUs_getAllSKUs_data_metadata } from '@firstbase/data/SKU/__generated__/getAllSKUs'
import { getAllWarrantySKUMetadataOptionValues_getAllWarrantySKUMetadataOptionValues } from '@firstbase/data/Warranties'
import { formatMetadataValue } from '@utils/formatMetadataValue'
import { SkuMetadataFieldName } from '@globalTypes'
import { filterNullsOut } from '@firstbase/utils/array/filterNullsOut'

import { ReducerActions, ReducerState } from './types'

export const reducer = (
  state: ReducerState,
  action: ReducerActions
): ReducerState => {
  switch (action.type) {
    case 'SET_CATEGORY':
      return {
        ...state,
        // reset metadata fields when category changes
        metadataFields: {},
        category: action.payload,
      }
    case 'SET_VENDOR':
      return {
        ...state,
        // reset metadata fields when vendor changes
        metadataFields: {},
        vendor: action.payload,
      }
    case 'SET_METADATA_FIELD':
      return {
        ...state,
        metadataFields: {
          ...state.metadataFields,
          [action.payload.fieldId]: {
            value: action.payload.value,
            name: action.payload.name,
          },
        },
      }
    case 'SET_IMAGE_DATA':
      return {
        ...state,
        imageData: {
          ...state.imageData,
          ...action.payload,
        },
      }
    case 'SET_MPN':
      return {
        ...state,
        mpn: action.payload,
      }
    case 'SET_PRICING_DETAILS_CURRENCY_CODE':
      return {
        ...state,
        pricingDetails: {
          ...state.pricingDetails,
          currencyCodeSelect: action.payload,
        },
      }
    case 'SET_PRICING_DETAILS_REGION_CODE':
      return {
        ...state,
        pricingDetails: {
          ...state.pricingDetails,
          regionCodeSelect: action.payload,
        },
      }
    case 'ADD_PRICING_DETAILS_ROW':
      return {
        ...state,
        pricingDetails: {
          regionCodeSelect: '',
          currencyCodeSelect: '',
          rows: [...state.pricingDetails.rows, action.payload],
        },
      }
    case 'REMOVE_PRICING_DETAILS_ROW':
      return {
        ...state,
        pricingDetails: {
          ...state.pricingDetails,
          rows: state.pricingDetails.rows.filter(
            (_, index) => index !== action.payload.index
          ),
        },
      }
    case 'REMOVE_METADATA_FIELD':
      const { [action.payload.fieldId]: _, ...restMetadataFields } =
        state.metadataFields
      return {
        ...state,
        metadataFields: restMetadataFields,
      }
    case 'SET_PRICING_DETAILS_PRICE':
      return {
        ...state,
        pricingDetails: {
          ...state.pricingDetails,
          rows: state.pricingDetails.rows.map((row, index) =>
            index === action.payload.index
              ? { ...row, purchasePrice: action.payload.purchasePrice }
              : row
          ),
        },
      }
    default:
      return state
  }
}

export const groupMetadataFieldsByGroup = (
  items: getAllWarrantySKUMetadataOptionValues_getAllWarrantySKUMetadataOptionValues[]
) => {
  const grouped = items.reduce((acc, item) => {
    if (!acc[item.group]) {
      acc[item.group] = []
    }
    acc[item.group].push(item)
    return acc
  }, {} as Record<number, getAllWarrantySKUMetadataOptionValues_getAllWarrantySKUMetadataOptionValues[]>)

  return Object.keys(grouped)
    .sort((a, b) => Number(a) - Number(b))
    .map((group) => grouped[Number(group)])
}

interface MetadataItem {
  field: SkuMetadataFieldName
  value: string
}

const warrantyFieldsForTitle = [
  SkuMetadataFieldName.WARRANTY_TYPE,
  SkuMetadataFieldName.WARRANTY_TERM,
  SkuMetadataFieldName.WARRANTY_TIER,
  SkuMetadataFieldName.WARRANTY_REGION,
]

export const getWarrantySkuChips = (metadata: MetadataItem[]): string[] => {
  const chips: string[] = []

  metadata.forEach(({ field, value }) => {
    if (warrantyFieldsForTitle.includes(field) && value) {
      chips.push(value)
    }
  })

  return chips
}

export const getWarrantySkuTitle = (metadata: MetadataItem[]): string => {
  const titleValues: string[] = []

  metadata.forEach(({ field, value }) => {
    if (!warrantyFieldsForTitle.includes(field) && value) {
      titleValues.push(formatMetadataValue(field, value))
    }
  })

  return titleValues.filter(Boolean).join(' ')
}

export const getMetadataItemsFromQueryData = (
  metadata: (getAllSKUs_getAllSKUs_data_metadata | null)[]
): MetadataItem[] =>
  filterNullsOut(metadata).map(({ field, value }) => ({
    field: field as SkuMetadataFieldName,
    value: value ?? '',
  }))
