import React, { useCallback, useMemo } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { AuthState } from '@okta/okta-auth-js'
import { Role as RoleEnum } from '@firstbase/constants/roles'

export type HasRoleInput = RoleEnum | RoleEnum[]

export type AuthInfo = {
  authState: AuthState | null
  hasRole: (role: HasRoleInput) => boolean
}

const mgmtRegExp = (group: string) => /mgmt/.test(group)

export const AuthStateContext = React.createContext<AuthInfo>({
  authState: null,
  hasRole: () => false,
})

export const useAuthState = () => React.useContext(AuthStateContext)

const AuthProvider: React.FC = ({ children }) => {
  const { authState } = useOktaAuth()

  const groups: string[] = authState?.accessToken?.claims.groups
  if (!groups?.find(mgmtRegExp)) {
    throw new Error('Unknown user role')
  }

  const mgmtRoles = useMemo(
    () =>
      groups
        .filter(mgmtRegExp)
        .map((mgmtGroup) => mgmtGroup.split(':')[1])
        // keep only known roles
        .filter((mgmtGroupType) =>
          Object.values(RoleEnum).includes(mgmtGroupType as RoleEnum)
        ),
    [groups]
  )

  const hasRole = useCallback(
    (role: HasRoleInput) => {
      const rolesArray = Array.isArray(role) ? role : [role]

      return rolesArray.some((roleEl) => mgmtRoles.includes(roleEl))
    },
    [mgmtRoles]
  )

  return (
    <AuthStateContext.Provider value={{ authState, hasRole }}>
      {children}
    </AuthStateContext.Provider>
  )
}

export const Role = RoleEnum

export default AuthProvider
