import React from 'react'

import Page from '@firstbase/components/atoms/Page'
import Table, { SortDirections } from '@firstbase/components/atoms/Table'

import useTableGraphqlQuery from '@firstbase/hooks/useTableGraphqlQuery'
import Link from '@firstbase/components/atoms/Link'

import { cellComponents } from '@firstbase/components/atoms/Table/cellComponents/cellComponents'
import { GET_ALL_INVENTORY_ORDERS } from '@firstbase/data/InventoryOrders/queries'
import {
  getInventoryOrders as getInventoryOrders,
  getInventoryOrdersVariables,
} from '@firstbase/data/InventoryOrders/__generated__/getInventoryOrders'
import { interpolateQueryParamsInPath } from '@firstbase/routes/routeUtils'
import { format, parseISO } from 'date-fns'
import { ColumnI } from '@atoms/Table/types'

const columns: ColumnI[] = [
  {
    header: ' ID',
    id: 'id',
    defaultSort: SortDirections.asc,
    cell: {
      as: cellComponents.ID,
      value: ({ id }: any) => id,
    },
  },
  {
    header: 'Organization',
    id: 'organization.name',
    defaultSort: SortDirections.asc,
    cell: {
      value: ({ organization }: any) => (
        <Link
          to={interpolateQueryParamsInPath('client', {
            clientId: organization?.id,
          })}
        >
          {organization.name}
        </Link>
      ),
    },
  },
  {
    header: 'Created date',
    id: 'createdAt',
    defaultSort: SortDirections.asc,
    cell: {
      value: ({ createdAt }: any) =>
        format(parseISO(createdAt), 'dd/MM/yyyy HH:mm'),
    },
  },
  {
    header: 'Updated date',
    id: 'updatedAt',
    defaultSort: SortDirections.asc,
    cell: {
      value: ({ updatedAt }: any) =>
        format(parseISO(updatedAt), 'dd/MM/yyyy HH:mm'),
    },
  },
  {
    header: 'Category',
    id: 'sku.category.code',
    defaultSort: SortDirections.asc,
    cell: { value: ({ sku }: any) => sku.category?.code },
  },
  {
    header: 'Vendor',
    id: 'sku.vendor.code',
    defaultSort: SortDirections.asc,
    cell: {
      value: ({ sku }: any) => sku.vendor?.code,
    },
  },
  {
    header: 'Product title',
    id: 'sku.skuInformation.productTitle',
    defaultSort: SortDirections.asc,
    cell: {
      sx: {
        minWidth: '22rem',
      },
      value: ({
        sku: { id: skuId, skuInformation },
        organization: { id: orgId },
      }: any) => (
        <Link
          to={interpolateQueryParamsInPath('product', {
            clientId: orgId,
            productId: skuId,
          })}
        >
          {skuInformation.productTitle}
        </Link>
      ),
    },
  },
  {
    header: 'Quantity',
    id: 'quantity',
    defaultSort: SortDirections.asc,
    cell: {
      value: ({ quantity }: any) => quantity,
    },
  },
  {
    header: 'Warehouse',
    id: 'warehouse.name',
    defaultSort: SortDirections.asc,
    cell: {
      value: ({ warehouse }: any) => warehouse?.name,
    },
  },
  {
    header: 'Order status',
    id: 'status',
    defaultSort: SortDirections.asc,
    cell: {
      value: ({ status }: any) => status,
    },
  },
]

const InventoryOrders = () => {
  const query = useTableGraphqlQuery<
    getInventoryOrders,
    getInventoryOrdersVariables
  >(
    GET_ALL_INVENTORY_ORDERS,
    {},
    ({ getInventoryOrdersPaginated }: getInventoryOrders) =>
      getInventoryOrdersPaginated
  )

  return (
    <>
      <Page.TitleSection
        sectionTitle="Inventory orders"
        sectionSubtitle="List of orders that are on the platform"
      />
      <Table
        query={query}
        tableId="inventoryOrders"
        columns={columns}
        defaultSortId="updatedAt"
        pagination
        customRowsPerPage={100}
        customRowsPerPageOptions={[100, 250, 500, 1000]}
      />
    </>
  )
}

export default InventoryOrders
