import { gql } from '@apollo/client'

export const CREATE_VENDOR = gql`
  mutation createVendor(
    $vendorName: String!
    $vendorCode: String!
    $fpnSegment: String!
  ) {
    createVendor(
      createVendor: {
        name: $vendorName
        code: $vendorCode
        fpnSegment: $fpnSegment
      }
    ) {
      id
      code
      name
      logoUrl
      active
      fpnSegment
    }
  }
`
