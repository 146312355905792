import { gql } from '@apollo/client'

export const UPDATE_PERSON_BY_SLUG = gql`
  mutation updatePersonBySlug($slug: String!, $updatePerson: UpdatePerson!) {
    updatePersonBySlug(slug: $slug, updatePerson: $updatePerson) {
      person {
        id
      }
    }
  }
`

export const REACTIVATE_PERSON_AND_CANCEL_RETURNS = gql`
  mutation reactivateAndCancelPendingReturns(
    $slug: String!
    $updateReturnItem: [UpdateReturnOrderItem!]!
  ) {
    updatePersonBySlug(
      slug: $slug
      updatePerson: { status: ACTIVE, groups: [APP_USER] }
    ) {
      person {
        id
      }
    }
    updateReturnItems(updateReturnItem: $updateReturnItem) {
      id
    }
  }
`

export const RESEND_ACTIVATION_EMAIL = gql`
  mutation activatePersonOrResendActivationEmail($personId: ID!) {
    activatePersonOrResendActivationEmail(personId: $personId) {
      id
      slug
    }
  }
`
