import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  SelectChangeEvent,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'

import Page from '@firstbase/components/atoms/Page'
import {
  useApolloClient,
  useQuery,
  useQuery as useGQLQuery,
} from '@apollo/client'
import useTableGraphqlQuery from '@firstbase/hooks/useTableGraphqlQuery'
import {
  GET_ALL_MINIMUM_INVENTORY_FOR_ORG_ID,
  GET_ORGANIZATION_BY_ID,
} from '@firstbase/data/Organization/queries'
import { GET_ORGANIZATION_WAREHOUSES } from '@firstbase/data/Warehouse/queries'
import {
  getWarehousesForOrgId,
  getWarehousesForOrgIdVariables,
  getWarehousesForOrgId_getWarehousesForOrgId,
} from '@firstbase/data/Warehouse/__generated__/getWarehousesForOrgId'
import { primaryWarehouses } from '@firstbase/constants/warehouse'
import {
  getAllSkuMinimumInventoryStatsForOrganization as getAllSkuMinimumInventoryStatsForOrganizationType,
  getAllSkuMinimumInventoryStatsForOrganizationVariables,
} from '@firstbase/data/Organization/__generated__/getAllSkuMinimumInventoryStatsForOrganization'
import Select from '@firstbase/components/atoms/FBSelect'
import MinInventoryTable from '@firstbase/components/molecules/MinInventoryTable/MinInventoryTable'
import { useParams } from 'react-router-dom'
import { ClientPageParams } from '@firstbase/types/Pages'

const MinInventory = () => {
  const { clientId }: ClientPageParams = useParams()
  const { data: { getOrganizationById: client } = {} } = useGQLQuery(
    GET_ORGANIZATION_BY_ID,
    {
      variables: { orgId: clientId },
    }
  )
  const [warehouseIds, setWarehouseIds] = useState<string[]>([])
  const apolloClient = useApolloClient()
  const queryVariables = {
    orgId: clientId,
    warehouseIds: warehouseIds.length ? warehouseIds : undefined,
  }
  const query = useTableGraphqlQuery<
    getAllSkuMinimumInventoryStatsForOrganizationType,
    Pick<
      getAllSkuMinimumInventoryStatsForOrganizationVariables,
      'orgId' | 'warehouseIds'
    >
  >(
    GET_ALL_MINIMUM_INVENTORY_FOR_ORG_ID,
    {
      variables: queryVariables,
    },
    ({
      getAllSkuMinimumInventoryStatsForOrganization,
    }: getAllSkuMinimumInventoryStatsForOrganizationType) =>
      getAllSkuMinimumInventoryStatsForOrganization
  )
  const { data: allOrgWarehouses, loading } = useQuery<
    getWarehousesForOrgId,
    getWarehousesForOrgIdVariables
  >(GET_ORGANIZATION_WAREHOUSES, { variables: { orgId: clientId } })

  // on unmount, remove tables responses from cache
  // so that changes to min inventory are reflected on next load
  useEffect(() => {
    return () => {
      apolloClient.cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'getAllSkuMinimumInventoryStatsForOrganization',
      })
    }
  }, [apolloClient.cache])

  const sortedSelectableWarehouses = useMemo(() => {
    if (!allOrgWarehouses?.getWarehousesForOrgId.length) return []

    const allOrgWarehouseOptions =
      allOrgWarehouses?.getWarehousesForOrgId.filter(({ active }) => active)

    const primaryWarehouseOptions = primaryWarehouses
      .map((primaryName) =>
        allOrgWarehouseOptions.find(({ name }) => name === primaryName)
      )
      .filter((el) => el) as getWarehousesForOrgId_getWarehousesForOrgId[]

    return [
      ...primaryWarehouseOptions,
      ...allOrgWarehouseOptions.filter(
        ({ name }) => !primaryWarehouses.includes(name)
      ),
    ]
  }, [allOrgWarehouses?.getWarehousesForOrgId])

  const handleChange = (event: SelectChangeEvent<typeof warehouseIds>) => {
    const {
      target: { value },
    } = event
    setWarehouseIds(
      // On autofill we get a stringified value
      typeof value === 'string' ? value.split(',') : value
    )
  }

  const getNameById = (byId: string) =>
    sortedSelectableWarehouses.find(({ id }) => id === byId)!.name

  const isLoading = (query.isLoading || loading) && !query.isPreviousData
  const isWarehouseFilterDisabled =
    isLoading || !sortedSelectableWarehouses.length

  return (
    <>
      <Page.TitleSection
        sectionTitle="Minimum Inventory"
        sectionSubtitle={`List of ${client.name}’s inventory levels by warehouse against agreed minimum requirements`}
      />
      <FormControl
        sx={{
          width: '50%',
          mb: 2,
          mt: 1,
          opacity: isWarehouseFilterDisabled ? 0.5 : 1,
        }}
      >
        <InputLabel id="warehouse-select-label">Filter by Warehouse</InputLabel>
        <Select
          labelId="warehouse-select-label"
          id="warehouse-select"
          multiple
          value={warehouseIds}
          onChange={handleChange}
          input={
            <OutlinedInput
              label="Filter by Warehouse"
              disabled={isWarehouseFilterDisabled}
            />
          }
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={getNameById(value)} />
              ))}
            </Box>
          )}
          MenuProps={{ sx: { maxHeight: '33vh' } }}
        >
          {sortedSelectableWarehouses.map(({ id, name }) => (
            <MenuItem
              key={id}
              value={id}
              sx={warehouseIds.includes(id) ? { fontWeight: 'bold' } : {}}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <MinInventoryTable
        org={client}
        query={{
          ...query,
          isLoading,
          variables: queryVariables,
        }}
      />
    </>
  )
}

export default MinInventory
