import { gql } from '@apollo/client'

import { ORGANIZATION_CATEGORY_BASICS } from './fragments'

export const GET_ALL_ORGANIZATIONS = gql`
  query getAllOrganizations(
    $pageNumber: Int!
    $pageSize: Int!
    $sort: [Sort]
    $organizationFilter: String = ""
  ) {
    getAllOrganizations(
      pagingAndSorting: {
        pageNumber: $pageNumber
        pageSize: $pageSize
        sort: $sort
      }
      filter: { name: $organizationFilter }
    ) {
      totalElements
      data {
        name
        id
        status
        slug
      }
    }
  }
`

export const GET_ORGANIZATION_BY_ID = gql`
  query getOrganizationById($orgId: ID!) {
    getOrganizationById(orgId: $orgId) {
      id
      name
      status
      slug
      refreshMonths
      doNotShipBefore
      whiteLabeled
      apiAccess
      inventoryOrdering
      idp
      abmNumber
      machineRetentionLength
      whiteLabel {
        accent
        logoUrl
      }
      supportedCountries
      pos
      pauseReason
      productInformation(
        pagingAndSorting: { pageNumber: 1, pageSize: 1 }
        filter: { billingType: SUBSCRIPTION }
      ) {
        totalElements
      }
      integrations {
        workatoCustomerAccountId
        packageMappingTableId
        mergeTenant
      }
      code
      allowHAAS
      subscriptionLength
      msaWarrantyNumber
      categoryGroupDiscounts {
        categoryGroupName
        purchaseType
        discount {
          id
          percentageDiscount
        }
      }
    }
  }
`

export const GET_ORGANIZATION_SUBSCRIPTION_DETAILS = gql`
  query getOrganizationSubscriptionDetails($orgId: ID!) {
    getOrganizationById(orgId: $orgId) {
      allowHAAS
      subscriptionLength
    }
  }
`

export const GET_ORGANIZATION_PEOPLE = gql`
  query getOrganizationPeopleById(
    $orgId: ID!
    $pageSize: Int!
    $pageNumber: Int!
    $sort: [Sort]
    $personFilter: String = ""
  ) {
    getOrganizationById(orgId: $orgId) {
      id
      people(
        pagingAndSorting: {
          pageNumber: $pageNumber
          pageSize: $pageSize
          sort: $sort
        }
        filter: { name: $personFilter }
      ) {
        totalElements
        data {
          id
          forename
          surname
          email
          status
          appAccess
          secondaryEmail
          slug
        }
      }
    }
  }
`

export const GET_ORGANIZATION_INVENTORY_ORDERS = gql`
  query getOrganizationInventoryOrdersById(
    $orgId: ID!
    $pageSize: Int!
    $pageNumber: Int!
    $sort: [Sort]
  ) {
    getOrganizationById(orgId: $orgId) {
      id
      inventoryOrders(
        pagingAndSorting: {
          pageNumber: $pageNumber
          pageSize: $pageSize
          sort: $sort
        }
      ) {
        totalElements
        data {
          id
          status
          sku {
            id
            category {
              code
            }
            skuInformation {
              productTitle
            }
          }
          warehouse {
            name
          }
          quantity
          createdAt
        }
      }
    }
  }
`

export const GET_ORGANIZATION_INVENTORIES = gql`
  query getOrganizationInventoriesById(
    $orgId: ID!
    $pageSize: Int!
    $pageNumber: Int!
    $sort: [Sort]
  ) {
    getOrganizationById(orgId: $orgId) {
      id
      inventories(
        pagingAndSorting: {
          pageNumber: $pageNumber
          pageSize: $pageSize
          sort: $sort
        }
      ) {
        totalElements
        data {
          id
          serialNumber
          description
          warehouse {
            id
            name
          }
          active
          conditionStatus
          deployStatus
          deployReason
          firstbaseSupplied
          pricing {
            priceOption
          }
          sku {
            id
            skuInformation {
              productTitle
            }
            vendorSku
          }
          person {
            id
            forename
            surname
            slug
            email
          }
          estimatedRenewalDate
          renewalDate
          unlockDate
        }
      }
    }
  }
`

export const GET_ALL_MINIMUM_INVENTORY_FOR_ORG_ID = gql`
  query getAllSkuMinimumInventoryStatsForOrganization(
    $pageNumber: Int!
    $pageSize: Int!
    $sort: [Sort]
    $orgId: ID!
    $warehouseIds: [ID!]
  ) {
    getAllSkuMinimumInventoryStatsForOrganization(
      pagingAndSorting: {
        pageNumber: $pageNumber
        pageSize: $pageSize
        sort: $sort
      }
      request: { orgId: $orgId, warehouseIds: $warehouseIds }
    ) {
      totalElements
      data {
        sku {
          id
          skuInformation {
            productTitle
          }
        }
        warehouse {
          name
        }
        minimumInventory {
          id
          minimumQuantity
          includeUsed
        }
        available {
          all
          new
          used
        }
        ordered
      }
    }
  }
`

export const GET_ALL_MINIMUM_INVENTORY_FOR_WAREHOUSE_ID = gql`
  query getAllSkuMinimumInventoryStatsForWarehouse(
    $pageNumber: Int!
    $pageSize: Int!
    $warehouseId: ID!
  ) {
    getAllSkuMinimumInventoryStatsForWarehouse(
      pagingAndSorting: { pageNumber: $pageNumber, pageSize: $pageSize }
      request: { warehouseId: $warehouseId }
    ) {
      totalElements
      data {
        organization {
          id
          name
        }
        sku {
          id
          skuInformation {
            productTitle
          }
        }
        warehouse {
          name
        }
        minimumInventory {
          id
          minimumQuantity
          includeUsed
        }
        available {
          all
          new
          used
        }
        ordered
      }
    }
  }
`

export const GET_ORGANIZATION_PACKAGES = gql`
  query getPackagesByOrganizationSlug(
    $organizationSlug: String!
    $pageNumber: Int!
    $pageSize: Int!
    $sort: [Sort]
  ) {
    getAllPackagesByOrganizationSlug(
      organizationSlug: $organizationSlug
      pagingAndSorting: {
        pageNumber: $pageNumber
        pageSize: $pageSize
        sort: $sort
      }
    ) {
      data {
        organization {
          id
        }
        name
        slug
        id
        numberOrdered
        numberDeployed
        approvalRequired
        automaticOrder
      }
      totalElements
    }
  }
`

export const GET_ORGANIZATION_CATEGORIES = gql`
  ${ORGANIZATION_CATEGORY_BASICS}
  query getOrganizationCategories($orgId: ID!) {
    getOrganizationById(orgId: $orgId) {
      id
      name
      slug
      organizationCategories {
        ...OrganizationCategoryBasics
      }
    }
  }
`

export const GET_ORGANIZATION_CATEGORIES_WITH_REFRESH_MONTHS = gql`
  ${ORGANIZATION_CATEGORY_BASICS}
  query getOrganizationCategoriesWithRefreshMonths($orgId: ID!) {
    getOrganizationById(orgId: $orgId) {
      id
      name
      slug
      organizationCategories {
        ...OrganizationCategoryBasics
        refreshMonths
      }
    }
  }
`
