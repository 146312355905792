import { useQuery } from '@apollo/client'
import Table, { SortDirections } from '@firstbase/components/atoms/Table'
import { cellComponents } from '@firstbase/components/atoms/Table/cellComponents/cellComponents'
import { ColumnI } from '@firstbase/components/atoms/Table/types'
import {
  GET_ORGANIZATION_BY_ID,
  GET_ORGANIZATION_PACKAGES,
} from '@firstbase/data/Organization/queries'
import { getPackagesByOrganizationSlug } from '@firstbase/data/Organization/__generated__/getPackagesByOrganizationSlug'
import useTableGraphqlQuery from '@firstbase/hooks/useTableGraphqlQuery'
import { interpolateQueryParamsInPath } from '@firstbase/routes/routeUtils'
import { ClientPageParams } from '@firstbase/types/Pages'
import { Chip } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'

const columns = [
  {
    header: 'Name',
    defaultSort: SortDirections.asc,
    id: 'name',
    cell: {
      as: cellComponents.Link,
      asProps: ({ slug, organization }) => ({
        to: interpolateQueryParamsInPath('package', {
          clientId: organization.id,
          packageSlug: slug,
        }),
      }),
      value: ({ name }) => name,
    },
  },
  {
    header: 'Slug',
    defaultSort: SortDirections.asc,
    id: 'slug',
    cell: { value: ({ slug }) => slug },
  },
  {
    header: 'ID',
    defaultSort: SortDirections.asc,
    id: 'id',
    cell: { as: cellComponents.ID, value: ({ id }) => id },
  },
  {
    header: 'Number deployed',
    id: 'numberDeployed',
    cell: { value: ({ numberDeployed }) => numberDeployed },
  },
  {
    header: 'Number ordered',
    id: 'numberOrdered',
    cell: { value: ({ numberOrdered }) => numberOrdered },
  },
  {
    header: 'Options',
    id: 'options',
    cell: {
      value: ({ approvalRequired, automaticOrder }) => (
        <>
          {approvalRequired && (
            <Chip color="primary" label="Requires Approval" />
          )}
          {automaticOrder && <Chip label="Automatic Order" />}
        </>
      ),
    },
  },
] as ColumnI[]

const ClientPackages = () => {
  const params: ClientPageParams = useParams()
  const { data, loading } = useQuery(GET_ORGANIZATION_BY_ID, {
    variables: { orgId: params.clientId },
  })
  const query = useTableGraphqlQuery<getPackagesByOrganizationSlug, any>(
    GET_ORGANIZATION_PACKAGES,
    {
      variables: {
        organizationSlug: data?.getOrganizationById?.slug,
      },
    },
    ({ getAllPackagesByOrganizationSlug }: getPackagesByOrganizationSlug) =>
      getAllPackagesByOrganizationSlug
  )

  return (
    <Table
      tableId={`org=${params.clientId}-packages`}
      pagination
      columns={columns}
      query={{ ...query, isLoading: query.isLoading || loading }}
    />
  )
}

export default ClientPackages
