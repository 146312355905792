import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import {
  GET_WARRANTY_SKU_FOR_EDIT,
  getWarrantySkuForEdit,
  getWarrantySkuForEditVariables,
  GET_ALL_WARRANTY_SKU_METADATA_OPTION_VALUES,
  getAllWarrantySKUMetadataOptionValues,
  getAllWarrantySKUMetadataOptionValuesVariables,
} from '@firstbase/data/Warranties'
import { filterNullsOut } from '@firstbase/utils/array/filterNullsOut'
import { SkuMetadataFieldName } from '@globalTypes'

import { ReducerState } from '../WarrantySkuForm'

type UseFormInitialValuesHook = () => { loading: boolean; form?: ReducerState }

export const useFormInitialValues: UseFormInitialValuesHook = () => {
  const { skuId } = useParams<{ skuId: string }>()

  const { data: warrantySkuData, loading: warrantySkuLoading } = useQuery<
    getWarrantySkuForEdit,
    getWarrantySkuForEditVariables
  >(GET_WARRANTY_SKU_FOR_EDIT, {
    variables: { skuId },
  })
  const categoryCode = 'COMPUTER' // always computer for edit
  const vendorCode = warrantySkuData?.getSKU.vendor.code ?? ''

  const {
    data: metadataOptionValuesData,
    loading: metadataOptionValuesLoading,
  } = useQuery<
    getAllWarrantySKUMetadataOptionValues,
    getAllWarrantySKUMetadataOptionValuesVariables
  >(GET_ALL_WARRANTY_SKU_METADATA_OPTION_VALUES, {
    variables: { categoryCode, vendorCode },
    skip: !vendorCode,
  })

  const form = useMemo(() => {
    if (!warrantySkuData || !metadataOptionValuesData) return undefined

    return getFormInitialValues(warrantySkuData, metadataOptionValuesData)
  }, [warrantySkuData, metadataOptionValuesData])

  return { loading: warrantySkuLoading || metadataOptionValuesLoading, form }
}

const getFormInitialValues = (
  warrantySkuData: getWarrantySkuForEdit,
  metadataOptionValuesData: getAllWarrantySKUMetadataOptionValues
): ReducerState => {
  const { getSKU } = warrantySkuData
  const { getAllWarrantySKUMetadataOptionValues: metadataOptionValues } =
    metadataOptionValuesData

  const skuMetadataValues = filterNullsOut(getSKU.metadata || []).reduce(
    (acc, { value, field }) => {
      if (!!field) acc[field] = value ?? ''
      return acc
    },
    {} as Record<string, string>
  )

  return {
    category: 'COMPUTER', // NOTE: Edit will only work for computers to start.
    vendor: { name: getSKU.vendor.name, code: getSKU.vendor.code },
    imageData: { url: getSKU.skuImages?.[0] ?? '', isValid: true },
    mpn: getSKU.vendorSku,
    pricingDetails: {
      regionCodeSelect: '',
      currencyCodeSelect: '',
      rows:
        getSKU.standardPricing?.map(
          ({ regionCode, currencyCode, purchasePrice }) => ({
            regionCode,
            currencyCode,
            purchasePrice:
              typeof purchasePrice === 'number' ? purchasePrice : 0,
          })
        ) ?? [],
    },
    metadataFields: metadataOptionValues.reduce((acc, { id, name }) => {
      acc[id] = { value: skuMetadataValues[name] ?? '', name }
      return acc
    }, {} as Record<string, { value: string; name: SkuMetadataFieldName }>),
  }
}
