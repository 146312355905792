import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import {
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import {
  GET_ORGANIZATION_WARRANTIES,
  getOrganizationWarranties,
  getOrganizationWarrantiesVariables,
} from '@firstbase/data/Warranties'
import { ClientPageParams } from '@firstbase/types/Pages'
import Loader from '@firstbase/components/atoms/Loader'
import Page from '@firstbase/components/atoms/Page'

import { AppleMsaWarrantyNumberInput } from './AppleMsaWarrantyNumberInput'
import { ClientWarrantiesTable } from './ClientWarrantiesTable'
import {
  groupWarrantiesByVendor,
  getOrgWarrantyAssignmentMap,
} from './clientWarrantiesUtils'

export const ClientWarranties = () => {
  const { clientId }: ClientPageParams = useParams()

  const { data, loading } = useQuery<
    getOrganizationWarranties,
    getOrganizationWarrantiesVariables
  >(GET_ORGANIZATION_WARRANTIES, {
    variables: { organizationId: clientId },
    fetchPolicy: 'cache-and-network',
  })

  const warrantiesGroupedByVendors = groupWarrantiesByVendor(
    data?.getWarrantyTypes ?? []
  )
  const orgWarrantyAssignmentMap = getOrgWarrantyAssignmentMap(
    data?.getOrganizationWarrantyTypeAssignments ?? []
  )

  if (loading || !data?.getWarrantyTypes) return <Loader />

  return (
    <>
      <Page.TitleSection
        sectionTitle="Warranty Settings"
        sectionSubtitle="List of warranty options that should be shown to the customer"
      />
      <Stack spacing={2}>
        {warrantiesGroupedByVendors.map((vendorWarranties) => {
          const vendorName = vendorWarranties[0].vendorName

          return (
            <Accordion key={vendorName} sx={{ backgroundColor: 'transparent' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">{vendorName}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={1}>
                  {vendorName.toLowerCase() === 'apple' && (
                    <AppleMsaWarrantyNumberInput />
                  )}
                  <ClientWarrantiesTable
                    warranties={vendorWarranties}
                    orgWarrantyAssignmentMap={orgWarrantyAssignmentMap}
                  />
                </Stack>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </Stack>
    </>
  )
}
