import { gql } from '@apollo/client'

export const GET_RETRIEVALS_BY_RETURN_ORDER_ID = gql`
  query getRetrievalsByReturnOrderId($returnOrderId: ID!) {
    getRetrievalsByReturnOrderId(id: $returnOrderId) {
      methodType
      items {
        id
        sku {
          id
          category {
            skuType
          }
        }
        retrievalMethod {
          id
          status
          code
          type
          inboundDetails {
            trackingLink
            trackingCode
          }
          outboundDetails {
            trackingLink
            trackingCode
          }
        }
      }
    }
  }
`
