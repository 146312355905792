import { IconButton, TextField, TextFieldProps } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

import { ReactComponent as MagnifyingGlass } from '@firstbase/assets/MagnifyingGlass.svg'
import { ReactComponent as Cross } from '@firstbase/assets/Cross.svg'

type OwnProps = {
  disabled?: boolean
  defaultValue?: string
  onChange: (value: string) => void
  placeholder?: string
  label: string
  sx?: Pick<TextFieldProps, 'sx'>
}

const isPasteEvent = (previousValue: string, newValue: string) => {
  const previousValueLength = previousValue.length
  const newValueLength = newValue.length

  return (
    newValueLength !== previousValueLength + 1 &&
    newValueLength !== previousValueLength - 1
  )
}

const SearchBox = ({
  disabled,
  defaultValue = '',
  onChange,
  label,
  placeholder,
  sx,
}: OwnProps) => {
  const [searchValue, setSearchValue] = useState(defaultValue)
  const [debouncedSearchValue] = useDebounce<string>(
    searchValue,
    // only debounce if there is a value
    Number(searchValue && 500)
  )

  const handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e
    const cleanedValue = value.trimStart()

    setSearchValue(cleanedValue)

    /**
     * bypass debounce on paste event
     * caveat: this still triggers the debounce
     * it just force bubbles the change event to the parent
     * before timeout is reached
     */
    if (isPasteEvent(searchValue, cleanedValue)) {
      onChange(cleanedValue)
    }
  }

  const resetSearchValue = () => setSearchValue('')

  useEffect(
    () => onChange(debouncedSearchValue),
    [onChange, debouncedSearchValue]
  )

  return (
    <TextField
      value={searchValue}
      onChange={handleSearchValueChange}
      placeholder={placeholder}
      label={label}
      sx={{ width: '40%', mb: 2, ...sx }}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        disabled,
        startAdornment: <MagnifyingGlass style={{ marginRight: '0.5rem' }} />,
        endAdornment: (
          <IconButton
            disabled={!searchValue || disabled}
            onClick={resetSearchValue}
            aria-label={`clear ${label}`}
          >
            <Cross fillOpacity={!searchValue || disabled ? 0.25 : 1} />
          </IconButton>
        ),
      }}
    />
  )
}

export default SearchBox
