import {
  OrganizationStatus,
  OrderStatus,
  DeployStatus,
  SKUStatus,
  RetrievalMethodStatus,
  ProductTierStatus,
} from '@globalTypes'
import { ChipProps } from '@mui/material'

type ColorNames = ChipProps['color'] | 'caution'

export const getOrganizationColorVariant: (
  status: OrganizationStatus
) => ColorNames = (status) => {
  switch (status) {
    case OrganizationStatus.DEMO:
      return 'primary'
    case OrganizationStatus.LIVE:
      return 'success'
    case OrganizationStatus.INACTIVE:
      return 'secondary'
  }
}

export const getOrderStatusColor: (
  orderStatus: OrderStatus | null
) => ColorNames = (orderStatus) => {
  switch (orderStatus) {
    case OrderStatus.CANCELLED:
    case OrderStatus.DECLINED:
      return 'warning'
    case OrderStatus.DELIVERED:
    case OrderStatus.RETURNED:
      return 'success'
    case OrderStatus.PROCESSING:
    case OrderStatus.SHIPPED:
    case OrderStatus.ORDERED_NOT_SHIPPED:
    case OrderStatus.PARTIALLY_SHIPPED:
    case OrderStatus.CONFIRMED:
      return 'info'
    default:
      return 'secondary'
  }
}

export const getRetrievalStatusColor: (
  retirvalStatus: RetrievalMethodStatus | null
) => ColorNames = (retrivalStatus) => {
  switch (retrivalStatus) {
    case RetrievalMethodStatus.CANCELLED:
      return 'warning'
    case RetrievalMethodStatus.PROCESSED:
      return 'success'
    case RetrievalMethodStatus.REQUESTED:
      return 'secondary'
    default:
      return 'info'
  }
}

export const getInventoryDeployStatusColor: (
  inventoryDeployStatus: DeployStatus
) => ColorNames = (inventoryDeployStatus) => {
  switch (inventoryDeployStatus) {
    case DeployStatus.ARCHIVED:
      return 'warning'
    case DeployStatus.AVAILABLE:
      return 'success'
    case DeployStatus.AWAITING_ARRIVAL:
    case DeployStatus.OUT_FOR_REPAIR:
    case DeployStatus.ON_HOLD:
      return 'info'
    case DeployStatus.LOCKED:
      return 'caution'
    case DeployStatus.UNAVAILABLE:
      return 'info'
    case DeployStatus.DEPLOYED:
    default:
      return 'secondary'
  }
}

export const getSKUStatusColor: (skuStatus: string) => ColorNames = (
  skuStatus
) => {
  switch (skuStatus) {
    case SKUStatus.ARCHIVED:
      return 'warning'
    case SKUStatus.ACTIVE:
      return 'success'
    default:
      return 'secondary'
  }
}

export const getProductTierStatusColor: (
  productTierStatus: ProductTierStatus
) => ColorNames = (productTierStatus) => {
  switch (productTierStatus) {
    case 'ACTIVE':
      return 'success'
    case 'NOT_ACTIVE':
      return 'default'
    default:
      return 'default'
  }
}

export type Getter = (input: any) => ColorNames

export default {
  organization: getOrganizationColorVariant,
  order: getOrderStatusColor,
  retrieval: getRetrievalStatusColor,
  productTier: getProductTierStatusColor,
}
