import { gql } from '@apollo/client'

export const CREATE_INVENTORY = gql`
  mutation createInventory($createInventory: CreateInventory!) {
    createInventory(createInventory: $createInventory) {
      id
      sku {
        id
      }
      warehouse {
        id
        name
      }
      organization {
        id
        name
      }
    }
  }
`
export const UPDATE_INVENTORY_BY_ID = gql`
  mutation updateInventoryById(
    $inventoryId: ID!
    $updateInventory: UpdateInventory!
  ) {
    updateInventoryById(id: $inventoryId, updateInventory: $updateInventory) {
      id
    }
  }
`
