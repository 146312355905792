import React from 'react'
import { useQuery } from '@apollo/client'
import { Typography, Skeleton } from '@mui/material'

import { GET_RETRIEVALS_BY_RETURN_ORDER_ID } from '@firstbase/data/Retrievals/queries'
import {
  getRetrievalsByReturnOrderId,
  getRetrievalsByReturnOrderIdVariables,
} from '@firstbase/data/Retrievals/__generated__/getRetrievalsByReturnOrderId'

import RetrievalContainer from './RetrievalContainer'
import { getRetrievalsSummaryData } from '../detailUtils'

interface OwnProps {
  returnOrderId: string
}

const ReturnRetrievals = ({ returnOrderId }: OwnProps) => {
  const { data, loading, error } = useQuery<
    getRetrievalsByReturnOrderId,
    getRetrievalsByReturnOrderIdVariables
  >(GET_RETRIEVALS_BY_RETURN_ORDER_ID, {
    variables: {
      returnOrderId,
    },
  })

  const renderRetrievals = () => {
    if (!data) return null

    const { equipment, furniture } = getRetrievalsSummaryData(data)

    if (!equipment && !furniture)
      return (
        <Typography fontStyle="italic">
          No retrievals found for this return order.
        </Typography>
      )

    return (
      <>
        {equipment && (
          <RetrievalContainer data={equipment} retrievalType="EQUIPMENT" />
        )}
        {furniture && (
          <RetrievalContainer data={furniture} retrievalType="FURNITURE" />
        )}
      </>
    )
  }

  return (
    <>
      <Typography variant="h6" fontWeight="bold" sx={{ marginBottom: 1 }}>
        Retrievals
      </Typography>
      {error && (
        <Typography fontStyle="italic">
          An error was encountered when trying to see if there are retrievals!
        </Typography>
      )}
      {loading && <Skeleton variant="rectangular" width={400} height={50} />}
      {renderRetrievals()}
    </>
  )
}

export default ReturnRetrievals
