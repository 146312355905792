import React from 'react'

import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'

type ConfirmModalProps = {
  open: boolean
  maxWidth?: number
  titleText?: string
  descText?: string
  cancelText?: string
  confirmText?: string
  children?: React.ReactChild
  onConfirm: () => void
  onCancel: () => void
  onClose: () => void
}

const ConfirmModal = ({
  open,
  maxWidth = 400,
  titleText = 'Do you want to confirm?',
  descText = '',
  cancelText = 'Cancel',
  confirmText = 'OK',
  children,
  onConfirm,
  onCancel,
  onClose,
}: ConfirmModalProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby={titleText}
      aria-describedby={descText}
      data-testid="confirmModal"
    >
      <Grid
        container
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 2,
          borderRadius: 1,
        }}
      >
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography variant="h6">{titleText}</Typography>
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}
        >
          <Button color="secondary" onClick={onCancel}>
            {cancelText}
          </Button>
          <Button variant="contained" sx={{ ml: 1 }} onClick={onConfirm}>
            {confirmText}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default ConfirmModal
