import React, { useEffect, useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import OktaSignIn from '@okta/okta-signin-widget'

import {} from '@firstbase/config/okta'

import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'
import { Tokens } from '@okta/okta-auth-js'

type OwnProps = {
  config: {
    baseUrl: string
    clientId: string
    redirectUri: string
    stateToken?: string
    recoveryToken?: string
  }
  onSuccess: (tokens: Tokens) => void
  onError: (e: Error) => void
}

const OktaSignInWidget = ({ config, onSuccess, onError }: OwnProps) => {
  const newConfig = useMemo(() => ({ ...config }), [config])
  const widgetRef = useRef<HTMLDivElement>(null)
  const { search } = useLocation()

  useEffect((): any => {
    if (!widgetRef.current) return false

    const params = new URLSearchParams(search)
    const token = params.get('token')
    const recoveryToken = params.get('recoveryToken')

    if (token) {
      newConfig.stateToken = token
    }

    // Handles password recovery
    if (recoveryToken) {
      newConfig.recoveryToken = recoveryToken
    }

    const widget = new OktaSignIn(newConfig)

    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
      })
      .then(onSuccess)
      .catch(onError)

    return () => widget.remove()
  }, [config, onSuccess, onError, search, newConfig])

  return <div ref={widgetRef} />
}

export default OktaSignInWidget
