import { useMemo } from 'react'
import { getAllSKUs_getAllSKUs_data } from '@firstbase/data/SKU/__generated__/getAllSKUs'
import { MetadataItem } from '../types'

type UseMetadataValidProps = {
  metadata: MetadataItem[]
  filteredSkus: getAllSKUs_getAllSKUs_data[]
  isCreate: boolean
  genericCategory: boolean
  skuMetadataM1Flag: boolean
  hasMetadataError: boolean
  filteredSKUsTotal: number
}

export const useMetadataValid = ({
  metadata,
  filteredSkus,
  isCreate,
  genericCategory,
  skuMetadataM1Flag,
  hasMetadataError,
  filteredSKUsTotal,
}: UseMetadataValidProps) => {
  const hasExactMatch = useMemo(() => {
    if (!metadata.length || !filteredSkus.length) return false

    return filteredSkus.some((sku) => {
      if (!sku.metadata || sku.metadata.length !== metadata.length) return false

      const metadataMap = new Map(metadata.map((m) => [m.fieldName, m.value]))
      return sku.metadata.every(
        (skuMeta) =>
          skuMeta?.field && skuMeta.value === metadataMap.get(skuMeta.field)
      )
    })
  }, [metadata, filteredSkus])

  const isMetadataValid = useMemo(() => {
    if (genericCategory || !skuMetadataM1Flag) {
      return true
    }
    // For create view, we can check filteredSKUs length to avoid exact matches
    // For edit view, we need to check for exact matches as metadata fields are optional
    if (!isCreate) {
      return !hasExactMatch
    }
    return !hasMetadataError && filteredSKUsTotal === 0
  }, [
    genericCategory,
    skuMetadataM1Flag,
    isCreate,
    hasExactMatch,
    hasMetadataError,
    filteredSKUsTotal,
  ])

  return isMetadataValid
}
