import { useCallback, useRef, useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useParams } from 'react-router-dom'
import debounce from 'lodash.debounce'

import { GET_ORGANIZATION_BY_ID } from '@firstbase/data/Organization/queries'
import { UPDATE_MSA_WARRANTY_NUMBER } from '@firstbase/data/Organization/mutations'
import {
  getOrganizationById,
  getOrganizationByIdVariables,
} from '@firstbase/data/Organization/__generated__/getOrganizationById'
import {
  updateMsaWarrantyNumber as updateMsaWarrantyNumberMutationType,
  updateMsaWarrantyNumberVariables,
} from '@firstbase/data/Organization/__generated__/updateMsaWarrantyNumber'
import { ClientPageParams } from '@firstbase/types/Pages'

export const useUpdateMsaWarrantyNumber = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [updateMutation, { loading }] = useMutation<
    updateMsaWarrantyNumberMutationType,
    updateMsaWarrantyNumberVariables
  >(UPDATE_MSA_WARRANTY_NUMBER, {
    onError: () => {
      enqueueSnackbar(
        'Looks like something went wrong when trying to update the MSA warranty number. Please try again or reach out to support.',
        { variant: 'error' }
      )
    },
    onCompleted: () => {
      enqueueSnackbar('The warranty number has been updated successfully', {
        variant: 'success',
      })
    },
  })

  const updateMsaWarrantyNumber = useCallback(
    debounce((slug, msaWarrantyNumber) => {
      updateMutation({ variables: { slug, msaWarrantyNumber } })
    }, 1000), // 1 second debounce
    [updateMutation]
  )

  return { updateMsaWarrantyNumber, loading }
}

export const useMsaWarrantyData = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { clientId }: ClientPageParams = useParams()
  const { data: queryData, loading } = useQuery<
    getOrganizationById,
    getOrganizationByIdVariables
  >(GET_ORGANIZATION_BY_ID, {
    variables: { orgId: clientId },
    onError: () => {
      enqueueSnackbar(
        'Looks like something went wrong when trying to load the MSA warranty number. Please try again or reach out to support.',
        { variant: 'error' }
      )
    },
  })

  const data = queryData?.getOrganizationById
    ? {
        slug: queryData.getOrganizationById.slug,
        msaWarrantyNumber: queryData.getOrganizationById.msaWarrantyNumber,
      }
    : null

  return { data, loading }
}

export const useMsaWarrantyInputValue = (
  msaWarrantyNumberFromServer: string,
  loadingMsaWarrantyData: boolean
) => {
  const inputInitialized = useRef(false)
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    if (
      !loadingMsaWarrantyData &&
      msaWarrantyNumberFromServer &&
      !inputInitialized.current
    ) {
      setInputValue(msaWarrantyNumberFromServer)
      inputInitialized.current = true
    }
  }, [msaWarrantyNumberFromServer, loadingMsaWarrantyData])

  return { inputValue, setInputValue }
}
