import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  SelectChangeEvent,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'

import { useApolloClient, useQuery } from '@apollo/client'
import useTableGraphqlQuery from '@firstbase/hooks/useTableGraphqlQuery'
import { GET_ALL_MINIMUM_INVENTORY_FOR_WAREHOUSE_ID } from '@firstbase/data/Organization/queries'
import { GET_ALL_WAREHOUSES } from '@firstbase/data/Warehouse/queries'
import { getWarehousesForOrgId_getWarehousesForOrgId } from '@firstbase/data/Warehouse/__generated__/getWarehousesForOrgId'
import { primaryWarehouses } from '@firstbase/constants/warehouse'
import MinInventoryTable from '@firstbase/components/molecules/MinInventoryTable/MinInventoryTable'
import { getAllWarehouses } from '@firstbase/data/Warehouse/__generated__/getAllWarehouses'
import {
  getAllSkuMinimumInventoryStatsForWarehouse as getAllSkuMinimumInventoryStatsForWarehouseType,
  getAllSkuMinimumInventoryStatsForWarehouseVariables,
} from '@firstbase/data/Organization/__generated__/getAllSkuMinimumInventoryStatsForWarehouse'
import Page from '@firstbase/components/atoms/Page'
import Select from '@firstbase/components/atoms/FBSelect'

const GlobalMinimumInventory = () => {
  const { data, loading: isLoadingWarehouses } =
    useQuery<getAllWarehouses>(GET_ALL_WAREHOUSES)

  const selectableWarehouses = data?.getAllWarehouses.data.filter(
    ({ active }) => active
  )

  const [warehouseId, setWarehouseId] = useState<string>('')
  const client = useApolloClient()
  const queryVariables = {
    warehouseId,
  }
  const query = useTableGraphqlQuery<
    getAllSkuMinimumInventoryStatsForWarehouseType,
    Pick<getAllSkuMinimumInventoryStatsForWarehouseVariables, 'warehouseId'>
  >(
    GET_ALL_MINIMUM_INVENTORY_FOR_WAREHOUSE_ID,
    {
      variables: queryVariables,
    },
    ({
      getAllSkuMinimumInventoryStatsForWarehouse,
    }: getAllSkuMinimumInventoryStatsForWarehouseType) =>
      getAllSkuMinimumInventoryStatsForWarehouse
  )

  // on unmount, remove tables responses from cache
  // so that changes to min inventory are reflected on next load
  useEffect(() => {
    return () => {
      client.cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'getAllSkuMinimumInventoryStatsForWarehouse',
      })
    }
  }, [client.cache])

  const sortedSelectableWarehouses = useMemo(() => {
    if (!selectableWarehouses?.length) return []

    const primaryWarehouseOptions = primaryWarehouses
      .map((primaryName) =>
        selectableWarehouses.find(({ name }) => name === primaryName)
      )
      .filter((el) => el) as getWarehousesForOrgId_getWarehousesForOrgId[]

    return [
      ...primaryWarehouseOptions,
      ...selectableWarehouses.filter(
        ({ name }) => !primaryWarehouses.includes(name)
      ),
    ]
  }, [selectableWarehouses])

  const handleChange = (event: SelectChangeEvent<typeof warehouseId>) => {
    const {
      target: { value },
    } = event
    const selectedWarehouseId =
      typeof value === 'string'
        ? value.split(',').find((el) => el !== warehouseId)
        : value

    if (selectedWarehouseId) setWarehouseId(selectedWarehouseId)
  }

  const getNameById = (byId: string) =>
    sortedSelectableWarehouses.find(({ id }) => id === byId)!.name

  const isLoading =
    (query.isLoading || isLoadingWarehouses) && !query.isPreviousData
  const isWarehouseFilterDisabled =
    isLoading || !sortedSelectableWarehouses.length

  useEffect(() => {
    if (!isWarehouseFilterDisabled && !warehouseId) {
      setWarehouseId(sortedSelectableWarehouses[0].id)
    }
  }, [isWarehouseFilterDisabled, sortedSelectableWarehouses, warehouseId])

  return (
    <>
      <Page.TitleSection
        sectionTitle="Minimum Inventory"
        sectionSubtitle="All inventory levels by region against agreed minimum requirements"
      />
      <FormControl
        sx={{
          width: '50%',
          mb: 2,
          mt: 1,
          opacity: isWarehouseFilterDisabled ? 0.5 : 1,
        }}
      >
        <InputLabel id="warehouse-select-label">Filter by Warehouse</InputLabel>
        <Select
          labelId="warehouse-select-label"
          id="warehouse-select"
          value={warehouseId}
          onChange={handleChange}
          input={
            <OutlinedInput
              label="Filter by Warehouse"
              disabled={isWarehouseFilterDisabled}
            />
          }
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              <Chip key={selected} label={getNameById(selected)} />
            </Box>
          )}
          MenuProps={{ sx: { maxHeight: '33vh' } }}
        >
          {sortedSelectableWarehouses.map(({ id, name }) => (
            <MenuItem
              key={id}
              value={id}
              sx={warehouseId === id ? { fontWeight: 'bold' } : {}}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <MinInventoryTable
        query={{
          ...query,
          isLoading,
          variables: queryVariables,
        }}
      />
    </>
  )
}

export default GlobalMinimumInventory
