import { AuthInfo, HasRoleInput } from '@firstbase/context/auth/AuthProvider'
import routes, { Routes } from '@firstbase/routes/routesTree'

type RouteType = {
  key: string
  id: string
  exact?: boolean
  path: string
  component: (props: any) => JSX.Element
  navLabel?: string
  NavIcon?: (props: any) => JSX.Element
  NavIconActive?: (props: any) => JSX.Element
  role?: HasRoleInput
}

type RoutesType = RouteType[]

const getRoutes: (parent: Routes, parentPath?: string) => RoutesType = (
  parent,
  parentPath = ''
) => {
  const path = parent.excludeParentPath
    ? parent.path
    : `${parentPath}${parent.path}`
  const exact = typeof parent.exact === 'undefined' || parent.exact

  const id = parent.id || 'parent'

  const newRoute = {
    key: id,
    id,
    exact,
    path,
    navLabel: parent.navLabel,
    NavIcon: parent.NavIcon,
    NavIconActive: parent.NavIconActive,
    component: parent.component,
    role: parent.role,
  }

  if (!parent.children) {
    return [newRoute]
  }

  const allChildren = Object.entries(parent.children)

  return [
    newRoute,
    ...allChildren
      .map(([childId, child]) => getRoutes({ id: childId, ...child }, path))
      .flat(),
  ]
}

export const isAuthorizedForRoute =
  (hasRole: AuthInfo['hasRole']) =>
  ({ role }: RouteType) =>
    !role || hasRole(role)

export default getRoutes(routes)
