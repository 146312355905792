import Page from '@firstbase/components/atoms/Page'
import TabGroup from '@firstbase/components/molecules/TabGroup'
import React from 'react'
import GlobalMinimumInventory from './GlobalMinimumInventory'

const Reports = () => {
  return (
    <Page title="Reports">
      <TabGroup queryParam>
        <TabGroup.Page label="Minimum Inventory" id="minimum-inventory">
          <GlobalMinimumInventory />
        </TabGroup.Page>
      </TabGroup>
    </Page>
  )
}

export default Reports
