import React from 'react'
import { ClientPageParams } from '@firstbase/types/Pages'
import { Button, Typography } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import BulkUpgradeAlert from './BulkUpgrade/BulkUpgradeAlert'
import AddIcon from '@mui/icons-material/Add'

const BulkActions = () => {
  const { clientId }: ClientPageParams = useParams()

  return (
    <>
      <Typography variant="h6">Bulk upgrade</Typography>
      <BulkUpgradeAlert />
      <Link to={`${clientId}/actions/bulk-upgrade`}>
        <Button color="primary" variant="contained">
          <AddIcon />
          Bulk upgrade assets
        </Button>
      </Link>
    </>
  )
}

export default BulkActions
