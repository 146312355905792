import Select from '@atoms/FBSelect'
import Page from '@firstbase/components/atoms/Page'
import { getAllRegions_getAllRegions } from '@firstbase/data/Geo/__generated__/getAllRegions'
import { theme } from '@firstbase/muiTheme'
import { updatePrice } from '@firstbase/views/Home/Platform/Products/StandardPricingTable/utils'
import DeleteIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Card,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import React, { FC, useState } from 'react'
import { CurrencySelect } from '@firstbase/components/molecules/CurrencySelect'

const {
  palette: {
    secondary: { main: secondaryColor },
    grey: { '300': grey300, '500': grey500, '700': grey700 },
  },
} = theme

const tableSx = {
  backgroundColor: grey300,
  color: secondaryColor,
}

export interface StandardPricingFormRow {
  regionCode: string
  purchasePrice: string
  subscriptionPrice24: string
  subscriptionPrice36: string
  currencyCode: string
}

interface OwnProps {
  standardPricing: StandardPricingFormRow[]
  regions: getAllRegions_getAllRegions[]
  onChange: (values: StandardPricingFormRow[]) => void
}

const StandardPricingTable: FC<OwnProps> = ({
  standardPricing,
  onChange,
  regions,
}) => {
  const [selectedRegionCode, setSelectedRegionCode] = useState('')
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState('')

  const addPricingRow = (regionCode: string, currencyCode: string) => {
    const updatedPricing = [
      ...standardPricing,
      {
        regionCode,
        currencyCode,
        purchasePrice: '0',
        subscriptionPrice24: '0',
        subscriptionPrice36: '0',
      },
    ]

    onChange(updatedPricing)
    setSelectedRegionCode('')
    setSelectedCurrencyCode('')
  }

  const addRowDisabled = () => !selectedRegionCode || !selectedCurrencyCode

  const removeRegion = (regionCode: string) => {
    const updatedPricing = standardPricing.reduce<StandardPricingFormRow[]>(
      (pricing, region) => {
        return region.regionCode === regionCode ? pricing : [...pricing, region]
      },
      []
    )

    onChange(updatedPricing)
  }

  const findRegion = (
    regionCode: string
  ): getAllRegions_getAllRegions | undefined =>
    regions.find((r) => r.regionCode === regionCode)

  const regionName = (regionCode: string): string => {
    return findRegion(regionCode)?.name || regionCode
  }

  const updateStandardPricingItem = (
    regionCode: string,
    currencyCode: string,
    attrs: Partial<StandardPricingFormRow>
  ) => {
    const newStandardPricing: StandardPricingFormRow[] = standardPricing.map(
      (pricing) => {
        const isMatch =
          pricing.regionCode === regionCode &&
          pricing.currencyCode === currencyCode

        return isMatch ? { ...pricing, ...attrs } : pricing
      }
    )

    onChange(newStandardPricing)
  }

  const getAvailableCurrencies = (overrideCurrencies: string[]) => {
    return overrideCurrencies.filter(
      (currencyCode) =>
        !standardPricing.some(
          (pricing) =>
            pricing.regionCode === selectedRegionCode &&
            pricing.currencyCode === currencyCode
        )
    )
  }

  const renderPricingRow = ({
    regionCode,
    currencyCode,
    purchasePrice,
    subscriptionPrice24,
    subscriptionPrice36,
  }: StandardPricingFormRow) => {
    const cellSx: SxProps = { padding: 1 }
    return (
      <TableRow key={regionCode}>
        <TableCell sx={cellSx}>
          <Typography variant="inherit">{regionName(regionCode)}</Typography>
        </TableCell>
        <TableCell sx={cellSx}>
          <Typography variant="inherit">{currencyCode}</Typography>
        </TableCell>
        <TableCell sx={cellSx}>
          <TextField
            label="Purchase price"
            inputProps={{
              'data-testid': `purchasePrice-${regionCode}`,
              min: 0,
              onWheel: (e) => e.currentTarget.blur(),
              step: '0.01',
            }}
            type="number"
            value={purchasePrice}
            onChange={(e) =>
              updatePrice(e.target.value, false, (newValue) =>
                updateStandardPricingItem(regionCode, currencyCode, {
                  purchasePrice: newValue,
                })
              )
            }
            onBlur={(e) =>
              updatePrice(e.target.value, true, (newValue) =>
                updateStandardPricingItem(regionCode, currencyCode, {
                  purchasePrice: newValue,
                })
              )
            }
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            size="small"
            error={
              Number(subscriptionPrice24) > 0 && Number(purchasePrice) === 0
            }
          />
        </TableCell>
        <TableCell sx={cellSx}>
          <TextField
            label="24 months price"
            inputProps={{
              'data-testid': `subscriptionPrice-${regionCode}`,
              min: 0,
              onWheel: (e) => e.currentTarget.blur(),
              step: '0.01',
            }}
            type="number"
            value={subscriptionPrice24}
            onChange={(e) =>
              updatePrice(e.target.value, false, (newValue) =>
                updateStandardPricingItem(regionCode, currencyCode, {
                  subscriptionPrice24: newValue,
                })
              )
            }
            onBlur={(e) =>
              updatePrice(e.target.value, true, (newValue) =>
                updateStandardPricingItem(regionCode, currencyCode, {
                  subscriptionPrice24: newValue,
                })
              )
            }
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            size="small"
          />
        </TableCell>
        <TableCell sx={cellSx}>
          <TextField
            label="36 months price"
            inputProps={{
              'data-testid': `subscriptionPrice-${regionCode}`,
              min: 0,
              onWheel: (e) => e.currentTarget.blur(),
              step: '0.01',
            }}
            type="number"
            value={subscriptionPrice36}
            onChange={(e) =>
              updatePrice(e.target.value, false, (newValue) =>
                updateStandardPricingItem(regionCode, currencyCode, {
                  subscriptionPrice36: newValue,
                })
              )
            }
            onBlur={(e) =>
              updatePrice(e.target.value, true, (newValue) =>
                updateStandardPricingItem(regionCode, currencyCode, {
                  subscriptionPrice36: newValue,
                })
              )
            }
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            size="small"
          />
        </TableCell>
        <TableCell sx={cellSx}>
          <Typography variant="inherit">
            <IconButton
              id="deleteIcon"
              color="inherit"
              aria-label="delete-row"
              onClick={() => removeRegion(regionCode)}
              size="medium"
            >
              <DeleteIcon fontSize="medium" sx={{ color: grey700 }} />
            </IconButton>
          </Typography>
        </TableCell>
      </TableRow>
    )
  }

  const renderTableHead = () => {
    const cellSx: SxProps = { borderBottom: `1px solid ${grey500}`, padding: 1 }

    return (
      <TableHead sx={tableSx}>
        <TableRow>
          <TableCell sx={cellSx}>
            <Typography variant="inherit" sx={{ fontWeight: 'bold' }}>
              Suppported region
            </Typography>
          </TableCell>
          <TableCell sx={cellSx}>
            <Typography variant="inherit" sx={{ fontWeight: 'bold' }}>
              Charged Currency
            </Typography>
          </TableCell>
          <TableCell sx={cellSx}>
            <Typography variant="inherit" sx={{ fontWeight: 'bold' }}>
              Purchase price
            </Typography>
          </TableCell>
          <TableCell sx={cellSx}>
            <Typography variant="inherit" sx={{ fontWeight: 'bold' }}>
              24 months price
            </Typography>
          </TableCell>
          <TableCell sx={cellSx}>
            <Typography variant="inherit" sx={{ fontWeight: 'bold' }}>
              36 months price
            </Typography>
          </TableCell>
          <TableCell size="small" sx={cellSx}></TableCell>
        </TableRow>
      </TableHead>
    )
  }

  const renderMenuItems = (value: getAllRegions_getAllRegions) => {
    return (
      <MenuItem key={value.regionCode} value={value.regionCode}>
        {value.name}
      </MenuItem>
    )
  }

  const renderRegionDropdown = () => {
    return (
      <FormControl sx={{ width: '100%' }}>
        <InputLabel
          size="small"
          id="mgmt-standard-pricing-add-region-select-label"
        >
          {selectedRegionCode ? '' : 'Select region'}
        </InputLabel>
        <Select
          sx={{ borderColor: grey300 }}
          label={selectedRegionCode ? '' : 'Select region'}
          placeholder="Select Region"
          id="mgmt-standard-pricing-add-region-select"
          labelId="mgmt-standard-pricing-add-region-select-label"
          size="small"
          onChange={(e) => setSelectedRegionCode(e.target.value)}
          value={selectedRegionCode}
        >
          {regions.map(renderMenuItems)}
        </Select>
      </FormControl>
    )
  }

  const renderTableFooter = () => {
    return (
      <TableFooter sx={tableSx}>
        <TableRow>
          <TableCell colSpan={2}>
            <Box sx={{ width: '100%' }}>{renderRegionDropdown()}</Box>
          </TableCell>
          <TableCell colSpan={2}>
            <CurrencySelect
              selectedCurrencyCode={selectedCurrencyCode}
              setSelectedCurrencyCode={setSelectedCurrencyCode}
              filterCurrencies={getAvailableCurrencies}
            />
          </TableCell>
          <TableCell colSpan={2}>
            <Button
              variant="contained"
              disabled={addRowDisabled()}
              onClick={() =>
                addPricingRow(selectedRegionCode, selectedCurrencyCode)
              }
            >
              Add
            </Button>
          </TableCell>
        </TableRow>
      </TableFooter>
    )
  }

  return (
    <div>
      <Page.TitleSection
        sectionTitle="Pricing details"
        sectionSubtitle="Purchase or subscription pricing per region is only required for Firstbase standard catalog SKUs. Please select a region to add pricing details to it."
      />
      <Card variant="outlined" sx={{ marginBottom: 4 }}>
        <TableContainer>
          <Table aria-label="management fees table">
            {standardPricing.length >= 1 && renderTableHead()}
            <TableBody>{standardPricing.map(renderPricingRow)}</TableBody>
            {renderTableFooter()}
          </Table>
        </TableContainer>
      </Card>
    </div>
  )
}

export default StandardPricingTable
