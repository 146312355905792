import { useMutation } from '@apollo/client'
import {
  createContract as CreateContract,
  createContractVariables,
} from '@firstbase/data/ProductTier/__generated__/createContract'
import { GET_CONTRACTS_BY_ORGANIZATION } from '@firstbase/data/ProductTier/contractQueries'
import { CREATE_CONTRACT } from '@firstbase/data/ProductTier/createContract.mutation'

export const useCreateContract = () => {
  const [createContract, { loading: isCreateContractLoading }] = useMutation<
    CreateContract,
    createContractVariables
  >(CREATE_CONTRACT, { refetchQueries: [GET_CONTRACTS_BY_ORGANIZATION] })

  return {
    createContract,
    isCreateContractLoading,
  }
}
