import {
  ApolloClient,
  ApolloLink,
  ServerError,
  HttpLink,
  HttpOptions,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'

import environmentVariables from '@firstbase/utils/environmentVariables'

import cache from './assistantCache'

const httpLink = (opts: HttpOptions | undefined) => new HttpLink(opts)

const getTerminatingLink = (uri: string) =>
  httpLink({
    uri: `${uri}/graphql`,
  })

const errorLink = () =>
  onError(({ graphQLErrors, networkError }) => {
    if (networkError && (networkError as ServerError).statusCode === 401) {
      // eslint-disable-next-line no-console
      console.log('networkError', networkError)
    }

    if (graphQLErrors) {
      // eslint-disable-next-line no-console
      console.log('graphQLErrors', graphQLErrors)
    }
  })

const assistantClient = () =>
  new ApolloClient({
    link: ApolloLink.from([
      errorLink(),
      getTerminatingLink(environmentVariables.get().VITE_ASSISTANT_URI),
    ]),
    connectToDevTools: environmentVariables.get().MODE === 'development',
    cache,
  })

export default assistantClient
