import React, { useState } from 'react'

import { interpolateQueryParamsInPath } from '@firstbase/routes/routeUtils'

import Table, { SortDirections } from '@atoms/Table'
import { Link } from 'react-router-dom'
import { GET_ALL_ORGANIZATIONS } from '@firstbase/data/Organization/queries'
import {
  getAllOrganizationsVariables,
  getAllOrganizations as getAllOrganizationsType,
  getAllOrganizations_getAllOrganizations_data,
} from '@firstbase/data/Organization/__generated__/getAllOrganizations'
import useTableGraphqlQuery from '@firstbase/hooks/useTableGraphqlQuery'
import StatusChip from '@firstbase/components/atoms/StatusChip'
import { Box } from '@mui/material'
import SearchBox from '@firstbase/components/atoms/SearchBox'
import { useFlags } from 'launchdarkly-react-client-sdk'
import FLAGS from '@firstbase/constants/featureFlags'

const TABLE_ID = 'clients table'
const columns = [
  {
    header: 'Client Name',
    defaultSort: SortDirections.asc,
    id: 'name',
    cell: {
      value: ({ name, id }: getAllOrganizations_getAllOrganizations_data) => (
        <Link
          to={interpolateQueryParamsInPath('client', {
            clientId: id,
          })}
        >
          {name}
        </Link>
      ),
    },
  },
  {
    header: 'ID',
    id: 'id',
    cell: {
      value: ({ id }: getAllOrganizations_getAllOrganizations_data) => id,
    },
  },
  {
    header: 'Slug',
    id: 'slug',
    cell: {
      value: ({ slug }: getAllOrganizations_getAllOrganizations_data) => slug,
    },
  },
  {
    header: 'Status',
    defaultSort: SortDirections.asc,
    id: 'status',
    cell: {
      value: ({ status }: getAllOrganizations_getAllOrganizations_data) => (
        <StatusChip type="organization" status={status} />
      ),
    },
  },
]

function ClientsTable() {
  const flags = useFlags()
  const showSearchByNameFilter = flags[FLAGS.SE_4972_ADD_SEARCH_BY_NAME_FILTER]
  const [organizationNameFilter, setOrganizationNameFilter] = useState('')
  const query = useTableGraphqlQuery<
    getAllOrganizationsType,
    getAllOrganizationsVariables
  >(
    GET_ALL_ORGANIZATIONS,
    {
      variables: {
        pageNumber: 1,
        pageSize: 1000,
        organizationFilter: organizationNameFilter,
      },
    },
    ({ getAllOrganizations }) => getAllOrganizations
  )

  const handleProductNameChange = (newSearch: string) =>
    setOrganizationNameFilter(newSearch)

  return (
    <Box sx={{ pt: 1 }}>
      {showSearchByNameFilter ? (
        <SearchBox
          placeholder="Organization name"
          label="Search organizations"
          onChange={handleProductNameChange}
        />
      ) : null}
      <Table
        maxHeight="75vh"
        tableId={TABLE_ID}
        customRowsPerPage={250}
        customRowsPerPageOptions={[100, 250, 500, 1000]}
        pagination
        query={query}
        columns={columns}
      />
    </Box>
  )
}

export default ClientsTable
