import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import MUILink, { LinkProps } from '@mui/material/Link'
import { Typography } from '@mui/material'

type OwnProps = LinkProps & {
  to?: string
}

function Link({ variant = 'textLink', ...props }: OwnProps) {
  if (!props.href && !props.to) {
    return (
      <>{(typeof props.children == 'string' && <>{props.children}</>) || '-'}</>
    )
  }

  return (
    <Typography variant={variant}>
      <MUILink
        color="inherit"
        component={props.to ? RouterLink : 'a'}
        {...props}
      />
    </Typography>
  )
}

export default Link
