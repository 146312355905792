import { AxiosError } from 'axios'
import { QueryClientConfig } from 'react-query'

const queryClient = {
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
      retry: (retryCount: number, error: AxiosError) => {
        if (error.response?.status === 404) return false
        return retryCount < 3
      },
    },
  },
}

export default queryClient as QueryClientConfig
