import React from 'react'

import SupplierCheckbox from './SupplierCheckbox'

interface OwnProps {
  checked: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void | undefined
  disabled?: boolean
}

const CustomerSuppliedCheckbox = ({
  checked,
  onChange,
  disabled = false,
}: OwnProps) => (
  <SupplierCheckbox
    title="Customer-supplied"
    description="Support inbound inventory sent to Firstbase by the customer"
    checked={checked}
    onChange={onChange}
    disabled={disabled}
    inputProps={{
      // @ts-ignore
      'data-testid': 'customer-supplied-checkbox',
    }}
  />
)

export default CustomerSuppliedCheckbox
