import usStates from '@firstbase/components/molecules/AddressForm/AdministrativeAreaField/data/usStates.json'
import caProvinces from '@firstbase/components/molecules/AddressForm/AdministrativeAreaField/data/caProvinces.json'
import { CreatePerson } from '@globalTypes'

const isValidAdminArea = (
  administrativeArea: string | null | undefined,
  countryCode: string
) => {
  const adminAreaExists = !!administrativeArea?.trim()

  if (
    !countryCode ||
    // if empty string, should be valid for checked countries
    !adminAreaExists ||
    !['US', 'CA'].includes(countryCode)
  )
    return true

  if (countryCode === 'CA')
    return !!caProvinces.find(
      (province) => province.value === administrativeArea
    )

  return !!usStates.find((state) => state.value === administrativeArea)
}

const getAdminAreaErrorMessage = (
  administrativeArea: string | null | undefined,
  countryCode: string,
  email: string
) =>
  `Error for ${email}: ${administrativeArea} is not a valid state/region code for ${countryCode}`

const getUploadValidationErrors = (data: CreatePerson[]) => {
  const validationErrors: string[] = []

  data.forEach((row) => {
    if (
      row.address &&
      !isValidAdminArea(row.address.administrativeArea, row.address.countryCode)
    ) {
      validationErrors.push(
        getAdminAreaErrorMessage(
          row.address.administrativeArea,
          row.address.countryCode,
          row.email
        )
      )
    }
  })

  return validationErrors
}

export default getUploadValidationErrors
