import { gql } from '@apollo/client'

export const GET_MINIMUM_INVENTORY_BY_ORG_ID_SKU_ID = gql`
  query getSKUMinimumInventoryForOrganization($orgId: ID!, $skuId: ID!) {
    getSKUMinimumInventoryForOrganization(orgId: $orgId, skuId: $skuId) {
      minimumInventory {
        warehouse {
          name
          id
          active
        }
        minimumQuantity
        includeUsed
      }
    }
  }
`
