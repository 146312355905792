import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import Select from '@atoms/FBSelect'
import { ContractResponse, BillingFrequency } from '@firstbase/types/Contract'
import { getAllRegions } from '@firstbase/data/Geo/__generated__/getAllRegions'
import { GET_ALL_REGIONS } from '@firstbase/data/Geo/queries'
import { useQuery } from '@apollo/client'

type OwnProps = {
  formContractDetails?: ContractResponse['contract']
  setFormContractDetails: (
    updatedContract: ContractResponse['contract']
  ) => void
  hasFees: boolean
  dispatchContractChange: (value: number) => void
}

function ContractDetailsForm({
  formContractDetails,
  setFormContractDetails,
  hasFees,
  dispatchContractChange,
}: OwnProps) {
  const [contractVersion, setContractVersion] = useState(
    formContractDetails?.version || 2
  )
  const [versionDescription, setVersionDescription] = useState(
    formContractDetails?.versionDescription || ''
  )
  const [contractLengthMonths, setContractLengthMonths] = useState(
    formContractDetails?.contractLengthMonths || 0
  )
  const [contractStartDate, setContractStartDate] = useState(
    formContractDetails?.contractStartDate
  )
  const [haasBillingFrequency, setHaasBillingFrequency] = useState<
    BillingFrequency | ''
  >(formContractDetails?.haasBillingFrequency || '')
  const [feesBillingFrequency, setFeesBillingFrequency] = useState<
    BillingFrequency | ''
  >(formContractDetails?.feesBillingFrequency || '')
  const [defaultsToRegion, setDefaultsToRegion] = useState<string | ''>(
    formContractDetails?.defaultsToRegion || ''
  )
  const { data: regionsData } = useQuery<getAllRegions>(GET_ALL_REGIONS)
  const regions = useMemo<{ code: string; label: string }[]>(() => {
    if (!regionsData) return []

    return regionsData.getAllRegions.map((region) => ({
      code: region.regionCode,
      label: region.name,
    }))
  }, [regionsData])

  const updateContract = () => {
    if (
      !formContractDetails ||
      !contractLengthMonths ||
      !contractStartDate ||
      !haasBillingFrequency ||
      !feesBillingFrequency ||
      !defaultsToRegion ||
      !contractVersion
    )
      return

    setFormContractDetails({
      ...formContractDetails,
      contractLengthMonths,
      contractStartDate,
      haasBillingFrequency,
      feesBillingFrequency,
      defaultsToRegion,
      version: contractVersion,
      versionDescription,
    })
  }

  useEffect(() => {
    if (formContractDetails) {
      setContractLengthMonths(formContractDetails.contractLengthMonths)
      setContractStartDate(formContractDetails.contractStartDate)
      setHaasBillingFrequency(formContractDetails.haasBillingFrequency)
      setFeesBillingFrequency(formContractDetails.feesBillingFrequency)
    }
  }, [formContractDetails])

  // Trigger contract to update so we show the appropriate fields for fees when changing between contract V3 and other.
  useEffect(() => {
    updateContract()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractVersion])

  const renderRegionOptions = () => {
    return regions.map(({ code, label }) => (
      <MenuItem value={code} key={code}>
        {label}
      </MenuItem>
    ))
  }

  const handleContractLengthChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      target: { value },
    } = event

    const newLength = Number(value) > 1 ? Number(value) : 1
    setContractLengthMonths(newLength)
  }

  return (
    <>
      <Typography sx={{ fontWeight: 500, marginBottom: 1.5 }}>
        Overview
      </Typography>
      <Box sx={{ maxWidth: '564px', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ width: '100%', marginBottom: 2, display: 'flex' }}>
          <FormControl sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel size="small" id="contract-version-label">
              Contract version
            </InputLabel>
            <Select
              label="Contract version"
              labelId="contract-version-label"
              id="contract-version-select"
              size="small"
              onBlur={() => updateContract()}
              onChange={(event) => {
                dispatchContractChange(parseInt(event.target.value as string))
                setContractVersion(parseInt(event.target.value as string))
              }}
              type="number"
              value={contractVersion}
              inputProps={{
                'data-testid': 'contract-version-input',
              }}
            >
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Version description"
            size="small"
            onBlur={() => updateContract()}
            onChange={(event) => setVersionDescription(event.target.value)}
            value={versionDescription}
            sx={{ flex: 2 }}
            inputProps={{
              'data-testid': 'version-description-input',
            }}
          />
        </Box>
        <Box sx={{ width: '100%', marginBottom: 2, display: 'flex' }}>
          <TextField
            label="Contract length (in months)"
            size="small"
            onBlur={() => updateContract()}
            onChange={handleContractLengthChange}
            type="number"
            value={contractLengthMonths}
            sx={{ marginRight: 2, flex: 1 }}
          />
          <TextField
            label="Contract start"
            size="small"
            onBlur={() => updateContract()}
            onChange={(event) => setContractStartDate(event.target.value)}
            type="date"
            value={contractStartDate}
            sx={{ flex: 2 }}
          />
        </Box>
        <FormControl sx={{ marginBottom: 2 }}>
          <InputLabel size="small" id="hardware-billing-frequency-label">
            Hardware billing frequency
          </InputLabel>
          <Select
            label="Hardware billing frequency"
            labelId="hardware-billing-frequency-label"
            id="hardware-billing-frequency-select"
            size="small"
            onBlur={() => updateContract()}
            onChange={(event) =>
              setHaasBillingFrequency(event.target.value as BillingFrequency)
            }
            value={haasBillingFrequency}
          >
            <MenuItem value="Annually">Annually</MenuItem>
            <MenuItem value="Monthly">Monthly</MenuItem>
          </Select>
          <FormHelperText>Only applies to subscription products</FormHelperText>
        </FormControl>
        {hasFees && (
          <FormControl>
            <InputLabel size="small" id="mgmt-billing-frequency-label">
              Management fee billing frequency
            </InputLabel>
            <Select
              label="Management fee billing frequency"
              id="mgmt-billing-frequency-select"
              labelId="mgmt-billing-frequency-label"
              size="small"
              onBlur={() => updateContract()}
              onChange={(event) =>
                setFeesBillingFrequency(event.target.value as BillingFrequency)
              }
              value={feesBillingFrequency}
              sx={{ marginBottom: 2 }}
            >
              <MenuItem value="Annually">Annually</MenuItem>
              <MenuItem value="Monthly">Monthly</MenuItem>
            </Select>
          </FormControl>
        )}
        <FormControl>
          <InputLabel size="small" id="defaults-to-region-label">
            Defaults to billing region
          </InputLabel>
          <Select
            label="Defaults to billing region"
            id="defaults-to-region-select"
            labelId="mgmt-billing-frequency-label"
            size="small"
            onBlur={() => updateContract()}
            onChange={(event) => setDefaultsToRegion(event.target.value)}
            value={defaultsToRegion}
            sx={{ marginBottom: 2 }}
          >
            {renderRegionOptions()}
          </Select>
        </FormControl>
      </Box>
    </>
  )
}

export default ContractDetailsForm
