import React, { useContext, useEffect } from 'react'
import { Box, TabProps } from '@mui/material'

import { TabGroupContext } from './TabGroupContext'

export type TabPageProps = {
  id: string
  children: React.ReactNode
}

const TabPage = ({
  id,
  children,
  ...rest
}: Omit<Partial<TabProps>, 'children'> & TabPageProps) => {
  const { activeTabId, setTabSelector } = useContext(TabGroupContext)

  // register this page on mount
  useEffect(() => setTabSelector(id, rest), [id, rest, setTabSelector])

  const isActiveTab = id === activeTabId

  return (
    <div
      role="tabpanel"
      hidden={!isActiveTab}
      id={`tabpanel-${id}`}
      aria-labelledby={`tab-${id}`}
    >
      {isActiveTab && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  )
}

export default TabPage
