import { useMutation } from '@apollo/client'
import {
  createFeature as CreateFeature,
  createFeatureVariables,
} from '@firstbase/data/ProductTier/__generated__/createFeature'
import {
  updateFeature as UpdateFeature,
  updateFeatureVariables,
} from '@firstbase/data/ProductTier/__generated__/updateFeature'
import {
  CREATE_FEATURE,
  UPDATE_FEATURE,
} from '@firstbase/data/ProductTier/productTierFeatureMutations'
import { GET_ALL_FEATURES } from '@firstbase/data/ProductTier/productTierFeatureQueries'

export const useProductTierFeatureMutations = () => {
  const [createFeature, { loading: isCreateFeatureLoading }] = useMutation<
    CreateFeature,
    createFeatureVariables
  >(CREATE_FEATURE, { refetchQueries: [GET_ALL_FEATURES] })

  const [updateFeature, { loading: isUpdateFeatureLoading }] = useMutation<
    UpdateFeature,
    updateFeatureVariables
  >(UPDATE_FEATURE, { refetchQueries: [GET_ALL_FEATURES] })

  return {
    createFeature,
    updateFeature,
    isCreateFeatureLoading,
    isUpdateFeatureLoading,
  }
}
