import React from 'react'
import { Box } from '@mui/material'

interface OwnProps {
  children: React.ReactNode
}

/**
 * Hacky component used as a short term fix for a core issue
 * with MGMT. Its job is to remove the wrapped children
 * from the flow of the document, so that the layout issue
 * faced without it is removed. This component essentially makes it so that
 * the tables, that it wraps (as of this writing), become
 * responsive. Feel free to remove this and see the effect
 * that it has. The core issue is that MGMT is not setup to be
 * responsive, at its core. Thus, trying to solve the problem that
 * this component solves leads to these hacks. A more holistic approach
 * should be taken with the tables and app as a whole to avoid
 * hacky workarounds. Given the internal nature of MGMT and the
 * initially percieved level of effort to fix this, this hack was implemented,
 * rather than a true more time intensive fix.
 */
const FloatingContainer = ({ children }: OwnProps) => (
  <Box sx={{ position: 'relative' }}>
    <Box sx={{ position: 'absolute', width: '100%' }}>{children}</Box>
  </Box>
)

export default FloatingContainer
