import React from 'react'
import { useQuery } from '@apollo/client'
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'

import { GET_ORGANIZATION_BY_ID } from '@firstbase/data/Organization/queries'
import {
  getOrganizationById,
  getOrganizationByIdVariables,
} from '@firstbase/data/Organization/__generated__/getOrganizationById'
import { AddressI } from '@firstbase/types/Address'
import { getCountryOptionByValue } from '@firstbase/utils/country'
import Select from '@firstbase/components/atoms/FBSelect'

import AdministrativeAreaField from './AdministrativeAreaField/AdministrativeAreaField'

interface OwnProps {
  addressFormValues: AddressI
  header: string
  orgId: string
  setAddressFormValues: React.Dispatch<React.SetStateAction<AddressI>>
}

const PhoneRegex = new RegExp(
  /^\+?(\d{1,3})?\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/
)

function AddressForm({
  addressFormValues,
  header,
  orgId,
  setAddressFormValues,
}: OwnProps) {
  const {
    data: { getOrganizationById: { supportedCountries = [] } = {} } = {},
  } = useQuery<getOrganizationById, getOrganizationByIdVariables>(
    GET_ORGANIZATION_BY_ID,
    {
      variables: { orgId },
    }
  )

  const updateFieldValue = (field: keyof AddressI, value: string) =>
    setAddressFormValues((previousState) => ({
      ...previousState,
      [field]: value,
    }))

  const renderCountryCode = () => {
    if (supportedCountries?.length) {
      const countryNotNull = (country: string | null): country is string =>
        !!country

      const countryOptions = supportedCountries.filter(countryNotNull)

      return (
        <FormControl fullWidth>
          <InputLabel size="small" id="status-label">
            Country
          </InputLabel>
          <Select
            id="country-select"
            inputProps={{
              'data-testid': 'countrySelect',
            }}
            label="Country"
            labelId="country-select-label"
            onChange={(event) =>
              updateFieldValue('countryCode', event.target.value as string)
            }
            size="small"
            value={addressFormValues.countryCode || ''}
          >
            {countryOptions.map((countryCodeOption) => (
              <MenuItem key={countryCodeOption} value={countryCodeOption}>
                {getCountryOptionByValue(countryCodeOption).label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    }

    return (
      <TextField
        fullWidth
        label="Country Code"
        size="small"
        value={addressFormValues.countryCode || ''}
        inputProps={{ maxLength: 2 }}
        onChange={(event) =>
          updateFieldValue('countryCode', event.target.value)
        }
      />
    )
  }

  // Not required, but if filled out, ensure right format
  const isValidPhoneNumber = () =>
    !addressFormValues.phoneNumber ||
    PhoneRegex.test(addressFormValues.phoneNumber || '')

  return (
    <>
      <Grid container item>
        <Typography variant="h6">{header}</Typography>
      </Grid>

      <Grid container item>
        {renderCountryCode()}
      </Grid>

      <Grid container item>
        <TextField
          label="Address line 1"
          value={addressFormValues.addressLine1 || ''}
          onChange={(e) => updateFieldValue('addressLine1', e.target.value)}
          fullWidth
          size="small"
        />
      </Grid>

      <Grid container item>
        <TextField
          label="Address line 2"
          value={addressFormValues.addressLine2 || ''}
          onChange={(e) => updateFieldValue('addressLine2', e.target.value)}
          fullWidth
          size="small"
        />
      </Grid>

      <Grid container item>
        <TextField
          fullWidth
          label="City"
          size="small"
          value={addressFormValues.locality || ''}
          onChange={(e) => updateFieldValue('locality', e.target.value)}
        />
      </Grid>

      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <AdministrativeAreaField
            administrativeArea={addressFormValues.administrativeArea || ''}
            setAdministrativeArea={(value) =>
              updateFieldValue('administrativeArea', value)
            }
            countryCode={addressFormValues.countryCode || ''}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={
              addressFormValues.countryCode === 'US' ? 'Zip code' : 'Post code'
            }
            size="small"
            value={addressFormValues.postalCode || ''}
            onChange={(event) =>
              updateFieldValue('postalCode', event.target.value)
            }
          />
        </Grid>
      </Grid>

      <Grid container item>
        <TextField
          fullWidth
          label="Phone number"
          value={addressFormValues.phoneNumber || ''}
          onChange={(e) => updateFieldValue('phoneNumber', e.target.value)}
          error={!isValidPhoneNumber()}
          helperText={
            !isValidPhoneNumber() && 'Please enter a valid phone number'
          }
          size="small"
        />
      </Grid>
    </>
  )
}

export default AddressForm
