import React, { useEffect, useState } from 'react'
import request from '@firstbase/utils/request'
import Page from '@firstbase/components/atoms/Page'
import { useBreadcrumbs } from '@firstbase/context/breadcrumbs/BreadcrumbsProvider'
import { ClientResponse } from '@firstbase/types/Client'
import { PersonPageParams } from '@firstbase/types/Pages'
import { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { Alert, Box, Button } from '@mui/material'
import BulkUpgradeAlert from './BulkUpgradeAlert'
import UploadStep from '@firstbase/views/Home/Platform/BulkUpload/UploadStep'
import {
  createReplacement,
  createReplacementVariables,
} from '@firstbase/data/Replacements/__generated__/createReplacement'
import BulkUpgradeReplacements from './BulkUpgradeReplacements'
import { CREATE_REPLACEMENT } from '@firstbase/data/Replacements/mutations'
import { useMutation } from '@apollo/client'

type ResultRow = {
  rowIndex: number
  resultType: 'SUCCESS' | 'ERROR'
  error?: string
}

const BulkUpgrade = () => {
  const [rows, setRows] = useState<createReplacementVariables[] | null>(null)
  const [resultRows, setResultRows] = useState<ResultRow[] | null>(null)
  const [rowsErrors, setRowsErrors] = useState<string[]>([])
  const [loadingReplacements, setLoadingReplacements] = useState<boolean>(false)
  const { setBreadcrumbs } = useBreadcrumbs()
  const { clientId }: PersonPageParams = useParams()
  const { isLoading, data: client } = useQuery<ClientResponse, AxiosError>(
    ['client', { clientId }],
    () =>
      request<ClientResponse>({
        url: `/organizations/${clientId}`,
      })
  )
  const [createReplacementMutation] =
    useMutation<createReplacement>(CREATE_REPLACEMENT)

  useEffect(() => {
    setBreadcrumbs([
      {
        label: 'Clients',
        pathId: 'clients',
      },
      {
        label: client?.name,
        pathId: 'client',
        searchParams: { tab: 'bulk-operations' },
        isLoading: isLoading,
      },
      {
        label: 'Bulk upgrade',
        isLoading: isLoading,
      },
    ])
  }, [client, isLoading, setBreadcrumbs])

  const setData = (data: createReplacementVariables[]) => {
    setRows(data)
  }

  useEffect(() => {
    setRowsErrors([])
    const errors: string[] = []

    if (rows !== null && rows.length > 0 && !rows[0].organizationSlug) {
      errors.push('Organization slug could not be retrieved')
    }

    rows?.forEach((element, index) => {
      if (!element.personSlug)
        errors.push(`Missing person slug at row ${index + 1}`)
      if (!element.createReplacement.createReturn.returnItems[0].inventoryId)
        errors.push(`Missing inventory ID at row ${index + 1}`)
      if (!element.createReplacement.createReturn.returnItems[0].returnReason)
        errors.push(`Missing return reason at row ${index + 1}`)
      if (!element.createReplacement.createReturn.returnKitAddress.addressLine1)
        errors.push(`Missing address at row ${index + 1}`)
      if (
        !element.createReplacement.createReturn.returnKitAddress
          .administrativeArea
      )
        errors.push(`Missing state at row ${index + 1}`)
      if (!element.createReplacement.createReturn.returnKitAddress.countryCode)
        errors.push(`Missing country at row ${index + 1}`)
      if (!element.createReplacement.createReturn.returnKitAddress.locality)
        errors.push(`Missing locality at row ${index + 1}`)
      if (!element.createReplacement.createReturn.returnKitAddress.phoneNumber)
        errors.push(`Missing phone number at row ${index + 1}`)
      if (!element.createReplacement.createReturn.returnKitAddress.postalCode)
        errors.push(`Missing postal code at row ${index + 1}`)
      if (!element.createReplacement.createOrder.products[0].vendorCode)
        errors.push(`Missing vendor code at row ${index + 1}`)
      if (!element.createReplacement.createOrder.products[0].vendorSku)
        errors.push(`Missing vendor sku at row ${index + 1}`)
    })

    setRowsErrors(errors)
  }, [rows])

  const createReplacementsParser = (data: unknown[]) => {
    const replacements: createReplacementVariables[] = []

    data.forEach((row: any) => {
      const address = {
        addressLine1: row['Address line 1'],
        addressLine2: row['Address line 2'],
        locality: row.City,
        postalCode: row['Zip/Postal code'],
        administrativeArea: row['State/Region'],
        countryCode: row['Country code'],
        phoneNumber: row['Phone number'],
      }

      replacements.push({
        createReplacement: {
          createOrder: {
            products: [
              {
                vendorCode:
                  row[
                    'Vendor code (To be filled by your Customer Success Manager)'
                  ],
                vendorSku:
                  row[
                    'Vendor SKU (To be filled by your Customer Success Manager)'
                  ],
              },
            ],
            shippingAddress: address,
          },
          createReturn: {
            returnItems: [
              {
                inventoryId: row['Asset ID'],
                returnReason: row['Replacement reason'],
              },
            ],
            returnKitAddress: address,
          },
        },
        personSlug: row['Assignee slug'],
        organizationSlug: client?.slug || '',
      })
    })

    return replacements
  }

  const createReplacements = async () => {
    if (!rows) return null

    const newResultRows: ResultRow[] = []

    setLoadingReplacements(true)
    for (const [index, row] of rows.entries()) {
      try {
        await createReplacementMutation({ variables: row })
        newResultRows.push({ rowIndex: index + 1, resultType: 'SUCCESS' })
      } catch (e) {
        if (e instanceof Error) {
          newResultRows.push({
            rowIndex: index + 1,
            resultType: 'ERROR',
            error: e.message,
          })
        } else {
          //  eslint-disable-next-line no-console
          console.error(e)
          newResultRows.push({
            rowIndex: index + 1,
            resultType: 'ERROR',
            error: 'Unknown error',
          })
        }
      }
    }

    setResultRows(newResultRows)
    setLoadingReplacements(false)
  }

  return (
    <>
      <Page breadcrumb isLoading={isLoading} title={'Bulk Upgrade'}>
        <BulkUpgradeAlert />

        {/* Display upload and summary before creating replacements */}
        {!loadingReplacements && !resultRows && (
          <>
            {/* Uploading CSV */}
            <Box sx={{ my: 5 }}>
              <UploadStep
                dataParser={createReplacementsParser}
                setData={setData}
                uploadTitle="Upload the upgrade form given by the client"
                uploadSubtitle="Addresses must be full, phone number included"
                loading={false}
                disabled={rows !== null}
                displayRemoveBtn
              ></UploadStep>
            </Box>

            {/* CSV Summary */}
            {rows && (
              <Box sx={{ my: 5 }}>
                <BulkUpgradeReplacements rows={rows} />

                {rowsErrors.map((e, i) => (
                  <Box key={i} sx={{ my: 1 }}>
                    <Alert key={e} severity="warning">
                      {e}
                    </Alert>
                  </Box>
                ))}

                {/* CSV Errors */}
                <Box sx={{ my: 2 }}>
                  <Button
                    disabled={rowsErrors.length > 0}
                    variant="contained"
                    onClick={createReplacements}
                  >
                    Create replacements
                  </Button>
                </Box>
              </Box>
            )}
          </>
        )}

        {/* Display loading replacements alert */}
        {loadingReplacements && (
          <Alert severity="info">Creating replacements, please wait...</Alert>
        )}

        {/* Mutation results */}
        {resultRows && (
          <>
            {resultRows.map((row, i) => (
              <Alert
                key={i}
                sx={{ my: 1 }}
                severity={row.resultType === 'ERROR' ? 'error' : 'success'}
              >
                Row {row.rowIndex}{' '}
                {row.resultType === 'ERROR' ? 'errored: ' : 'succeeded'}{' '}
                {row.error ? row.error : ''}
              </Alert>
            ))}
          </>
        )}
      </Page>
    </>
  )
}

export default BulkUpgrade
