import { GET_ORGANIZATION_BY_ID } from '@firstbase/data/Organization/queries'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery as useGQLQuery } from '@apollo/client'
import Page from '@firstbase/components/atoms/Page'
import { ClientPageParams } from '@firstbase/types/Pages'
import ClientApiTokensTable from './ClientApiTokensTable'
import ClientApiTokensCreate from './ClientApiTokensCreate'

const ClientApiTokens = () => {
  const { clientId }: ClientPageParams = useParams()
  const { data: { getOrganizationById: client } = {} } = useGQLQuery(
    GET_ORGANIZATION_BY_ID,
    {
      variables: { orgId: clientId },
    }
  )

  return (
    <>
      <Page.TitleSection
        sectionTitle="Client API Tokens"
        sectionSubtitle={`List of API Tokens available for ${client?.name} on platform`}
      />
      <ClientApiTokensCreate clientId={client.id} />
      <ClientApiTokensTable clientId={client.id} />
    </>
  )
}

export default ClientApiTokens
