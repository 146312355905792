import React, { useState } from 'react'

import ClientsTable from '@views/Home/Clients/ClientsTable'
import Page from '@atoms/Page'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import ClientDetailsForm from '../Client/Settings/ClientDetailsForm/ClientDetailsForm'
import { useApolloClient } from '@apollo/client'
import { Role } from '@firstbase/context/auth/AuthProvider'
import ProtectedChildren from '@firstbase/components/atoms/ProtectedChildren'

function Clients() {
  // number so that each new add organization modal is a force mounted new component
  const [addOrganizationOpen, setAddOrganizationOpen] = useState<number>(0)
  const client = useApolloClient()

  const handleCloseAddOrganization = (shouldRefreshList?: boolean) => {
    setAddOrganizationOpen(0)

    if (shouldRefreshList) {
      client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'getAllOrganizations' })
    }
  }

  const renderTitleAction = () => (
    <ProtectedChildren hasRole={Role.Admin}>
      <Button
        variant="contained"
        onClick={() => setAddOrganizationOpen(Math.random() + 1)}
      >
        <AddIcon /> New Organization
      </Button>
    </ProtectedChildren>
  )

  return (
    <Page title="Clients" titleAction={renderTitleAction}>
      <ClientsTable />
      {!!addOrganizationOpen && (
        <ClientDetailsForm
          open={!!addOrganizationOpen}
          key={addOrganizationOpen}
          handleClose={handleCloseAddOrganization}
          version="CREATE"
        />
      )}
    </Page>
  )
}

export default Clients
