import { FormFeesByRegionByType } from '@firstbase/types/Fee'

import { FeeActions, FeeActionTypes } from './types'
import {
  updateAllOverrideCurrencyCode,
  updateFeeForRegion,
  convertFeesBetweenContractVersions,
} from './feesReducerUtils'

const feesReducer = (
  state: FormFeesByRegionByType,
  action: FeeActions
): FormFeesByRegionByType => {
  switch (action.type) {
    case FeeActionTypes.UPDATE_FEE: {
      const {
        regionCode,
        price,
        feeType,
        overrideCurrencyCode,
        currencyCodeMap,
      } = action.payload
      return updateFeeForRegion(
        state,
        price,
        regionCode,
        feeType,
        currencyCodeMap,
        overrideCurrencyCode
      )
    }
    case FeeActionTypes.OVERRIDE_FEES_CURRENCY: {
      const { currencyCode, billingType } = action.payload
      return updateAllOverrideCurrencyCode(state, currencyCode, billingType)
    }
    case FeeActionTypes.UPDATE_CONTRACT_VERSION: {
      const { version } = action.payload
      return convertFeesBetweenContractVersions(state, version)
    }
    default:
      return state
  }
}

export default feesReducer
