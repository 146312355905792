import React, { useState } from 'react'
import { Switch, FormControlLabel } from '@mui/material'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'

import {
  ADD_WARRANTY_METADATA_CONFIG_TO_CATEGORY,
  addWarrantyMetadataConfigToCategory,
  addWarrantyMetadataConfigToCategoryVariables,
  REMOVE_WARRANTY_METADATA_CONFIG,
  removeWarrantyMetadataConfig,
  removeWarrantyMetadataConfigVariables,
  GET_WARRANTY_METADATA_CONFIG_FOR_CATEGORY,
} from '@firstbase/data/Warranties'
import { SkuMetadataFieldName } from '@globalTypes'

interface OwnProps {
  categoryCode: string
  fieldName: SkuMetadataFieldName
  id?: string
  initialValue: boolean
}

export const WarrantyMetadataConfigSwitch = ({
  categoryCode,
  fieldName,
  id,
  initialValue,
}: OwnProps) => {
  const [checked, setChecked] = useState(initialValue)
  const { enqueueSnackbar } = useSnackbar()

  const [
    addWarrantyMetadataConfigToCategoryMutation,
    { loading: addWarrantyMetadataConfigToCategoryLoading },
  ] = useMutation<
    addWarrantyMetadataConfigToCategory,
    addWarrantyMetadataConfigToCategoryVariables
  >(ADD_WARRANTY_METADATA_CONFIG_TO_CATEGORY, {
    // quick implementation for updating the cache so that the ID is available for deletion, if needed
    refetchQueries: [
      {
        variables: { categoryCode },
        query: GET_WARRANTY_METADATA_CONFIG_FOR_CATEGORY,
      },
    ],
  })

  const [
    removeWarrantyMetadataConfigMutation,
    { loading: removeWarrantyMetadataConfigLoading },
  ] = useMutation<
    removeWarrantyMetadataConfig,
    removeWarrantyMetadataConfigVariables
  >(REMOVE_WARRANTY_METADATA_CONFIG, {
    // quick implementation for updating the cache
    refetchQueries: [
      {
        variables: { categoryCode },
        query: GET_WARRANTY_METADATA_CONFIG_FOR_CATEGORY,
      },
    ],
  })

  const handleMutation = async (newValue: boolean) => {
    try {
      if (newValue) {
        await addWarrantyMetadataConfigToCategoryMutation({
          variables: { fieldName, categoryCode },
        })
        enqueueSnackbar('Added warranty metadata config successfully', {
          variant: 'success',
        })
      } else {
        if (id) {
          await removeWarrantyMetadataConfigMutation({ variables: { id } })
          enqueueSnackbar('Removed warranty metadata config successfully', {
            variant: 'success',
          })
        }
      }
    } catch (error) {
      enqueueSnackbar('An error occurred', { variant: 'error' })
    }
  }

  const handleChange = () => {
    const newValue = !checked
    setChecked(newValue)
    handleMutation(newValue)
  }

  return (
    <FormControlLabel
      control={
        <Switch
          onChange={handleChange}
          checked={checked}
          disabled={
            addWarrantyMetadataConfigToCategoryLoading ||
            removeWarrantyMetadataConfigLoading
          }
        />
      }
      label={fieldName}
    />
  )
}
