import React from 'react'

import Page from '@atoms/Page'
import ErrorMessagesAccordion from '@firstbase/components/molecules/ErrorMessagesAccordion'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Chip,
  Stack,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { Box } from '@mui/system'

const SuccessStep = ({ errors, successful }: any) => {
  return (
    <>
      <Page.TitleSection
        sectionTitle="Upload complete"
        sectionSubtitle={`Successfully uploaded ${successful.length} rows`}
      />
      <Box sx={{ padding: '1px' }}>
        <ErrorMessagesAccordion errors={errors} />
        <Accordion sx={{ borderRadius: 2, marginTop: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="success-content"
            id="success-header"
          >
            <Stack direction="row" alignItems="center">
              Success{' '}
              <Chip
                color="success"
                sx={{ marginLeft: 1 }}
                label={successful.length}
              />{' '}
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            {successful.map((e: string, i: number) => (
              <Alert
                sx={{ marginTop: 1 }}
                key={`success-${i}`}
                severity="success"
              >
                {e}
              </Alert>
            ))}
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  )
}

export default SuccessStep
