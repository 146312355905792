import React from 'react'
import {
  Button as MUIButton,
  Menu,
  IconButton as MUIIconButton,
  ButtonProps,
} from '@mui/material'

type OwnProps = {
  children: React.ReactNode
  Button?: string
  IconButton?: React.ReactNode
  buttonProps?: ButtonProps
}

function DropdownMenu({ children, Button, IconButton, buttonProps }: OwnProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {Button && !IconButton ? (
        <MUIButton
          data-testid={Button}
          {...buttonProps}
          id="dropdown-button"
          aria-haspopup="true"
          aria-label={Button}
          onClick={handleClick}
        >
          {Button}
        </MUIButton>
      ) : (
        <MUIIconButton
          data-testid="dropdownIconButton"
          {...buttonProps}
          id="dropdown-button"
          aria-haspopup="true"
          onClick={handleClick}
        >
          {IconButton}
        </MUIIconButton>
      )}
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </>
  )
}

export default DropdownMenu
