import React from 'react'
import Page from '@firstbase/components/atoms/Page'
import Chip from '@firstbase/components/atoms/Table/cellComponents/Chip'
import { getPersonBySlugAndOrganizationSlug_getPersonBySlugAndOrganizationSlug as PersonI } from '@firstbase/data/Person/__generated__/getPersonBySlugAndOrganizationSlug'
import { OrderStatus } from '@globalTypes'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { sentenceCase } from 'change-case'
import { ProductWithOrderInformation } from './ReactivatePerson'

type OwnProps = {
  openReturnOrderIds: string
  cancelReactivation: () => void
  confirmReactivation: () => void
  canBeReactivated: boolean
  person: PersonI
  returnOrderItems: ProductWithOrderInformation[]
}

const ReactivatePersonConfirmReturnItemsModal = ({
  person,
  openReturnOrderIds,
  canBeReactivated,
  returnOrderItems,
  confirmReactivation,
  cancelReactivation,
}: OwnProps) => {
  const renderPersonCannotBeReactivatedAlert = () => {
    return (
      <Alert sx={{ mb: 3 }} severity="warning">
        You are not able to reactivate this user on MGMT because they have
        processing return orders. Please raise a TDR to reactivate this user.
      </Alert>
    )
  }

  return (
    <Dialog onClose={cancelReactivation} maxWidth="md" fullWidth open>
      <DialogTitle>
        <Page.TitleSection
          sectionTitle={`Reactivate ${person.forename} ${person.surname}`}
          sectionSubtitle={
            <>
              {person.forename} has an open return order
              <br />
              Return order ID: {openReturnOrderIds}
            </>
          }
        />
      </DialogTitle>

      {/* Return items */}
      <DialogContent sx={{ mt: -2 }}>
        {!canBeReactivated && renderPersonCannotBeReactivatedAlert()}
        {returnOrderItems.map((returnItem) => (
          <Box
            key={returnItem.id}
            sx={{
              my: 1.5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="body1">
              {returnItem.sku.skuInformation.productTitle}
            </Typography>
            <Chip
              color={
                returnItem.orderStatus === OrderStatus.PROCESSING ||
                returnItem.status === 'PENDING'
                  ? 'warning'
                  : 'info'
              }
            >
              {sentenceCase(returnItem.status || '-')}
            </Chip>
          </Box>
        ))}
      </DialogContent>

      {/* Actions */}
      <DialogActions>
        <Button variant="text" color="secondary" onClick={cancelReactivation}>
          Cancel
        </Button>

        <Button
          variant="contained"
          disabled={!canBeReactivated}
          onClick={confirmReactivation}
        >
          Reactivate and cancel pending return items
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReactivatePersonConfirmReturnItemsModal
