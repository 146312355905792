import { gql } from '@apollo/client'

export const GET_ALL_REGIONS = gql`
  query getAllRegions {
    getAllRegions {
      name
      regionCode
      defaultCurrency {
        name
        acronym
        symbol
      }
    }
  }
`
export const GET_ALL_COUNTRIES = gql`
  query getAllCountries {
    getAllCountries {
      name
      countryCode
      defaultCurrency {
        name
        acronym
        symbol
      }
    }
  }
`
