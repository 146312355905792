import { useParams } from 'react-router-dom'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'

import Loader from '@firstbase/components/atoms/Loader'
import Page from '@firstbase/components/atoms/Page'
import Table from '@firstbase/components/atoms/Table'
import { ColumnI } from '@firstbase/components/atoms/Table/types'
import FullscreenModal from '@firstbase/components/molecules/FullscreenModal'
import { UPDATE_RETURN_ORDER } from '@firstbase/data/Order/mutation'
import {
  getOrderByIdForOrganisation_getOrderByIdForOrganisation,
  getOrderByIdForOrganisation_getOrderByIdForOrganisation_person,
} from '@firstbase/data/Order/__generated__/getOrderByIdForOrganisation'
import {
  getReplacementByIdForOrganization_getReplacementByIdForOrganization_person,
  getReplacementByIdForOrganization_getReplacementByIdForOrganization_returnOrder,
  getReplacementByIdForOrganization_getReplacementByIdForOrganization_returnOrder_products,
} from '@firstbase/data/Order/__generated__/getReplacementByIdForOrganization'
import { GET_RETRIEVALS_BY_RETURN_ORDER_ID } from '@firstbase/data/Retrievals/queries'
import {
  getRetrievalsByReturnOrderId,
  getRetrievalsByReturnOrderIdVariables,
} from '@firstbase/data/Retrievals/__generated__/getRetrievalsByReturnOrderId'
import {
  DeployReason,
  DeployStatus,
  OrderStatus,
  UpdateReturnOrder,
  UpdateReturnOrderItem,
} from '@globalTypes'
import { Box, Divider, SelectChangeEvent } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import ReturnOrderItemStatusSelect, {
  SELECTABLE_STATUS_OPTIONS,
} from './ReturnOrderItemStatusSelect'

import {
  updateReturnOrder as updateReturnOrderType,
  updateReturnOrderVariables,
} from '@firstbase/data/Order/__generated__/updateReturnOrder'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { UPDATE_INVENTORY_BY_ID } from '@firstbase/data/Inventory/mutations'
import {
  updateInventoryById as updateInventoryByIdType,
  updateInventoryByIdVariables,
} from '@firstbase/data/Inventory/__generated__/updateInventoryById'
import { GET_INVENTORY_BY_SERIAL } from '@firstbase/data/Inventory/queries'
import {
  getInventoryBySerial,
  getInventoryBySerialVariables,
} from '@firstbase/data/Inventory/__generated__/getInventoryBySerial'
import FLAGS from '@firstbase/constants/featureFlags'
import SerialNumberInput from './SerialNumberInput'
import DeployReasonSelect from './DeployReasonSelect'
import { Product } from './types'
import ConfirmRetrievalMethodsModal from './ConfirmRetrievalMethodsModal'
import { readableReturnReason } from '@firstbase/constants/returnReasons'
import { AddressI } from '@firstbase/types/Address'
import { OrderPageParams } from '@firstbase/types/Pages'
import { isAddressComplete, validateAddress } from '@firstbase/utils/address'
import EditReturnOrderAddressForm from './EditReturnOrderAddressForm'
import { updatePersonBySlugVariables } from '@firstbase/data/Person/__generated__/updatePersonBySlug'
import { UPDATE_PERSON_BY_SLUG } from '@firstbase/data/Person/mutations'

import {
  getActiveValueForInventoryUpdate,
  hasPendingReturnOrderProductStatus,
} from './editReturnOrderUtils'

import useEquipmentFurnitureTableQueries from '../hooks/useEquipmentFurnitureTableQueries'

export type ReturnOrder =
  | getReplacementByIdForOrganization_getReplacementByIdForOrganization_returnOrder
  | getOrderByIdForOrganisation_getOrderByIdForOrganisation

export type ReturnOrderPerson =
  | getOrderByIdForOrganisation_getOrderByIdForOrganisation_person
  | getReplacementByIdForOrganization_getReplacementByIdForOrganization_person

type UpdateProductStatusType = (
  productId: string
) => (event: SelectChangeEvent) => void

type UpdateSerialNumberType = (
  productId: string
) => (event: ChangeEvent<HTMLInputElement>) => void

type OwnProps = {
  handleClose: () => void
  returnOrder: ReturnOrder
  person: ReturnOrderPerson
  isReplacement: boolean
}

type GetChangedInventoryIdSerialNumbersType = (
  acc: any,
  initialOrderProduct: getReplacementByIdForOrganization_getReplacementByIdForOrganization_returnOrder_products | null
) => SerialNumberInventoryMapping

const getColumns: (
  handleUpdateProductStatus: UpdateProductStatusType,
  handleChangeSerial: UpdateSerialNumberType,
  handleChangeInventoryReason: any,
  orderInventory: any,
  serialNumberErrors: string[],
  isShowReasonErrors: boolean,
  showSerialAndReason: boolean,
  isFurniture: boolean
) => ColumnI[] = (
  handleUpdateProductStatus,
  handleChangeSerial,
  handleChangeInventoryReason,
  orderInventory,
  serialNumberErrors,
  isShowReasonErrors,
  showSerialAndReason,
  isFurniture
) => [
  {
    header: isFurniture ? 'Furniture' : 'Product Name',
    id: 'sku.skuInformation.productTitle',
    cell: {
      sx: {
        maxWidth: '10rem',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      value: ({ sku }: Product) => sku.skuInformation.productTitle,
    },
  },
  ...(!showSerialAndReason
    ? [
        {
          header: 'Return Reason',
          id: 'returnReason',
          sx: { width: '25%' },
          cell: {
            value: ({ returnReason }: Product) =>
              readableReturnReason(returnReason),
          },
        },
      ]
    : []),
  {
    header: 'Return Order Item Status',
    id: 'status',
    sx: { width: '25%' },
    cell: {
      value: ({ status, id }: Product) => (
        <ReturnOrderItemStatusSelect
          status={status!}
          onUpdateProductStatus={handleUpdateProductStatus(id)}
        />
      ),
    },
  },
  ...(showSerialAndReason
    ? [
        {
          header: 'Deploy Reason',
          id: 'deployReason',
          sx: { width: '25%' },
          cell: {
            value: (product: Product) => (
              <DeployReasonSelect
                product={product}
                isShowReasonErrors={isShowReasonErrors}
                deployReason={orderInventory[product.id]}
                onChangeInventoryReason={handleChangeInventoryReason(
                  product.id
                )}
              />
            ),
          },
        },
      ]
    : []),
  ...(!isFurniture && showSerialAndReason
    ? [
        {
          header: 'Serial Number',
          id: 'serialNumber',
          sx: { width: '25%' },
          cell: {
            value: (product: Product) => (
              <SerialNumberInput
                serialNumberErrors={serialNumberErrors}
                product={product}
                handleChangeSerial={handleChangeSerial(product.id)}
              />
            ),
          },
        },
      ]
    : []),
]

type SerialNumberInventoryMapping = {
  inventoryId: string
  serialNumber?: string
  deployReason?: DeployReason
  deployStatus?: DeployStatus
}[]

const EditReturnOrderModal = ({
  handleClose,
  returnOrder,
  person,
  isReplacement,
}: OwnProps) => {
  const flags = useFlags()
  const confirmRetrievalFeatureOn = flags[FLAGS.SE_3407_CREATE_RETRIEVAL_METHOD]
  const updateShippingAddressFlag =
    flags[FLAGS.SE_3266_UPDATE_RETURN_ORDER_ADDRESS]

  const { clientId } = useParams<OrderPageParams>()

  const [orderProducts, setOrderProducts] = useState<Product[]>(
    returnOrder.products as Product[]
  )
  const [isUpdatingInventories, setIsUpdatingInventories] = useState(false)
  const [orderInventoryDeployReasons, setOrderInventoryDeployReasons] =
    useState<Record<string, string | undefined | null>>(
      (returnOrder.products as Product[])?.reduce(
        (acc, { inventory, id }) =>
          inventory?.deployReason
            ? { ...acc, [id]: inventory.deployReason }
            : acc,
        {}
      ) || {}
    )

  // before save this represents all duplicated serial numbers entered by user
  // after save attempt this represents all API responses where serial number already exists
  const [serialNumberErrors, setSerialNumberErrors] = useState<string[]>([])
  const [hasDuplicatedSerialsBeforeSave, setHasDuplicatedSerialsBeforeSave] =
    useState(false)
  const [canShowReasonErrors, setCanShowReasonErrors] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const { shippingAddress } = returnOrder
  const [addressFormValues, setAddressFormValues] = useState<AddressI>({
    addressLine1: shippingAddress?.addressLine1,
    addressLine2: shippingAddress?.addressLine2,
    locality: shippingAddress?.locality,
    administrativeArea: shippingAddress?.administrativeArea,
    postalCode: shippingAddress?.postalCode,
    countryCode: shippingAddress?.countryCode,
    phoneNumber: shippingAddress?.phoneNumber,
  })
  const [updateUserAddressChecked, setUpdatedUserAddressChecked] =
    useState(true)

  const [retrievalModalVisible, setRetrievalModalVisible] = useState(false)

  const { equipmentTableQuery, furnitureTableQuery } =
    useEquipmentFurnitureTableQueries(orderProducts)

  const orderRefetchQueries = [
    isReplacement
      ? 'getReplacementByIdForOrganization'
      : 'getOrderByIdForOrganisation',
  ]

  useEffect(() => {
    const productsWithDuplicatedSerialNumber = orderProducts
      .map(({ id, serialNumber }, index) =>
        orderProducts
          .slice(0, index)
          .find(
            ({ serialNumber: previousSerialNumber }) =>
              serialNumber && previousSerialNumber === serialNumber
          )
          ? id
          : undefined
      )
      .filter((id) => id) as string[]

    if (productsWithDuplicatedSerialNumber.length) {
      setSerialNumberErrors(productsWithDuplicatedSerialNumber)
      setHasDuplicatedSerialsBeforeSave(true)
    } else {
      setSerialNumberErrors([])
      setHasDuplicatedSerialsBeforeSave(false)
    }
  }, [orderProducts])

  /**
   * Useful for when the component re-renders after saving
   * when the confirm button is clicked.  It helps re-render
   * the view with additional legacy inventory that may have
   * been added.  It essentially keeps the view in sync while
   * the user is confirming a return in that modal.
   */
  useEffect(() => {
    if (retrievalModalVisible) {
      setOrderProducts(returnOrder.products as Product[])
    }
  }, [returnOrder.products, retrievalModalVisible])

  const [updatePersonMutation, { loading: updatePersonMutationLoading }] =
    useMutation<updatePersonBySlugVariables>(UPDATE_PERSON_BY_SLUG)

  const [updateInventoryById] = useMutation<
    updateInventoryByIdType,
    updateInventoryByIdVariables
  >(UPDATE_INVENTORY_BY_ID, {
    refetchQueries: orderRefetchQueries,
  })
  const client = useApolloClient()

  const getDeployStatusForInventoryUpdate = (
    deployReason: string
  ): DeployStatus =>
    deployReason === DeployReason.SENT_TO_OFFICE
      ? DeployStatus.DEPLOYED
      : DeployStatus.ARCHIVED

  const getChangedInventoryMutationParams: GetChangedInventoryIdSerialNumbersType =
    (acc, orderItem) => {
      if (!orderItem) return acc

      const {
        id: orderItemId,
        serialNumber: initialSerialNumber,
        inventory,
      } = orderItem
      if (!inventory?.id) return acc

      const newSerialNumber = orderProducts.find(
        ({ id: changedProductId }) => changedProductId === orderItemId
      )?.serialNumber
      const orderItemStatus = orderProducts.find(
        (item) => item.id === orderItemId
      )?.status
      const deployReason = orderInventoryDeployReasons[orderItemId]

      if (!deployReason && newSerialNumber === initialSerialNumber) {
        return acc
      }

      return [
        ...acc,
        {
          inventoryId: inventory.id,
          ...(newSerialNumber && { serialNumber: newSerialNumber }),
          ...(deployReason &&
            orderItemStatus === OrderStatus.NOT_RETURNED && {
              deployReason,
              deployStatus: getDeployStatusForInventoryUpdate(deployReason),
            }),
        },
      ]
    }

  const getInventoryToUpdate = () => {
    return returnOrder.products?.reduce(
      getChangedInventoryMutationParams,
      [] as SerialNumberInventoryMapping
    )
  }

  const updateAssociatedInventory = async (
    inventoryToUpdate: SerialNumberInventoryMapping
  ) => {
    try {
      setIsUpdatingInventories(true)
      await Promise.all(
        inventoryToUpdate.map(
          ({ inventoryId, serialNumber, deployReason, deployStatus }) =>
            updateInventoryById({
              variables: {
                inventoryId,
                updateInventory: {
                  serialNumber,
                  deployReason,
                  deployStatus,
                  active: getActiveValueForInventoryUpdate(
                    deployStatus,
                    deployReason
                  ),
                },
              },
            })
        )
      )
    } catch (error) {
      throw new Error(
        `Failed to update ${person.forename}'s inventory. Please try again.`
      )
    } finally {
      setIsUpdatingInventories(false)
    }
  }

  const updatePersonAddress = async (validatedAddress: AddressI) => {
    try {
      await updatePersonMutation({
        variables: {
          slug: person.slug,
          updatePerson: {
            address: { ...validatedAddress },
          },
        },
      })
    } catch (error) {
      throw new Error("Failed to update the user's address")
    }
  }

  const updateOtherReturnData = async (
    validatedAddress: AddressI | null,
    submittingViaConfirmButton = false
  ) => {
    try {
      const inventoryToUpdate = getInventoryToUpdate()

      if (inventoryToUpdate?.length)
        await updateAssociatedInventory(inventoryToUpdate)

      if (updateUserAddressChecked && validatedAddress)
        await updatePersonAddress(validatedAddress)

      if (submittingViaConfirmButton) {
        setRetrievalModalVisible(true)
      } else {
        enqueueSnackbar(`Successfully updated ${person.forename}'s order`)
        handleClose()
      }
    } catch (error: unknown) {
      let message = 'Failed when updating other return data.  Please try again.'
      if (error instanceof Error) message = error.message

      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  const [
    updateReturnOrder,
    { loading: isUpdatingReturnOrder, error: isError },
  ] = useMutation<updateReturnOrderType, updateReturnOrderVariables>(
    UPDATE_RETURN_ORDER,
    {
      refetchQueries: orderRefetchQueries,
    }
  )

  const { data: existingRetrievals, loading: loadingExistingRetrievals } =
    useQuery<
      getRetrievalsByReturnOrderId,
      getRetrievalsByReturnOrderIdVariables
    >(GET_RETRIEVALS_BY_RETURN_ORDER_ID, {
      variables: {
        returnOrderId: returnOrder.id,
      },
      skip: !confirmRetrievalFeatureOn,
    })

  const updateProductIdToStatus = (productId: string, newStatus: OrderStatus) =>
    setOrderProducts((currentOrderProducts) => {
      if (!currentOrderProducts) return currentOrderProducts

      const updateChangedProductsStatus = (product: Product) => {
        if (product.id === productId) return { ...product, status: newStatus }
        return product
      }

      return currentOrderProducts.map(updateChangedProductsStatus)
    })

  const handleUpdateProductStatus: UpdateProductStatusType = useCallback(
    (productId) => (event) => {
      const newStatus = event.target.value as OrderStatus
      const getNewReason = () => {
        if (newStatus !== OrderStatus.NOT_RETURNED) return undefined

        const productHasInventory = orderProducts.find(
          ({ id }) => id === productId
        )!.inventory?.id

        if (productHasInventory) return null
        return undefined
      }

      updateProductIdToStatus(productId, newStatus)
      setOrderInventoryDeployReasons((currentOrderInventory) => ({
        ...currentOrderInventory,
        [productId]: getNewReason(),
      }))
    },
    [orderProducts]
  )

  const handleChangeOrderInventory = useCallback(
    (productId) => (event: SelectChangeEvent) => {
      const newReason = event.target.value

      setOrderInventoryDeployReasons((currentOrderInventory) => ({
        ...currentOrderInventory,
        [productId]: newReason,
      }))
    },
    []
  )

  const getUpdatedReturnOrder: (
    validatedAddress: AddressI | null
  ) => UpdateReturnOrder = useCallback(
    (validatedAddress) => {
      const addressForUpdatedReturnOrder = validatedAddress
        ? { shippingAddress: { ...validatedAddress } }
        : {}

      return {
        id: returnOrder.id,
        ...addressForUpdatedReturnOrder,
      }
    },
    [returnOrder.id]
  )

  const getUpdatedReturnOrderItems: () => UpdateReturnOrderItem[] = () =>
    orderProducts
      .filter(
        (product): product is Product & { status: OrderStatus } =>
          !!product.status &&
          [...SELECTABLE_STATUS_OPTIONS, OrderStatus.CANCELLED].includes(
            product.status
          )
      )
      .map(({ id, status, serialNumber }) => ({
        id,
        status,
        serialNumber: serialNumber || null,
        // allow for rollback of status from not returned to pending
        forceUpdate: true,
      }))

  const checkSerialValidity = async () => {
    const productsWithSerial = orderProducts
      .filter(({ serialNumber }) => serialNumber)
      .map(({ inventory, serialNumber, id }) => ({
        inventoryId: inventory?.id,
        serialNumber: serialNumber as string,
        orderItemId: id,
      }))

    setSerialNumberErrors([])

    return Promise.all(
      productsWithSerial.map(({ inventoryId, serialNumber, orderItemId }) =>
        client
          .query<getInventoryBySerial, getInventoryBySerialVariables>({
            variables: { serialNumber },
            query: GET_INVENTORY_BY_SERIAL,
          })
          .then(
            (data) => {
              if (data?.data.getInventoryBySerial) {
                const { id: existingInventoryWithSerial } =
                  data?.data.getInventoryBySerial

                // if legacy item but serial already assigned to inventory
                // or item has inventory, but serial assigned to different inventory
                if (
                  (!inventoryId && existingInventoryWithSerial) ||
                  existingInventoryWithSerial !== inventoryId
                ) {
                  setSerialNumberErrors((current) => [...current, orderItemId])
                  throw new Error(`${serialNumber} already exists `)
                }
              }
            },
            (e: any) => {
              // case where inventory is not found is success - this avoids creating duplicates
              if (!e.message.includes('Unable to find Inventory with serial')) {
                enqueueSnackbar(
                  `An unexpected error occurred updating inventory for this order: ${e.message}`,
                  { variant: 'error' }
                )
                throw new Error(e.message)
              }
            }
          )
      )
    )
  }

  const isDeployReasonEmptyForNotReturnedItem = () =>
    Object.values(orderInventoryDeployReasons).includes(null)

  const checkDeployReasonValidity = async () => {
    if (isDeployReasonEmptyForNotReturnedItem()) {
      setCanShowReasonErrors(true)
      throw new Error(
        'You must select deploy reason for all not returned items'
      )
    }
  }

  const validateForm = async () =>
    Promise.all(
      [checkSerialValidity, checkDeployReasonValidity].map((validationFnc) =>
        validationFnc()
      )
    )

  const handleSubmitEditReturnOrder = async (
    submittingViaConfirmButton = false
  ) => {
    try {
      let validatedAddress: AddressI | null = null

      if (flags[FLAGS.SE_3494_EDIT_RETURN_ORDER_SERIAL_AND_DEPLOY_REASON])
        await validateForm()

      if (updateShippingAddressFlag) {
        validatedAddress = await validateAddress(addressFormValues)
        setAddressFormValues(validatedAddress)
      }

      updateReturnOrder({
        onCompleted: () =>
          updateOtherReturnData(validatedAddress, submittingViaConfirmButton),
        variables: {
          updatedReturnOrder: getUpdatedReturnOrder(validatedAddress),
          updatedReturnOrderItems: getUpdatedReturnOrderItems(),
        },
        awaitRefetchQueries: submittingViaConfirmButton,
      })
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  const handleChangeSerial: UpdateSerialNumberType = (productId) => (event) => {
    const {
      target: { value },
    } = event

    setOrderProducts((currentOrderProducts) => {
      if (!currentOrderProducts) return currentOrderProducts
      return currentOrderProducts.map((orderProduct) =>
        orderProduct.id === productId
          ? { ...orderProduct, serialNumber: value }
          : orderProduct
      )
    })
  }

  const columns = useCallback(
    (isFurniture: boolean) =>
      getColumns(
        handleUpdateProductStatus,
        handleChangeSerial,
        handleChangeOrderInventory,
        orderInventoryDeployReasons,
        serialNumberErrors,
        canShowReasonErrors,
        flags[FLAGS.SE_3494_EDIT_RETURN_ORDER_SERIAL_AND_DEPLOY_REASON],
        isFurniture
      ),
    [
      canShowReasonErrors,
      flags,
      handleChangeOrderInventory,
      handleUpdateProductStatus,
      orderInventoryDeployReasons,
      serialNumberErrors,
    ]
  )

  const isSaving =
    isUpdatingReturnOrder ||
    isUpdatingInventories ||
    updatePersonMutationLoading
  const continueButtonsDisabled =
    isSaving ||
    hasDuplicatedSerialsBeforeSave ||
    isDeployReasonEmptyForNotReturnedItem()

  const continuePropsForSave = {
    label: 'Save',
    onClick: () => handleSubmitEditReturnOrder(),
    disabled: continueButtonsDisabled,
  }

  const doesReturnHaveRetrievals = () =>
    !!existingRetrievals?.getRetrievalsByReturnOrderId?.length &&
    existingRetrievals.getRetrievalsByReturnOrderId.some(
      /**
       * HACK: Not mapped is a part of the results; this contains the return order items
       * that do not yet have a retrieval method.  Thus, we can ignore that one.
       */
      (retrievalMethod) =>
        !!retrievalMethod?.items?.length &&
        retrievalMethod.methodType !== 'NOT_MAPPED'
    )

  const canReturnOrderBeRetrieved = () => {
    return (
      !doesReturnHaveRetrievals() &&
      returnOrder.orderStatus !== OrderStatus.COMPLETED &&
      returnOrder.orderStatus !== OrderStatus.CANCELLED
    )
  }

  const addressIsIncomplete =
    updateShippingAddressFlag && !isAddressComplete(addressFormValues, true)

  const canReturnOrderBeConfirmed = () => {
    return (
      hasPendingReturnOrderProductStatus(orderProducts) &&
      ((canReturnOrderBeRetrieved() &&
        returnOrder.orderStatus === OrderStatus.PROCESSING) ||
        returnOrder.orderStatus === OrderStatus.SUBMITTED) &&
      !addressIsIncomplete
    )
  }

  const getConfirmPropTooltip = (): string => {
    if (!canReturnOrderBeRetrieved())
      return 'This return order is already confirmed.'
    if (addressIsIncomplete)
      return 'This return order has an incomplete address.'
    if (!canReturnOrderBeConfirmed())
      return 'This return order is not eligible to be confirmed'
    return ''
  }

  const continuePropsForConfirmingRetrieval = {
    label: 'Confirm',
    onClick: () => handleSubmitEditReturnOrder(true),
    disabled: isSaving || !canReturnOrderBeConfirmed(),
    loading: loadingExistingRetrievals,
    tooltip: getConfirmPropTooltip(),
  }

  const handleCloseConfirmModal = (closingPostSubmit?: boolean) => {
    setRetrievalModalVisible(false)
    if (closingPostSubmit) handleClose()
  }

  return (
    <FullscreenModal
      continueProps={
        confirmRetrievalFeatureOn
          ? continuePropsForConfirmingRetrieval
          : continuePropsForSave
      }
      secondaryContinueProps={
        confirmRetrievalFeatureOn ? continuePropsForSave : undefined
      }
      handleClose={handleClose}
      errorText={isError?.message}
      open
      title={`Edit return ${!isReplacement ? 'order ' : ''}for ${
        person.forename
      } ${person.surname}`}
    >
      {isSaving && <Loader />}
      <Page.TitleSection
        sectionTitle={`${
          isReplacement ? 'Replacement' : 'Return'
        } started on ${new Date(returnOrder.createdAt).toLocaleDateString()}`}
        sectionSubtitle={`Return ID: ${returnOrder.id}`}
      />
      {updateShippingAddressFlag && (
        <EditReturnOrderAddressForm
          clientId={clientId}
          addressFormValues={addressFormValues}
          setAddressFormValues={setAddressFormValues}
          personForename={person.forename || ''}
          setUpdatedUserAddressChecked={setUpdatedUserAddressChecked}
          updateUserAddressChecked={updateUserAddressChecked}
        />
      )}
      <Table
        noDataMessage="No Products listed in this return order"
        tableId="non-furniture-return-order-items"
        query={equipmentTableQuery}
        columns={columns(false)}
      />
      {!!furnitureTableQuery.data.data.length && (
        <Box sx={{ mt: 2 }}>
          <Divider sx={{ mb: 2 }} />
          <Table
            tableId="furniture-return-order-items"
            query={furnitureTableQuery}
            columns={columns(true)}
          />
        </Box>
      )}
      {retrievalModalVisible && (
        <ConfirmRetrievalMethodsModal
          closeModal={handleCloseConfirmModal}
          orderRefetchQueries={orderRefetchQueries}
          personName={`${person.forename} ${person.surname}`}
          shippingAddress={returnOrder.shippingAddress}
        />
      )}
    </FullscreenModal>
  )
}

export default EditReturnOrderModal
