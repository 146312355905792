import React from 'react'
import { useMutation } from '@apollo/client'
import {
  TextField,
  ListItem,
  ListItemText,
  InputAdornment,
} from '@mui/material'
import { ArrowDropDown } from '@mui/icons-material'
import { useSnackbar } from 'notistack'

import {
  GET_ALL_WARRANTY_SKU_METADATA_OPTION_VALUES,
  getAllWarrantySKUMetadataOptionValues_getAllWarrantySKUMetadataOptionValues,
  CREATE_WARRANTY_SKU_METADATA_OPTION_VALUE,
  createWarrantySKUMetadataOptionValue,
  createWarrantySKUMetadataOptionValueVariables,
} from '@firstbase/data/Warranties'
import { CreateableSelect } from '@firstbase/components/molecules/CreateableSelect'
import { formatSkuMetadataFieldLabel } from '@utils/formatSkuMetadataFieldLabel'

import { CommonProps } from '../types'

interface FieldSelectCreateableProps {
  dispatch: CommonProps['dispatch']
  field: getAllWarrantySKUMetadataOptionValues_getAllWarrantySKUMetadataOptionValues
  value: string
}

export const FieldSelectCreateable = ({
  field,
  value,
  dispatch,
}: FieldSelectCreateableProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const [createWarrantySkuMetadataOptionValue] = useMutation<
    createWarrantySKUMetadataOptionValue,
    createWarrantySKUMetadataOptionValueVariables
  >(CREATE_WARRANTY_SKU_METADATA_OPTION_VALUE, {
    refetchQueries: [GET_ALL_WARRANTY_SKU_METADATA_OPTION_VALUES],
    onError: () => {
      dispatch({
        type: 'REMOVE_METADATA_FIELD',
        payload: { fieldId: field.id },
      })
      enqueueSnackbar(
        'There was an error when trying to create the new metadata option',
        { variant: 'error' }
      )
    },
  })

  const handleCreate = (newValue: string) => {
    createWarrantySkuMetadataOptionValue({
      variables: {
        request: {
          fieldId: field.id,
          value: newValue,
        },
      },
    })
  }

  const options = field.values.map((valueObject) => ({
    id: valueObject.value,
    label: valueObject.value,
  }))

  return (
    <CreateableSelect
      id={field.id}
      value={value ? { id: value, label: value } : null}
      options={options}
      onChange={(event, newValue) => {
        const selectedValue = (() => {
          if (typeof newValue === 'string') {
            const optionObject = options.find(
              (option) => option.label === newValue
            )
            const optionAlreadyExists = !!optionObject
            return optionAlreadyExists ? optionObject : newValue
          }

          if (!!newValue && 'inputValue' in newValue) return newValue.inputValue

          // TS implementation for handling multi-select. Not really the case for this component, though.
          return newValue instanceof Array ? '' : newValue
        })()

        dispatch({
          type: 'SET_METADATA_FIELD',
          payload: {
            fieldId: field.id,
            value:
              typeof selectedValue === 'string'
                ? selectedValue
                : selectedValue?.label ?? '',
            name: field.name,
          },
        })

        if (typeof selectedValue === 'string') {
          handleCreate(selectedValue)
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={formatSkuMetadataFieldLabel(field.name)}
          required={field.required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <ArrowDropDown />
              </InputAdornment>
            ),
          }}
        />
      )}
      /**
       * used to overwrite prop above, so that the add new option
       * appears correctly in the dropdown option component
       */
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ListItemText primary={option.label} />
        </ListItem>
      )}
      disableClearable
    />
  )
}
