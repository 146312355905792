import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import {
  CREATE_ORGANIZATION_WARRANTY_TYPE_ASSIGNMENT,
  createOrganizationWarrantyTypeAssignment as createOrganizationWarrantyTypeAssignmentType,
  createOrganizationWarrantyTypeAssignmentVariables,
  UPDATE_ORGANIZATION_WARRANTY_TYPE_ASSIGNMENT,
  updateOrganizationWarrantyTypeAssignment as updateOrganizationWarrantyTypeAssignmentType,
  updateOrganizationWarrantyTypeAssignmentVariables,
  REMOVE_ORGANIZATION_WARRANTY_TYPE_ASSIGNMENT,
  removeOrganizationWarrantyTypeAssignment as removeOrganizationWarrantyTypeAssignmentType,
  removeOrganizationWarrantyTypeAssignmentVariables,
  GET_ORGANIZATION_WARRANTIES,
  getOrganizationWarranties,
  getOrganizationWarrantiesVariables,
} from '@firstbase/data/Warranties'

import { ClientPageParams } from '@firstbase/types/Pages'

export const useClientWarrantiesMutations = () => {
  const { clientId } = useParams<ClientPageParams>()
  const { enqueueSnackbar } = useSnackbar()

  const [createAssigmentMutation] = useMutation<
    createOrganizationWarrantyTypeAssignmentType,
    createOrganizationWarrantyTypeAssignmentVariables
  >(CREATE_ORGANIZATION_WARRANTY_TYPE_ASSIGNMENT, {
    onError: (error) => {
      enqueueSnackbar(
        'Oops! Something went wrong when creating the assignment. Please share this with the team.',
        { variant: 'error' }
      )
      // eslint-disable-next-line no-console
      console.error(error)
    },
    onCompleted: () => {
      enqueueSnackbar('Assignment created successfully', { variant: 'success' })
    },
  })

  const [updateAssignmentMutation] = useMutation<
    updateOrganizationWarrantyTypeAssignmentType,
    updateOrganizationWarrantyTypeAssignmentVariables
  >(UPDATE_ORGANIZATION_WARRANTY_TYPE_ASSIGNMENT, {
    onError: (error) => {
      enqueueSnackbar(
        'Oops! Something went wrong when updating the assignment. Please share this with the team.',
        { variant: 'error' }
      )
      // eslint-disable-next-line no-console
      console.error(error)
    },
    onCompleted: () => {
      enqueueSnackbar('Assignment updated successfully', { variant: 'success' })
    },
  })

  const [removeAssignmentMutation] = useMutation<
    removeOrganizationWarrantyTypeAssignmentType,
    removeOrganizationWarrantyTypeAssignmentVariables
  >(REMOVE_ORGANIZATION_WARRANTY_TYPE_ASSIGNMENT, {
    onError: (error) => {
      enqueueSnackbar(
        'Oops! Something went wrong when disabling the assignment. Please share this with the team.',
        { variant: 'error' }
      )
      // eslint-disable-next-line no-console
      console.error(error)
    },
    onCompleted: () => {
      enqueueSnackbar('Assignment removed successfully', { variant: 'success' })
    },
  })

  const createOrganizationWarrantyTypeAssignment = (
    warrantyId: string,
    term: number
  ) =>
    createAssigmentMutation({
      variables: {
        request: {
          organizationId: clientId,
          term,
          warrantyTypeId: warrantyId,
        },
      },
      optimisticResponse: {
        createOrganizationWarrantyTypeAssignment: {
          __typename: 'OrganizationWarrantyTypeAssignment',
          id: 'optimistic-response-id',
          term,
          tier: null,
          warrantyType: {
            __typename: 'WarrantyType',
            id: warrantyId,
          },
        },
      },
      update: (cache, data) => {
        const existingWarranties = cache.readQuery<
          getOrganizationWarranties,
          getOrganizationWarrantiesVariables
        >({
          query: GET_ORGANIZATION_WARRANTIES,
          variables: {
            organizationId: clientId,
          },
        })

        if (existingWarranties) {
          cache.writeQuery({
            query: GET_ORGANIZATION_WARRANTIES,
            variables: {
              organizationId: clientId,
            },
            data: {
              ...existingWarranties,
              getOrganizationWarrantyTypeAssignments: [
                ...existingWarranties.getOrganizationWarrantyTypeAssignments,
                {
                  __typename: 'OrganizationWarrantyTypeAssignment',
                  id: data.data?.createOrganizationWarrantyTypeAssignment.id,
                  term,
                  tier: null,
                  warrantyType: {
                    __typename: 'WarrantyType',
                    id: warrantyId,
                  },
                },
              ],
            },
          })
        }
      },
    })

  const updateOrganizationWarrantyTypeAssignment = (
    assignmentId: string,
    warrantyId: string,
    { term, tier }: { term: number; tier: string }
  ) =>
    updateAssignmentMutation({
      variables: {
        request: {
          id: assignmentId,
          organizationId: clientId,
          tier,
          term,
        },
      },
      optimisticResponse: {
        updateOrganizationWarrantyTypeAssignment: {
          __typename: 'OrganizationWarrantyTypeAssignment',
          id: assignmentId,
          term,
          // Handle empty string
          tier: tier || null,
          warrantyType: {
            __typename: 'WarrantyType',
            id: warrantyId,
          },
        },
      },
    })

  const removeOrganizationWarrantyTypeAssignment = (assignmentId: string) =>
    removeAssignmentMutation({
      variables: {
        id: assignmentId,
        organizationId: clientId,
      },
      optimisticResponse: {
        removeOrganizationWarrantyTypeAssignment: assignmentId,
      },
      /**
       * Writing to the query is more performant than using evict and gc.
       */
      update: (cache) => {
        const existingWarranties = cache.readQuery<
          getOrganizationWarranties,
          getOrganizationWarrantiesVariables
        >({
          query: GET_ORGANIZATION_WARRANTIES,
          variables: {
            organizationId: clientId,
          },
        })

        if (existingWarranties) {
          cache.writeQuery({
            query: GET_ORGANIZATION_WARRANTIES,
            variables: {
              organizationId: clientId,
            },
            data: {
              ...existingWarranties,
              getOrganizationWarrantyTypeAssignments:
                existingWarranties.getOrganizationWarrantyTypeAssignments.filter(
                  (assignment) => assignment.id !== assignmentId
                ),
            },
          })
        }
      },
    })

  return {
    createOrganizationWarrantyTypeAssignment,
    updateOrganizationWarrantyTypeAssignment,
    removeOrganizationWarrantyTypeAssignment,
  }
}
