import { gql } from '@apollo/client'

export const CREATE_PERSON_FOR_ORGANIZATION = gql`
  mutation CreatePersonForOrganization(
    $slug: String!
    $createPerson: CreatePerson!
  ) {
    createPersonForOrganization(
      organizationSlug: $slug
      createPerson: $createPerson
    ) {
      id
      forename
      surname
      email
    }
  }
`

// This is NOT gql format intentionally since we use axios to post this request
// This is necessary since apollo does not post multipart/form-data
// which is requires for posting files to the backend
export const CREATE_NEW_ORGANIZATION = `
  mutation createOrganization(
    $name: String!
    $status: OrganizationStatus
    $refreshMonths: Int
    $doNotShipBefore: Boolean
    $whiteLabeled: Boolean
    $apiAccess: Boolean
    $inventoryOrdering: Boolean
    $idp: Boolean
    $abmNumber: String
    $machineRetentionLength: Int
    $logo: Upload
    $warehouseIds: [ID]
    $whiteLabel: UpdateWhiteLabel
    $integrations: UpdateOrganizationIntegrations
    $allowHAAS: Boolean
    $subscriptionLength: Int
    $code: String
    $categoryGroupDiscounts: [UpdateCategoryGroupDiscount!]
  ) {
    createOrganization(
      createOrganization: {
        name: $name
        status: $status
        refreshMonths: $refreshMonths
        doNotShipBefore: $doNotShipBefore
        whiteLabeled: $whiteLabeled
        apiAccess: $apiAccess
        inventoryOrdering: $inventoryOrdering
        idp: $idp
        abmNumber: $abmNumber
        machineRetentionLength: $machineRetentionLength
        warehouseIds: $warehouseIds
        whiteLabel: $whiteLabel
        integrations: $integrations
        allowHAAS: $allowHAAS
        subscriptionLength: $subscriptionLength
        code: $code
        categoryGroupDiscounts: $categoryGroupDiscounts
      }
      logo: $logo
    ) {
      id
      slug
    }
  }
`

export const UPDATE_ORGANIZATION_BY_SLUG = `
  mutation updateOrganizationBySlug(
    $slug: String!
    $name: String!
    $status: OrganizationStatus
    $refreshMonths: Int
    $doNotShipBefore: Boolean
    $whiteLabeled: Boolean
    $apiAccess: Boolean
    $inventoryOrdering: Boolean
    $idp: Boolean
    $abmNumber: String
    $machineRetentionLength: Int
    $logo: Upload
    $whiteLabel: UpdateWhiteLabel
    $pos: Date
    $pauseReason: PauseReason
    $integrations: UpdateOrganizationIntegrations
    $allowHAAS: Boolean
    $subscriptionLength: Int
    $code: String
    $categoryGroupDiscounts: [UpdateCategoryGroupDiscount!]
  ) {
    updateOrganizationBySlug(
      slug: $slug
      updateOrganization: {
        name: $name
        status: $status
        refreshMonths: $refreshMonths
        doNotShipBefore: $doNotShipBefore
        whiteLabeled: $whiteLabeled
        apiAccess: $apiAccess
        inventoryOrdering: $inventoryOrdering
        idp: $idp
        abmNumber: $abmNumber
        machineRetentionLength: $machineRetentionLength
        whiteLabel: $whiteLabel
        pos: $pos
        pauseReason: $pauseReason
        integrations: $integrations
        allowHAAS: $allowHAAS
        subscriptionLength: $subscriptionLength
        code: $code
        categoryGroupDiscounts: $categoryGroupDiscounts
      }
      logo: $logo
    ) {
      id
      slug
    }
  }
`

export const DELETE_SKU_ASSIGNMENT_FOR_ORGANIZATION = gql`
  mutation DeleteSKUAssignmentForOrganization($skuId: ID!, $orgId: ID!) {
    deleteSKUAssignmentForOrganization(skuId: $skuId, orgId: $orgId)
  }
`

export const UPDATE_CATEGORY_RETURN_REQUIRED = gql`
  mutation updateCategoryReturnRequired(
    $slug: String!
    $noReturnRequiredCategoryCodes: [String]!
    $returnRequiredCategoryCodes: [String]!
  ) {
    updateOrganizationBySlug(
      slug: $slug
      updateOrganization: {
        noReturnRequiredCategoryCodes: $noReturnRequiredCategoryCodes
        returnRequiredCategoryCodes: $returnRequiredCategoryCodes
      }
    ) {
      id
    }
  }
`

export const UPDATE_CATEGORY_REFRESH_RATE = gql`
  mutation updateCategoryRefreshRate(
    $slug: String!
    $updateCategoryRefreshMonths: UpdateCategoryRefreshMonths!
  ) {
    updateOrganizationBySlug(
      slug: $slug
      updateOrganization: {
        categoryRefreshMonths: [$updateCategoryRefreshMonths]
      }
    ) {
      id
    }
  }
`

export const UPDATE_MSA_WARRANTY_NUMBER = gql`
  mutation updateMsaWarrantyNumber($slug: String!, $msaWarrantyNumber: String) {
    updateOrganizationBySlug(
      slug: $slug
      updateOrganization: { msaWarrantyNumber: $msaWarrantyNumber }
    ) {
      id
      msaWarrantyNumber
    }
  }
`
