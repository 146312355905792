import { useAuthState } from '@firstbase/context/auth/AuthProvider'
import { Redeem } from '@mui/icons-material'
import { Button, Dialog, DialogTitle, Grow } from '@mui/material'
import React, { useState } from 'react'

import { snowflakeCursor } from 'cursor-effects'
import { useFlags } from 'launchdarkly-react-client-sdk'
import FLAGS from '@firstbase/constants/featureFlags'
import { confettiSequence } from '@firstbase/views/NewYear'

type Props = {
  personId?: string
  personName?: string | null
}

const emojis = ['🎄', '🎅', '⛄️']

const Christmas = ({ personId, personName }: Props) => {
  const auth = useAuthState()
  const flags = useFlags()
  const [showMessage, setShowMessage] = useState(false)

  if (!personId || !flags[FLAGS.SE_3028_CHRISTMAS]) return null
  if (auth.authState?.idToken?.claims.personId !== personId) return null

  const handleChristmasClick = async () => {
    await confettiSequence(emojis, setShowMessage)

    const cur = new snowflakeCursor()
    /**
     * Below timeout not destroyed on unmount, because it
     * should keep the cursor around until 15m has passed
     * regardless of this component being in DOM or not
     */
    setTimeout(() => cur.destroy(), 15 * 60 * 1000)
  }

  return (
    <>
      <Button variant="contained" onClick={handleChristmasClick}>
        <Redeem sx={{ mr: 1 }} /> A present just for you!
      </Button>
      <Dialog
        TransitionComponent={Grow}
        transitionDuration={{ enter: 1000, exit: 200 }}
        open={showMessage}
        onClose={() => setShowMessage(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          variant="h2"
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          🎅 Happy Holidays {personName} 🧑‍🎄
        </DialogTitle>
      </Dialog>
    </>
  )
}

export default Christmas
