import React from 'react'
import { ApolloProvider } from '@apollo/client'

import Page from '@firstbase/components/atoms/Page'
import TabGroup from '@firstbase/components/molecules/TabGroup'
import assistantClient from '@firstbase/config/apollo/assistantClient'

import Chat from './Chat'
import { ExportChats } from './ExportChats'
import Documents from './Documents'

const Assistant = () => (
  <ApolloProvider client={assistantClient()}>
    <Page title="Assistant">
      <TabGroup queryParam>
        <TabGroup.Page label="Chat" id="chat">
          <Chat />
        </TabGroup.Page>
        <TabGroup.Page label="Export" id="export">
          <ExportChats />
        </TabGroup.Page>
        <TabGroup.Page label="Documents" id="documents">
          <Documents />
        </TabGroup.Page>
      </TabGroup>
    </Page>
  </ApolloProvider>
)

export default Assistant
