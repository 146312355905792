import { getOrderByIdForOrganisation_getOrderByIdForOrganisation_shippingAddress } from '@firstbase/data/Order/__generated__/getOrderByIdForOrganisation'
import { getReplacementByIdForOrganization_getReplacementByIdForOrganization_returnOrder_shippingAddress } from '@firstbase/data/Order/__generated__/getReplacementByIdForOrganization'

export interface ConfirmMethodSection {
  comment: string
  onChangeComment: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
}

export type RetrievalAddress =
  | getOrderByIdForOrganisation_getOrderByIdForOrganisation_shippingAddress
  | getReplacementByIdForOrganization_getReplacementByIdForOrganization_returnOrder_shippingAddress
  | null

export enum RetrievalMethodOptionValues {
  RETURN_KIT = 'RETURN_KIT',
  INBOUND_DIGITAL = 'INBOUND_DIGITAL',
  INBOUND_PHYSICAL = 'INBOUND_PHYSICAL',
}

export type RetrievalMethods = 'RETURN_KIT' | 'INBOUND' | 'PL'
