import { ReactComponent as ArrowDown } from '@firstbase/assets/ArrowDown.svg'
import Page from '@firstbase/components/atoms/Page'
import ProtectedChildren from '@firstbase/components/atoms/ProtectedChildren'
import TablePaginationActions from '@firstbase/components/atoms/Table/TablePagination'
import FullscreenModal from '@firstbase/components/molecules/FullscreenModal'
import { Role } from '@firstbase/context/auth/AuthProvider'
import { useGetProductTiers, usePagingAndSorting } from '@firstbase/hooks'
import { theme } from '@firstbase/muiTheme'
import { FeatureStatus, ProductTierStatus } from '@globalTypes'
import { zodResolver } from '@hookform/resolvers/zod'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import {
  Alert,
  Button,
  Card,
  FormHelperText,
  IconButton,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { z } from 'zod'
import { useGetProductTierFeatures } from './useGetProductTierFeatures'
import { useProductTierMutations } from './useProductTierMutations'

const pageTitle = 'Edit product tier'

const formSchema = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
  status: z.nativeEnum(ProductTierStatus),
  featureIds: z.array(z.string()).min(1, 'At least one feature is required'),
})

export const ProductTierEditFormPage = () => {
  const history = useHistory()
  const params = useParams<{
    productTierId?: string
  }>()
  const { enqueueSnackbar } = useSnackbar()

  const { pagingAndSorting, setPagingAndSorting } = usePagingAndSorting()

  const { sortedFeatures: features, totalElements } = useGetProductTierFeatures(
    {
      pagingAndSorting,
    }
  )
  const { productTierById } = useGetProductTiers(
    undefined,
    params.productTierId
  )
  const { updateProductTier, isUpdateProductTierLoading } =
    useProductTierMutations()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      description: '',
      status: ProductTierStatus.ACTIVE,
      featureIds: [],
    },
  })

  useEffect(() => {
    if (!productTierById) {
      return
    }

    const {
      name,
      description,
      status,
      features: productTierFeatures,
    } = productTierById

    form.reset({
      name,
      description: description ?? '',
      status,
      featureIds: productTierFeatures?.map(({ id }) => id) ?? [],
    })
  }, [productTierById, form])

  const onSubmit = async ({
    name,
    status,
    description,
    featureIds,
  }: z.infer<typeof formSchema>) => {
    if (!productTierById) {
      form.setError('root', {
        type: 'manual',
        message: 'Product tier not found',
      })
      return
    }

    try {
      const { data } = await updateProductTier({
        variables: {
          params: {
            id: productTierById.id,
            name,
            description,
            status,
            features: featureIds,
          },
        },
      })

      if (data?.updateProductTier?.productTier) {
        enqueueSnackbar('Product tier updated successfully', {
          variant: 'success',
        })
      }
    } catch (error) {
      enqueueSnackbar('Failed to update product tier', { variant: 'error' })
    }

    history.goBack()
  }

  return (
    <Page title={pageTitle}>
      <FullscreenModal
        open={true}
        handleClose={history.goBack}
        handleBack={history.goBack}
        title={pageTitle}
      >
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-8"
        >
          <div className="space-y-6">
            <Page.TitleSection sectionTitle={pageTitle} />

            <Controller
              control={form.control}
              name="name"
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    className="max-w-xl"
                    label="Name"
                    fullWidth
                    {...field}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )
              }}
            />

            <Controller
              control={form.control}
              name="description"
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    className="max-w-xl"
                    label="Description"
                    fullWidth
                    multiline
                    {...field}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )
              }}
            />

            <Controller
              control={form.control}
              name="status"
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    className="max-w-xl"
                    select
                    label="Status"
                    fullWidth
                    {...field}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  >
                    {Object.values(FeatureStatus).map((status) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </div>

          <div>
            <Page.TitleSection
              sectionTitle="Features"
              sectionSubtitle="List of features that should be available under the Product Tier"
              subtitleAction={CreateProductFeatureButton}
            />

            <Controller
              control={form.control}
              name="featureIds"
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <Card variant="outlined">
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 650 }}
                        aria-label="product tier features table"
                      >
                        <TableHead
                          sx={{
                            backgroundColor: theme.palette.grey[300],
                            color: theme.palette.secondary.main,
                          }}
                        >
                          <TableRow>
                            <TableCell>Category</TableCell>

                            <TableCell>Name</TableCell>

                            <TableCell>Description</TableCell>

                            <TableCell>Enabled?</TableCell>

                            <TableCell />
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {(!features || features.length === 0) && (
                            <TableRow>
                              <TableCell colSpan={5} className="text-center">
                                <Alert severity="warning">
                                  No data available
                                </Alert>
                              </TableCell>
                            </TableRow>
                          )}

                          {!!features &&
                            features.map(
                              ({ id, name, description, category }) => (
                                <TableRow
                                  key={id}
                                  sx={{
                                    '&:last-child td, &:last-child th': {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell>{category ?? '-'}</TableCell>

                                  <TableCell>{name}</TableCell>

                                  <TableCell className="max-w-32">
                                    {description}
                                  </TableCell>

                                  <TableCell width={150}>
                                    <Typography>
                                      <Switch
                                        checked={field.value.includes(id)}
                                        onChange={(e) => {
                                          const selectedFeatureIds = new Set(
                                            field.value
                                          )
                                          if (e.target.checked) {
                                            selectedFeatureIds.add(id)
                                          } else {
                                            selectedFeatureIds.delete(id)
                                          }
                                          field.onChange([
                                            ...selectedFeatureIds,
                                          ])
                                        }}
                                      />
                                      {field.value.includes(id) ? 'YES' : 'NO'}
                                    </Typography>
                                  </TableCell>

                                  <TableCell>
                                    <IconButton
                                      onClick={() =>
                                        history.push(
                                          `/platform/product_tiers/feature/edit/${id}`
                                        )
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    {features && features.length > 0 && (
                      <TablePagination
                        data-testid="table-pagination"
                        component="div"
                        SelectProps={{
                          IconComponent: () => (
                            <ArrowDown
                              style={{ marginLeft: '-1rem' }}
                              fill="#191A1B"
                              opacity="0.52"
                              width="1.5rem"
                              height="1.5rem"
                            />
                          ),
                        }}
                        ActionsComponent={TablePaginationActions}
                        count={totalElements ?? features.length}
                        page={pagingAndSorting.pageNumber - 1}
                        onPageChange={(_, newPageNumber) =>
                          setPagingAndSorting.pageNumber(newPageNumber + 1)
                        }
                        rowsPerPage={pagingAndSorting.pageSize}
                        onRowsPerPageChange={(e) => {
                          setPagingAndSorting.pageSize(
                            Number(e.target.value) ?? 10
                          )
                          setPagingAndSorting.pageNumber(1)
                        }}
                      />
                    )}
                  </Card>
                  {!!fieldState.error && (
                    <FormHelperText error={true} variant="outlined">
                      {fieldState.error.message}
                    </FormHelperText>
                  )}
                </div>
              )}
            />
          </div>

          <Button
            type="submit"
            variant="contained"
            className="max-w-fit"
            disabled={form.formState.isSubmitting || isUpdateProductTierLoading}
          >
            Update Product Tier
          </Button>
        </form>
      </FullscreenModal>
    </Page>
  )
}

const CreateProductFeatureButton = () => {
  const history = useHistory()

  return (
    <ProtectedChildren hasRole={Role.Admin}>
      <Button
        variant="contained"
        onClick={() => history.push('/platform/product_tiers/feature/create')}
      >
        <AddIcon />
        Create feature
      </Button>
    </ProtectedChildren>
  )
}
