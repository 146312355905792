import React from 'react'
import Page from '@firstbase/components/atoms/Page'
import { useHistory } from 'react-router-dom'
import AddGlobalProduct from '@firstbase/views/Home/Platform/Products/AddGlobalProduct'

const NewProductPage = () => {
  const history = useHistory()

  return (
    <Page title="New Product">
      <AddGlobalProduct
        staticGenericCategory={false}
        open
        handleClose={() => history.push('/platform')}
      />
    </Page>
  )
}

export default NewProductPage
