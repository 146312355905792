import { GET_ORGANIZATION_PEOPLE } from '@firstbase/data/Organization/queries'
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Table, { SortDirections } from '@firstbase/components/atoms/Table'
import useTableGraphqlQuery from '@firstbase/hooks/useTableGraphqlQuery'
import { cellComponents } from '@firstbase/components/atoms/Table/cellComponents/cellComponents'
import { interpolateQueryParamsInPath } from '@firstbase/routes/routeUtils'

import { ColumnI } from '@firstbase/components/atoms/Table/types'
import {
  getOrganizationPeopleById,
  getOrganizationPeopleByIdVariables,
} from '@firstbase/data/Organization/__generated__/getOrganizationPeopleById'
import SearchBox from '@firstbase/components/atoms/SearchBox'
import { ClientPageParams } from '@firstbase/types/Pages'

const PEOPLE_COLUMNS = (orgId: string) =>
  [
    {
      header: 'Name',
      id: 'forename',
      defaultSort: SortDirections.asc,
      cell: {
        sx: { minWidth: '16rem' },
        value: ({ slug, forename, surname }) => (
          <Link
            to={interpolateQueryParamsInPath('person', {
              clientId: orgId,
              personSlug: slug,
            })}
          >
            {forename} {surname}
          </Link>
        ),
      },
    },
    {
      header: 'ID',
      id: 'id',
      defaultSort: SortDirections.asc,
      cell: {
        as: cellComponents.ID,
        value: ({ id }) => id,
      },
    },
    {
      header: 'Slug',
      id: 'slug',
      defaultSort: SortDirections.asc,
      cell: { value: ({ slug }) => slug },
    },
    {
      header: 'Email',
      id: 'email',
      defaultSort: SortDirections.asc,
      cell: { value: ({ email }) => email },
    },
    {
      header: 'Secondary Email',
      id: 'secondaryEmail',
      defaultSort: SortDirections.asc,
      cell: { value: ({ secondaryEmail }) => secondaryEmail || '-' },
    },
    {
      header: 'Role',
      id: 'appAccessLevel',
      defaultSort: SortDirections.asc,
      cell: {
        value: ({ appAccess }) => appAccess,
      },
    },
    {
      header: 'Status',
      id: 'status',
      defaultSort: SortDirections.asc,
      cell: {
        as: cellComponents.Chip,
        asProps: ({ status }) => {
          if (status === 'ACTIVE') return { color: 'success' }
          if (status === 'DELETED' || status === 'INACTIVE')
            return { color: 'warning' }
        },
        value: ({ status }) => status,
      },
    },
  ] as ColumnI[]

const ClientPeople = () => {
  const params: ClientPageParams = useParams()
  const [personFilter, setPersonFilter] = useState('')
  const query = useTableGraphqlQuery<
    getOrganizationPeopleById,
    getOrganizationPeopleByIdVariables
  >(
    GET_ORGANIZATION_PEOPLE,
    {
      variables: {
        orgId: params.clientId!,
        pageSize: 10,
        pageNumber: 1,
        personFilter,
      },
      fetchPolicy: 'cache-and-network',
    },
    ({ getOrganizationById: { people } }: getOrganizationPeopleById) => people
  )

  return (
    <>
      <SearchBox
        placeholder="Person name"
        label="Search people"
        disabled={(query.isLoading && !query.isPreviousData) || !!query.error}
        onChange={setPersonFilter}
      />
      <Table
        noDataMessage="No people found"
        query={{ ...query, variables: { personFilter } }}
        tableId={`org=${params.clientId!}-people`}
        pagination
        customRowsPerPageOptions={[50, 100, 200]}
        columns={PEOPLE_COLUMNS(params.clientId!)}
      />
    </>
  )
}

export default ClientPeople
