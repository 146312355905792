import { gql } from '@apollo/client'

export const ORGANIZATION_WARRANTY_TYPE_ASSIGNMENT_FRAGMENT = gql`
  fragment OrganizationWarrantyTypeAssignmentFragment on OrganizationWarrantyTypeAssignment {
    id
    term
    tier
    warrantyType {
      id
    }
  }
`

export const WARRANTY_SKU_FRAGMENT = gql`
  fragment WarrantySKUFragment on SKU {
    id
    skuImages
    vendorSku
    vendor {
      id
      name
      code
    }
    category {
      id
      code
    }
    metadata {
      id
      field
      value
    }
    standardPricing {
      regionCode
      currencyCode
      purchasePrice
    }
  }
`
