import environmentVariables from '@firstbase/utils/environmentVariables'
import initializeDatadogRum from './datadogRum'
import initializeDatadogLogs from './datadogLogs'

const initializeDatadog = () => {
  const env = environmentVariables.get().MODE === 'production' ? 'prod' : 'dev'
  const site = 'datadoghq.eu'
  const service = 'mgmt-appp'
  const clientToken = environmentVariables.get().VITE_DATADOG_CLIENT_TOKEN
  const applicationId = environmentVariables.get().VITE_DATADOG_APP_ID
  initializeDatadogRum(applicationId, clientToken, service, site, env)
  initializeDatadogLogs(clientToken, service, site, env)
}

export default initializeDatadog
