import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link,
  useTheme,
} from '@mui/material'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Drawer, DrawerHeader } from './menuStyles'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import routes from '@firstbase/routes'
import { useAuthState } from '@firstbase/context/auth/AuthProvider'
import { isAuthorizedForRoute } from '@firstbase/routes/routes'
import FLAGS from '@firstbase/constants/featureFlags'
import { useFeatureFlag } from '@firstbase/hooks/useFeatureFlag'

const navRoutes = routes.filter(({ navLabel, NavIcon }) => navLabel && NavIcon)

type OwnProps = {
  open: boolean
  toggleDrawerOpen: () => void
}

const renderIcon: (
  NavIcon: ((props: any) => JSX.Element) | null,
  NavIconActive: ((props: any) => JSX.Element) | null,
  isActive: boolean
) => JSX.Element | null = (NavIcon, NavIconActive, isActive) => {
  if (!NavIcon) return null
  if (!NavIconActive) return <NavIcon />

  return isActive ? <NavIconActive /> : <NavIcon />
}

const MenuDrawer = ({ open, toggleDrawerOpen }: OwnProps) => {
  const theme = useTheme()
  const { pathname: activePath } = window.location
  const { hasRole } = useAuthState()
  const isAssistantEnabled = useFeatureFlag(FLAGS.PE_1317_MGMT_ASSISTANT)

  const userNavRoutes = navRoutes.filter((route) => {
    const authorized = isAuthorizedForRoute(hasRole)(route)
    return route.key !== 'assistant'
      ? authorized
      : authorized && isAssistantEnabled
  })

  return (
    <Drawer
      variant="permanent"
      open={open}
      as="menu"
      data-testid={`menu?expanded=${open}`}
    >
      <DrawerHeader>
        <IconButton onClick={toggleDrawerOpen} aria-label="collapse menu">
          {theme.direction === 'rtl' ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {userNavRoutes.map(
          ({ NavIcon = null, NavIconActive = null, navLabel, path }) => (
            <ListItem key={navLabel} disablePadding sx={{ display: 'block' }}>
              <Link component={RouterLink} to={path}>
                <ListItemButton
                  selected={activePath === path}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                  aria-label={navLabel}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 0,
                      justifyContent: 'center',
                    }}
                  >
                    {renderIcon(NavIcon, NavIconActive, activePath === path)}
                  </ListItemIcon>
                  <ListItemText
                    primary={navLabel}
                    sx={{
                      display: open ? 'visible' : 'none',
                    }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          )
        )}
      </List>
    </Drawer>
  )
}

export default MenuDrawer
