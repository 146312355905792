import {
  TextField,
  Typography,
  InputAdornment,
  Box,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useCallback, useEffect, useState, useRef } from 'react'

import FLAGS from '@firstbase/constants/featureFlags'
import { NOT_SUPPORTED } from '@firstbase/constants/contract'
import { FeesRow, FeeType } from '@firstbase/types/Fee'
import { useSupportedCurrencies } from '@utils/contractUtils'

type Override = null | Pick<FeesRow, 'price' | 'currencyCode'>
type CheckboxValues = 'a' | 'b'

type OwnProps = {
  formCurrenciesByCountry: Record<string, string>
  updateFeePrice: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    countryCode: string,
    feeType: FeeType,
    defaultValue?: any,
    currencyCode?: string | undefined
  ) => void
  regionsToOverride: string[]
  override: Override
  setOverride: React.Dispatch<React.SetStateAction<Override>>
  defaultsToRegion: string
  overrideWithDifferentPrices: boolean
  setOverrideWithDifferentPrices: React.Dispatch<React.SetStateAction<boolean>>
}

const OverrideManagementFeesForm = ({
  formCurrenciesByCountry,
  updateFeePrice,
  regionsToOverride,
  override,
  setOverride,
  defaultsToRegion,
  overrideWithDifferentPrices,
  setOverrideWithDifferentPrices,
}: OwnProps) => {
  const mounted = useRef(false)
  const flags = useFlags()
  const updatedOverridesFlag = flags[FLAGS.SE_3532_OVERRIDE_DIFFERENT_PRICES]
  const supportedCurrencies = useSupportedCurrencies()
  const initailizeValue = () => {
    if (override) return 'a'
    if (overrideWithDifferentPrices) return 'b'
    return null
  }
  const [value, setValue] = useState<CheckboxValues | null>(initailizeValue())
  // Wrapper to help when clicking on active one
  const toggleCheckbox = (optionValue: CheckboxValues) => {
    setValue((previousValue) =>
      previousValue === optionValue ? null : optionValue
    )
  }

  const updateManagementFees = useCallback(
    (feePrice: string, currencyCode?: string) =>
      regionsToOverride.forEach((region) =>
        updateFeePrice(
          { target: { value: feePrice } } as React.FocusEvent<HTMLInputElement>,
          region,
          'Management fee',
          null,
          currencyCode || formCurrenciesByCountry[region]
        )
      ),
    [formCurrenciesByCountry, regionsToOverride, updateFeePrice]
  )

  const updateOverrideFees = useCallback(
    (feePrice: string, currencyCode?: string) => {
      if (currencyCode) {
        setOverride({ price: feePrice, currencyCode })
      }

      updateManagementFees(feePrice, currencyCode)
    },
    [setOverride, updateManagementFees]
  )

  const handleToggleOverride = useCallback(
    (optionValue: CheckboxValues | null, componentHasMounted: boolean) => {
      if (componentHasMounted && optionValue === 'a') {
        updateOverrideFees(
          NOT_SUPPORTED,
          formCurrenciesByCountry[defaultsToRegion]
        )
      } else {
        setOverride(null)
      }
    },
    [updateOverrideFees, formCurrenciesByCountry, defaultsToRegion, setOverride]
  )

  const handleToggleOverrideWithDifferentPrices = useCallback(
    (optionValue: CheckboxValues | null) =>
      setOverrideWithDifferentPrices(optionValue === 'b'),
    [setOverrideWithDifferentPrices]
  )

  // when supported currencies changes, retrigger override
  // to handle cases where country is added or removed from support
  // after overriding
  useEffect(() => {
    if (override) {
      updateManagementFees(override.price.toString(), override.currencyCode)
    }
  }, [override, updateManagementFees, formCurrenciesByCountry])

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      return
    }

    handleToggleOverride(value, mounted.current)
    if (updatedOverridesFlag) {
      handleToggleOverrideWithDifferentPrices(value)
    }
  }, [
    value,
    handleToggleOverride,
    handleToggleOverrideWithDifferentPrices,
    updatedOverridesFlag,
  ])

  const handleBlurOverrideValue = (e: React.FocusEvent<HTMLInputElement>) => {
    const {
      target: { value: price },
    } = e

    if (!price) {
      updateOverrideFees(NOT_SUPPORTED, override?.currencyCode)
      e.target.value = ''

      return
    }

    if (price !== NOT_SUPPORTED && !Number.isNaN(Number(price))) {
      const newPrice = String(parseFloat(price).toFixed(2))

      updateOverrideFees(newPrice, override?.currencyCode)
      e.target.value = newPrice
    } else {
      // force invalid input back to previous valid value
      if (override?.price.toString() === NOT_SUPPORTED) {
        e.target.value = ''
      } else {
        e.target.value = override?.price.toString() || ''
      }
    }
  }

  const defaultValue =
    override?.price.toString() !== NOT_SUPPORTED
      ? override?.price.toString()
      : undefined

  return (
    <Box sx={{ mb: 1 }} data-testid="mgmt-override-section">
      <Box>
        <FormControlLabel
          data-testid={`override-mgmt?override=${!!override}`}
          control={
            <Checkbox
              checked={value === 'a'}
              onChange={() => toggleCheckbox('a')}
            />
          }
          label={
            <Typography>
              <Typography component="span" sx={{ fontWeight: 'bold' }}>
                Override:{' '}
              </Typography>
              Charge management fees in billing region currency
            </Typography>
          }
        />
      </Box>
      {updatedOverridesFlag ? (
        <FormControlLabel
          control={
            <Checkbox
              checked={value === 'b'}
              onChange={() => toggleCheckbox('b')}
            />
          }
          label={
            <Typography>
              <Typography component="span" sx={{ fontWeight: 'bold' }}>
                Override:{' '}
              </Typography>
              Charge management fees in billing region currency but with
              different prices per region
            </Typography>
          }
        />
      ) : null}
      {override && (
        <Box sx={{ mt: 1, mb: 2, display: 'flex', gap: 2 }}>
          <TextField
            id="Management fee override"
            defaultValue={defaultValue}
            placeholder={!defaultValue ? NOT_SUPPORTED : undefined}
            onBlur={handleBlurOverrideValue}
            label="Price"
            size="small"
            InputLabelProps={{ shrink: true }}
            inputProps={{
              'data-testid': 'Management fee override',
            }}
            InputProps={{
              startAdornment: override.price !== NOT_SUPPORTED && (
                <InputAdornment position="start">
                  {supportedCurrencies[override.currencyCode]}
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
    </Box>
  )
}

export default OverrideManagementFeesForm
