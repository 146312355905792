import { getOrderByIdForOrganisation_getOrderByIdForOrganisation } from '@firstbase/data/Order/__generated__/getOrderByIdForOrganisation'
import {
  getReplacementByIdForOrganization_getReplacementByIdForOrganization_order,
  getReplacementByIdForOrganization_getReplacementByIdForOrganization_returnOrder,
} from '@firstbase/data/Order/__generated__/getReplacementByIdForOrganization'
import { getRetrievalsByReturnOrderId } from '@firstbase/data/Retrievals/__generated__/getRetrievalsByReturnOrderId'
import { SKUType } from '@globalTypes'
import { RetrievalSummary } from './types'

export const getShippingAddress = (
  order?:
    | getOrderByIdForOrganisation_getOrderByIdForOrganisation
    | getReplacementByIdForOrganization_getReplacementByIdForOrganization_order
    | getReplacementByIdForOrganization_getReplacementByIdForOrganization_returnOrder
) => {
  if (!order) return null

  const {
    addressLine1,
    addressLine2,
    postalCode,
    countryCode,
    administrativeArea,
    locality,
  } = order?.shippingAddress || {}

  return [
    addressLine1,
    addressLine2,
    locality,
    administrativeArea,
    postalCode,
    countryCode,
  ]
    .filter((segment) => !!segment)
    .join(', ')
}

interface RetrievalSummaryData {
  equipment: null | RetrievalSummary
  furniture: null | RetrievalSummary
}

export const getRetrievalsSummaryData = (
  rawRetrievalsData: getRetrievalsByReturnOrderId
) => {
  const retrievals: RetrievalSummaryData = { equipment: null, furniture: null }

  if (!rawRetrievalsData.getRetrievalsByReturnOrderId) return retrievals

  const cleanedRetrievalsData =
    rawRetrievalsData.getRetrievalsByReturnOrderId.filter(
      (item) => item !== null && item.methodType !== 'NOT_MAPPED'
    )

  cleanedRetrievalsData.forEach((retrievalType) => {
    if (!retrievalType?.items?.length) return
    retrievalType.items.forEach((item) => {
      const isFurnitureSku = item.sku.category.skuType === SKUType.FURNITURE
      const methodName = retrievalType.methodType
      const retrievalKey: keyof RetrievalSummaryData = isFurnitureSku
        ? 'furniture'
        : 'equipment'
      if (retrievals[retrievalKey] === null && item.retrievalMethod) {
        retrievals[retrievalKey] = {
          methodName,
          ...item.retrievalMethod,
        }
      }
    })
  })

  return retrievals
}
