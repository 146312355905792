import { gql } from '@apollo/client'

export const CREATE_RETRIEVAL_METHOD = gql`
  mutation createRetrievalMethod($retrievalMethod: CreateRetrievalMethod!) {
    createRetrievalMethod(retrievalMethod: $retrievalMethod) {
      id
      status
      description
      code
      type
    }
  }
`
