import {
  Paper,
  TableContainer,
  Table as MUITable,
  TableSortLabel,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Skeleton,
  TablePagination,
  Typography,
  Box,
  LinearProgress,
  Alert,
  SxProps,
  BoxTypeMap,
  PaperTypeMap,
} from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import get from 'lodash.get'
import isEqual from 'lodash.isequal'

import TablePaginationActions from './TablePagination'
import { ReactComponent as ArrowDown } from '@firstbase/assets/ArrowDown.svg'

import { CellI, ColumnI, SortDirections, Sticky } from './types'
import SectionError from '../SectionError'
import { theme } from '@firstbase/muiTheme'
import { OverridableComponent } from '@mui/material/OverridableComponent'

import FBTableCell from './TableCell'

type GetComponent = (hasSortDirection: boolean) => (props: any) => JSX.Element

export type PageQueryParams = {
  pageIndex: number
  rowsPerPage: number
  activeSortId?: string
  activeSortDirection: SortDirections
}

type ColumnGroup = {
  label: string
  colSpan?: number
}

export type TableQuery = {
  isLoading?: boolean
  error?: any
  isPreviousData?: boolean
  data?: {
    data: unknown[]
    totalElements?: number
  }
  variables?: object
  fetch?: (args: PageQueryParams) => void
}

type OwnProps = {
  tableId: string
  dataKey?: string
  defaultSortId?: string
  readonly columns: ColumnI[]
  pagination?: boolean
  customRowsPerPage?: number
  customRowsPerPageOptions?: number[]
  showHover?: boolean
  rowIsSelected?: (datum: any) => boolean
  handleRowClick?: (datum: any) => void
  query: TableQuery
  columnGroups?: ColumnGroup[] | ColumnGroup[][]
  skeletonHeight?: string | number
  noDataMessage?: string
  wrapper?: 'Box' | 'Paper'
  maxHeight?: string
  stickyHeader?: boolean
}

const DEFAULT_PROPS = {
  dataFetchBaseUrl: '',
  dataFetchExtraParam: {},
  customRowsPerPageOptions: [50, 100, 200],
  showHover: false,
}

const stickyCell = (
  stickyPosition?: Sticky,
  isHeader?: boolean,
  isSelected?: boolean
) => {
  if (isSelected) return {} as SxProps

  const backgroundColor = isHeader ? '#ededed' : theme.palette.common.white

  if (!stickyPosition) return { backgroundColor } as SxProps

  return {
    position: 'sticky',
    [stickyPosition === 'end' ? 'right' : 'left']: 0,
    backgroundColor,
    boxShadow: 'inset 1px 0px 0 #CACACB',
  }
}

const borderStyle: (sx: SxProps, colIndex: number) => SxProps = (
  sx,
  colIndex
) => ({
  ...sx,
  borderLeft: colIndex ? '1px solid #CACACB' : undefined,
})

function Table({
  tableId,
  dataKey,
  columns,
  defaultSortId,
  pagination,
  customRowsPerPage,
  customRowsPerPageOptions = DEFAULT_PROPS.customRowsPerPageOptions,
  showHover = DEFAULT_PROPS.showHover,
  rowIsSelected,
  handleRowClick,
  query,
  columnGroups,
  skeletonHeight,
  noDataMessage,
  wrapper = 'Paper',
  maxHeight = '50vh',
  stickyHeader = true,
}: OwnProps) {
  const tableRef = useRef<HTMLDivElement>(null)
  const [page, setPage] = useState(0)
  const [providedQueryVariables, setProvidedQueryVariables] = useState(
    query.variables || {}
  )
  const [rowsPerPage, setRowsPerPage] = useState(
    customRowsPerPage || customRowsPerPageOptions[0]
  )
  const [activeSortId, setActiveSortId] = useState(
    defaultSortId ||
      columns.find(({ defaultSort }) => typeof defaultSort === 'string')?.id ||
      ''
  )
  const [sortDirections, setSortDirections] = useState<{
    [k: string]: SortDirections
  }>(() => {
    return columns.reduce(
      (acc, { id, defaultSort }) => ({
        ...acc,
        ...(defaultSort ? { [id]: defaultSort } : {}),
      }),
      {}
    )
  })

  // force to first page when query variables change
  useEffect(() => {
    if (query.variables) {
      const newVariables = query.variables || {}

      if (!isEqual(newVariables, providedQueryVariables)) {
        setProvidedQueryVariables(newVariables)
        setPage(0)
      }
    }
  }, [providedQueryVariables, query.variables])

  const scrollToTopOfTable = () => {
    if (!tableRef.current) return null
    const { top } = tableRef.current.getBoundingClientRect()

    // only scroll if top of table is off viewport
    if (top < 0) {
      window.scrollTo({ top: top + window.scrollY, behavior: 'smooth' })
    }
  }

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage)
    scrollToTopOfTable()
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    scrollToTopOfTable()
  }

  const handleOrderChange = (columnId: string) => {
    if (activeSortId === columnId) {
      setSortDirections((prevDirections) => ({
        ...prevDirections,
        [columnId]:
          prevDirections[columnId] === SortDirections.asc
            ? SortDirections.desc
            : SortDirections.asc,
      }))
    } else {
      setActiveSortId(columnId)
    }

    setPage(0)
    scrollToTopOfTable()
  }
  const activeSortDirection: SortDirections = sortDirections[activeSortId || '']

  const { isLoading, error, data: rawData, isPreviousData, fetch } = query
  const { data, totalElements } = rawData || {}
  useEffect(() => {
    fetch?.({
      pageIndex: page,
      rowsPerPage,
      activeSortId,
      activeSortDirection,
    })
  }, [activeSortDirection, activeSortId, fetch, page, rowsPerPage])

  const getComponent: GetComponent = (hasSortDirection) =>
    hasSortDirection && data?.length ? TableSortLabel : Box

  const renderColumnGroups = () => {
    if (!columnGroups?.length) return null

    const renderSingleColumnGroup = (group: ColumnGroup[], index: number) => (
      <TableRow key={index}>
        {group.map(({ label, colSpan = columns.length }) => (
          <TableCell
            sx={{ borderRight: '1px solid ' + theme.palette.grey[300] }}
            key={label}
            align="left"
            colSpan={colSpan}
          >
            <Typography variant="inherit" sx={{ fontWeight: 'bold' }}>
              {label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    )

    const groups = !Array.isArray(columnGroups[0])
      ? ([columnGroups] as ColumnGroup[][])
      : (columnGroups as ColumnGroup[][])

    return groups.map(renderSingleColumnGroup)
  }

  const renderTableHeaders = () => (
    <>
      {renderColumnGroups()}
      <TableRow>
        {columns.map(({ header, sticky, id, align, sx: columnSx }, index) => {
          const hasSortDirection = !!sortDirections[id]
          const Component = getComponent(hasSortDirection)
          const componentProps =
            hasSortDirection && data?.length
              ? {
                  active: activeSortId === id,
                  direction: sortDirections[id],
                  onClick: () => handleOrderChange(id),
                }
              : null

          const sx = {
            ...(columnSx || { whiteSpace: 'nowrap' }),
            ...stickyCell(sticky, true),
          }

          return (
            <TableCell
              align={align}
              key={id}
              sx={borderStyle(sx, index)}
              sortDirection={sortDirections[id]}
            >
              <Component {...componentProps}>
                <Typography variant="inherit" sx={{ fontWeight: 'bold' }}>
                  {header}
                </Typography>
              </Component>
            </TableCell>
          )
        })}
      </TableRow>
    </>
  )

  const renderCellAs = (
    { as: AsComponent, asProps, value }: CellI,
    datum: any
  ) => {
    if (!AsComponent) return null
    const renderValue = value(datum)

    const passThroughAsProps =
      (typeof asProps === 'function'
        ? asProps({ ...datum, value: renderValue })
        : asProps) || {}

    return (
      <AsComponent {...(passThroughAsProps as any)}>{renderValue}</AsComponent>
    )
  }

  const renderDataRow = (datum: any) => {
    const isSelected = rowIsSelected && rowIsSelected(datum)

    return (
      <TableRow
        key={get(datum, dataKey || 'id')}
        hover={showHover}
        selected={isSelected}
        onClick={() => handleRowClick && handleRowClick(datum)}
        sx={{ cursor: showHover ? 'pointer' : '' }}
      >
        {columns.map(({ cell, sticky, align, id }, index) => {
          const sx = {
            ...(typeof cell.sx === 'function'
              ? cell.sx(datum)
              : cell.sx || { whiteSpace: 'nowrap' }),
            ...stickyCell(sticky, false, isSelected),
          }

          const testId = dataKey
            ? `${datum[dataKey]}-${id}`
            : `${datum.id}-${id}`

          return (
            <FBTableCell
              align={align}
              key={id}
              sx={borderStyle(sx, index)}
              data-testid={testId}
            >
              {cell.as ? renderCellAs(cell, datum) : cell.value(datum)}
            </FBTableCell>
          )
        })}
      </TableRow>
    )
  }

  const renderNoDataMessage = () => {
    return (
      <TableRow>
        <TableCell colSpan={columns.length} sx={{ textAlign: 'center' }}>
          <Alert severity="warning">
            {noDataMessage || 'No data available'}
          </Alert>
        </TableCell>
      </TableRow>
    )
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = useMemo(
    () => (page > 0 ? rowsPerPage - (data?.length || 0) : 0),
    [data?.length, page, rowsPerPage]
  )

  const rowsPerPageOptions = useMemo(() => {
    const allPageRows = query.data?.totalElements
    const hasAllOptionAvailable =
      allPageRows && !Number.isNaN(Number(allPageRows))
    const numericalOptions = customRowsPerPageOptions.map((value) => ({
      label: value.toString(),
      value,
    }))

    if (hasAllOptionAvailable) {
      return [...numericalOptions, { label: 'All', value: allPageRows }]
    }

    return numericalOptions
  }, [customRowsPerPageOptions, query.data?.totalElements])

  if (isLoading && !isPreviousData) {
    return (
      <Skeleton
        data-testid={`${tableId}?loading=true`}
        variant="rectangular"
        height={skeletonHeight || 'calc(100vh - 16rem)'}
      />
    )
  }

  if (error) {
    return <SectionError error={error as Error} />
  }

  const Wrapper: OverridableComponent<BoxTypeMap | PaperTypeMap> =
    wrapper === 'Box' ? Box : Paper

  return (
    <Wrapper sx={{ mb: 2, position: 'relative', overflow: 'hidden' }}>
      {isPreviousData && (
        <LinearProgress
          sx={{
            position: 'absolute',
            zIndex: 1,
            width: '100%',
          }}
        />
      )}
      <TableContainer
        sx={{ position: 'relative', maxHeight }}
        ref={tableRef}
        data-testid={`${tableId}?loading=${Boolean(
          isPreviousData || isLoading
        )}`}
      >
        {/* after first load, table will only update re-render rows once new
      data has been fetched and loaded */}
        <MUITable stickyHeader={stickyHeader} aria-label={tableId}>
          <TableHead
            sx={{ backgroundColor: 'rgba(25, 26, 27, 0.04)' }}
            data-testid={`${tableId}-head`}
          >
            {renderTableHeaders()}
          </TableHead>
          <TableBody>
            {data?.length ? data.map(renderDataRow) : renderNoDataMessage()}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={columns.length} />
              </TableRow>
            )}
          </TableBody>
        </MUITable>
      </TableContainer>
      {pagination && data?.length ? (
        <TablePagination
          data-testid="table-pagination"
          component="div"
          rowsPerPageOptions={rowsPerPageOptions}
          SelectProps={{
            IconComponent: () => (
              <ArrowDown
                style={{ marginLeft: '-1rem' }}
                fill="#191A1B"
                opacity="0.52"
                width="1.5rem"
                height="1.5rem"
              />
            ),
          }}
          ActionsComponent={TablePaginationActions}
          count={totalElements || data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </Wrapper>
  )
}

export default Table
