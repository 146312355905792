import countryList from 'react-select-country-list'

export interface DropdownOption {
  label: string
  value: any
}

const countries = countryList()

export const getCountryOptionByValue = (
  countryCode: string | undefined | null
) =>
  countryCode
    ? { label: countries.getLabel(countryCode), value: countryCode }
    : { label: '', value: '' }
