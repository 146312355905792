import { DeployReason, OrderStatus } from '@globalTypes'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { sentenceCase } from 'change-case'
import React, { useRef } from 'react'
import type { Product } from '../types'

interface Props {
  product: Product
  deployReason?: DeployReason
  isShowReasonErrors?: boolean
  onChangeInventoryReason: (event: SelectChangeEvent) => void
}

const SELECTABLE_DEPLOY_REASONS = [
  DeployReason.GIFTED_BY_EMPLOYER,
  DeployReason.GIFTED_BY_FIRSTBASE,
  DeployReason.LOST_BY_FIRSTBASE,
  DeployReason.LOST_BY_WORKER,
  DeployReason.LOST_IN_TRANSIT,
  DeployReason.UNRESPONSIVE_WORKER,
  DeployReason.NEVER_RETURNED_BY_WORKER,
  DeployReason.SENT_TO_OFFICE,
  DeployReason.UNSUPPORTED_REGION,
  DeployReason.BOUGHT_OUT,
  DeployReason.DECOMMISSIONED_BY_EMPLOYER,
  DeployReason.NEVER_RECEIVED_BY_WORKER,
  DeployReason.PERSONAL_DEVICE,
]

const sortedSelectableDeployReasons = SELECTABLE_DEPLOY_REASONS.sort((a, b) => {
  if (a > b) return 1
  if (a < b) return -1
  return 0
})

const DeployReasonSelect = ({
  product,
  deployReason,
  isShowReasonErrors,
  onChangeInventoryReason,
}: Props) => {
  const noInventory = !product.inventory?.id
  const initialStatus = useRef(product.status)
  const isInitialStatusNotReturned =
    initialStatus?.current === OrderStatus.NOT_RETURNED
  const isCurrentStatusNotReturned = product.status === OrderStatus.NOT_RETURNED
  const isDisabled =
    noInventory || isInitialStatusNotReturned || !isCurrentStatusNotReturned
  const showReasonOptions =
    !noInventory && (isInitialStatusNotReturned || isCurrentStatusNotReturned)
  const disabledLabel = noInventory ? 'Not Applicable' : 'Not returned only'

  // error when showing errors, and status is not returned
  // but no reason has been provided
  const isError =
    !isDisabled &&
    isShowReasonErrors &&
    product.status === OrderStatus.NOT_RETURNED &&
    !deployReason

  const renderReasonOptions = () =>
    ['', ...sortedSelectableDeployReasons].map((selectableDeployReason) => (
      <MenuItem value={selectableDeployReason} key={selectableDeployReason}>
        {sentenceCase(selectableDeployReason)}
      </MenuItem>
    ))

  return (
    <FormControl disabled={isDisabled} sx={{ width: '100%' }} error={isError}>
      <InputLabel size="small" id="reason">
        Deploy Reason
      </InputLabel>
      <Select
        inputProps={{
          'data-testid': 'select-return-reason-input',
        }}
        error={isError}
        disabled={isDisabled}
        fullWidth
        onChange={onChangeInventoryReason}
        label="Deploy Reason"
        aria-labelledby="reason"
        size="small"
        value={showReasonOptions ? deployReason || '' : disabledLabel}
      >
        {showReasonOptions ? (
          renderReasonOptions()
        ) : (
          <MenuItem value={disabledLabel}>{disabledLabel}</MenuItem>
        )}
      </Select>
      {isError && (
        <FormHelperText>You must provide a deploy reason</FormHelperText>
      )}
    </FormControl>
  )
}

export default DeployReasonSelect
