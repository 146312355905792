import { useMutation } from '@apollo/client'
import Page from '@firstbase/components/atoms/Page'
import FLAGS from '@firstbase/constants/featureFlags'
import { getPersonBySlugAndOrganizationSlug_getPersonBySlugAndOrganizationSlug as PersonI } from '@firstbase/data/Person/__generated__/getPersonBySlugAndOrganizationSlug'
import { updatePersonBySlugVariables } from '@firstbase/data/Person/__generated__/updatePersonBySlug'
import { PersonStatus } from '@globalTypes'
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { UPDATE_PERSON_BY_SLUG } from '@firstbase/data/Person/mutations'
import { useHistory } from 'react-router-dom'

interface OwnProps {
  person?: PersonI
  redirect: string
}

const DeletePerson = ({ person, redirect }: OwnProps) => {
  const flags = useFlags()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [updatePersonMutation, { loading: updating }] =
    useMutation<updatePersonBySlugVariables>(UPDATE_PERSON_BY_SLUG, {
      refetchQueries: ['getPersonBySlugAndOrganizationSlug'],
    })

  const deletePerson = () => {
    updatePersonMutation({
      variables: {
        slug: person?.slug,
        updatePerson: {
          status: PersonStatus.DELETED,
        },
      },
    })
      .then(() => {
        enqueueSnackbar(
          `Successfully deleted ${person?.forename} ${person?.surname}'s account`
        )
        history.push(redirect)
      })
      .catch(() => {
        enqueueSnackbar('Failed to delete user', {
          variant: 'error',
        })
      })
  }

  if (
    person?.status !== PersonStatus.INACTIVE ||
    !flags[FLAGS.SE_3043_DELETE_USER]
  )
    return null

  const cancelDeletion = () => setShowConfirmation(false)

  const renderConfirmation = () =>
    showConfirmation && (
      <Dialog onClose={cancelDeletion} maxWidth="sm" fullWidth open>
        <DialogTitle>
          <Page.TitleSection
            sectionTitle={`Are you sure you want to delete ${person.forename} ${person.surname}’s account?`}
          />
        </DialogTitle>
        <DialogActions>
          <Button variant="text" color="secondary" onClick={cancelDeletion}>
            Cancel
          </Button>

          <Button variant="contained" onClick={deletePerson}>
            Yes, delete them
          </Button>
        </DialogActions>
      </Dialog>
    )

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => setShowConfirmation(true)}
        disabled={updating}
      >
        Delete
      </Button>
      {renderConfirmation()}
    </>
  )
}

export default DeletePerson
