import { EnvironmentVariablesI } from './types'

export const defaultEnvironmentVariables: EnvironmentVariablesI = {
  VITE_API_URI: '',
  VITE_OKTA_CUSTOMER_DOMAIN: '',
  VITE_OKTA_MGMT_CLIENT_ID: '',
  VITE_PRICING_API_URL: '',
  VITE_LAUNCHDARKLY_CLIENT_ID: '',
  VITE_SMARTY_API_KEY: '',
  VITE_DATADOG_APP_ID: '',
  VITE_DATADOG_CLIENT_TOKEN: '',
  VITE_ASSISTANT_URI: '',
  PROD: false,
  MODE: 'development',
}

class EnvironmentVariables {
  variables: EnvironmentVariablesI = defaultEnvironmentVariables

  notDefinedYet = true

  /**
   * Method used for wrapping the getting of this.variables with a protection --
   * waiting until the variables have initialized.
   */
  public get(): EnvironmentVariablesI {
    if (this.notDefinedYet) {
      throw new Error('Enivronment variables have not been defined yet.')
    } else {
      return this.variables
    }
  }

  /**
   * Method used for wrapping the setting of this.variables with a protection --
   * can only be defined/initialized once.
   */
  public set(value: EnvironmentVariablesI): void {
    if (this.notDefinedYet) {
      this.variables = value
      this.notDefinedYet = false
    } else {
      throw new Error('Enivronment variables have already been defined')
    }
  }
}

export default EnvironmentVariables
