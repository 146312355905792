import { gql } from '@apollo/client'

export const UPDATE_RETURN_ORDER = gql`
  mutation updateReturnOrder(
    $updatedReturnOrder: UpdateReturnOrder!
    $updatedReturnOrderItems: [UpdateReturnOrderItem!]!
  ) {
    updateReturnItems(updateReturnItem: $updatedReturnOrderItems) {
      id
    }
    updateReturnOrder(updateReturnOrder: $updatedReturnOrder) {
      id
    }
  }
`
