import Page from '@firstbase/components/atoms/Page'
import Table from '@firstbase/components/atoms/Table'
import { cellComponents } from '@firstbase/components/atoms/Table/cellComponents/cellComponents'
import { ColumnI } from '@firstbase/components/atoms/Table/types'
import { getPersonBySlugAndOrganizationSlug_getPersonBySlugAndOrganizationSlug_packages } from '@firstbase/data/Person/__generated__/getPersonBySlugAndOrganizationSlug'
import { interpolateQueryParamsInPath } from '@firstbase/routes/routeUtils'
import { PersonPageParams } from '@firstbase/types/Pages'
import React from 'react'
import { useParams } from 'react-router-dom'

const columns = (clientId: string) =>
  [
    {
      header: 'Product name',
      id: 'name',
      cell: {
        as: cellComponents.Link,
        asProps: ({ id }) => ({
          to: interpolateQueryParamsInPath('product', {
            clientId,
            productId: id,
          }),
        }),
        value: ({ skuInformation }) => skuInformation?.productTitle,
      },
    },
    {
      header: 'ID',
      id: 'id',
      cell: { as: cellComponents.ID, value: ({ id }) => id },
    },
    {
      header: 'Brand',
      id: 'vendor',
      cell: { value: ({ vendor }) => vendor?.name },
    },
    {
      header: 'Category',
      id: 'category',
      cell: { value: ({ category }) => category?.nameSingular },
    },
  ] as ColumnI[]

type OwnProps = {
  packages:
    | (getPersonBySlugAndOrganizationSlug_getPersonBySlugAndOrganizationSlug_packages | null)[]
    | null
  personName: string
}

const Package = ({ packages, personName }: OwnProps) => {
  const queryParams: PersonPageParams = useParams()
  const packageSkus = (packages || [])[0]?.skus

  return (
    <>
      <Page.TitleSection
        sectionTitle="Assigned package"
        sectionSubtitle={`List of items that are a part of ${personName}'s package`}
      />
      <Table
        tableId={`${queryParams.personSlug}-package`}
        columns={columns(queryParams.clientId)}
        query={{ data: { data: packageSkus || [] } }}
      />
    </>
  )
}

export default Package
