import Page from '@firstbase/components/atoms/Page'
import Table from '@firstbase/components/atoms/Table'
import { cellComponents } from '@firstbase/components/atoms/Table/cellComponents/cellComponents'
import { ColumnI } from '@firstbase/components/atoms/Table/types'
import { getPackageSelectionsBySlug_getPackageBySlug_people } from '@firstbase/data/Package/__generated__/getPackageSelectionsBySlug'
import { interpolateQueryParamsInPath } from '@firstbase/routes/routeUtils'
import { PackagePageParams } from '@firstbase/types/Pages'
import React from 'react'
import { useParams } from 'react-router-dom'

const columns = (clientId: string) =>
  [
    {
      header: 'Person name',
      id: 'personName',
      cell: {
        as: cellComponents.Link,
        asProps: ({ slug }) => ({
          to: interpolateQueryParamsInPath('person', {
            clientId,
            personSlug: slug,
          }),
        }),
        value: ({ forename, surname }) => (
          <>
            {forename} {surname}
          </>
        ),
      },
    },
    {
      header: 'ID',
      id: 'id',
      cell: {
        as: cellComponents.ID,
        value: ({ id }) => id,
      },
    },
    {
      header: 'Slug',
      id: 'slug',
      cell: {
        value: ({ slug }) => slug,
      },
    },
    {
      header: 'Email',
      id: 'email',
      cell: {
        value: ({ email }) => email,
      },
    },
  ] as ColumnI[]

type OwnProps = {
  people: getPackageSelectionsBySlug_getPackageBySlug_people[] | null
  loading: boolean
}

const AssignedPeople = ({ people, loading }: OwnProps) => {
  const params: PackagePageParams = useParams()

  return (
    <>
      <Page.TitleSection
        sectionTitle="Assigned People"
        sectionSubtitle="List of people this package is currently assigned to"
      />
      <Table
        tableId={`package=${params.packageSlug}-people`}
        columns={columns(params.clientId)}
        query={{
          data: { data: people || [] },
          isLoading: loading,
        }}
      />
    </>
  )
}

export default AssignedPeople
