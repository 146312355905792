import React from 'react'
import { parseISO, format } from 'date-fns'

import Link from '@firstbase/components/atoms/Link'
import {
  ColumnI,
  SortDirections,
} from '@firstbase/components/atoms/Table/types'
import { getDocuments_documents } from '@firstbase/data/Assistant'

import DeleteDocumentCell from './DeleteDocumentCell'

const formatDate = (date: string) => format(parseISO(date), 'MM/dd/yyyy')

const titleColumn: ColumnI = {
  header: 'Title',
  defaultSort: SortDirections.asc,
  id: 'title',
  cell: { value: ({ title }: getDocuments_documents) => title },
}

const fileUrlColumn: ColumnI = {
  header: 'URL',
  defaultSort: SortDirections.asc,
  id: 'url',
  cell: {
    value: ({ fileUrl }: getDocuments_documents) => {
      if (!fileUrl) return null
      const fileName = fileUrl.split('/').pop() || ''
      return (
        <Link href={fileUrl} target="_blank" rel="noopener noreferrer">
          {fileName}
        </Link>
      )
    },
  },
}

const indexedColumn: ColumnI = {
  header: 'Indexed',
  defaultSort: SortDirections.asc,
  id: 'indexed',
  cell: { value: ({ indexed }: getDocuments_documents) => indexed.toString() },
}

const updatedAtColumn: ColumnI = {
  header: 'Updated At',
  defaultSort: SortDirections.asc,
  id: 'updatedAt',
  cell: {
    value: ({ updatedAt }: getDocuments_documents) => formatDate(updatedAt),
  },
}

const createdAtColumn: ColumnI = {
  header: 'Created At',
  defaultSort: SortDirections.asc,
  id: 'createdAt',
  cell: {
    value: ({ createdAt }: getDocuments_documents) => formatDate(createdAt),
  },
}

const deleteColumn: ColumnI = {
  id: 'delete',
  header: '',
  sticky: 'end',
  cell: {
    value: ({ id }: getDocuments_documents) => <DeleteDocumentCell id={id} />,
  },
}

export const DOCUMENTS_COLUMNS: ColumnI[] = [
  titleColumn,
  fileUrlColumn,
  indexedColumn,
  createdAtColumn,
  updatedAtColumn,
  deleteColumn,
]
