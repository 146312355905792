import { cellComponents } from '@firstbase/components/atoms/Table/cellComponents/cellComponents'
import {
  ColumnI,
  SortDirections,
} from '@firstbase/components/atoms/Table/types'
import { getAllOffices_getAllOffices_data } from '@firstbase/data/Office/__generated__/getAllOffices'

const officeColumn: ColumnI = {
  header: 'Name',
  defaultSort: SortDirections.asc,
  id: 'name',
  cell: { value: ({ name }: getAllOffices_getAllOffices_data) => name },
}

const officeIdColumn: ColumnI = {
  header: 'ID',
  id: 'id',
  cell: {
    as: cellComponents.ID,
    value: ({ id }: getAllOffices_getAllOffices_data) => id,
  },
}

const organizationColumn: ColumnI = {
  header: 'Organization',
  defaultSort: SortDirections.asc,
  id: 'organization',
  cell: {
    value: ({ organization }: getAllOffices_getAllOffices_data) =>
      organization?.name,
  },
}
export const OFFICES_COLUMNS = (): ColumnI[] => [
  officeIdColumn,
  officeColumn,
  organizationColumn,
]
