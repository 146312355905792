const getFileNameFromUrl = (url: string) => {
  const urlObject = new URL(url)
  const pathname = urlObject.pathname

  return pathname.substring(pathname.lastIndexOf('/') + 1)
}

export const downloadFile = (url: string) => {
  const fileName = getFileNameFromUrl(url)
  const link = document.createElement('a')

  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
