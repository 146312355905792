import React from 'react'
import { Alert } from '@mui/material'

const BulkUpgradeAlert = () => {
  return (
    <Alert severity="info" sx={{ my: 1 }}>
      For bulk updating a customer's asset using the upgrade csv form (
      <a
        href={
          'https://www.notion.so/firstbase/Tutorial-How-to-perform-a-bulk-upgrade-replacement-1ba3b84e7fe64ec19ff96ca7f529e3dc'
        }
        target="_blank"
        rel="noreferrer"
      >
        documentation
      </a>
      )
    </Alert>
  )
}

export default BulkUpgradeAlert
