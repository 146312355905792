import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  InputAdornment,
} from '@mui/material'
import { FeeType } from '@firstbase/types/Fee'
import { NOT_SUPPORTED } from '@firstbase/constants/contract'
import {
  useSupportedCurrencies,
  useSupportedRegions,
} from '@utils/contractUtils'

type Props = {
  getFeeForRegionCode: (regionCode: string, feeType: FeeType) => string | number
  updateFeePrice: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    regionCode: string,
    feeType: FeeType,
    defaultValue?: any,
    currencyCode?: string | undefined
  ) => void
  regionIsSupported: (countryCode: string) => boolean
  formCurrenciesByRegion: Record<string, string>
  orgServiceFees: string[]
  overrideCurrencyCode: string | null
}

const ServiceFeesTableForm = ({
  getFeeForRegionCode,
  updateFeePrice,
  regionIsSupported,
  formCurrenciesByRegion,
  orgServiceFees,
  overrideCurrencyCode,
}: Props) => {
  const getDefaultValue = (regionCode: string, feeType: FeeType) => {
    const feeValue = getFeeForRegionCode(regionCode, feeType).toString()

    return feeValue !== NOT_SUPPORTED ? feeValue : undefined
  }

  const supportedRegions = useSupportedRegions()
  const supportedCurrencies = useSupportedCurrencies()

  const contractSupportedCountries = Object.entries(supportedRegions).filter(
    ([regionCode]) => regionIsSupported(regionCode)
  )

  return (
    <>
      {contractSupportedCountries.length > 0 ? (
        <Table aria-label="service fees table" data-testid="service fees table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ padding: 1 }}>
                <Typography variant="inherit" sx={{ fontWeight: 'bold' }}>
                  Fees
                </Typography>
              </TableCell>
              {contractSupportedCountries.map(([regionCode]) => (
                <TableCell
                  sx={{ padding: 1 }}
                  key={`${regionCode} - service fees`}
                >
                  <Typography variant="inherit" sx={{ fontWeight: 'bold' }}>
                    {regionCode}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {orgServiceFees.map((feeType: FeeType) => (
              <TableRow key={feeType} data-testid={feeType}>
                <TableCell sx={{ padding: 1, minWidth: 180 }}>
                  <Typography variant="inherit">{feeType}</Typography>
                </TableCell>
                {contractSupportedCountries.map(([regionCode]) => (
                  <TableCell sx={{ p: 1 }} key={`${regionCode} - ${feeType}`}>
                    <TextField
                      id={`${feeType} - ${regionCode}`}
                      label="Price"
                      size="small"
                      sx={{ minWidth: 150 }}
                      // when support for region changes, reset field value
                      key={regionIsSupported(regionCode).toString()}
                      defaultValue={getDefaultValue(regionCode, feeType)}
                      placeholder={
                        !getDefaultValue(regionCode, feeType)
                          ? NOT_SUPPORTED
                          : undefined
                      }
                      onBlur={(event) =>
                        updateFeePrice(
                          event,
                          regionCode,
                          feeType,
                          getFeeForRegionCode(regionCode, feeType).toString(),
                          // HACK: If override exists, then use that currency for adding/updating price
                          overrideCurrencyCode ?? undefined
                        )
                      }
                      disabled={!regionIsSupported(regionCode)}
                      inputProps={{
                        'data-testid': `${feeType} - ${regionCode}`,
                      }}
                      InputProps={{
                        startAdornment: (
                          <>
                            {regionIsSupported(regionCode) &&
                              getFeeForRegionCode(
                                regionCode,
                                feeType
                              ).toString() !== NOT_SUPPORTED && (
                                <InputAdornment position="start">
                                  {
                                    supportedCurrencies[
                                      overrideCurrencyCode ||
                                        formCurrenciesByRegion[regionCode]
                                    ]
                                  }
                                </InputAdornment>
                              )}
                          </>
                        ),
                      }}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography variant="subtitle1" sx={{ p: 2, textAlign: 'center' }}>
          No regions configured
        </Typography>
      )}
    </>
  )
}

export default ServiceFeesTableForm
