import { useMutation } from '@apollo/client'
import {
  addItemToStandardCatalog,
  addItemToStandardCatalogVariables,
} from '@firstbase/data/SKU/__generated__/addItemToStandardCatalog'
import {
  removeItemFromStandardCatalog,
  removeItemFromStandardCatalogVariables,
} from '@firstbase/data/SKU/__generated__/removeItemFromStandardCatalog'
import {
  ADD_SKU_TO_STANDARD_CATALOG,
  REMOVE_SKU_FROM_STANDARD_CATALOG,
} from '@firstbase/data/SKU/mutations'

export const useUpdateStandardCatalogListing = () => {
  const [
    addToStandardCatalog,
    { loading: addingToStandardCatalog, error: addingError },
  ] = useMutation<addItemToStandardCatalog, addItemToStandardCatalogVariables>(
    ADD_SKU_TO_STANDARD_CATALOG
  )
  const [
    removeFromStandardCatalog,
    { loading: removingFromStandardCatalog, error: removingError },
  ] = useMutation<
    removeItemFromStandardCatalog,
    removeItemFromStandardCatalogVariables
  >(REMOVE_SKU_FROM_STANDARD_CATALOG)

  const updateListingStatus = (
    productId: string,
    standardCatalog: boolean
  ): Promise<unknown> => {
    const variables = { variables: { id: productId } }

    if (standardCatalog) return addToStandardCatalog(variables)
    else return removeFromStandardCatalog(variables)
  }

  return [
    updateListingStatus,
    {
      loading: addingToStandardCatalog || removingFromStandardCatalog,
      error: addingError || removingError,
    },
  ] as const
}
