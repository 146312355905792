import { useState } from 'react'

import { ProductPriceBillingType, DiscountCategoryGroup } from '@globalTypes'
import { getOrganizationById_getOrganizationById_categoryGroupDiscounts } from '@firstbase/data/Organization/__generated__/getOrganizationById'

type HardwareDiscountFormState = Record<
  keyof typeof DiscountCategoryGroup,
  Record<keyof typeof ProductPriceBillingType, number | null>
>

interface HardwareDiscountPayload {
  categoryGroupName: keyof typeof DiscountCategoryGroup
  purchaseType: keyof typeof ProductPriceBillingType
  percentageDiscount: number
}

export type GetHardwareDiscountValue = (
  field: keyof HardwareDiscountFormState,
  billingType: keyof typeof ProductPriceBillingType
) => number | null

export type UpdateHardwareDiscountValue = (
  field: keyof HardwareDiscountFormState,
  billingType: keyof typeof ProductPriceBillingType,
  value: number | null
) => void

/**
 * Hook to manage the hardware discount form state and payload.
 * Feel free to refactor this to use something like react-hook-form
 * when the time is right. This was implemented like this to keep
 * consistent with the rest of the form that does not already use
 * react-hook-form.
 */
export const useHardwareDiscountForm = (
  categoryGroupDiscounts: getOrganizationById_getOrganizationById_categoryGroupDiscounts[]
) => {
  const [formState, setFormState] = useState<HardwareDiscountFormState>(() =>
    initializeState(categoryGroupDiscounts)
  )

  const getHardwareDiscountPayload: () => HardwareDiscountPayload[] = () =>
    Object.entries(formState).flatMap(([categoryGroupName, billingTypes]) =>
      Object.entries(billingTypes).map(
        ([purchaseType, percentageDiscount]) => ({
          categoryGroupName:
            categoryGroupName as keyof typeof DiscountCategoryGroup,
          purchaseType: purchaseType as keyof typeof ProductPriceBillingType,
          percentageDiscount: percentageDiscount ?? 0,
        })
      )
    )

  const getValue: GetHardwareDiscountValue = (
    field: keyof HardwareDiscountFormState,
    billingType: keyof typeof ProductPriceBillingType
  ) => formState[field][billingType]

  const updateValue: UpdateHardwareDiscountValue = (
    field: keyof HardwareDiscountFormState,
    billingType: keyof typeof ProductPriceBillingType,
    value: number | null
  ) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: { ...prevState[field], [billingType]: value },
    }))
  }

  return {
    getHardwareDiscountFieldValue: getValue,
    updateHardwareDiscountFieldValue: updateValue,
    getHardwareDiscountPayload,
  }
}

const initializeState = (
  categoryGroupDiscounts: getOrganizationById_getOrganizationById_categoryGroupDiscounts[]
): HardwareDiscountFormState => {
  const initialState: HardwareDiscountFormState =
    {} as HardwareDiscountFormState

  for (const categoryGroupKey in DiscountCategoryGroup) {
    initialState[categoryGroupKey as keyof typeof DiscountCategoryGroup] =
      {} as Record<keyof typeof ProductPriceBillingType, number | null>

    for (const billingTypeKey in ProductPriceBillingType) {
      const discount = categoryGroupDiscounts.find(
        (d) =>
          d.categoryGroupName === categoryGroupKey &&
          d.purchaseType === billingTypeKey
      )
      initialState[categoryGroupKey as keyof typeof DiscountCategoryGroup][
        billingTypeKey as keyof typeof ProductPriceBillingType
      ] = discount ? discount.discount.percentageDiscount : null
    }
  }

  return initialState
}
