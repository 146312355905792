import request from '@firstbase/utils/request'
import { AxiosError } from 'axios'
import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import SettingsForm from '../ContractSettingsForm/SettingsForm'
import Loader from '@firstbase/components/atoms/Loader'
import { ContractResponse } from '@firstbase/types/Contract'
import { ClientPageParams } from '@firstbase/types/Pages'

type OwnProps = {
  open: boolean
  handleClose: () => void
}

const EditSettings = ({ open, handleClose }: OwnProps) => {
  const { clientId }: ClientPageParams = useParams()
  const {
    isLoading: isLoadingContract,
    data: contractResponse,
    error: contractError,
  } = useQuery<ContractResponse, AxiosError>(
    ['client contract', { clientId }],
    () =>
      request<ContractResponse>({
        url: `/organizations/${clientId}/contract`,
      })
  )

  if (!contractResponse || isLoadingContract) return <Loader />

  return (
    <SettingsForm
      contract={contractResponse}
      isLoading={isLoadingContract}
      error={contractError}
      open={open}
      handleClose={handleClose}
    />
  )
}

export default EditSettings
