import Page from '@firstbase/components/atoms/Page'
import FullscreenModal from '@firstbase/components/molecules/FullscreenModal'
import { FeatureStatus } from '@globalTypes'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  FormControlLabel,
  MenuItem,
  Radio,
  TextField,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { z } from 'zod'
import { useGetProductTierFeatures } from '../useGetProductTierFeatures'
import { useProductTierFeatureMutations } from './useProductTierFeatureMutations'

const pageTitle = 'Create product tier feature'

const formSchema = z.object({
  name: z.string().min(1, 'Product Tier Name is required'),
  description: z.string(),
  category: z.object({
    select: z.string().optional(),
    create: z.string().optional(),
  }),
  status: z.nativeEnum(FeatureStatus),
})

export const ProductTierFeatureCreateFormPage = () => {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const { createFeature, isCreateFeatureLoading } =
    useProductTierFeatureMutations()

  const [createCategory, setCreateCategory] = useState(false)
  const { categories } = useGetProductTierFeatures()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      description: '',
      category: {
        select: '',
        create: '',
      },
      status: FeatureStatus.ACTIVE,
    },
  })

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      const { data } = await createFeature({
        variables: {
          params: {
            name: values.name,
            description: values.description,
            category: createCategory
              ? values.category.create
              : values.category.select,
          },
        },
      })

      if (data?.createFeature?.feature) {
        enqueueSnackbar('Feature created successfully', { variant: 'success' })
        form.reset()
      }
    } catch (error) {
      enqueueSnackbar('Failed to create feature', { variant: 'error' })
    }
  }

  return (
    <Page title={pageTitle}>
      <FullscreenModal
        open={true}
        handleClose={history.goBack}
        handleBack={history.goBack}
        title={pageTitle}
      >
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Page.TitleSection sectionTitle={pageTitle} />

          <div className="flex flex-col gap-6">
            <Controller
              control={form.control}
              name="name"
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    className="max-w-xl"
                    label="Name *"
                    fullWidth
                    {...field}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )
              }}
            />

            <Controller
              control={form.control}
              name="description"
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    className="max-w-xl"
                    label="Description *"
                    multiline
                    fullWidth
                    {...field}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )
              }}
            />

            <div className="flex gap-2 flex-col pl-3">
              <FormControlLabel
                onChange={() => setCreateCategory(true)}
                control={<Radio />}
                label="Create a Category"
                checked={createCategory}
              />

              <FormControlLabel
                onChange={() => setCreateCategory(false)}
                control={<Radio />}
                label="Select an Existing Category"
                checked={!createCategory}
                disabled={categories.length === 0}
              />
            </div>

            <div className="grid grid-cols-2 gap-2 max-w-xl">
              <Controller
                control={form.control}
                name="category.select"
                disabled={categories.length === 0 || createCategory}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      select
                      label="Select Category"
                      fullWidth
                      {...field}
                      error={!!fieldState.error}
                      helperText={
                        categories.length === 0
                          ? 'No categories available'
                          : fieldState.error?.message
                      }
                    >
                      {categories.map((status) => (
                        <MenuItem key={status} value={status}>
                          {status}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              />

              <Controller
                control={form.control}
                name="category.create"
                disabled={!createCategory}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      label="Create Category"
                      multiline
                      fullWidth
                      {...field}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )
                }}
              />
            </div>

            <Controller
              control={form.control}
              name="status"
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    className="max-w-xl"
                    select
                    label="Status"
                    fullWidth
                    disabled
                    {...field}
                    error={!!fieldState.error}
                    helperText={
                      'Setting a status is not supported when creating a new feature'
                    }
                  >
                    {Object.values(FeatureStatus).map((status) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />

            <Button
              type="submit"
              variant="contained"
              className="max-w-fit"
              disabled={form.formState.isSubmitting || isCreateFeatureLoading}
            >
              Create feature
            </Button>
          </div>
        </form>
      </FullscreenModal>
    </Page>
  )
}
