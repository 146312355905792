import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useQueries, useQueryClient } from 'react-query'

import Page from '@atoms/Page'

import request from '@firstbase/utils/request'
import { useBreadcrumbs } from '@firstbase/context/breadcrumbs/BreadcrumbsProvider'

import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'

import { ReactComponent as KebabMenuIcon } from '@firstbase/assets/KebabMenu.svg'
import { ReactComponent as PencilEditIcon } from '@firstbase/assets/PencilEdit.svg'
import { ReactComponent as HistoryIcon } from '@firstbase/assets/History.svg'
import DeleteIcon from '@mui/icons-material/Delete'

import DropdownMenu from '@firstbase/components/atoms/DropdownMenu/DropdownMenu'
import PricingTable from './PricingTable'
import SectionError from '@firstbase/components/atoms/SectionError'
import { useSnackbar } from 'notistack'
import { useQuery, useMutation } from '@apollo/client'
import { DELETE_SKU_ASSIGNMENT_FOR_ORGANIZATION } from '@firstbase/data/Organization/mutations'
import { GET_ORGANIZATION_BY_ID } from '@firstbase/data/Organization/queries'
import EditProduct, { PriceData } from './EditProduct/EditProduct'
import PricingHistory from './PricingHistory/PricingHistory'
import { ContractResponse } from '@firstbase/types/Contract'
import { interpolateQueryParamsInPath } from '@firstbase/routes/routeUtils'
import Loader from '@firstbase/components/atoms/Loader'
import { Role, useAuthState } from '@firstbase/context/auth/AuthProvider'
import { ProductPageParams } from '@firstbase/types/Pages'

export type BillingType = 'Subscription' | 'Purchased'
export type PricingRow = {
  orgId: string
  skuId: string
  id?: string
  regionCode: string
  price: number
  currency: string
  billingType: BillingType
  startDate?: string | null
  endDate?: string | null
  lastModified?: string | null
}

export interface ProductResponse {
  orgId: string
  orgName: string
  skuId: string
  productName: string
  billingFrequency: 'Monthly' | 'Annually'
  prices: PricingRow[]
  firstbaseSupplied: boolean | null
}

function Product() {
  const { productId, clientId }: ProductPageParams = useParams()
  const queryClient = useQueryClient()
  const [editing, setEditing] = useState(false)
  const [pricingHistory, setPricingHistory] = useState(false)
  const [prices, setPrices] = useState([] as PricingRow[])
  const [pricesChanges, setPricesChanged] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { setBreadcrumbs } = useBreadcrumbs()
  const { hasRole } = useAuthState()
  const history = useHistory()
  const [
    {
      data: productData,
      isLoading: isLoadingPrices,
      isSuccess: isSuccessPrices,
      error,
    },
    {
      data: contractResponse,
      isLoading: isLoadingContract,
      isSuccess: isSuccessContract,
    },
  ] = useQueries([
    {
      queryKey: ['product pricing', { clientId, productId }],
      queryFn: () =>
        request<ProductResponse>({
          url: `/organizations/${clientId}/products/${productId}/pricing`,
        }),
    },
    {
      queryKey: ['client contract', { clientId }],
      queryFn: () =>
        request<ContractResponse>({
          url: `/organizations/${clientId}/contract`,
        }),
    },
  ])

  const { data: { getOrganizationById: client } = {} } = useQuery(
    GET_ORGANIZATION_BY_ID,
    {
      variables: { orgId: clientId },
    }
  )

  const [removeProduct, { loading: isRemovingProduct }] = useMutation(
    DELETE_SKU_ASSIGNMENT_FOR_ORGANIZATION
  )

  const isLoading = isLoadingPrices || isLoadingContract
  const isSuccess = isSuccessPrices && isSuccessContract
  const orgDisplayName = productData?.orgName || clientId
  const productDisplayName = productData?.productName || productId

  useEffect(() => {
    if (productData && contractResponse?.currencies.length) {
      setPrices(() => {
        const newPriceData = contractResponse.currencies
          .map(({ regionCode: orgRegionCode, currency: orgCurrency }) => {
            return (
              productData.prices?.find(
                ({ regionCode, currency }) =>
                  regionCode === orgRegionCode && currency === orgCurrency
              ) ||
              ({
                orgId: productData.orgId,
                skuId: productData.skuId,
                regionCode: orgRegionCode,
                currency: orgCurrency,
                billingType: 'Subscription',
                price: 0,
              } as PricingRow)
            )
          })
          .filter((el) => el)

        return newPriceData || ([] as PriceData)
      })
    }
  }, [contractResponse?.currencies, productData])

  useEffect(() => {
    setBreadcrumbs([
      {
        label: 'Clients',
        pathId: 'clients',
      },
      {
        label: orgDisplayName,
        pathId: 'client',
        searchParams: { tab: 'catalog' },
        isLoading: isLoading && !isSuccess,
      },
      {
        label: productDisplayName,
        isLoading: isLoading && !isSuccess,
      },
    ])
  }, [orgDisplayName, productDisplayName, isLoading, setBreadcrumbs, isSuccess])

  const canAddPrices = isSuccess && contractResponse?.currencies.length

  const handleRemove = useCallback(async () => {
    try {
      await removeProduct({
        variables: { skuId: productId, orgId: clientId },
        refetchQueries: ['getOrganizationById'],
      })
      enqueueSnackbar(`Successfully removed product ${productDisplayName}`)
      await queryClient.invalidateQueries({
        predicate: (query: any) =>
          query.queryKey[0].includes('products table') &&
          query.queryKey[1].clientId === clientId,
      })
      history.push(interpolateQueryParamsInPath('client', { clientId }))
    } catch (e) {
      enqueueSnackbar('Failed to remove product', {
        variant: 'error',
      })
    }
  }, [
    clientId,
    enqueueSnackbar,
    history,
    productDisplayName,
    productId,
    queryClient,
    removeProduct,
  ])

  const renderTitleAction = useCallback(() => {
    return (
      <DropdownMenu IconButton={<KebabMenuIcon />}>
        <MenuItem
          disabled={!canAddPrices || !hasRole(Role.Admin)}
          onClick={() => setEditing(true)}
        >
          <ListItemIcon>
            <PencilEditIcon />
          </ListItemIcon>
          <ListItemText>Edit client product details</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setPricingHistory(true)}>
          <ListItemIcon>
            <HistoryIcon />
          </ListItemIcon>
          <ListItemText>See update history</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleRemove} disabled={!hasRole(Role.Admin)}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>Remove from client</ListItemText>
        </MenuItem>
      </DropdownMenu>
    )
  }, [canAddPrices, handleRemove, hasRole])

  const handleEditPricesClose = useCallback(
    async (newPriceData: PricingRow[]) => {
      if (newPriceData) {
        setPricesChanged(true)
        enqueueSnackbar(`Successfully updated ${productData?.productName}`)
        await queryClient.invalidateQueries({
          predicate: (query: any) =>
            query.queryKey[0].includes('product pricing'),
        })
      }
      setEditing(false)
      setPricingHistory(false)
    },
    [enqueueSnackbar, productData, queryClient]
  )

  const getPageSubtitle = () => {
    return <>Client Pricing </>
  }

  const showViewPriceRows = productData?.prices.length || pricesChanges

  return (
    <Page
      breadcrumb
      isLoading={isLoading}
      title={productDisplayName}
      titleAction={renderTitleAction}
    >
      {isRemovingProduct && <Loader />}
      <>
        <Page.TitleSection sectionTitle={getPageSubtitle()} />
        {error && !productData && (
          <SectionError
            title="Error fetching product information"
            body="Please ensure client settings have been created and has at least one valid currency. If this problem persists, please contact engineering"
            sx={{ marginBottom: '24px' }}
          />
        )}
        <PricingTable
          priceData={showViewPriceRows ? prices : []}
          isLoading={isLoading}
        />
        {canAddPrices ? (
          <EditProduct
            product={{ ...productData!, prices }}
            open={editing}
            handleClose={handleEditPricesClose}
            allowSubscription={client?.allowHAAS}
          />
        ) : null}
        <PricingHistory
          product={{ ...productData! }}
          open={pricingHistory}
          handleClose={() => setPricingHistory(false)}
        />
      </>
    </Page>
  )
}

export default Product
