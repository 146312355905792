import React, { useState } from 'react'
import UploadStep from '@views/Home/Platform/BulkUpload/UploadStep'
import BulkUpload from '@views/Home/Platform/BulkUpload'
import { useMutation } from '@apollo/client'
import { RESEND_ACTIVATION_EMAIL } from '@firstbase/data/Person/mutations'
import {
  activatePersonOrResendActivationEmail as activatePersonOrResendActivationEmailResponse,
  activatePersonOrResendActivationEmailVariables,
} from '@firstbase/data/Person/__generated__/activatePersonOrResendActivationEmail'

const BulkSendActivationEmail = () => {
  const [data, setData] = useState<any>([])
  const [resendActivationEmail] = useMutation<
    activatePersonOrResendActivationEmailResponse,
    activatePersonOrResendActivationEmailVariables
  >(RESEND_ACTIVATION_EMAIL)
  const handleSendActivationEmail = (
    personId: string,
    logStatus: React.Dispatch<any>
  ) => {
    resendActivationEmail({
      variables: { personId },
    })
      .then((r) => {
        const response = r?.data?.activatePersonOrResendActivationEmail
        if (!response) {
          logStatus({
            type: 'error',
            message: 'Failed to reactivate email due to missing data.',
          })
          return
        }
        logStatus({
          type: 'success',
          message: `Successfully reactivated email for ID: ${response.id} with slug ${response.slug}`,
        })
      })
      .catch((error: any) => {
        logStatus({
          type: 'error',
          message: error.message,
        })
      })
  }

  const parseIds = (row: any[]) => {
    return row.map((dataRow: any) => {
      if (dataRow.id) {
        return dataRow.id
      }
      return {}
    })
  }

  return (
    <BulkUpload
      addMessage={`Resend ${data.length} email${data.length > 1 ? 's' : ''}`}
      handleCreateRow={handleSendActivationEmail}
      data={data}
      continueDisabled={!data}
      uploadTitle="Resend activation email"
    >
      <UploadStep
        dataParser={parseIds}
        setData={setData}
        uploadTitle="Bulk resend activation email"
        uploadSubtitle="To resend activation emails to users, create a CSV file with a column named 'id' containing the desired person IDs."
      />
    </BulkUpload>
  )
}
export default BulkSendActivationEmail
