import { gql } from '@apollo/client'

export const ORDER = gql`
  fragment ORDER on OrderResponse {
    id
    orderType
    orderStatus
    placedBy
    createdAt
    shippingAddress {
      id
      addressLine1
      addressLine2
      administrativeArea
      countryCode
      postalCode
      phoneNumber
      locality
    }
    products {
      id
      trackingLink
      inboundTrackingLink
      serialNumber
      status
      returnReason
      serialNumber
      inventory {
        id
        deployReason
        deployStatus
      }
      sku {
        vendor {
          code
        }
        category {
          skuType
        }
        skuInformation {
          productTitle
        }
      }
    }
  }
`
