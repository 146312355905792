import { GroupName, CreatePerson } from '@globalTypes'

import { helpers } from '../BulkUpload'

export const parseForMutation = (data: any): CreatePerson[] =>
  data.map((d: any) => {
    const person: any = {
      forename: d.forename,
      surname: d.surname,
      email: d.email,
    }

    if (d.secondary_email?.trim().length > 0) {
      person.secondaryEmail = d.secondary_email
    }

    if (d.package_slug?.trim().length > 0) {
      person.packageSlugs = [d.package_slug]
    }

    if (helpers.isStringTrue(d.send_activation_email)) {
      person.groups = [GroupName.APP_USER]
    }

    return {
      ...person,
      address: {
        addressLine1: d.address_line_1,
        addressLine2: d.addressLine2,
        administrativeArea: d['state/region'],
        countryCode: d.country_code,
        locality: d.city_code,
        postalCode: d.postal_code,
        phoneNumber: d.phone_number,
      },
    }
  })
