import React, { useState } from 'react'

import Page from '@firstbase/components/atoms/Page'
import Table from '@firstbase/components/atoms/Table'

import useTableGraphqlQuery from '@firstbase/hooks/useTableGraphqlQuery'
import SearchBox from '@firstbase/components/atoms/SearchBox'
import { OFFICES_COLUMNS } from '@firstbase/constants/officeColumns'
import { GET_ALL_OFFICES } from '@firstbase/data/Office/queries'
import {
  getAllOffices,
  getAllOfficesVariables,
} from '@firstbase/data/Office/__generated__/getAllOffices'

const Offices = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const query = useTableGraphqlQuery<getAllOffices, getAllOfficesVariables>(
    GET_ALL_OFFICES,
    { variables: { officeFilter: searchTerm } as getAllOfficesVariables },
    ({ getAllOffices: officesData }) => officesData
  )

  return (
    <>
      <Page.TitleSection
        sectionTitle="Offices"
        sectionSubtitle="List of all offices for all organizations"
      />
      <SearchBox
        placeholder="Search by office name or organization"
        disabled={(query.isLoading && !query.isPreviousData) || !!query.error}
        onChange={setSearchTerm}
        label="Search offices"
      />
      <Table
        defaultSortId="name"
        query={{ ...query, variables: { searchTerm } }}
        tableId="offices"
        columns={OFFICES_COLUMNS()}
        pagination
        customRowsPerPageOptions={[100, 500, 5000]}
      />
    </>
  )
}

export default Offices
