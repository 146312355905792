import React from 'react'
import {
  TextField,
  InputAdornment,
  StandardTextFieldProps,
} from '@mui/material'

interface OwnProps extends Omit<StandardTextFieldProps, 'onChange'> {
  onChange: (value: number | null) => void
}

export const PercentageInput = ({ onChange, ...props }: OwnProps) => (
  <TextField
    {...props}
    sx={{
      ...(props.sx || {}),
      '& input[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
        {
          '-webkit-appearance': 'none',
          margin: 0,
        },
    }}
    onChange={(e) => {
      const inputValue = e.target.value.replace('-', '')
      onChange(inputValue ? parseFloat(inputValue) : null)
    }}
    InputProps={{
      endAdornment: <InputAdornment position="end">%</InputAdornment>,
    }}
    variant="outlined"
    type="number"
  />
)
