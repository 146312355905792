import { GET_ORGANIZATION_BY_ID } from '@firstbase/data/Organization/queries'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import AddProduct from '../Product/AddProduct/AddProduct'
import ProductsTable from './ProductsTable'
import { useQuery as useGQLQuery } from '@apollo/client'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import Page from '@firstbase/components/atoms/Page'
import { useQueryClient } from 'react-query'
import { Role } from '@firstbase/context/auth/AuthProvider'
import ProtectedChildren from '@firstbase/components/atoms/ProtectedChildren'
import { ClientPageParams } from '@firstbase/types/Pages'

function ClientProducts() {
  const { clientId }: ClientPageParams = useParams()
  const queryClient = useQueryClient()
  // number so that each new add product modal is a force mounted new component
  const [addProductOpen, setAddProductOpen] = useState<number>(0)
  const { data: { getOrganizationById: client } = {} } = useGQLQuery(
    GET_ORGANIZATION_BY_ID,
    {
      variables: { orgId: clientId },
    }
  )

  const renderTitleAction = () => (
    <ProtectedChildren hasRole={Role.Admin}>
      <Button
        variant="contained"
        onClick={() => setAddProductOpen(Math.random() + 1)}
      >
        <AddIcon /> Add Product
      </Button>
    </ProtectedChildren>
  )

  const handleClose: (flushData?: boolean) => void = async (flushData) => {
    if (flushData) {
      await queryClient.invalidateQueries({
        predicate: (query: any) =>
          query.queryKey[0].includes('products table') &&
          query.queryKey[1].clientId === clientId,
      })
    }
    setAddProductOpen(0)
  }

  return (
    <>
      <Page.TitleSection
        sectionTitle="Client Catalog"
        sectionSubtitle={`List of products that are available for ${client?.name} on platform`}
        subtitleAction={renderTitleAction}
      />
      <ProductsTable />
      <AddProduct
        open={!!addProductOpen}
        key={addProductOpen}
        handleClose={handleClose}
        clientId={clientId}
        clientName={client?.name}
      />
    </>
  )
}

export default ClientProducts
