import { SkuMetadataFieldName } from '@globalTypes'

export const formatMetadataValue = (
  fieldName: SkuMetadataFieldName,
  value: string
): string => {
  if (value === 'N/A') {
    return ''
  }
  switch (fieldName) {
    case SkuMetadataFieldName.GENERATION:
      return `(${value})`
    case SkuMetadataFieldName.SCREEN_SIZE:
      return `${value}-inch`
    case SkuMetadataFieldName.RAM_CAPACITY:
      return `${value} RAM`
    case SkuMetadataFieldName.SSD_CAPACITY:
      return `${value} SSD`
    case SkuMetadataFieldName.CPU_CORES:
      return `${value}-Core CPU`
    case SkuMetadataFieldName.GPU_CORES:
      return `${value}-Core GPU`
    case SkuMetadataFieldName.POWER_PLUG_TYPE:
      return `${value} Plug`
    case SkuMetadataFieldName.TOUCH_SCREEN:
      return value === 'TRUE' ? 'Touch Screen' : ''
    default:
      return value
  }
}
