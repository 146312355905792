import React from 'react'
import { Box, Typography } from '@mui/material'

import { RetrievalAddress as RetrievalAddressType } from './types'

interface OwnProps {
  address: RetrievalAddressType
  personName: string
}

const RetrievalAddress = ({ address, personName }: OwnProps) => {
  const {
    addressLine1,
    addressLine2,
    administrativeArea,
    postalCode,
    countryCode,
    locality,
  } = address || {}

  return (
    <Box p={2} border="1px solid" borderColor="grey.500" borderRadius="0.5rem">
      <Typography fontWeight="bold" marginBottom={1}>
        Return Address
      </Typography>
      <Typography variant="body2">{personName}</Typography>
      <Typography variant="body2">{`${addressLine1}`}</Typography>
      {addressLine2 && (
        <Typography variant="body2">{`${addressLine2}`}</Typography>
      )}
      <Typography variant="body2">{`${locality}, ${
        administrativeArea ? administrativeArea + ', ' : ''
      } ${postalCode}`}</Typography>
      <Typography variant="body2">{countryCode}</Typography>
    </Box>
  )
}

export default RetrievalAddress
