import { gql } from '@apollo/client'

export const CREATE_CONTRACT = gql`
  mutation createContract($params: CreateContractParams!) {
    createContract(params: $params) {
      contract {
        id
      }
    }
  }
`
