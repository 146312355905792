import { gql } from '@apollo/client'

export const ORGANIZATION_CATEGORY_BASICS = gql`
  fragment OrganizationCategoryBasics on OrganizationCategory {
    id
    gift
    category {
      id
      nameSingular
      code
    }
  }
`
