import { Chip, ChipProps, Skeleton } from '@mui/material'
import React, { useMemo } from 'react'
import {
  OrganizationStatus,
  OrderStatus,
  ReplacementStatus,
  RetrievalMethodStatus,
  ProductTierStatus,
} from '@globalTypes'

import colorFunctions, { Getter } from './getColors'
import { sentenceCase } from 'change-case'

type OwnProps = {
  type: 'organization' | 'order' | 'retrieval' | 'productTier'
  status?:
    | OrganizationStatus
    | OrderStatus
    | ReplacementStatus
    | RetrievalMethodStatus
    | ProductTierStatus
    | null
  isLoading?: boolean
  size?: 'small' | 'medium'
}

const StatusChip = ({
  type = 'organization',
  status,
  isLoading,
  size = 'small',
}: OwnProps) => {
  const statusLabel = status?.toLowerCase() || ''
  const color = useMemo(() => {
    if (!status) return 'secondary'

    const colorGetter: Getter = colorFunctions[type]

    return colorGetter(status) || 'secondary'
  }, [status, type])

  if (!status && isLoading) return <Skeleton width={50} />

  return (
    <Chip
      // although chip color prop allows for other colors to be used
      // TS cannot find support for other colors
      // need to case here to avoid issue in TS
      color={color as ChipProps['color']}
      label={sentenceCase(statusLabel || 'Unknown')}
      size={size}
    />
  )
}

export default StatusChip
