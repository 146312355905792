import FLAGS from '@firstbase/constants/featureFlags'
import useLocalStorage from '@firstbase/hooks/useLocalStorage/useLocalStorage'
import { isAfter, isBefore } from 'date-fns'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useEffect, useState } from 'react'

import JSConfetti from 'js-confetti'
import { Dialog, DialogTitle, Grow } from '@mui/material'

const jsConfetti = new JSConfetti()

const NEW_YEAR_EMOJIS = ['🥳', '🎆', '🥂']

const asyncConfetti = (confettiTrigger: () => void, delay: number) =>
  new Promise((res) =>
    setTimeout(() => {
      confettiTrigger()
      res('')
    }, delay)
  )

export const confettiSequence = async (
  emojis: string[],
  triggerMessage: (newShowState: boolean) => void
) => {
  jsConfetti.addConfetti({
    emojis,
    emojiSize: 150,
  })
  jsConfetti.addConfetti({
    confettiColors: ['#F82EA3'],
  })

  await asyncConfetti(() => {
    jsConfetti.addConfetti({
      emojis,
      emojiSize: 150,
    })
    jsConfetti.addConfetti({
      confettiColors: ['#F82EA3'],
      confettiNumber: 500,
    })
    jsConfetti.addConfetti({
      confettiColors: ['#F82EA3'],
    })
  }, 1000)

  await asyncConfetti(() => {
    jsConfetti.addConfetti({
      emojis,
      confettiNumber: 250,
    })
  }, 1700)

  await asyncConfetti(() => {
    jsConfetti.addConfetti({
      emojis,
      emojiSize: 150,
    })
    jsConfetti.addConfetti({
      confettiColors: ['#F82EA3'],
    })
    jsConfetti.addConfetti({
      emojis,
    })
  }, 200)

  triggerMessage(true)

  await asyncConfetti(() => {
    jsConfetti.addConfetti({
      emojis,
      confettiNumber: 20,
      emojiSize: 300,
    })
    jsConfetti.addConfetti({
      confettiColors: ['#F82EA3'],
      confettiNumber: 240,
    })
    jsConfetti.addConfetti({
      emojis,
      emojiSize: 75,
    })
  }, 350)

  return Promise.resolve()
}

const NewYear = () => {
  const flags = useFlags()
  const [showMessage, setShowMessage] = useState(false)
  const [hasSeenNY, setHasSeenNY] = useLocalStorage(
    'firstbase_hasSeenNY_2022',
    false
  )

  const triggerNYCelebration = () => {
    confettiSequence(NEW_YEAR_EMOJIS, setShowMessage)
  }

  useEffect(() => {
    if (
      flags[FLAGS.SE_3028_CHRISTMAS] &&
      isBefore(Date.now(), new Date('2023-01-07')) &&
      isAfter(Date.now(), new Date('2022-12-30')) &&
      !hasSeenNY
    ) {
      setHasSeenNY(true)
      setTimeout(triggerNYCelebration, 1000)
    }
  }, [flags, hasSeenNY, setHasSeenNY])

  return (
    <Dialog
      TransitionComponent={Grow}
      transitionDuration={{ enter: 1000, exit: 200 }}
      open={showMessage}
      onClose={() => setShowMessage(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle
        variant="h2"
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        Happy 2023
      </DialogTitle>
    </Dialog>
  )
}

export default React.memo(NewYear)
