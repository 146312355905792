import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Chip,
  Stack,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

interface OwnProps {
  errors: string[]
}

const ErrorMessagesAccordion = ({ errors }: OwnProps) => (
  <Accordion sx={{ borderRadius: 2 }}>
    <AccordionSummary
      expandIcon={<ExpandMore />}
      aria-controls="errors-content"
      id="errors-header"
    >
      <Stack direction="row" alignItems="center">
        Errors{' '}
        <Chip color="error" sx={{ marginLeft: 1 }} label={errors.length} />{' '}
      </Stack>
    </AccordionSummary>
    <AccordionDetails>
      {errors.map((e: string, i: number) => (
        <Alert sx={{ marginTop: 1 }} key={`error-${i}`} severity="error">
          {e}
        </Alert>
      ))}
    </AccordionDetails>
  </Accordion>
)

export default ErrorMessagesAccordion
