import React from 'react'
import { Box, Typography } from '@mui/material'

type OwnProps = {
  productName: string
  organizationName: string
}

const ProductSettingsTitleSection = ({
  productName,
  organizationName,
}: OwnProps) => (
  <Box
    sx={{
      marginBottom: 1.5,
    }}
  >
    <Typography variant="h4" role="header">
      Supply Settings
    </Typography>
    <Typography variant="body1">
      Set rules for how {productName} will be supplied for {organizationName}
    </Typography>
  </Box>
)

export default ProductSettingsTitleSection
