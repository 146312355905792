import React, { useEffect, useState } from 'react'

import { ColumnI } from '@firstbase/components/atoms/Table/types'

import Table from '@atoms/Table'
import { Box, Button } from '@mui/material'
import { useMutation, useQuery } from '@apollo/client'
import {
  DELETE_API_TOKEN_FOR_ORGANIZATION,
  GET_ALL_API_TOKENS_FOR_ORGANIZATION,
} from '@firstbase/data/ApiToken/queries'
import {
  getAllApiTokensForOrganization,
  getAllApiTokensForOrganizationVariables,
  getAllApiTokensForOrganization_getAllTokensForOrganization,
} from '@firstbase/data/ApiToken/__generated__/getAllApiTokensForOrganization'
import { isBefore, parseISO } from 'date-fns'
import { Role, useAuthState } from '@firstbase/context/auth/AuthProvider'
import {
  deleteApiTokenForOrg,
  deleteApiTokenForOrgVariables,
} from '@firstbase/data/ApiToken/__generated__/deleteApiTokenForOrg'
import { useSnackbar } from 'notistack'
import ConfirmModal from '@firstbase/components/molecules/ConfirmModal/ConfirmModal'

type OwnProps = {
  clientId: string
}

const ClientApiTokensTable = ({ clientId }: OwnProps) => {
  const authState = useAuthState()
  const { enqueueSnackbar } = useSnackbar()
  const [selectedToken, setSelectedToken] = useState<string>('')
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState<boolean>(false)
  const [sortedTokens, setsortedTokens] = useState<
    | (getAllApiTokensForOrganization_getAllTokensForOrganization | null)[]
    | null
    | undefined
  >(null)
  const {
    data: tokens,
    loading: tokensLoading,
    error: tokensError,
  } = useQuery<
    getAllApiTokensForOrganization,
    getAllApiTokensForOrganizationVariables
  >(GET_ALL_API_TOKENS_FOR_ORGANIZATION, {
    variables: {
      organizationId: clientId,
    },
  })

  const [deleteTokenMutation, { loading: actionLoading }] = useMutation<
    deleteApiTokenForOrg,
    deleteApiTokenForOrgVariables
  >(DELETE_API_TOKEN_FOR_ORGANIZATION, {
    refetchQueries: ['getAllApiTokensForOrganization'],
  })

  const resetSelectedToken = () => {
    setSelectedToken('')
    setConfirmModalIsOpen(false)
  }

  const deleteToken = async (tokenName: string) => {
    try {
      await deleteTokenMutation({
        variables: {
          organizationId: clientId,
          tokenName,
        },
      })
      enqueueSnackbar(`Successfully deleted token ${tokenName}`)
    } catch (e) {
      const error = e as Error
      enqueueSnackbar(`Failed to delete token ${tokenName}: ${error.message}`, {
        variant: 'error',
      })
    } finally {
      resetSelectedToken()
    }
  }

  const columns = () => {
    return [
      {
        header: 'Name',
        id: 'name',
        cell: {
          value: ({
            name,
          }: getAllApiTokensForOrganization_getAllTokensForOrganization) =>
            name,
        },
      },
      {
        header: 'Expires',
        id: 'expiration',
        cell: {
          value: ({
            expiration,
          }: getAllApiTokensForOrganization_getAllTokensForOrganization) => {
            const isExpired =
              expiration && isBefore(new Date(expiration), new Date())
            return (
              (expiration
                ? new Date(expiration).toLocaleDateString()
                : 'Never') + (isExpired ? ' (expired)' : '')
            )
          },
        },
      },
      {
        header: 'Creation date',
        id: 'created',
        cell: {
          value: ({
            created,
          }: getAllApiTokensForOrganization_getAllTokensForOrganization) =>
            new Date(created).toLocaleDateString(),
        },
      },
      {
        header: 'Created by Firstbase',
        id: 'createdByFirstbase',
        cell: {
          value: ({
            createdByFirstbase,
          }: getAllApiTokensForOrganization_getAllTokensForOrganization) =>
            createdByFirstbase ? 'Yes' : 'No',
        },
      },
      ...(authState.hasRole([Role.CX, Role.Admin])
        ? [
            {
              header: 'Action',
              id: 'delete',
              cell: {
                value: ({
                  name,
                  createdByFirstbase,
                }: getAllApiTokensForOrganization_getAllTokensForOrganization) => (
                  <Button
                    onClick={() => {
                      setSelectedToken(name)
                      setConfirmModalIsOpen(true)
                    }}
                    disabled={!createdByFirstbase || actionLoading}
                  >
                    Delete
                  </Button>
                ),
              },
            },
          ]
        : []),
    ] as ColumnI[]
  }

  useEffect(() => {
    setsortedTokens(
      [...(tokens?.getAllTokensForOrganization || [])].sort((a, b) => {
        const dateA = parseISO(a?.created)
        const dateB = parseISO(b?.created)
        return isBefore(dateB, dateA) ? -1 : 1
      })
    )
  }, [tokens])

  return (
    <Box sx={{ pt: 1 }}>
      <ConfirmModal
        titleText={`Are you sure you want to delete the token '${selectedToken}' ?`}
        open={confirmModalIsOpen}
        maxWidth={600}
        onConfirm={() => {
          deleteToken(selectedToken)
        }}
        onCancel={resetSelectedToken}
        onClose={resetSelectedToken}
      />
      <Table
        noDataMessage="No Api token exists for this organization"
        tableId={`org=${clientId}-api-tokens`}
        query={{
          isLoading: tokensLoading,
          error: tokensError,
          data: {
            data: sortedTokens || [],
          },
        }}
        dataKey="name"
        columns={columns()}
      />
    </Box>
  )
}

export default ClientApiTokensTable
