import React from 'react'

import SupplierCheckbox from './SupplierCheckbox'

interface OwnProps {
  checked: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void | undefined
  disabled?: boolean
}

const FirstbaseSuppliedCheckbox = ({
  checked,
  onChange,
  disabled = false,
}: OwnProps) => (
  <SupplierCheckbox
    title="Firstbase-supplied"
    description="Set pricing for Firstbase to procure this inventory for the customer"
    checked={checked}
    onChange={onChange}
    disabled={disabled}
    inputProps={{
      // @ts-ignore
      'data-testid': 'firstbase-supplied-checkbox',
    }}
  />
)

export default FirstbaseSuppliedCheckbox
