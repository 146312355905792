import { useLazyQuery } from '@apollo/client'
import StatusChip from '@firstbase/components/atoms/StatusChip'
import Table from '@firstbase/components/atoms/Table'
import TextSkeleton from '@firstbase/components/atoms/TextSkeleton/TextSkeleton'
import { GET_PERSON_BY_ORGANIZATION_SLUG_AND_PERSON_SLUG } from '@firstbase/data/Person/queries'
import { Box, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { sentenceCase } from 'change-case'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  getOrderByIdForOrganisation_getOrderByIdForOrganisation,
  getOrderByIdForOrganisation_getOrderByIdForOrganisation_person,
} from '@firstbase/data/Order/__generated__/getOrderByIdForOrganisation'
import { getPersonBySlugAndOrganizationSlug } from '@firstbase/data/Person/__generated__/getPersonBySlugAndOrganizationSlug'
import { ORDER_COLUMNS, RETURN_COLUMNS } from './orderDetailColumns'
import { getOrganizationById } from '@firstbase/data/Organization/__generated__/getOrganizationById'
import { getShippingAddress } from './detailUtils'
import { OrderPageParams } from '@firstbase/types/Pages'
import FLAGS from '@firstbase/constants/featureFlags'
import ReturnRetrievals from './ReturnRetrievals/ReturnRetrievals'

type OwnProps = {
  organization: getOrganizationById
  order: getOrderByIdForOrganisation_getOrderByIdForOrganisation
}

const OrderReturnDetail = ({
  organization: orgData,
  order: data,
}: OwnProps) => {
  const [placedBy, setPlacedBy] =
    useState<getOrderByIdForOrganisation_getOrderByIdForOrganisation_person>()
  const { personSlug }: OrderPageParams = useParams()
  const [queryPerson] = useLazyQuery<getPersonBySlugAndOrganizationSlug>(
    GET_PERSON_BY_ORGANIZATION_SLUG_AND_PERSON_SLUG
  )

  const flags = useFlags()

  // get and set the user who placed the order
  useEffect(() => {
    if (data && !placedBy) {
      if (!data.placedBy) {
        return setPlacedBy(data.person)
      }

      if (orgData) {
        queryPerson({
          variables: {
            slug: data.placedBy,
            organizationSlug: orgData.getOrganizationById.slug,
          },
        }).then(({ data: person }) =>
          setPlacedBy(person?.getPersonBySlugAndOrganizationSlug!)
        )
      }
    }
  }, [
    data,
    data?.person.forename,
    data?.person.surname,
    data?.placedBy,
    orgData,
    personSlug,
    placedBy,
    queryPerson,
  ])

  const isReturn = data?.orderType === 'RETURN'
  const columns = isReturn ? RETURN_COLUMNS : ORDER_COLUMNS

  const showRetrievalsData =
    flags[FLAGS.SE_3854_SHOW_CONFIRMED_RETRIEVALS] && isReturn

  return (
    <>
      <Stack sx={{ mb: 2, gap: 0.5 }}>
        <TextSkeleton>
          {sentenceCase(data?.orderType || '')} for {data?.person.forename}{' '}
          {data?.person.surname} with {orgData?.getOrganizationById.name}
        </TextSkeleton>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography fontWeight="bold">Status</Typography>
          <StatusChip type="order" status={data?.orderStatus} />
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography fontWeight="bold">
            {isReturn ? 'Return Order ID:' : 'Order ID:'}
          </Typography>
          <TextSkeleton>{data?.id}</TextSkeleton>
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography fontWeight="bold">
            {isReturn ? 'Return Address:' : 'Shipping Address:'}
          </Typography>
          <TextSkeleton>{getShippingAddress(data)}</TextSkeleton>
        </Box>
      </Stack>
      {showRetrievalsData && (
        <Box sx={{ marginBottom: 2 }}>
          <ReturnRetrievals returnOrderId={data.id} />
        </Box>
      )}
      <Stack sx={{ mb: 2, gap: 0.5 }}>
        {showRetrievalsData && (
          <Typography fontWeight="bold" variant="h6">
            Products
          </Typography>
        )}
        <Table
          tableId={`${isReturn ? 'return' : 'order'}-detail-table`}
          columns={columns}
          query={{
            data: { data: data?.products || [] },
          }}
        />
      </Stack>
    </>
  )
}

export default OrderReturnDetail
