import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

type Breadcrumb = {
  label?: string
  pathId?: string
  searchParams?: Record<string, string>
  isLoading?: boolean
}

interface BreadcrumbContextType {
  breadcrumbs: Breadcrumb[]
  setBreadcrumbs: Dispatch<SetStateAction<Breadcrumb[]>>
}

export const BreadcrumbContext = React.createContext<BreadcrumbContextType>({
  breadcrumbs: [],
  setBreadcrumbs: () => null,
})

export const useBreadcrumbs = () => {
  const context = React.useContext(BreadcrumbContext)
  const { setBreadcrumbs } = context

  // clear breadcrumbs on un-mount
  useEffect(() => {
    return () => setBreadcrumbs([])
  }, [setBreadcrumbs])

  return context
}

const BreadcrumbProvider = ({ children }: any) => {
  const [breadcrumbs, setBreadcrumbs] = useState<never[] | Breadcrumb[]>([])

  const value = { breadcrumbs, setBreadcrumbs }
  return (
    <BreadcrumbContext.Provider value={value}>
      {children}
    </BreadcrumbContext.Provider>
  )
}

export default BreadcrumbProvider
