import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
} from '@mui/material'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import Page from '@firstbase/components/atoms/Page'
import { CREATE_RETRIEVAL_METHOD } from '@firstbase/data/Retrievals/mutations'
import {
  createRetrievalMethod,
  createRetrievalMethodVariables,
} from '@firstbase/data/Retrievals/__generated__/createRetrievalMethod'
import { getOrderByIdForOrganisation_getOrderByIdForOrganisation } from '@firstbase/data/Order/__generated__/getOrderByIdForOrganisation'
import { OrderPageParams } from '@firstbase/types/Pages'
import Loader from '@firstbase/components/atoms/Loader'
import { RetrievalMethodMaterialType } from '@globalTypes'

import FurnitureSection from './FurnitureSection'
import EquipmentSection from './EquipmentSection'
import RetrievalAddress from './RetrievalAddress'
import {
  RetrievalAddress as RetrievalAddressType,
  RetrievalMethodOptionValues,
  RetrievalMethods,
} from './types'
import useOrderData from '../../hooks/useOrderData'
import useEquipmentFurnitureTableQueries from '../../hooks/useEquipmentFurnitureTableQueries'
import { Product } from '../types'

interface OwnProps {
  closeModal: (closingPostSubmit?: boolean) => void
  orderRefetchQueries: string[]
  personName: string
  shippingAddress: RetrievalAddressType
}

const ConfirmRetrievalMethodsModal = ({
  closeModal,
  orderRefetchQueries,
  personName,
  shippingAddress,
}: OwnProps) => {
  const { enqueueSnackbar } = useSnackbar()

  /**
   * Refetch the order data and parse it again, even though the parent has it.
   * This is done, since before being able to confirm the order, the return order
   * is updated.  During this process, new items (i.e. legacy inventory item) may
   * have been added or existing return items updated.  In order to get the most up
   * to date list of return order items, refetch here.  This seemed to be the simplest
   * way, at the time of writing; feel free to refactor if you see an alternative way.
   */
  const { orderId, clientId } = useParams<OrderPageParams>()
  const { orderData, loading: loadingOrderData } = useOrderData(
    orderId,
    clientId
  )

  const doesOrderDataContainProducts = (
    order: any
  ): order is getOrderByIdForOrganisation_getOrderByIdForOrganisation =>
    'products' in order
  // Used to encapsulate the handling of the undefined use case for order data
  const getOrderDataProducts = (): Product[] => {
    if (!orderData) return []

    return !!orderData && doesOrderDataContainProducts(orderData)
      ? (orderData.products as Product[])
      : (orderData?.returnOrder.products as Product[])
  }

  const { equipmentTableQuery, furnitureTableQuery } =
    useEquipmentFurnitureTableQueries(getOrderDataProducts())

  const [equipmentComment, setEquipmentComment] = useState('')
  const [furnitureComment, setFurnitureComment] = useState('')
  const [equipmentRetrievalMethod, setEquipmentRetrievalMethod] =
    useState<RetrievalMethodOptionValues | null>(null)

  const [createRetrieval, { loading: loadingCreateRetrieval }] = useMutation<
    createRetrievalMethod,
    createRetrievalMethodVariables
  >(CREATE_RETRIEVAL_METHOD)

  const equipmentExists = !!equipmentTableQuery.data?.data.length
  const furnitureExists = !!furnitureTableQuery.data?.data.length
  const isCountryCodeGb = shippingAddress?.countryCode === 'GB'

  const getRetrievalTypeForEquipment = (): RetrievalMethods => {
    if (isCountryCodeGb) return 'RETURN_KIT'

    switch (equipmentRetrievalMethod) {
      case RetrievalMethodOptionValues.INBOUND_DIGITAL:
        return 'INBOUND'
      case RetrievalMethodOptionValues.INBOUND_PHYSICAL:
        return 'INBOUND'
      case RetrievalMethodOptionValues.RETURN_KIT:
        return 'RETURN_KIT'
      default:
        return 'PL'
    }
  }

  const getRetrievalInboundType = () => {
    if (
      equipmentRetrievalMethod === RetrievalMethodOptionValues.INBOUND_DIGITAL
    )
      return RetrievalMethodMaterialType.DIGITAL
    if (
      equipmentRetrievalMethod === RetrievalMethodOptionValues.INBOUND_PHYSICAL
    )
      return RetrievalMethodMaterialType.PHYSICAL

    return undefined
  }

  const handleSubmit = async () => {
    try {
      const refetchQueries = [
        'getRetrievalsByReturnOrderId',
        ...orderRefetchQueries,
      ]

      if (equipmentExists)
        await createRetrieval({
          variables: {
            retrievalMethod: {
              returnOrderItemIds: equipmentTableQuery?.data
                ? equipmentTableQuery.data.data.map(
                    (item: Product) => item.id || ''
                  )
                : [],
              retrievalType: getRetrievalTypeForEquipment(),
              description: equipmentComment,
              type: getRetrievalInboundType(),
            },
          },
          refetchQueries: !furnitureExists ? refetchQueries : undefined,
        })

      if (furnitureExists) {
        const furnitureRetrievalType: RetrievalMethods = 'PL'
        await createRetrieval({
          variables: {
            retrievalMethod: {
              returnOrderItemIds: furnitureTableQuery?.data
                ? furnitureTableQuery.data.data.map(
                    (item: Product) => item.id || ''
                  )
                : [],
              retrievalType: furnitureRetrievalType,
              description: furnitureComment,
            },
          },
          refetchQueries: refetchQueries,
        })
      }

      enqueueSnackbar(
        'You have successfully updated and confirmed the return order'
      )

      closeModal(true)
    } catch (error) {
      enqueueSnackbar(
        'Failed to confirm the return order!  Please try again or reach out to the team for help.'
      )
    }
  }

  const renderEquipmentSection = () => {
    if (!equipmentExists) return null

    return (
      <EquipmentSection
        comment={equipmentComment}
        equipment={equipmentTableQuery}
        isCountryGb={isCountryCodeGb}
        method={equipmentRetrievalMethod}
        onChangeMethod={(event) =>
          setEquipmentRetrievalMethod(
            event.target.value as RetrievalMethodOptionValues
          )
        }
        onChangeComment={(event) => setEquipmentComment(event.target.value)}
      />
    )
  }

  const renderFurnitureSection = () => {
    if (!furnitureExists) return null

    return (
      <FurnitureSection
        furniture={furnitureTableQuery}
        onChangeComment={(event) => setFurnitureComment(event.target.value)}
        comment={furnitureComment}
      />
    )
  }

  if (loadingOrderData) return <Loader />

  return (
    <Dialog onClose={() => closeModal()} open maxWidth="md">
      <DialogTitle>
        <Page.TitleSection
          sectionTitle="Are you sure you want to confirm this return order?"
          sectionSubtitle="Confirming a return order notifies the Ops team that this return is ready to be processed"
        />
      </DialogTitle>
      <DialogContent>
        <RetrievalAddress address={shippingAddress} personName={personName} />
        {renderEquipmentSection()}
        {renderFurnitureSection()}
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => closeModal()}
          disabled={loadingCreateRetrieval}
          color="secondary"
        >
          No, Go Back
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={
            (equipmentExists &&
              !isCountryCodeGb &&
              !equipmentRetrievalMethod) ||
            loadingCreateRetrieval
          }
        >
          Yes, Confirm Return
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmRetrievalMethodsModal
