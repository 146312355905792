import { AppBar, IconButton, Toolbar, Typography } from '@mui/material'
import React from 'react'

import { ReactComponent as BackIcon } from '@firstbase/assets/Back.svg'
import { TopBarProps } from './types'

function TopBar({ handleBack, title }: TopBarProps) {
  return (
    <AppBar
      sx={{
        backgroundColor: '#ffffff',
      }}
      position="fixed"
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleBack}
          aria-label="back"
        >
          <BackIcon />
        </IconButton>
        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
