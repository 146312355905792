import { gql } from '@apollo/client'

export const GET_ALL_PRODUCT_TIERS = gql`
  query getAllProductTiers(
    $pagingAndSorting: PagingAndSorting!
    $filter: ProductTierFilter
  ) {
    getAllProductTiers(pagingAndSorting: $pagingAndSorting, filter: $filter) {
      productTiers {
        totalElements
        data {
          id
          name
          status
          description
          features {
            id
          }
        }
      }
    }
  }
`
